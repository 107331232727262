import "~/app/config/effector";

import "~/app/config/i18n";
import "./src/shared/ui/web/main.css";
import "@sign/uikit/style.css";
import "@sign/meetspace/style.css";

import * as Sentry from "@sentry/react";
import React from "react";
import { AppRegistry } from "react-native";
import BuildConfig from "react-native-build-config";

import App from "./App";
import app from "./app.json";

if (!process.env.DEV) {
  Sentry.init({
    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({ spaceId: event.event_id });
      }
      return event;
    },

    dsn: process.env.VITE_SENTRY_DSN,

    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1,

    // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1,
    // Performance Monitoring
    tracesSampleRate: 1,
  });
}

AppRegistry.registerComponent(app.name, () => App);

AppRegistry.runApplication(app.name, {
  rootTag: document.querySelector("#root"),
});
