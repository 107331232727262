var he = Object.defineProperty;
var ve = (e, n, o) => n in e ? he(e, n, { enumerable: !0, configurable: !0, writable: !0, value: o }) : e[n] = o;
var Y = (e, n, o) => (ve(e, typeof n != "symbol" ? n + "" : n, o), o);
import { ApolloError as Se, ApolloLink as Z, fromPromise as le, ApolloClient as be, InMemoryCache as Fe } from "@apollo/client";
import { loadDevMessages as we, loadErrorMessages as Te } from "@apollo/client/dev";
import { setContext as xe } from "@apollo/client/link/context";
import { onError as Ee } from "@apollo/client/link/error";
import { GraphQLWsLink as _e } from "@apollo/client/link/subscriptions";
import { initialized as Re, maybeData as $e } from "@dmitry.olyenyov/remote-data";
import { createStore as O, createEvent as C, createEffect as ee, combine as Ie, sample as Ae } from "effector";
import { t as me } from "i18next";
import { jwtDecode as De } from "jwt-decode";
import "effector-react";
import "effector-storage/local";
import { persist as Pe } from "effector-storage/session";
function v(e) {
  return e === null ? "null" : Array.isArray(e) ? "array" : typeof e;
}
function I(e) {
  return v(e) === "object";
}
function Ue(e) {
  return Array.isArray(e) && // must be at least one error
  e.length > 0 && // error has at least a message
  e.every((n) => "message" in n);
}
function ue(e, n) {
  return e.length < 124 ? e : n;
}
const Le = "graphql-transport-ws";
var w;
(function(e) {
  e[e.InternalServerError = 4500] = "InternalServerError", e[e.InternalClientError = 4005] = "InternalClientError", e[e.BadRequest = 4400] = "BadRequest", e[e.BadResponse = 4004] = "BadResponse", e[e.Unauthorized = 4401] = "Unauthorized", e[e.Forbidden = 4403] = "Forbidden", e[e.SubprotocolNotAcceptable = 4406] = "SubprotocolNotAcceptable", e[e.ConnectionInitialisationTimeout = 4408] = "ConnectionInitialisationTimeout", e[e.ConnectionAcknowledgementTimeout = 4504] = "ConnectionAcknowledgementTimeout", e[e.SubscriberAlreadyExists = 4409] = "SubscriberAlreadyExists", e[e.TooManyInitialisationRequests = 4429] = "TooManyInitialisationRequests";
})(w || (w = {}));
var c;
(function(e) {
  e.ConnectionInit = "connection_init", e.ConnectionAck = "connection_ack", e.Ping = "ping", e.Pong = "pong", e.Subscribe = "subscribe", e.Next = "next", e.Error = "error", e.Complete = "complete";
})(c || (c = {}));
function ke(e) {
  if (!I(e))
    throw new Error(`Message is expected to be an object, but got ${v(e)}`);
  if (!e.type)
    throw new Error("Message is missing the 'type' property");
  if (typeof e.type != "string")
    throw new Error(`Message is expects the 'type' property to be a string, but got ${v(e.type)}`);
  switch (e.type) {
    case c.ConnectionInit:
    case c.ConnectionAck:
    case c.Ping:
    case c.Pong: {
      if (e.payload != null && !I(e.payload))
        throw new Error(`"${e.type}" message expects the 'payload' property to be an object or nullish or missing, but got "${e.payload}"`);
      break;
    }
    case c.Subscribe: {
      if (typeof e.id != "string")
        throw new Error(`"${e.type}" message expects the 'id' property to be a string, but got ${v(e.id)}`);
      if (!e.id)
        throw new Error(`"${e.type}" message requires a non-empty 'id' property`);
      if (!I(e.payload))
        throw new Error(`"${e.type}" message expects the 'payload' property to be an object, but got ${v(e.payload)}`);
      if (typeof e.payload.query != "string")
        throw new Error(`"${e.type}" message payload expects the 'query' property to be a string, but got ${v(e.payload.query)}`);
      if (e.payload.variables != null && !I(e.payload.variables))
        throw new Error(`"${e.type}" message payload expects the 'variables' property to be a an object or nullish or missing, but got ${v(e.payload.variables)}`);
      if (e.payload.operationName != null && v(e.payload.operationName) !== "string")
        throw new Error(`"${e.type}" message payload expects the 'operationName' property to be a string or nullish or missing, but got ${v(e.payload.operationName)}`);
      if (e.payload.extensions != null && !I(e.payload.extensions))
        throw new Error(`"${e.type}" message payload expects the 'extensions' property to be a an object or nullish or missing, but got ${v(e.payload.extensions)}`);
      break;
    }
    case c.Next: {
      if (typeof e.id != "string")
        throw new Error(`"${e.type}" message expects the 'id' property to be a string, but got ${v(e.id)}`);
      if (!e.id)
        throw new Error(`"${e.type}" message requires a non-empty 'id' property`);
      if (!I(e.payload))
        throw new Error(`"${e.type}" message expects the 'payload' property to be an object, but got ${v(e.payload)}`);
      break;
    }
    case c.Error: {
      if (typeof e.id != "string")
        throw new Error(`"${e.type}" message expects the 'id' property to be a string, but got ${v(e.id)}`);
      if (!e.id)
        throw new Error(`"${e.type}" message requires a non-empty 'id' property`);
      if (!Ue(e.payload))
        throw new Error(`"${e.type}" message expects the 'payload' property to be an array of GraphQL errors, but got ${JSON.stringify(e.payload)}`);
      break;
    }
    case c.Complete: {
      if (typeof e.id != "string")
        throw new Error(`"${e.type}" message expects the 'id' property to be a string, but got ${v(e.id)}`);
      if (!e.id)
        throw new Error(`"${e.type}" message requires a non-empty 'id' property`);
      break;
    }
    default:
      throw new Error(`Invalid message 'type' property "${e.type}"`);
  }
  return e;
}
function qe(e, n) {
  return ke(typeof e == "string" ? JSON.parse(e, n) : e);
}
function q(e, n) {
  return ke(e), JSON.stringify(e, n);
}
var U = function(e) {
  return this instanceof U ? (this.v = e, this) : new U(e);
}, Me = function(e, n, o) {
  if (!Symbol.asyncIterator)
    throw new TypeError("Symbol.asyncIterator is not defined.");
  var N = o.apply(e, n || []), b, F = [];
  return b = {}, T("next"), T("throw"), T("return"), b[Symbol.asyncIterator] = function() {
    return this;
  }, b;
  function T(k) {
    N[k] && (b[k] = function(t) {
      return new Promise(function(R, K) {
        F.push([k, t, R, K]) > 1 || d(k, t);
      });
    });
  }
  function d(k, t) {
    try {
      u(N[k](t));
    } catch (R) {
      z(F[0][3], R);
    }
  }
  function u(k) {
    k.value instanceof U ? Promise.resolve(k.value.v).then(y, x) : z(F[0][2], k);
  }
  function y(k) {
    d("next", k);
  }
  function x(k) {
    d("throw", k);
  }
  function z(k, t) {
    k(t), F.shift(), F.length && d(F[0][0], F[0][1]);
  }
};
function Ve(e) {
  const {
    url: n,
    connectionParams: o,
    lazy: N = !0,
    onNonLazyError: b = console.error,
    lazyCloseTimeout: F = 0,
    keepAlive: T = 0,
    disablePong: d,
    connectionAckWaitTimeout: u = 0,
    retryAttempts: y = 5,
    retryWait: x = async function(m) {
      let i = 1e3;
      for (let r = 0; r < m; r++)
        i *= 2;
      await new Promise((r) => setTimeout(r, i + // add random timeout from 300ms to 3s
      Math.floor(Math.random() * 2700 + 300)));
    },
    shouldRetry: z = B,
    isFatalConnectionProblem: k,
    on: t,
    webSocketImpl: R,
    /**
     * Generates a v4 UUID to be used as the ID using `Math`
     * as the random number generator. Supply your own generator
     * in case you need more uniqueness.
     *
     * Reference: https://gist.github.com/jed/982883
     */
    generateID: K = function() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (m) => {
        const i = Math.random() * 16 | 0;
        return (m == "x" ? i : i & 3 | 8).toString(16);
      });
    },
    jsonMessageReplacer: H,
    jsonMessageReviver: ge
  } = e;
  let A;
  if (R) {
    if (!We(R))
      throw new Error("Invalid WebSocket implementation provided");
    A = R;
  } else
    typeof WebSocket < "u" ? A = WebSocket : typeof global < "u" ? A = global.WebSocket || // @ts-expect-error: Support more browsers
    global.MozWebSocket : typeof window < "u" && (A = window.WebSocket || // @ts-expect-error: Support more browsers
    window.MozWebSocket);
  if (!A)
    throw new Error("WebSocket implementation missing; on Node you can `import WebSocket from 'ws';` and pass `webSocketImpl: WebSocket` to `createClient`");
  const D = A, g = (() => {
    const a = /* @__PURE__ */ (() => {
      const i = {};
      return {
        on(r, l) {
          return i[r] = l, () => {
            delete i[r];
          };
        },
        emit(r) {
          var l;
          "id" in r && ((l = i[r.id]) === null || l === void 0 || l.call(i, r));
        }
      };
    })(), m = {
      connecting: t != null && t.connecting ? [t.connecting] : [],
      opened: t != null && t.opened ? [t.opened] : [],
      connected: t != null && t.connected ? [t.connected] : [],
      ping: t != null && t.ping ? [t.ping] : [],
      pong: t != null && t.pong ? [t.pong] : [],
      message: t != null && t.message ? [a.emit, t.message] : [a.emit],
      closed: t != null && t.closed ? [t.closed] : [],
      error: t != null && t.error ? [t.error] : []
    };
    return {
      onMessage: a.on,
      on(i, r) {
        const l = m[i];
        return l.push(r), () => {
          l.splice(l.indexOf(r), 1);
        };
      },
      emit(i, ...r) {
        for (const l of [...m[i]])
          l(...r);
      }
    };
  })();
  function te(a) {
    const m = [
      // errors are fatal and more critical than close events, throw them first
      g.on("error", (i) => {
        m.forEach((r) => r()), a(i);
      }),
      // closes can be graceful and not fatal, throw them second (if error didnt throw)
      g.on("closed", (i) => {
        m.forEach((r) => r()), a(i);
      })
    ];
  }
  let E, $ = 0, ae, X = !1, j = 0, oe = !1;
  async function re() {
    clearTimeout(ae);
    const [a, m] = await (E ?? (E = new Promise((l, h) => (async () => {
      if (X) {
        if (await x(j), !$)
          return E = void 0, h({ code: 1e3, reason: "All Subscriptions Gone" });
        j++;
      }
      g.emit("connecting");
      const s = new D(typeof n == "function" ? await n() : n, Le);
      let _, L;
      function Q() {
        isFinite(T) && T > 0 && (clearTimeout(L), L = setTimeout(() => {
          s.readyState === D.OPEN && (s.send(q({ type: c.Ping })), g.emit("ping", !1, void 0));
        }, T));
      }
      te((p) => {
        E = void 0, clearTimeout(_), clearTimeout(L), h(p), B(p) && p.code === 4499 && (s.close(4499, "Terminated"), s.onerror = null, s.onclose = null);
      }), s.onerror = (p) => g.emit("error", p), s.onclose = (p) => g.emit("closed", p), s.onopen = async () => {
        try {
          g.emit("opened", s);
          const p = typeof o == "function" ? await o() : o;
          if (s.readyState !== D.OPEN)
            return;
          s.send(q(p ? {
            type: c.ConnectionInit,
            payload: p
          } : {
            type: c.ConnectionInit
            // payload is completely absent if not provided
          }, H)), isFinite(u) && u > 0 && (_ = setTimeout(() => {
            s.close(w.ConnectionAcknowledgementTimeout, "Connection acknowledgement timeout");
          }, u)), Q();
        } catch (p) {
          g.emit("error", p), s.close(w.InternalClientError, ue(p instanceof Error ? p.message : new Error(p).message, "Internal client error"));
        }
      };
      let P = !1;
      s.onmessage = ({ data: p }) => {
        try {
          const f = qe(p, ge);
          if (g.emit("message", f), f.type === "ping" || f.type === "pong") {
            g.emit(f.type, !0, f.payload), f.type === "pong" ? Q() : d || (s.send(q(f.payload ? {
              type: c.Pong,
              payload: f.payload
            } : {
              type: c.Pong
              // payload is completely absent if not provided
            })), g.emit("pong", !1, f.payload));
            return;
          }
          if (P)
            return;
          if (f.type !== c.ConnectionAck)
            throw new Error(`First message cannot be of type ${f.type}`);
          clearTimeout(_), P = !0, g.emit("connected", s, f.payload), X = !1, j = 0, l([
            s,
            new Promise((He, Ne) => te(Ne))
          ]);
        } catch (f) {
          s.onmessage = null, g.emit("error", f), s.close(w.BadResponse, ue(f instanceof Error ? f.message : new Error(f).message, "Bad response"));
        }
      };
    })())));
    a.readyState === D.CLOSING && await m;
    let i = () => {
    };
    const r = new Promise((l) => i = l);
    return [
      a,
      i,
      Promise.race([
        // wait for
        r.then(() => {
          if (!$) {
            const l = () => a.close(1e3, "Normal Closure");
            isFinite(F) && F > 0 ? ae = setTimeout(() => {
              a.readyState === D.OPEN && l();
            }, F) : l();
          }
        }),
        // or
        m
      ])
    ];
  }
  function se(a) {
    if (B(a) && (Oe(a.code) || [
      w.InternalServerError,
      w.InternalClientError,
      w.BadRequest,
      w.BadResponse,
      w.Unauthorized,
      // CloseCode.Forbidden, might grant access out after retry
      w.SubprotocolNotAcceptable,
      // CloseCode.ConnectionInitialisationTimeout, might not time out after retry
      // CloseCode.ConnectionAcknowledgementTimeout, might not time out after retry
      w.SubscriberAlreadyExists,
      w.TooManyInitialisationRequests
      // 4499, // Terminated, probably because the socket froze, we want to retry
    ].includes(a.code)))
      throw a;
    if (oe)
      return !1;
    if (B(a) && a.code === 1e3)
      return $ > 0;
    if (!y || j >= y || !z(a) || k != null && k(a))
      throw a;
    return X = !0;
  }
  N || (async () => {
    for ($++; ; )
      try {
        const [, , a] = await re();
        await a;
      } catch (a) {
        try {
          if (!se(a))
            return;
        } catch (m) {
          return b == null ? void 0 : b(m);
        }
      }
  })();
  function de(a, m) {
    const i = K(a);
    let r = !1, l = !1, h = () => {
      $--, r = !0;
    };
    return (async () => {
      for ($++; ; )
        try {
          const [s, _, L] = await re();
          if (r)
            return _();
          const Q = g.onMessage(i, (P) => {
            switch (P.type) {
              case c.Next: {
                m.next(P.payload);
                return;
              }
              case c.Error: {
                l = !0, r = !0, m.error(P.payload), h();
                return;
              }
              case c.Complete: {
                r = !0, h();
                return;
              }
            }
          });
          s.send(q({
            id: i,
            type: c.Subscribe,
            payload: a
          }, H)), h = () => {
            !r && s.readyState === D.OPEN && s.send(q({
              id: i,
              type: c.Complete
            }, H)), $--, r = !0, _();
          }, await L.finally(Q);
          return;
        } catch (s) {
          if (!se(s))
            return;
        }
    })().then(() => {
      l || m.complete();
    }).catch((s) => {
      m.error(s);
    }), () => {
      r || h();
    };
  }
  return {
    on: g.on,
    subscribe: de,
    iterate(a) {
      const m = [], i = {
        done: !1,
        error: null,
        resolve: () => {
        }
      }, r = de(a, {
        next(h) {
          m.push(h), i.resolve();
        },
        error(h) {
          i.done = !0, i.error = h, i.resolve();
        },
        complete() {
          i.done = !0, i.resolve();
        }
      }), l = function() {
        return Me(this, arguments, function* () {
          for (; ; ) {
            for (m.length || (yield U(new Promise((_) => i.resolve = _))); m.length; )
              yield yield U(m.shift());
            if (i.error)
              throw i.error;
            if (i.done)
              return yield U(void 0);
          }
        });
      }();
      return l.throw = async (h) => (i.done || (i.done = !0, i.error = h, i.resolve()), { done: !0, value: void 0 }), l.return = async () => (r(), { done: !0, value: void 0 }), l;
    },
    async dispose() {
      if (oe = !0, E) {
        const [a] = await E;
        a.close(1e3, "Normal Closure");
      }
    },
    terminate() {
      E && g.emit("closed", {
        code: 4499,
        reason: "Terminated",
        wasClean: !1
      });
    }
  };
}
function B(e) {
  return I(e) && "code" in e && "reason" in e;
}
function Oe(e) {
  return [
    1e3,
    1001,
    1006,
    1005,
    1012,
    1013,
    1014
    // Bad Gateway
  ].includes(e) ? !1 : e >= 1e3 && e <= 1999;
}
function We(e) {
  return typeof e == "function" && "constructor" in e && "CLOSED" in e && "CLOSING" in e && "CONNECTING" in e && "OPEN" in e;
}
const un = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }]
}, ze = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "Login"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "login"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "password"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "login"
            },
            arguments: [{
              kind: "Argument",
              name: {
                kind: "Name",
                value: "login"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "login"
                }
              }
            }, {
              kind: "Argument",
              name: {
                kind: "Name",
                value: "password"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "password"
                }
              }
            }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "access_token"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "expiration"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "refresh_token"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "user"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "User"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }]
}, je = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "SignUp"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "username"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "nickname"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "email"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "password"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "signup"
            },
            arguments: [{
              kind: "Argument",
              name: {
                kind: "Name",
                value: "username"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "username"
                }
              }
            }, {
              kind: "Argument",
              name: {
                kind: "Name",
                value: "nickname"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "nickname"
                }
              }
            }, {
              kind: "Argument",
              name: {
                kind: "Name",
                value: "email"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "email"
                }
              }
            }, {
              kind: "Argument",
              name: {
                kind: "Name",
                value: "password"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "password"
                }
              }
            }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "access_token"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "expiration"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "refresh_token"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "user"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "User"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }]
}, Qe = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "RefreshToken"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "refreshToken"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "refresh"
            },
            arguments: [{
              kind: "Argument",
              name: {
                kind: "Name",
                value: "refresh_token"
              },
              value: {
                kind: "Variable",
                name: {
                  kind: "Name",
                  value: "refreshToken"
                }
              }
            }],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "access_token"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "expiration"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "refresh_token"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "user"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "User"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }]
}, cn = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "FetchMyUserInfo"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "info"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "User"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }]
};
var S = "/src/model/auth/model.ts";
const Be = O(Re(), {
  loc: {
    file: S,
    line: 28,
    column: 27
  },
  name: "$myUserInfo",
  sid: "4rhpep:/src/model/auth/model.ts:$myUserInfo"
}), pe = C({
  loc: {
    file: S,
    line: 38,
    column: 36
  },
  name: "eventTraceIdReceived",
  sid: "rqihdu:/src/model/auth/model.ts:eventTraceIdReceived"
}), kn = O("", {
  loc: {
    file: S,
    line: 39,
    column: 24
  },
  name: "$traceId",
  sid: "-6xl1l:/src/model/auth/model.ts:$traceId"
}).on(pe, (e, n) => n), V = O(null, {
  loc: {
    file: S,
    line: 41,
    column: 28
  },
  name: "$accessToken",
  sid: "5d4kmp:/src/model/auth/model.ts:$accessToken"
}), G = O(null, {
  loc: {
    file: S,
    line: 45,
    column: 29
  },
  name: "$refreshToken",
  sid: "-xyolmr:/src/model/auth/model.ts:$refreshToken"
}), J = ee({
  loc: {
    file: S,
    line: 47,
    column: 23
  },
  name: "loginFx",
  sid: "6gal0a:/src/model/auth/model.ts:loginFx"
}), fe = ee((e) => ie().mutate({
  mutation: je,
  variables: e
}).then((n) => ({
  data: n.data.user.signup,
  type: "success"
}), (n) => n instanceof Se ? {
  message: n.message === 'pq: duplicate key value violates unique constraint "users_email_key"' ? me("BACKEND_ERRORS.email_exists") : n.message === 'pq: duplicate key value violates unique constraint "users_username_key"' ? me("BACKEND_ERRORS.username_exists") : n.message,
  type: "error"
} : {
  message: String(n),
  type: "error"
}), {
  loc: {
    file: S,
    line: 52,
    column: 24
  },
  name: "signupFx",
  sid: "udlyku:/src/model/auth/model.ts:signupFx"
}), ne = C({
  loc: {
    file: S,
    line: 81,
    column: 35
  },
  name: "eventTokenRefreshed",
  sid: "5gfyf7:/src/model/auth/model.ts:eventTokenRefreshed"
}), W = C({
  loc: {
    file: S,
    line: 87,
    column: 27
  },
  name: "eventLogout",
  sid: "f6mchv:/src/model/auth/model.ts:eventLogout"
}), Ge = C({
  loc: {
    file: S,
    line: 89,
    column: 45
  },
  name: "eventAnonymousNicknameUpdated",
  sid: "frvmd7:/src/model/auth/model.ts:eventAnonymousNicknameUpdated"
}), ye = O(null, {
  loc: {
    file: S,
    line: 90,
    column: 34
  },
  name: "$anonymousNickname",
  sid: "-b7kizi:/src/model/auth/model.ts:$anonymousNickname"
}).on(Ge, (e, n) => n).reset(W, J.done);
Pe({
  store: ye
});
const pn = Ie({
  and: [[Be, ye]],
  or: {
    loc: {
      file: S,
      line: 98,
      column: 27
    },
    name: "$myNickname",
    sid: "-971937:/src/model/auth/model.ts:$myNickname"
  }
}).map(([e, n]) => $e((o) => o.type === "authenticated" ? o.user.nickname : null, null, e) ?? n);
J.use(async ({
  login: e,
  password: n
}) => {
  const o = await ie().mutate({
    mutation: ze,
    variables: {
      login: e,
      password: n
    }
  });
  return o.errors == null && o.data != null ? o.data.user.login : Promise.reject(o.errors);
});
const Ce = ee(() => ie().clearStore(), {
  loc: {
    file: S,
    line: 122,
    column: 17
  },
  name: "logoutFx",
  sid: "vgayjg:/src/model/auth/model.ts:logoutFx"
});
Ae({
  and: [{
    clock: W,
    target: Ce
  }],
  or: {
    loc: {
      file: S,
      line: 124,
      column: 0
    },
    sid: "7oad66:/src/model/auth/model.ts:"
  }
});
const fn = G.map(Boolean), Je = V.map((e) => e != null ? De(e).exp * 1e3 : 0);
V.on(J.doneData, (e, n) => n.access_token).on(fe.doneData, (e, n) => n.type === "success" ? n.data.access_token : e).on(ne, (e, n) => n.access_token).reset(W);
G.on(J.doneData, (e, n) => n.refresh_token).on(fe.doneData, (e, n) => n.type === "success" ? n.data.refresh_token : e).on(ne, (e, n) => n.refresh_token).reset(W);
class Ke extends Z {
  constructor(o) {
    super();
    Y(this, "isRefreshing", !1);
    Y(this, "pendingRequests", []);
    this.options = o;
  }
  resolvePendingRequests() {
    for (const o of this.pendingRequests)
      o();
    this.pendingRequests = [];
  }
  request(o, N) {
    if (typeof N != "function")
      throw new Error("[Token Refresh Link]: Token Refresh Link is a non-terminating link and should not be the last in the composed chain");
    return !this.options.isAccessTokenNeedsRefresh() || o.operationName === "RefreshToken" ? N(o) : this.doRefresh(o).flatMap(() => N(o));
  }
  doRefresh(o) {
    let N;
    return this.isRefreshing ? N = le(
      // eslint-disable-next-line no-loop-func
      new Promise((b) => {
        this.pendingRequests.push(() => b(void 0));
      })
    ) : (this.isRefreshing = !0, N = le(this.options.refreshAccessToken(o).finally(() => {
      this.resolvePendingRequests(), this.isRefreshing = !1;
    }))), N;
  }
}
process.env.DEV && (we(), Te());
let M = null, ce;
const ie = () => {
  if (M != null)
    return M;
  const e = xe((d, u) => {
    const y = V.getState();
    return y != null ? {
      ...u,
      headers: {
        ...u.headers,
        authorization: y
      }
    } : u;
  });
  let n, o;
  const N = new _e(Ve({
    connectionParams: () => {
      const d = V.getState();
      return d == null ? {} : {
        authorization: d
      };
    },
    keepAlive: (
      /* 7 sec */
      7 * 1e3
    ),
    lazy: !0,
    lazyCloseTimeout: (
      /* 2min */
      2 * 60 * 1e3
    ),
    on: {
      connected: (d) => {
        n = d, window.activeSocket = d;
      },
      ping: (d) => {
        d || (o = setTimeout(() => {
          n.readyState === WebSocket.OPEN && ce();
        }, 5e3));
      },
      pong: (d) => {
        d && clearTimeout(o);
      }
    },
    retryAttempts: Number.MAX_SAFE_INTEGER,
    url: process.env.VITE_WS_API_URL ?? ""
  }));
  ce = () => {
    N.client.terminate();
  };
  const b = new Ke({
    isAccessTokenNeedsRefresh: () => {
      const d = V.getState(), u = G.getState();
      return d == null ? u != null : Je.getState() < Date.now() + 1 * 60 * 1e3;
    },
    refreshAccessToken: async () => {
      var d;
      try {
        const u = G.getState();
        if (u != null) {
          const y = await M.mutate({
            mutation: Qe,
            variables: {
              refreshToken: u
            }
          });
          if ((d = y.data) != null && d.user.refresh) {
            ne(y.data.user.refresh), console.info("Access token refreshed");
            return;
          }
        }
      } catch (u) {
        console.error("Token refresh failed, logging out", u), W();
      }
    }
  }), F = Ee((d) => {
    var u;
    (u = d.graphQLErrors) != null && u.some((y) => {
      var x;
      return ((x = y.extensions) == null ? void 0 : x.code) === 420;
    }) && console.log("errorLink ~ error:", d);
  }), T = new Z((d, u) => u(d).map((y) => {
    var x;
    return ((x = y.extensions) == null ? void 0 : x.trace) != null && pe(y.extensions.trace), y;
  }));
  return M = new be({
    cache: new Fe({
      typePolicies: {
        MediaInfoType: {
          keyFields: !1
        },
        MeetChatQueryType: {
          keyFields: []
        },
        MeetMemberQueryType: {
          keyFields: []
        },
        MeetQueryType: {
          keyFields: []
        },
        MeetSpaceQueryType: {
          keyFields: []
        },
        RoomQueryType: {
          keyFields: []
        },
        RoomType: {
          keyFields: !1,
          merge: !0
        },
        UserMeetListQueryType: {
          keyFields: []
        },
        UserMeetQueryType: {
          keyFields: []
        },
        UserQueryType: {
          keyFields: []
        }
      }
    }),
    link: Z.from([F, e, b, T, N]),
    uri: process.env.VITE_WS_API_URL ?? ""
  }), M;
};
export {
  Be as $,
  cn as F,
  ze as L,
  Qe as R,
  je as S,
  Ke as T,
  un as U,
  kn as a,
  V as b,
  G as c,
  ne as d,
  pe as e,
  W as f,
  ie as g,
  Ge as h,
  ye as i,
  pn as j,
  fn as k,
  J as l,
  Je as m,
  ce as r,
  fe as s
};
