var Vo = Object.defineProperty;
var Oo = (e, n, i) => n in e ? Vo(e, n, { enumerable: !0, configurable: !0, writable: !0, value: i }) : e[n] = i;
var qi = (e, n, i) => (Oo(e, typeof n != "symbol" ? n + "" : n, i), i);
import { createEvent as h, sample as _, createStore as D, createEffect as $, attach as yn, combine as we, withFactory as Po, split as Lo, restore as Uo } from "effector";
import { ApolloError as ca, useQuery as qn, useMutation as Tn } from "@apollo/client";
import { makeUniqueId as ui } from "@apollo/client/utilities";
import { hasData as Xn, initialized as en, failure as ua, success as he, maybeData as Se, remoteMap as Xe, RemoteDataStatus as ka, fold as Gt, pending as $o } from "@dmitry.olyenyov/remote-data";
import { t as x } from "i18next";
import { getApolloClient as Z, RtcMediaAudioMode as V, Permission as Y, restartGraphqlConnection as Bo, RtcMediaAudio as ki, RtcMediaVideo as Re, ChatEmbedType as bt, makeCallRoomLink as qt, makeGravatarUri as fi, MeetMemberPresenceState as zo } from "@sign/shared/api";
import { isNotNull as Mn, formatBackendError as jo, makeStyleSheet as Ho, postpone as Wo, throttle as Go, retryEffect as qo, waitFor as Xt, makeThumbnails as va, formatUserName as nn, opaqueTimeToDate as Yt, formatAvatarShortName as Sa, formatRoomIdAsText as Xo, createNoParamsInfiniteLoaderModel as Yo, formatMaybeEmptyTime as Ko, enumToList as Jo, formatMyUserName as Qo } from "@sign/shared/lib";
import { $myUserInfo as Kt, $anonymousNickname as pa, persistSES as Zo, $accessToken as es, useMyInfoOld as Fi, useMyInfo as ns } from "@sign/shared/model";
import { configure as Cn } from "effector-logger";
import { View as w, useTheme as me, useMediaQuery as is, useBreakpointValue as yt, theme as ze, Heading as hn, Text as b, Image as gi, Box as sn, Tooltip as Na, Pressable as te, Progress as as, Avatar as fa, FlatList as Fa, Center as hi, HStack as Je, VStack as Bn, CloseIcon as Jt, FormControl as Be, ScrollView as ga, Button as ha, Spinner as zn, Switch as ts, TextArea as ds, Select as Un, Popover as ge, Radio as Fn, Stack as Tt, Alert as Mt, IconButton as ls, useClipboard as os, useToast as ss, SectionList as rs } from "native-base";
import Qt, { memo as g, useCallback as E, useMemo as L, useState as H, useEffect as Q, useRef as je, forwardRef as ms } from "react";
import { jsx as d, jsxs as f, Fragment as be } from "react/jsx-runtime";
import { CustomIconButton as Yn, useBreakpoint as mn, FullScreenMessage as ba, FormPasswordInput as cs, SettingsItemRow as us, ActionButton as He, Button32 as bi, EmojiModal as ks, EMOJI_OFTEN_USED as Zt, EmojiItem as vs, FormTextInput as on, FormDatePicker as Ct, FormSwitch as Ge, FormErrorMessage as Ss, DisplayGraphQLError as Kn, CopyRoomNumber as ed, HAND_RAISED as bn, MenuItem as rn, CustomRTCView as yi, Menu as ps, SettingsContainer as Ns, SettingsContainerTitle as fs, FormSettingsRadio as Fs, BigAvatarWithName as nd } from "@sign/uikit";
import { IconPhone as gs, IconMicro as ya, IconCameraRound as id, IconCameraChange as hs, IconScreenShare as bs, IconFolder as ys, IconCloseCircle as Ti, IconEdit as Ts, IconReply as Ms, IconChat as ad, IconPlus as Cs, IconClose as ws, IconRaisedHand as Ta, IconWifi as _s, IconMicroDisabled as td, IconCamera as Es, IconCameraDisabled as Rs, IconChatWithSign as xs, IconSmile as Ds, IconSettings as Is, IconUserGroup as As, IconCameraRoundOff as Vs } from "@sign/uikit/icons";
import { useUnit as I } from "effector-react";
import { useWindowDimensions as wn, View as dd, Pressable as ld, NativeModules as Os, Linking as od, Platform as Qe, StyleSheet as _n, Modal as sd, ScrollView as Ps, Switch as Ls } from "react-native";
import { zodResolver as Jn } from "@hookform/resolvers/zod";
import { useForm as En, Controller as ae, useFieldArray as rd } from "react-hook-form";
import j, { z as B } from "zod";
import { mediaDevices as Ie, RTCPeerConnection as Us } from "react-native-webrtc";
import { useTranslation as ie } from "react-i18next";
import * as wt from "@sentry/react";
import { captureException as Ma, captureMessage as $s } from "@sentry/react";
import { UnreachableCaseError as X } from "ts-essentials";
import { interval as Bs } from "patronum";
import { parse as zs } from "@rocket.chat/message-parser";
import js, { AxiosError as Hs } from "axios";
import { IconPaperClipWeb as Ws, IconSmileWeb as Gs, IconSendWeb as qs, PortalContainer as Xs, IconCloseWeb as _t, IconCheckWeb as Ys } from "@sign/uikit/web";
import De from "classnames";
import { useDropzone as Ks } from "react-dropzone";
import { isToday as Js, isYesterday as Qs, format as Et } from "date-fns";
import Zs, { Path as er } from "react-native-svg";
import md from "@emoji-mart/data";
const Ev = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }]
}, Rv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }]
}, xv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbed"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "file"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "author"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "filename"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "mime"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updated"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "message"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "EmbeddedChatMessage"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "preview"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbedPreview"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "type"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "value"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }]
}, Dv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventChatMessageEdit"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEditType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbed"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "file"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "author"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "filename"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "mime"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updated"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "message"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "EmbeddedChatMessage"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "preview"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbedPreview"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "type"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "value"
        }
      }]
    }
  }]
}, Iv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reactions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbed"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "file"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "author"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "filename"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "mime"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updated"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "message"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "EmbeddedChatMessage"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "preview"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbedPreview"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "type"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "value"
        }
      }]
    }
  }]
}, Av = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventChat"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventChatType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "edit"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "messages"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventChatMessageEdit"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "sent"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "messages"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "ChatMessage"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "remove"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "messages"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reaction"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "messages"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "reactions"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "count"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "name"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "self"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "messages"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "reactions"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbed"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "file"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "author"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "filename"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "mime"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updated"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "message"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "EmbeddedChatMessage"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "preview"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbedPreview"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "type"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "value"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventChatMessageEdit"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEditType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reactions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }]
}, nr = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "RequestChatHistory"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "cursor"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "limit"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "Int"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "history"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "cursor"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "cursor"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "limit"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "limit"
                    }
                  }
                }],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cursor"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "data"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ChatMessage"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbed"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "file"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "author"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "filename"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "mime"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updated"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "message"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "EmbeddedChatMessage"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "preview"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbedPreview"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "type"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "value"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reactions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }]
}, ir = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "subscription",
    name: {
      kind: "Name",
      value: "Meet"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "phone"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "nickname"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "space"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "password"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "email"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "space"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "space"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "email"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "email"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "nickname"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "nickname"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "password"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "password"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "phone"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "phone"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventChat"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "member"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventMember"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventRtc"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "self"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventSelf"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeeetEventSpace"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "user"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventUser"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventVote"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbed"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "file"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "author"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "filename"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "mime"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updated"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "message"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "EmbeddedChatMessage"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "preview"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbedPreview"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "type"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "value"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventChatMessageEdit"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEditType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reactions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "RTCMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RTCMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "media"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "audio"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "audio_mode"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "video"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reaction"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "emotion"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "gesture"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "IceServers"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RTCICEServerType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "credential"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "urls"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMemberSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "connected_since"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetSpace"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetVote"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "DataVoteType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "cases"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "completion"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "majority"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "multiple"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventChat"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventChatType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "edit"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "messages"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventChatMessageEdit"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "sent"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "messages"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "ChatMessage"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "remove"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "messages"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reaction"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "messages"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "reactions"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "count"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "name"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "self"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "messages"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "reactions"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "add"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetMember"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "remove"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "id"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "source"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "ban"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "reason"
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "conflict"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "device_id"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "user_id"
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "disconnect"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "kick"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "reason"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "data"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetMember"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "change"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "id"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "connected_since"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "permissions_deny"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "permissions_grant"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "nickname"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "presence_state"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventRtc"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventRTCType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "draw"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "actor"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "primitives"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "line"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "color"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "points"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "thickness"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "target"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "add"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "RTCMember"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "change"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "RTCMember"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "remove"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "data"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "RTCMember"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "ice"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "candidates"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "init"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "codecs"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "ice_servers"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "IceServers"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "midi"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "actor"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "events"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "note"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "on"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "velocity"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "sdp"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "answer"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "offer"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventSelf"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetSelfType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "init"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "membership"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetMemberSpace"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeeetEventSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "add"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "spaces"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetSpace"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "remove"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space_ids"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "change"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "spaces"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetSpace"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventUser"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetUserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "change"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "users"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "User"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventVote"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventVoteType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "tally"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "votes"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cases"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "self"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "votes"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cases"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminate"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "channel"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "start"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetVote"
            }
          }]
        }
      }]
    }
  }]
}, Vv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }]
}, Ov = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "add"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetMember"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "remove"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "id"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "source"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "ban"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "reason"
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "conflict"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "device_id"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "user_id"
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "disconnect"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "kick"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "reason"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "data"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetMember"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "change"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "id"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "connected_since"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "permissions_deny"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "permissions_grant"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "nickname"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "presence_state"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }]
}, ar = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetSpaceMemberControl"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "ID"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "nickname"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Permission"
            }
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Permission"
            }
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "member"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "control"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "nickname"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "nickname"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "permissions_deny"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "permissions_deny"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "permissions_grant"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "permissions_grant"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Pv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "RTCMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RTCMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "media"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "audio"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "audio_mode"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "video"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reaction"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "emotion"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "gesture"
            }
          }]
        }
      }]
    }
  }]
}, Lv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "IceServers"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RTCICEServerType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "credential"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "urls"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }]
    }
  }]
}, Uv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventRtc"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventRTCType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "draw"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "actor"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "primitives"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "line"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "color"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "points"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "thickness"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "target"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "add"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "RTCMember"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "change"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "RTCMember"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "remove"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "data"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "RTCMember"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "ice"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "candidates"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "init"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "codecs"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "ice_servers"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "IceServers"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "midi"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "actor"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "events"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "note"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "on"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "velocity"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "sdp"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "answer"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "offer"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "RTCMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RTCMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "media"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "audio"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "audio_mode"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "video"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reaction"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "emotion"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "gesture"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "IceServers"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RTCICEServerType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "credential"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "urls"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }]
    }
  }]
}, tr = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetUpdateRtcReaction"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "emotion"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "gesture"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "reaction"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "emotion"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "emotion"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "gesture"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "gesture"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, $v = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMemberSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "connected_since"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetSpace"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, Bv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventSelf"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetSelfType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "init"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "membership"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetMemberSpace"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMemberSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "connected_since"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetSpace"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }]
}, zv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }]
}, jv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }]
}, Hv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeeetEventSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "add"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "spaces"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetSpace"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "remove"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space_ids"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "change"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "spaces"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetSpace"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, Wv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "VanityName"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "VanityNameType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }]
}, cd = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetMySpacesList"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "meet"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "list"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "spaces"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "joined"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "space"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "FragmentSpread",
                            name: {
                              kind: "Name",
                              value: "MeetSpace"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, dr = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MyMeetCalendar"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "states"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "MeetSpaceState"
            }
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "start"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "Time"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "end"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "Time"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "meet"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "list"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "calendar"
                    },
                    arguments: [{
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "start"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "start"
                        }
                      }
                    }, {
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "end"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "end"
                        }
                      }
                    }, {
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "states"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "states"
                        }
                      }
                    }],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpace"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, ud = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetSpaceByIdOrVanityName"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "ID"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "meet"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "get"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    },
                    arguments: [{
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "id"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "id"
                        }
                      }
                    }, {
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "vanity_name"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "vanity_name"
                        }
                      }
                    }],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpace"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, kd = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetSpaceSave"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "conditions"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "MeetSpaceConditionsInput"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "permissions"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Permission"
            }
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "ID"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "title"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "description"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "save"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "vanity_name"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "vanity_name"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "conditions"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "conditions"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "permissions"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "permissions"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "title"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "title"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "description"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "description"
                    }
                  }
                }],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpace"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, Gv = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetSpaceRemove"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "remove"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, qv = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetSpaceLeave"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "leave"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Xv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventUser"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetUserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "change"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "users"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "User"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }]
}, Mi = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "FetchMemberById"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "member"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "get"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetMember"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }]
}, Yv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetVote"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "DataVoteType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "cases"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "completion"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "majority"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "multiple"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }]
    }
  }]
}, Kv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventVote"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventVoteType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "tally"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "votes"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cases"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "self"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "votes"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cases"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminate"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "channel"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "start"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetVote"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetVote"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "DataVoteType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "cases"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "completion"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "majority"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "multiple"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }]
    }
  }]
}, lr = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetFetchVotes"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "limit"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "Int"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "cursor"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "list"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "cursor"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "cursor"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "limit"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "limit"
                    }
                  }
                }],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cursor"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "data"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetVote"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetVote"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "DataVoteType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "cases"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "completion"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "majority"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "multiple"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }]
    }
  }]
}, vd = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetVoteStart"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "title"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "cases"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "ListType",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "String"
              }
            }
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "multiple"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "Boolean"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "conditions"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "MeetVoteConditionsInput"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "cases"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "cases"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "conditions"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "conditions"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "multiple"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "multiple"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "title"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "title"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, or = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetVoteCast"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "ID"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "cases"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Int"
            }
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "cases"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "cases"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Jv = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetVoteTerminate"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "ID"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "terminate"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
};
var W = "/src/entities/meet/model/common.ts";
let Ye = /* @__PURE__ */ function(e) {
  return e.Ban = "Ban", e.DisconnectedFromBackend = "DisconnectedFromBackend", e.Error = "Error", e.Kick = "Kick", e.NormalHangup = "NormalHangup", e;
}({});
const ue = h({
  loc: {
    file: W,
    line: 35,
    column: 43
  },
  name: "eventMeetSpaceJoinRequested",
  sid: "6f6if8:/src/entities/meet/model/common.ts:eventMeetSpaceJoinRequested"
}), Sd = h({
  loc: {
    file: W,
    line: 38,
    column: 41
  },
  name: "eventMeetSpaceRestoreCall",
  sid: "-4wuhpx:/src/entities/meet/model/common.ts:eventMeetSpaceRestoreCall"
}), Ca = h({
  loc: {
    file: W,
    line: 39,
    column: 48
  },
  name: "eventMeetSpaceJoinedSuccessfully",
  sid: "nzq41d:/src/entities/meet/model/common.ts:eventMeetSpaceJoinedSuccessfully"
}), vi = h({
  loc: {
    file: W,
    line: 40,
    column: 35
  },
  name: "eventMeetSpaceLeave",
  sid: "dxkta2:/src/entities/meet/model/common.ts:eventMeetSpaceLeave"
}), Ne = h({
  loc: {
    file: W,
    line: 41,
    column: 37
  },
  name: "eventMeetSpaceCleanup",
  sid: "u6x1ok:/src/entities/meet/model/common.ts:eventMeetSpaceCleanup"
}), pd = h({
  loc: {
    file: W,
    line: 43,
    column: 42
  },
  name: "eventCallRatedSuccessfully",
  sid: "lwyajg:/src/entities/meet/model/common.ts:eventCallRatedSuccessfully"
});
_({
  and: [{
    clock: pd,
    target: Ne
  }],
  or: {
    loc: {
      file: W,
      line: 45,
      column: 0
    },
    sid: "vv9tmt:/src/entities/meet/model/common.ts:"
  }
});
const sr = D(!1, {
  loc: {
    file: W,
    line: 47,
    column: 35
  },
  name: "$showRateACallModal",
  sid: "-alc5cg:/src/entities/meet/model/common.ts:$showRateACallModal"
}).reset(ue, Ne), Nd = h({
  loc: {
    file: W,
    line: 52,
    column: 42
  },
  name: "eventMeetSpaceExitFinished",
  sid: "-c3wam8:/src/entities/meet/model/common.ts:eventMeetSpaceExitFinished"
}), wa = h({
  loc: {
    file: W,
    line: 54,
    column: 48
  },
  name: "eventGraphqlSubscriptionComplete",
  sid: "fxkm08:/src/entities/meet/model/common.ts:eventGraphqlSubscriptionComplete"
}), rr = h({
  loc: {
    file: W,
    line: 55,
    column: 47
  },
  name: "eventGraphqlSubscriptionStarted",
  sid: "-nhw1rc:/src/entities/meet/model/common.ts:eventGraphqlSubscriptionStarted"
}), mr = h({
  loc: {
    file: W,
    line: 57,
    column: 35
  },
  name: "eventControlsToggle",
  sid: "7guf0m:/src/entities/meet/model/common.ts:eventControlsToggle"
}), Qv = D(!0, {
  loc: {
    file: W,
    line: 58,
    column: 31
  },
  name: "$controlsOpened",
  sid: "l41m3s:/src/entities/meet/model/common.ts:$controlsOpened"
}).on(mr, (e) => !e), Zv = h({
  loc: {
    file: W,
    line: 63,
    column: 39
  },
  name: "eventCallViewFullToggle",
  sid: "bcv7tv:/src/entities/meet/model/common.ts:eventCallViewFullToggle"
}), eS = D(!0, {
  and: {
    name: "$loading"
  },
  loc: {
    file: W,
    line: 64,
    column: 24
  },
  name: "$loading",
  sid: "-xclcn6:/src/entities/meet/model/common.ts:$loading"
}), cr = h({
  loc: {
    file: W,
    line: 65,
    column: 26
  },
  name: "eventError",
  sid: "-x5jq7j:/src/entities/meet/model/common.ts:eventError"
}), Ci = D([], {
  and: {},
  loc: {
    file: W,
    line: 77,
    column: 28
  },
  name: "$fatalErrors",
  sid: "-tbdp79:/src/entities/meet/model/common.ts:$fatalErrors"
}), ur = (e) => {
  Ci.on(e.failData, (n, i) => [...n, i instanceof Error ? {
    error: i,
    type: "exception"
  } : i]);
};
Ci.on(cr, (e, n) => e.concat(Array.isArray(n) ? n.map((i) => ({
  error: i,
  type: "exception"
})) : [{
  error: n,
  type: "exception"
}])).reset(Ne, ue);
Ci.watch((e) => {
  for (const n of e)
    console.error(n);
});
const fd = h({
  loc: {
    file: W,
    line: 121,
    column: 30
  },
  name: "eventHideAlert",
  sid: "974ckf:/src/entities/meet/model/common.ts:eventHideAlert"
}), _a = h({
  loc: {
    file: W,
    line: 123,
    column: 29
  },
  name: "eventNewAlert",
  sid: "dxfeql:/src/entities/meet/model/common.ts:eventNewAlert"
}), Ea = D([], {
  loc: {
    file: W,
    line: 125,
    column: 23
  },
  name: "$alerts",
  sid: "xygy3g:/src/entities/meet/model/common.ts:$alerts"
}).reset(Ne, ue);
Ea.on(fd, (e, n) => e.filter((i) => i.id !== n)).on(_a, (e, n) => [...e, {
  id: ui("alert"),
  ...n
}]);
const wi = $(async ({
  spaceIdOrVanityName: e
}) => {
  if (/^\d+$/.test(e))
    return e;
  try {
    return (await Z().query({
      query: ud,
      variables: {
        vanity_name: e
      }
    })).data.user.meet.get.space.id;
  } catch (n) {
    throw n instanceof ca && n.message === "sql: no rows in result set" ? {
      message: x("BACKEND_ERRORS.MEETSPACE.TERMINATED_MESSAGE"),
      title: x("BACKEND_ERRORS.MEETSPACE.TERMINATED_TITLE"),
      type: "custom"
    } : n;
  }
}, {
  loc: {
    file: W,
    line: 141,
    column: 48
  },
  name: "fetchMeetSpaceByIdOrVanityNameFx",
  sid: "-r2yso8:/src/entities/meet/model/common.ts:fetchMeetSpaceByIdOrVanityNameFx"
});
_({
  and: [{
    clock: ue,
    target: wi
  }],
  or: {
    loc: {
      file: W,
      line: 168,
      column: 0
    },
    sid: "l5nes1:/src/entities/meet/model/common.ts:"
  }
});
ur(wi);
const Qn = D(null, {
  loc: {
    file: W,
    line: 174,
    column: 31
  },
  name: "$currentSpaceId",
  sid: "xjpsdw:/src/entities/meet/model/common.ts:$currentSpaceId"
}).on(wi.doneData, (e, n) => n).reset(Nd), kr = Kt.map((e) => Xn(e) ? e.data.type === "authenticated" ? e.data.user.id : e.data.type : null);
_({
  and: [{
    clock: [Sd, kr],
    source: Qn,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: Mn,
    fn: (e) => ({
      spaceIdOrVanityName: e
    }),
    target: ue
  }],
  or: {
    loc: {
      file: W,
      line: 186,
      column: 0
    },
    sid: "m2n2dp:/src/entities/meet/model/common.ts:"
  }
});
const vr = D(null, {
  and: {
    name: "$roomExit"
  },
  loc: {
    file: W,
    line: 195,
    column: 31
  },
  name: "$roomExitReason",
  sid: "-z0kg74:/src/entities/meet/model/common.ts:$roomExitReason"
}).reset(ue, Nd).on(wa, () => ({
  message: x("ROOM.EXIT_REASON.GENERIC_DISCONNECT"),
  type: Ye.DisconnectedFromBackend
}));
var Rn = "/src/entities/meet/model/aside-panel.ts";
const Sr = h({
  loc: {
    file: Rn,
    line: 6,
    column: 37
  },
  name: "eventAsidePanelClosed",
  sid: "-l9rzsc:/src/entities/meet/model/aside-panel.ts:eventAsidePanelClosed"
}), Fd = h({
  loc: {
    file: Rn,
    line: 7,
    column: 36
  },
  name: "eventToggleUsersList",
  sid: "-g9ub9i:/src/entities/meet/model/aside-panel.ts:eventToggleUsersList"
}), Zn = h({
  loc: {
    file: Rn,
    line: 8,
    column: 31
  },
  name: "eventToggleChat",
  sid: "cs34j0:/src/entities/meet/model/aside-panel.ts:eventToggleChat"
}), gd = h({
  loc: {
    file: Rn,
    line: 9,
    column: 32
  },
  name: "eventToggleVotes",
  sid: "mdc7jz:/src/entities/meet/model/aside-panel.ts:eventToggleVotes"
}), hd = h({
  loc: {
    file: Rn,
    line: 10,
    column: 35
  },
  name: "eventToggleSettings",
  sid: "-xdgwd8:/src/entities/meet/model/aside-panel.ts:eventToggleSettings"
});
let re = /* @__PURE__ */ function(e) {
  return e[e.UserList = 0] = "UserList", e[e.Chat = 1] = "Chat", e[e.Votes = 2] = "Votes", e[e.Settings = 3] = "Settings", e;
}({});
const xn = D(null, {
  loc: {
    file: Rn,
    line: 19,
    column: 27
  },
  name: "$asidePanel",
  sid: "-em4quf:/src/entities/meet/model/aside-panel.ts:$asidePanel"
}).reset(Sr, Ne).on(Zn, (e) => e === re.Chat ? null : re.Chat).on(Fd, (e) => e === re.UserList ? null : re.UserList).on(gd, (e) => e === re.Votes ? null : re.Votes).on(hd, (e) => e === re.Settings ? null : re.Settings);
function Si(e, n) {
  if (e === null || n === null || e === void 0 || n === void 0)
    return e === n;
  if (typeof e != typeof n)
    return !1;
  if (Object.is(e, n))
    return !0;
  if (typeof e == "object") {
    if (e.constructor !== n.constructor)
      return !1;
    if (Array.isArray(e)) {
      if (e.length !== n.length)
        return !1;
      for (let a = 0; a < e.length; ++a)
        if (!Si(e[a], n[a]))
          return !1;
      return !0;
    }
    if (e.constructor === RegExp)
      return e.source === n.source && e.flags === n.flags;
    if (e.valueOf !== Object.prototype.valueOf)
      return e.valueOf() === n.valueOf();
    if (e.toString !== Object.prototype.toString)
      return e.toString() === n.toString();
    const i = Object.keys(e);
    if (i.length !== Object.keys(n).length)
      return !1;
    for (let a = 0; a < i.length; ++a)
      if (!Object.prototype.hasOwnProperty.call(n, i[a]))
        return !1;
    for (let a = 0; a < i.length; ++a) {
      const t = i[a];
      if (!(t === "_owner" && e.$$typeof) && !Si(e[t], n[t]))
        return !1;
    }
    return !0;
  }
  return !1;
}
var bd = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function yd(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
var Qi = { exports: {} };
(function(e, n) {
  (function(i, a) {
    a(n);
  })(bd, function(i) {
    function a(m) {
      return m != null && typeof m == "object" && m["@@functional/placeholder"] === !0;
    }
    function t(m) {
      return function p(k) {
        return arguments.length === 0 || a(k) ? p : m.apply(this, arguments);
      };
    }
    function l(m) {
      return function p(k, F) {
        switch (arguments.length) {
          case 0:
            return p;
          case 1:
            return a(k) ? p : t(function(S) {
              return m(k, S);
            });
          default:
            return a(k) && a(F) ? p : a(k) ? t(function(S) {
              return m(S, F);
            }) : a(F) ? t(function(S) {
              return m(k, S);
            }) : m(k, F);
        }
      };
    }
    function o(m) {
      return function p(k, F, S) {
        switch (arguments.length) {
          case 0:
            return p;
          case 1:
            return a(k) ? p : l(function(N, y) {
              return m(k, N, y);
            });
          case 2:
            return a(k) && a(F) ? p : a(k) ? l(function(N, y) {
              return m(N, F, y);
            }) : a(F) ? l(function(N, y) {
              return m(k, N, y);
            }) : t(function(N) {
              return m(k, F, N);
            });
          default:
            return a(k) && a(F) && a(S) ? p : a(k) && a(F) ? l(function(N, y) {
              return m(N, y, S);
            }) : a(k) && a(S) ? l(function(N, y) {
              return m(N, F, y);
            }) : a(F) && a(S) ? l(function(N, y) {
              return m(k, N, y);
            }) : a(k) ? t(function(N) {
              return m(N, F, S);
            }) : a(F) ? t(function(N) {
              return m(k, N, S);
            }) : a(S) ? t(function(N) {
              return m(k, F, N);
            }) : m(k, F, S);
        }
      };
    }
    function s(m) {
      return Object.prototype.toString.call(m) === "[object Object]";
    }
    function r(m, p) {
      return Object.prototype.hasOwnProperty.call(p, m);
    }
    var c = o(function(p, k, F) {
      var S = {}, N;
      for (N in k)
        r(N, k) && (S[N] = r(N, F) ? p(N, k[N], F[N]) : k[N]);
      for (N in F)
        r(N, F) && !r(N, S) && (S[N] = F[N]);
      return S;
    }), u = o(function m(p, k, F) {
      return c(function(S, N, y) {
        return s(N) && s(y) ? m(p, N, y) : p(S, N, y);
      }, k, F);
    }), v = o(function(p, k, F) {
      return u(function(S, N, y) {
        return p(N, y);
      }, k, F);
    });
    i.mergeDeepWith = v, Object.defineProperty(i, "__esModule", { value: !0 });
  });
})(Qi, Qi.exports);
var pr = Qi.exports, Nr = pr.mergeDeepWith;
const Td = /* @__PURE__ */ yd(Nr), fr = (e) => {
  var i;
  if (e == null)
    return "event";
  const n = (i = e.conditions) == null ? void 0 : i.terminate;
  return !(n != null && n.empty) && (n == null ? void 0 : n.time) == null ? "room" : "event";
}, Md = (e, n) => n != null ? new Set([...e ?? [], ...n.permissions_grant].filter((i) => !n.permissions_deny.includes(i))) : new Set(e);
var ne = "/src/entities/meet/model/subscription.ts";
const _i = h({
  loc: {
    file: ne,
    line: 35,
    column: 2
  },
  name: "eventMeetSubscriptionSelfReceived",
  sid: "tjf57r:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionSelfReceived"
}), Fr = h({
  loc: {
    file: ne,
    line: 37,
    column: 53
  },
  name: "eventMeetSubscriptionSelfInitReceived",
  sid: "qnbmi9:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionSelfInitReceived"
}), Ra = h({
  loc: {
    file: ne,
    line: 40,
    column: 2
  },
  name: "eventMeetSubscriptionMemberReceived",
  sid: "-ite3f1:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionMemberReceived"
}), Cd = h({
  loc: {
    file: ne,
    line: 43,
    column: 2
  },
  name: "eventMeetSubscriptionRtcIceCandidateReceived",
  sid: "bozjhv:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionRtcIceCandidateReceived"
}), xa = h({
  loc: {
    file: ne,
    line: 46,
    column: 2
  },
  name: "eventMeetSubscriptionRtcInitReceived",
  sid: "-9ejhlu:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionRtcInitReceived"
}), wd = h({
  loc: {
    file: ne,
    line: 49,
    column: 2
  },
  name: "eventMeetSubscriptionRtcSDPReceived",
  sid: "-n71x6w:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionRtcSDPReceived"
}), gr = h({
  loc: {
    file: ne,
    line: 52,
    column: 2
  },
  name: "eventMeetSubscriptionRtcMidiEventsReceived",
  sid: "-n4n041:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionRtcMidiEventsReceived"
}), Ei = h({
  loc: {
    file: ne,
    line: 55,
    column: 2
  },
  name: "eventMeetSubscriptionRtcMemberReceived",
  sid: "t7ojw2:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionRtcMemberReceived"
}), _d = h({
  loc: {
    file: ne,
    line: 60,
    column: 2
  },
  name: "eventMeetSubscriptionVoteReceived",
  sid: "-cbman3:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionVoteReceived"
}), Ed = h({
  loc: {
    file: ne,
    line: 63,
    column: 2
  },
  name: "eventMeetSubscriptionChatReceived",
  sid: "-bacway:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionChatReceived"
}), Rd = h({
  loc: {
    file: ne,
    line: 66,
    column: 2
  },
  name: "eventMeetSubscriptionSpaceReceived",
  sid: "6q7c07:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionSpaceReceived"
}), xd = h({
  loc: {
    file: ne,
    line: 68,
    column: 40
  },
  name: "eventRestartSubscription",
  sid: "qdlj8s:/src/entities/meet/model/subscription.ts:eventRestartSubscription"
}), $n = h({
  loc: {
    file: ne,
    line: 71,
    column: 2
  },
  name: "eventMeetSubscriptionStatusUpdated",
  sid: "-5ccept:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionStatusUpdated"
}), Dd = D(en(), {
  loc: {
    file: ne,
    line: 73,
    column: 39
  },
  name: "$meetSubscriptionStatus",
  sid: "-xyrng0:/src/entities/meet/model/subscription.ts:$meetSubscriptionStatus"
}).on($n, (e, n) => n), Da = D(null, {
  loc: {
    file: ne,
    line: 77,
    column: 22
  },
  name: "$subscription",
  sid: "uxv8op:/src/entities/meet/model/subscription.ts:$subscription"
}), Id = yn({
  and: {
    effect: $(async ({
      currentSpaceId: e,
      email: n,
      nickname: i,
      password: a,
      subscription: t
    }) => {
      if (t != null)
        for (; !t.closed; )
          t.unsubscribe(), await br(100);
      let l = !1;
      return Z().subscribe({
        query: ir,
        variables: {
          email: n,
          nickname: i,
          password: a,
          space: e
        }
      }).subscribe({
        complete() {
          wa(), $n(en());
        },
        error(o) {
          o.message === "Socket closed" || o.message === "connection exists" || o.message === "no connection" ? xd() : ($n(ua(o)), vi({
            message: jo(o, {
              invalid_state: {
                "peer already exists": "Конфликт, пользователь уже присутствует во встрече",
                "space terminated": "Данная встреча уже завершена"
              }
            }),
            type: Ye.Error
          }));
        },
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        next(o) {
          var r, c, u, v, m, p;
          o.errors && vi({
            message: o.errors,
            type: Ye.Error
          }), l || (l = !0, rr(), $n(he(void 0)));
          const s = (r = o.data) == null ? void 0 : r.meet;
          s != null && (s.self != null ? (_i(s.self), s.self.init && Fr()) : ((c = s.rtc) == null ? void 0 : c.init) != null ? xa(s.rtc.init) : ((u = s.rtc) == null ? void 0 : u.member) != null ? Ei(s.rtc.member) : ((v = s.rtc) == null ? void 0 : v.ice) != null ? Cd(s.rtc.ice) : ((m = s.rtc) == null ? void 0 : m.sdp) != null ? wd(s.rtc.sdp) : ((p = s.rtc) == null ? void 0 : p.midi) != null ? gr(s.rtc.midi) : s.member != null ? Ra(s.member) : s.chat != null ? Ed(s.chat) : s.vote != null ? _d(s.vote) : s.space != null ? Rd(s.space) : console.warn("Unknown subscription event received", s));
        }
      });
    }, {
      loc: {
        file: ne,
        line: 80,
        column: 10
      },
      name: "effect",
      sid: "-oc38y6:/src/entities/meet/model/subscription.ts:effect"
    }),
    mapParams: (e, n) => ({
      currentSpaceId: e,
      /* TODO */
      email: void 0,
      nickname: n.$anonymousNickname,
      /* TODO */
      password: void 0,
      subscription: n.$subscription
    }),
    source: {
      $anonymousNickname: pa,
      $subscription: Da
    }
  },
  or: {
    loc: {
      file: ne,
      line: 79,
      column: 33
    },
    name: "subscribeToMeetFx",
    sid: "bsrixg:/src/entities/meet/model/subscription.ts:subscribeToMeetFx"
  }
});
Da.on(Id.doneData, (e, n) => n);
_({
  and: [{
    clock: wi.doneData,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: Mn,
    target: Id
  }],
  or: {
    loc: {
      file: ne,
      line: 196,
      column: 0
    },
    sid: "-95u8rm:/src/entities/meet/model/subscription.ts:"
  }
});
const hr = yn({
  and: {
    effect: $((e) => {
      e == null || e.unsubscribe();
    }, {
      loc: {
        file: ne,
        line: 204,
        column: 10
      },
      name: "effect",
      sid: "kt4k:/src/entities/meet/model/subscription.ts:effect"
    }),
    source: Da
  },
  or: {
    loc: {
      file: ne,
      line: 203,
      column: 35
    },
    name: "closeSubscriptionFx",
    sid: "-q5igis:/src/entities/meet/model/subscription.ts:closeSubscriptionFx"
  }
}), br = (e) => new Promise((n) => setTimeout(() => {
  n(void 0);
}, e));
var Oe = "/src/entities/meet/model/space.ts";
const yr = D(en(), {
  loc: {
    file: Oe,
    line: 32,
    column: 25
  },
  name: "$myPeerId",
  sid: "-l2ihky:/src/entities/meet/model/space.ts:$myPeerId"
}), Ia = D(en(), {
  loc: {
    file: Oe,
    line: 34,
    column: 46
  },
  name: "$meetSpacesMembershipBySpaceId",
  sid: "-n12u8i:/src/entities/meet/model/space.ts:$meetSpacesMembershipBySpaceId"
});
Ia.on(_i, (e, n) => {
  if (n.membership == null)
    return e;
  const i = Se((a) => a.ref, /* @__PURE__ */ new Map(), e);
  return n.membership.forEach((a) => {
    i.set(a.space.id, a);
  }), he({
    ref: i
  });
});
const fe = D([], {
  loc: {
    file: Oe,
    line: 66,
    column: 22
  },
  name: "$peers",
  sid: "hu79hc:/src/entities/meet/model/space.ts:$peers"
}), Ad = fe.map((e) => ({
  ref: new Map(e.filter((n) => n.stream != null).flatMap((n) => n.stream.getTracks().map((i) => [i.id, n.stream.id])))
}));
Cn(Ad, {
  log: "enabled"
});
fe.reset(Ne);
const Aa = D(en(), {
  loc: {
    file: Oe,
    line: 81,
    column: 36
  },
  name: "$meetSpacesBySpaceId",
  sid: "kzx7vj:/src/entities/meet/model/space.ts:$meetSpacesBySpaceId"
});
Aa.on(_i, (e, n) => {
  var a;
  const i = Se((t) => t.ref, /* @__PURE__ */ new Map(), e);
  return (a = n.membership) == null || a.forEach((t) => {
    i.set(t.space.id, t.space);
  }), he({
    ref: i
  });
}).on(Rd, (e, n) => {
  const i = Se((a) => a.ref, /* @__PURE__ */ new Map(), e);
  return n.add != null && n.add.spaces.forEach((a) => {
    i.set(a.id, a);
  }), n.change != null && n.change.spaces.forEach((a) => {
    i.set(a.id, a);
  }), n.remove != null && n.remove.space_ids.forEach((a) => {
    i.delete(a);
  }), he({
    ref: i
  });
});
const Ri = we({
  and: [{
    currentSpaceId: Qn,
    meetSpaces: Aa
  }],
  or: {
    loc: {
      file: Oe,
      line: 114,
      column: 33
    },
    name: "$currentMeetSpace",
    sid: "-q6u26d:/src/entities/meet/model/space.ts:$currentMeetSpace"
  }
}).map(({
  currentSpaceId: e,
  meetSpaces: n
}) => Se((i) => e != null ? i.ref.get(e) ?? null : null, null, n)), ei = D({
  ref: /* @__PURE__ */ new Map()
}, {
  loc: {
    file: Oe,
    line: 128,
    column: 40
  },
  name: "$currentMeetSpaceMembers",
  sid: "-dmci2n:/src/entities/meet/model/space.ts:$currentMeetSpaceMembers"
}).on(Ra, (e, n) => (n.add != null && n.add.members.forEach((i) => {
  e.ref.set(i.id, i);
}), n.remove != null && n.remove.members.forEach((i) => {
  e.ref.delete(i.id);
}), n.data != null && n.data.members.forEach((i) => {
  e.ref.set(i.id, i);
}), n.change != null && n.change.members.forEach((i) => {
  const a = e.ref.get(i.id);
  a != null ? e.ref.set(i.id, Td(
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    (t, l) => l ?? t,
    a,
    i
  )) : e.ref.set(i.id, {
    ...i,
    permissions_deny: i.permissions_deny ?? [],
    permissions_grant: i.permissions_grant ?? []
  });
}), {
  ref: e.ref
})), Va = we({
  and: [
    {
      currentMeetSpaceMembers: ei,
      currentSpaceId: Qn,
      meetSpacesMembershipBySpaceId: Ia,
      peers: fe
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    ({
      currentMeetSpaceMembers: e,
      currentSpaceId: n,
      meetSpacesMembershipBySpaceId: i,
      peers: a
    }) => {
      var l;
      if (n == null)
        return [];
      if (!Xn(i))
        return [];
      const t = (l = i.data.ref.get(n)) == null ? void 0 : l.id;
      return [...e.ref.values()].filter((o) => o.id !== t).map((o) => ({
        member: o,
        peer: a.find((s) => s.id === o.id)
      }));
    }
  ],
  or: {
    loc: {
      file: Oe,
      line: 176,
      column: 48
    },
    name: "$currentMeetSpaceMembersExceptMe",
    sid: "-s49yrz:/src/entities/meet/model/space.ts:$currentMeetSpaceMembersExceptMe"
  }
});
Cn(Va, {
  log: "enabled"
});
const an = we({
  and: [
    {
      currentMeetSpaceMembers: ei,
      currentSpaceId: Qn,
      meetSpacesMembershipBySpaceId: Ia,
      peers: fe
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    ({
      currentMeetSpaceMembers: e,
      currentSpaceId: n,
      meetSpacesMembershipBySpaceId: i,
      peers: a
    }) => {
      var o;
      if (n == null || !Xn(i))
        return null;
      const t = (o = i.data.ref.get(n)) == null ? void 0 : o.id;
      return {
        member: t != null ? e.ref.get(t) : void 0,
        peer: a.find((s) => s.id === t)
      };
    }
  ],
  or: {
    loc: {
      file: Oe,
      line: 206,
      column: 35
    },
    name: "$currentMeetSpaceMe",
    sid: "rd3171:/src/entities/meet/model/space.ts:$currentMeetSpaceMe"
  }
});
Cn(an, {
  log: "enabled"
});
const tn = D(/* @__PURE__ */ new Set(), {
  loc: {
    file: Oe,
    line: 239,
    column: 46
  },
  name: "$currentMeetSpaceMyPermissions",
  sid: "-vyvpbb:/src/entities/meet/model/space.ts:$currentMeetSpaceMyPermissions"
});
tn.on(we({
  and: [{
    currentMeetSpace: Ri,
    currentMeetSpaceMemberMe: an
  }],
  or: {
    loc: {
      file: Oe,
      line: 244,
      column: 2
    },
    sid: "hx7ayd:/src/entities/meet/model/space.ts:"
  }
}), (e, n) => {
  var a, t;
  const i = Md((a = n.currentMeetSpace) == null ? void 0 : a.permissions, (t = n.currentMeetSpaceMemberMe) == null ? void 0 : t.member);
  return Si([...e], [...i]) ? e : i;
});
var cn = "/src/entities/meet/model/reaction-emojis.ts";
const Oa = h({
  loc: {
    file: cn,
    line: 7,
    column: 44
  },
  name: "eventRoomReactionPanelToggle",
  sid: "5oqbwd:/src/entities/meet/model/reaction-emojis.ts:eventRoomReactionPanelToggle"
}), Tr = h({
  loc: {
    file: cn,
    line: 8,
    column: 43
  },
  name: "eventRoomReactionPanelReset",
  sid: "q9gpik:/src/entities/meet/model/reaction-emojis.ts:eventRoomReactionPanelReset"
}), Vd = D(!1, {
  loc: {
    file: cn,
    line: 10,
    column: 40
  },
  name: "$roomReactionPanelOpened",
  sid: "-u5e0bs:/src/entities/meet/model/reaction-emojis.ts:$roomReactionPanelOpened"
}).on(Oa, (e) => !e).reset(Tr), Od = h({
  loc: {
    file: cn,
    line: 22,
    column: 39
  },
  name: "eventRoomReactionUpdate",
  sid: "-byvrxx:/src/entities/meet/model/reaction-emojis.ts:eventRoomReactionUpdate"
}), Mr = h({
  loc: {
    file: cn,
    line: 23,
    column: 38
  },
  name: "eventRoomReactionReset",
  sid: "-d1hn3z:/src/entities/meet/model/reaction-emojis.ts:eventRoomReactionReset"
}), Pd = 2e3, Cr = D([], {
  loc: {
    file: cn,
    line: 26,
    column: 30
  },
  name: "$roomReactions",
  sid: "d7qi8c:/src/entities/meet/model/reaction-emojis.ts:$roomReactions"
}).on(Od, (e, n) => {
  const i = e.filter(({
    created: a
  }) => Date.now() - a.getTime() < Pd);
  return i.push(...n), i;
}).reset(Mr, Ne, ue), wr = [];
_({
  and: [{
    clock: Ei,
    fn: (e) => e.change != null ? e.change.filter((n) => n.reaction.emotion != null).map((n) => ({
      author: n.id,
      created: /* @__PURE__ */ new Date(),
      id: Date.now(),
      left: `${Math.floor(Math.random() * 10)}vw`,
      reaction: n.reaction.emotion ?? ""
    })) : wr,
    target: Od
  }],
  or: {
    loc: {
      file: cn,
      line: 43,
      column: 0
    },
    sid: "-i33qlr:/src/entities/meet/model/reaction-emojis.ts:"
  }
});
var _e = "/src/entities/meet/model/vote.ts";
const jn = h("eventVoteCreateFormVisible", {
  loc: {
    file: _e,
    line: 30,
    column: 42
  },
  name: "eventVoteCreateFormVisible",
  sid: "-x8b414:/src/entities/meet/model/vote.ts:eventVoteCreateFormVisible"
}), Ld = h({
  loc: {
    file: _e,
    line: 34,
    column: 34
  },
  name: "eventLoadMoreVotes",
  sid: "-hbcug9:/src/entities/meet/model/vote.ts:eventLoadMoreVotes"
}), Ud = $({
  loc: {
    file: _e,
    line: 36,
    column: 26
  },
  name: "castVoteFx",
  sid: "-m5wh2f:/src/entities/meet/model/vote.ts:castVoteFx"
}), xi = $(({
  cursor: e,
  distanceFromEnd: n
}) => Z().query({
  query: lr,
  variables: {
    cursor: e,
    limit: Math.max(n, 5)
  }
}).then((i) => i.data.meet.vote.list), {
  loc: {
    file: _e,
    line: 41,
    column: 28
  },
  name: "fetchVotesFx",
  sid: "-hl5199:/src/entities/meet/model/vote.ts:fetchVotesFx"
});
_({
  and: [{
    clock: Ca,
    fn: () => ({
      cursor: "",
      distanceFromEnd: 0
    }),
    target: xi
  }],
  or: {
    loc: {
      file: _e,
      line: 51,
      column: 0
    },
    sid: "7exatd:/src/entities/meet/model/vote.ts:"
  }
});
const _r = D("", {
  loc: {
    file: _e,
    line: 57,
    column: 25
  },
  name: "$votesListCursor",
  sid: "-vvwp8n:/src/entities/meet/model/vote.ts:$votesListCursor"
}).on(xi.doneData, (e, n) => n.cursor ?? null), $d = D([], {
  loc: {
    file: _e,
    line: 62,
    column: 21
  },
  name: "$votesFromBE",
  sid: "i4a518:/src/entities/meet/model/vote.ts:$votesFromBE"
});
$d.reset(ue, Ne).on(xi.doneData, (e, n) => [...n.data, ...e]).on(_d, (e, n) => {
  var t, l, o, s;
  const i = (l = (t = n.tally) == null ? void 0 : t.count) == null ? void 0 : l.votes, a = (s = (o = n.tally) == null ? void 0 : o.self) == null ? void 0 : s.votes;
  if (n.start != null)
    return [...e, n.start];
  if (n.terminate != null) {
    const r = n.terminate.id;
    return e.filter((c) => c.id !== r);
  } else {
    if (i != null)
      return e.map((r) => {
        const c = i.find((u) => u.id === r.id);
        return c == null ? r : {
          ...r,
          cases: r.cases.map((u, v) => ({
            ...u,
            count: c.cases[v]
          }))
        };
      });
    if (a != null)
      return e.map((r) => {
        const c = a.find((u) => u.id === r.id);
        return c == null ? r : {
          ...r,
          cases: r.cases.map((u, v) => c.cases.includes(v) ? {
            ...u,
            me: !0
          } : u)
        };
      });
  }
});
const Bd = $d.map((e) => e.map((n) => ({
  ...n,
  isActive: n.terminated == null && !n.cases.some((i) => i.me)
}))), Er = Bd.map((e) => e.filter((n) => n.isActive).length), nS = $({
  handler: (e) => Z().mutate({
    mutation: vd,
    variables: {
      cases: e.cases,
      conditions: e.conditions,
      multiple: e.multiple,
      title: e.title
    }
  })
}, {
  loc: {
    file: _e,
    line: 176,
    column: 26
  },
  name: "postVoteFx",
  sid: "xec4cd:/src/entities/meet/model/vote.ts:postVoteFx"
}), Rr = D(!1, {
  loc: {
    file: _e,
    line: 195,
    column: 40
  },
  name: "$isVoteCreateFormVisible",
  sid: "8klxca:/src/entities/meet/model/vote.ts:$isVoteCreateFormVisible"
}).on(jn, (e, n) => n).reset(ue, Ne);
_({
  and: [{
    clock: Ld,
    source: _r,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => Mn(e),
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => ({
      cursor: e,
      distanceFromEnd: n.distanceFromEnd
    }),
    target: xi
  }],
  or: {
    loc: {
      file: _e,
      line: 199,
      column: 0
    },
    sid: "x2koa6:/src/entities/meet/model/vote.ts:"
  }
});
Ud.use(({
  cases: e,
  voteId: n
}) => Z().mutate({
  mutation: or,
  variables: {
    cases: e,
    id: n
  }
}).then((i) => {
  var a;
  return ((a = i.data) == null ? void 0 : a.meet.vote.cast) ?? !1;
}));
const Rt = $((e) => (
  /* TODOgetApolloClient()
    .mutate({ mutation: VoteEndDocument, variables: { id: vote.id } })
    .then(() => {}) */
  Promise.resolve()
), {
  loc: {
    file: _e,
    line: 222,
    column: 25
  },
  name: "endVoteFx",
  sid: "-d6skpr:/src/entities/meet/model/vote.ts:endVoteFx"
}), xr = /* @__PURE__ */ g((e) => /* @__PURE__ */ d(w, {
  alignContent: "center",
  alignItems: "center",
  flex: "1",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
  ...e
}));
xr.displayName = "CallsView";
const Dr = /* @__PURE__ */ g(() => {
  const e = me(), n = E(() => {
    vi({
      message: void 0,
      type: Ye.NormalHangup
    });
  }, []);
  return /* @__PURE__ */ d(Yn, {
    bg: "red.50",
    h: {
      base: "48px",
      lg: "48px",
      md: "36px"
    },
    onPress: n,
    w: {
      base: "48px",
      lg: "48px",
      md: "36px"
    },
    children: /* @__PURE__ */ d(gs, {
      fill: e.colors.white,
      height: "24",
      width: "24"
    })
  });
});
Dr.displayName = "ControlHangup";
const Ir = 64, Xi = 64, Ar = 376, Vr = 720, iS = ({
  asidePanelOpened: e,
  callViewFull: n
}) => {
  const i = I(Vd), {
    height: a,
    width: t
  } = wn(), l = mn(), [o] = is([{
    orientation: "portrait"
  }]), s = yt({
    base: t,
    lg: e ? t - Ar : t
  }), r = yt({
    base: a - ((n || l === "base") && o ? 108 : 0) - (o ? 80 : 0) - Ir - (o && l === "base" && i || i && l !== "base" ? Xi : 0),
    lg: Math.max(a - (n ? 112 : 0) - 56 - (i ? Xi : 0), Vr - (n ? 112 : 0) - 56 - (i ? Xi : 0))
  }), c = L(() => {
    if (n)
      return {
        height: r,
        width: s
      };
    const u = 16 / 9 < s / r;
    return {
      height: u ? r : s / 16 * 9,
      width: u ? r / 9 * 16 : s
    };
  }, [n, s, r]);
  return L(() => ({
    containerSize: c,
    viewCallsHeight: r,
    viewCallsWidth: s,
    winHeight: a,
    winWidth: t
  }), [c, r, s, a, t]);
};
function Or(e) {
  const n = mn();
  return L(() => e === 1 ? "100%" : n === "base" ? e === 2 || e === 4 ? "50%" : e === 3 ? "33%" : `${90 / e * 2}%` : e < 5 ? "50%" : e > 9 ? `${100 / Math.sqrt(e)}%` : "33.333%", [n, e]);
}
function Pr(e) {
  const n = mn();
  return L(() => e === 1 ? "100%" : n === "base" ? e < 4 ? "100%" : "50%" : e < 5 ? "50%" : e > 9 ? `${100 / Math.sqrt(e)}%` : "33.333%", [n, e]);
}
function aS(e, n) {
  const i = mn(), a = Or(i === "base" || n ? 1 : e === 1 ? 2 : e), t = Pr(i === "base" || n ? 1 : e === 1 ? 2 : e);
  return {
    cardHeight: a,
    cardWidth: t
  };
}
const xt = j.object({
  password: j.string({
    required_error: x("VALIDATION.ERRORS.REQUIRED")
  }).nonempty(x("VALIDATION.ERRORS.REQUIRED"))
}).strict(), Lr = /* @__PURE__ */ g(({
  onCancel: e,
  onSubmit: n,
  variant: i
}) => {
  const {
    control: a,
    handleSubmit: t
  } = En({
    mode: "all",
    resolver: Jn(xt)
  });
  return /* @__PURE__ */ d(ba, {
    hasCancel: !0,
    okButtonText: "Продолжить",
    onClose: e,
    onOk: t((o) => {
      const s = xt.parse(o);
      n(s.password);
    }),
    title: "Для входа в комнату необходим пароль",
    children: /* @__PURE__ */ d(ae, {
      control: a,
      name: "password",
      render: ({
        field: o,
        fieldState: {
          error: s
        }
      }) => /* @__PURE__ */ d(cs, {
        errorMessage: s == null ? void 0 : s.message,
        isInvalid: !!s,
        onBlur: o.onBlur,
        onChangeText: o.onChange,
        placeholder: i === "room" ? "пароль для входа в комнату" : "пароль чтобы присоединиться к встрече",
        value: o.value
      })
    })
  });
});
Lr.displayName = "PasswordModal";
var ni = "/src/entities/webrtc/model/devices.ts";
const zd = h({
  loc: {
    file: ni,
    line: 11,
    column: 38
  },
  name: "eventDeviceListChanged",
  sid: "317v9b:/src/entities/webrtc/model/devices.ts:eventDeviceListChanged"
}), jd = h({
  loc: {
    file: ni,
    line: 12,
    column: 42
  },
  name: "eventDeviceListInitialized",
  sid: "gxjeyt:/src/entities/webrtc/model/devices.ts:eventDeviceListInitialized"
});
Ie.addEventListener("devicechange", () => {
  zd();
});
const Di = D(en(), {
  loc: {
    file: ni,
    line: 18,
    column: 24
  },
  name: "$devices",
  sid: "-8a7fnc:/src/entities/webrtc/model/devices.ts:$devices"
}), Ur = Di.map((e) => Xe((n) => n.filter((i) => i.kind === "videoinput"), e)), $r = Di.map((e) => Xe((n) => n.filter((i) => i.kind === "audioinput"), e)), tS = Di.map((e) => Xe((n) => n.filter((i) => i.kind === "audiooutput"), e)), Hd = $(async () => (await Ie.getUserMedia({
  audio: !0,
  video: !0
}), Ie.enumerateDevices()), {
  loc: {
    file: ni,
    line: 32,
    column: 27
  },
  name: "enumerateDevicesFx",
  sid: "-re7mn9:/src/entities/webrtc/model/devices.ts:enumerateDevicesFx"
});
Di.on(Hd.doneData, (e, n) => he(n));
_({
  and: [{
    clock: [zd, jd],
    target: Hd
  }],
  or: {
    loc: {
      file: ni,
      line: 39,
      column: 0
    },
    sid: "lrz6sm:/src/entities/webrtc/model/devices.ts:"
  }
});
function Wd(e) {
  switch (e.state) {
    case "plain-camera":
    case "screen-sharing":
      return e.stream;
    case "background-blur-initializing":
    case "background-blur-ready":
    case "background-image-initializing":
    case "background-image-ready":
      return e.cameraStream;
    default:
      return;
  }
}
function Br(e) {
  switch (e.state) {
    case "plain-camera":
    case "screen-sharing":
      return e.stream;
    case "background-blur-ready":
    case "background-image-ready":
      return e.virtualBackgroundStream;
    default:
      return;
  }
}
const Gd = /* @__PURE__ */ g(({
  onChange: e,
  small: n,
  value: i,
  images: a
}) => {
  const {
    t
  } = ie();
  return wn(), /* @__PURE__ */ f(be, {
    children: [/* @__PURE__ */ d(hn, {
      marginY: 3,
      size: "sm",
      children: t("SETTINGS.CAMERA.VIRTUAL_BACKGROUND.TITLE", {
        defaultValue: "Виртуальный фон"
      })
    }), /* @__PURE__ */ d(us, {
      noBorder: !0,
      flexWrap: "wrap",
      w: "100%",
      children: /* @__PURE__ */ f(dd, {
        dataSet: {
          media: zr.imagesContainer
        },
        style: jr.imagesContainer,
        children: [/* @__PURE__ */ d(mi, {
          onPress: () => e({
            backgroundType: "none"
          }),
          selected: i.backgroundType === "none",
          small: n,
          children: t("SETTINGS.CAMERA.VIRTUAL_BACKGROUND.NONE", {
            defaultValue: "Нет"
          })
        }), /* @__PURE__ */ d(mi, {
          onPress: () => e({
            backgroundType: "blur"
          }),
          selected: i.backgroundType === "blur",
          small: n,
          children: /* @__PURE__ */ d(b, {
            children: t("SETTINGS.CAMERA.VIRTUAL_BACKGROUND.BLUR", {
              defaultValue: "Размытие"
            })
          })
        }), a.map((l) => /* @__PURE__ */ d(mi, {
          image: !0,
          onPress: () => e({
            backgroundImage: l,
            backgroundType: "image"
          }),
          selected: i.backgroundType === "image" && i.backgroundImage === l,
          small: n,
          children: /* @__PURE__ */ d(gi, {
            height: n ? 100 : 200,
            src: l,
            width: n ? 100 : 200
          })
        }, l))]
      })
    })]
  });
});
Gd.displayName = "Background";
const mi = /* @__PURE__ */ g(({
  children: e,
  image: n,
  onPress: i,
  selected: a,
  small: t
}) => /* @__PURE__ */ d(ld, {
  onPress: i,
  children: /* @__PURE__ */ d(sn, {
    mb: 3,
    mr: 3,
    children: /* @__PURE__ */ d(sn, {
      alignItems: "center",
      borderColor: a ? "blue.100" : "gray.100",
      borderStyle: "solid",
      borderWidth: a ? 3 : 1,
      height: t ? 102 : 202,
      justifyContent: "center",
      width: n ? t ? 102 : 202 : 102,
      children: e
    })
  })
}));
mi.displayName = "ImageBox";
const {
  ids: zr,
  styles: jr
} = Ho({
  imagesContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
    [`@media (min-width: ${ze.breakpoints.md}px)`]: {
      justifyContent: "flex-start"
    }
  }
}), Hr = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "SendIceCandidate"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "candidate"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "ListType",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "String"
              }
            }
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "ice"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "candidates"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "candidate"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Wr = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "SendOffer"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "offer"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "sdp"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "offer"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "offer"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Gr = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "SendAnswer"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "answer"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "sdp"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "answer"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "answer"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, qd = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "UpdateMyMediaStatus"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "audio"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "RTCMediaAudio"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "video"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "RTCMediaVideo"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "audio_mode"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "RTCMediaAudioMode"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "media"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "audio"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "audio"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "video"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "video"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "audio_mode"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "audio_mode"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
};
var qr = "/src/features/meet-webrtc/model/rtc/rtcpeerconnection.ts";
const K = D(null, {
  and: {
    name: "$rtcPeerConnection"
  },
  loc: {
    file: qr,
    line: 6,
    column: 34
  },
  name: "$rtcPeerConnection",
  sid: "j4qimc:/src/features/meet-webrtc/model/rtc/rtcpeerconnection.ts:$rtcPeerConnection"
}), Ii = K.map((e) => e != null);
Cn(Ii, {
  log: "enabled"
});
var ee = "/src/features/meet-webrtc/model/rtc/video.ts";
const Pa = {
  facing: "user",
  type: "simple"
}, Xd = h("eventVirtualBackgroundCreated", {
  loc: {
    file: ee,
    line: 142,
    column: 45
  },
  name: "eventVirtualBackgroundCreated",
  sid: "cgb36t:/src/features/meet-webrtc/model/rtc/video.ts:eventVirtualBackgroundCreated"
}), pi = D({
  backgroundType: "none"
}, {
  loc: {
    file: ee,
    line: 146,
    column: 39
  },
  name: "$selectedBackgroundType",
  sid: "-bxnvnm:/src/features/meet-webrtc/model/rtc/video.ts:$selectedBackgroundType"
}), Xr = B.discriminatedUnion("type", [B.object({
  facing: B.union([B.literal("user"), B.literal("environment")]),
  type: B.literal("simple")
}), B.object({
  id: B.string(),
  type: B.literal("by_id")
})]).nullable(), La = D(null, {
  loc: {
    file: ee,
    line: 161,
    column: 31
  },
  name: "$selectedCamera",
  sid: "-30yits:/src/features/meet-webrtc/model/rtc/video.ts:$selectedCamera"
}), Ua = h({
  loc: {
    file: ee,
    line: 163,
    column: 36
  },
  name: "eventVideoTrackEnded",
  sid: "qxlg71:/src/features/meet-webrtc/model/rtc/video.ts:eventVideoTrackEnded"
}), ce = D({
  enabled: !1,
  state: "initial"
}, {
  loc: {
    file: ee,
    line: 165,
    column: 22
  },
  name: "$video",
  sid: "didqjb:/src/features/meet-webrtc/model/rtc/video.ts:$video"
}), dS = ce.map((e) => e.state === "background-blur-initializing" || e.state === "background-image-initializing" || e.state === "background-blur-ready" || e.state === "background-image-ready"), Yd = h({
  loc: {
    file: ee,
    line: 177,
    column: 38
  },
  name: "eventToggleCameraOnOff",
  sid: "-rqhj2t:/src/features/meet-webrtc/model/rtc/video.ts:eventToggleCameraOnOff"
}), Kd = h({
  loc: {
    file: ee,
    line: 179,
    column: 45
  },
  name: "eventScreenSharingIconPressed",
  sid: "-xjb6w9:/src/features/meet-webrtc/model/rtc/video.ts:eventScreenSharingIconPressed"
}), Jd = h({
  loc: {
    file: ee,
    line: 181,
    column: 39
  },
  name: "eventSwitchToNextCamera",
  sid: "-fypndf:/src/features/meet-webrtc/model/rtc/video.ts:eventSwitchToNextCamera"
}), Qd = $({
  loc: {
    file: ee,
    line: 183,
    column: 36
  },
  name: "switchToNextCameraFx",
  sid: "tkpk5y:/src/features/meet-webrtc/model/rtc/video.ts:switchToNextCameraFx"
}), Yr = $({
  loc: {
    file: ee,
    line: 192,
    column: 38
  },
  name: "changeVirtualBackgroundTypeFx",
  sid: "63sg1m:/src/features/meet-webrtc/model/rtc/video.ts:changeVirtualBackgroundTypeFx"
}), We = yn({
  and: {
    effect: $(async ({
      event: e,
      rtcPeerConnection: n,
      selectedBackgroundType: i,
      selectedCamera: a,
      video: t
    }) => {
      var l, o, s, r;
      try {
        if (n == null)
          throw new Error("$rtcPeerConnection is null");
        switch (t.state) {
          case "initial":
            switch (e.type) {
              case "camera-icon-pressed":
                const {
                  stream: c,
                  videoTrack: u
                } = await qe(a);
                return i.backgroundType === "none" ? {
                  enabled: !0,
                  sender: n.addTrack(u, c),
                  state: "plain-camera",
                  stream: c,
                  videoTrack: u
                } : i.backgroundType === "blur" ? {
                  cameraStream: c,
                  cameraVideoTrack: u,
                  enabled: !0,
                  oldVideoTrack: void 0,
                  sender: void 0,
                  state: "background-blur-initializing"
                } : {
                  backgroundImage: i.backgroundImage,
                  cameraStream: c,
                  cameraVideoTrack: u,
                  enabled: !0,
                  oldVideoTrack: void 0,
                  sender: void 0,
                  state: "background-image-initializing"
                };
              case "screen-sharing-icon-pressed": {
                const {
                  stream: v,
                  videoTrack: m
                } = await Vn();
                return {
                  cameraPreviouslyEnabled: !1,
                  enabled: !0,
                  sender: n.addTrack(m, v),
                  state: "screen-sharing",
                  stream: v,
                  videoTrack: m
                };
              }
              case "selected-camera-changed":
              case "selected-virtual-background-changed":
              case "rtc-peer-connection-recreated":
                return t;
              case "video-track-ended":
              case "virtual-background-created":
                return On(t, e);
              default:
                throw new X(e);
            }
          case "screen-sharing":
            switch (e.type) {
              case "screen-sharing-icon-pressed":
                if (t.cameraPreviouslyEnabled) {
                  const {
                    stream: c,
                    videoTrack: u
                  } = await qe(a);
                  return i.backgroundType === "none" ? (t.videoTrack.stop(), await t.sender.replaceTrack(u), {
                    enabled: !0,
                    sender: t.sender,
                    state: "plain-camera",
                    stream: c,
                    videoTrack: u
                  }) : i.backgroundType === "blur" ? {
                    cameraStream: c,
                    cameraVideoTrack: u,
                    enabled: !0,
                    oldVideoTrack: t.videoTrack,
                    sender: t.sender,
                    state: "background-blur-initializing"
                  } : {
                    backgroundImage: i.backgroundImage,
                    cameraStream: c,
                    cameraVideoTrack: u,
                    enabled: !0,
                    oldVideoTrack: t.videoTrack,
                    sender: t.sender,
                    state: "background-image-initializing"
                  };
                } else
                  return {
                    enabled: !1,
                    sender: t.sender,
                    state: "video-track-ended",
                    stream: t.stream,
                    videoTrack: t.videoTrack
                  };
              case "video-track-ended":
                return {
                  enabled: !1,
                  sender: t.sender,
                  state: "video-track-ended",
                  stream: t.stream,
                  videoTrack: t.videoTrack
                };
              case "camera-icon-pressed": {
                const {
                  stream: c,
                  videoTrack: u
                } = await qe(a);
                return i.backgroundType === "none" ? (t.videoTrack.stop(), await t.sender.replaceTrack(u), {
                  enabled: !0,
                  sender: t.sender,
                  state: "plain-camera",
                  stream: c,
                  videoTrack: u
                }) : i.backgroundType === "blur" ? {
                  cameraStream: c,
                  cameraVideoTrack: u,
                  enabled: !0,
                  oldVideoTrack: t.videoTrack,
                  sender: t.sender,
                  state: "background-blur-initializing"
                } : {
                  backgroundImage: i.backgroundImage,
                  cameraStream: c,
                  cameraVideoTrack: u,
                  enabled: !0,
                  oldVideoTrack: t.videoTrack,
                  sender: t.sender,
                  state: "background-image-initializing"
                };
              }
              case "rtc-peer-connection-recreated": {
                const c = e.newRTCPeerConnection.addTrack(t.videoTrack, t.stream);
                return {
                  ...t,
                  sender: c
                };
              }
              case "selected-camera-changed":
              case "selected-virtual-background-changed":
                return t;
              case "virtual-background-created":
                return On(t, e);
              default:
                throw new X(e);
            }
          case "plain-camera":
            switch (e.type) {
              case "video-track-ended":
                return {
                  enabled: !1,
                  sender: t.sender,
                  state: "video-track-ended",
                  stream: t.stream,
                  videoTrack: t.videoTrack
                };
              case "camera-icon-pressed":
                return t.videoTrack.enabled = !t.videoTrack.enabled, {
                  ...t,
                  enabled: t.videoTrack.enabled
                };
              case "screen-sharing-icon-pressed": {
                const {
                  stream: c,
                  videoTrack: u
                } = await Vn();
                return t.videoTrack.stop(), await t.sender.replaceTrack(u), {
                  cameraPreviouslyEnabled: !0,
                  enabled: !0,
                  sender: t.sender,
                  state: "screen-sharing",
                  stream: c,
                  videoTrack: u
                };
              }
              case "selected-camera-changed": {
                const {
                  stream: c,
                  videoTrack: u
                } = await qe(e.selectedCamera);
                return t.videoTrack.stop(), await t.sender.replaceTrack(u), {
                  enabled: !0,
                  sender: t.sender,
                  state: "plain-camera",
                  stream: c,
                  videoTrack: u
                };
              }
              case "rtc-peer-connection-recreated": {
                const c = e.newRTCPeerConnection.addTrack(t.videoTrack, t.stream);
                return {
                  ...t,
                  sender: c
                };
              }
              case "selected-virtual-background-changed":
                return e.background.backgroundType === "none" ? t : e.background.backgroundType === "blur" ? {
                  cameraStream: t.stream,
                  cameraVideoTrack: t.videoTrack,
                  enabled: t.enabled,
                  oldVideoTrack: t.videoTrack,
                  sender: t.sender,
                  state: "background-blur-initializing"
                } : {
                  backgroundImage: e.background.backgroundImage,
                  cameraStream: t.stream,
                  cameraVideoTrack: t.videoTrack,
                  enabled: t.enabled,
                  oldVideoTrack: t.videoTrack,
                  sender: t.sender,
                  state: "background-image-initializing"
                };
              case "virtual-background-created":
                return On(t, e);
              default:
                throw new X(e);
            }
          case "background-blur-initializing":
          case "background-image-initializing":
            switch (e.type) {
              case "virtual-background-created": {
                if (e.canvasRef.current == null)
                  throw new Error("canvasRef is null");
                e.canvasRef.current.getContext("webgl2");
                const c = e.canvasRef.current.captureStream(30), u = c.getVideoTracks()[0];
                let v = t.sender;
                return v == null ? v = n.addTrack(u, c) : await v.replaceTrack(u), t.state === "background-blur-initializing" ? {
                  ...t,
                  sender: v,
                  state: "background-blur-ready",
                  videoTrack: u,
                  virtualBackgroundStream: c
                } : {
                  ...t,
                  sender: v,
                  state: "background-image-ready",
                  videoTrack: u,
                  virtualBackgroundStream: c
                };
              }
              case "video-track-ended":
                return (l = t.oldVideoTrack) == null || l.stop(), {
                  enabled: !1,
                  sender: t.sender,
                  state: "video-track-ended",
                  stream: t.cameraStream,
                  videoTrack: t.cameraVideoTrack
                };
              case "camera-icon-pressed":
                return (o = t.oldVideoTrack) == null || o.stop(), t.cameraVideoTrack.stop(), {
                  enabled: !1,
                  sender: t.sender,
                  state: "video-track-ended",
                  stream: t.cameraStream,
                  videoTrack: t.cameraVideoTrack
                };
              case "selected-camera-changed": {
                const {
                  stream: c,
                  videoTrack: u
                } = await qe(e.selectedCamera);
                return (s = t.oldVideoTrack) == null || s.stop(), t.cameraVideoTrack.stop(), t.state === "background-blur-initializing" ? {
                  cameraStream: c,
                  cameraVideoTrack: u,
                  enabled: !0,
                  oldVideoTrack: void 0,
                  sender: t.sender,
                  state: "background-blur-initializing"
                } : {
                  backgroundImage: t.backgroundImage,
                  cameraStream: c,
                  cameraVideoTrack: u,
                  enabled: !0,
                  oldVideoTrack: void 0,
                  sender: t.sender,
                  state: "background-image-initializing"
                };
              }
              case "screen-sharing-icon-pressed": {
                const {
                  stream: c,
                  videoTrack: u
                } = await Vn();
                (r = t.oldVideoTrack) == null || r.stop(), t.cameraVideoTrack.stop();
                let v = t.sender;
                return v == null ? v = n.addTrack(u, c) : await v.replaceTrack(u), {
                  cameraPreviouslyEnabled: !0,
                  enabled: !0,
                  sender: v,
                  state: "screen-sharing",
                  stream: c,
                  videoTrack: u
                };
              }
              case "selected-virtual-background-changed": {
                if (e.background.backgroundType === "none") {
                  const c = t.cameraStream.getVideoTracks()[0];
                  let u = t.sender;
                  return u == null ? u = n.addTrack(c, t.cameraStream) : await u.replaceTrack(c), {
                    enabled: t.enabled,
                    sender: u,
                    state: "plain-camera",
                    stream: t.cameraStream,
                    videoTrack: c
                  };
                }
                return e.background.backgroundType === "blur" ? {
                  cameraStream: t.cameraStream,
                  cameraVideoTrack: t.cameraVideoTrack,
                  enabled: t.enabled,
                  oldVideoTrack: t.oldVideoTrack,
                  sender: t.sender,
                  state: "background-blur-initializing"
                } : {
                  backgroundImage: e.background.backgroundImage,
                  cameraStream: t.cameraStream,
                  cameraVideoTrack: t.cameraVideoTrack,
                  enabled: t.enabled,
                  oldVideoTrack: t.oldVideoTrack,
                  sender: t.sender,
                  state: "background-image-initializing"
                };
              }
              case "rtc-peer-connection-recreated":
                return {
                  ...t,
                  sender: void 0
                };
              default:
                throw new X(e);
            }
          case "background-blur-ready":
          case "background-image-ready":
            switch (e.type) {
              case "camera-icon-pressed":
                return t.videoTrack.enabled = !t.videoTrack.enabled, {
                  ...t,
                  enabled: t.videoTrack.enabled
                };
              case "selected-camera-changed": {
                const {
                  stream: v,
                  videoTrack: m
                } = await qe(e.selectedCamera);
                return t.videoTrack.stop(), t.cameraVideoTrack.stop(), {
                  cameraStream: v,
                  cameraVideoTrack: m,
                  enabled: !0,
                  oldVideoTrack: void 0,
                  sender: t.sender,
                  state: "background-blur-initializing"
                };
              }
              case "screen-sharing-icon-pressed":
                const {
                  stream: c,
                  videoTrack: u
                } = await Vn();
                return t.videoTrack.stop(), t.cameraVideoTrack.stop(), await t.sender.replaceTrack(u), {
                  cameraPreviouslyEnabled: !0,
                  enabled: !0,
                  sender: t.sender,
                  state: "screen-sharing",
                  stream: c,
                  videoTrack: u
                };
              case "virtual-background-created":
                On(t, e);
              case "video-track-ended":
                return {
                  enabled: !1,
                  sender: t.sender,
                  state: "video-track-ended",
                  stream: t.cameraStream,
                  videoTrack: t.videoTrack
                };
              case "selected-virtual-background-changed":
                if (e.background.backgroundType === "none") {
                  const v = t.cameraStream.getVideoTracks()[0];
                  return await t.sender.replaceTrack(v), {
                    enabled: t.enabled,
                    sender: t.sender,
                    state: "plain-camera",
                    stream: t.cameraStream,
                    videoTrack: v
                  };
                } else
                  return e.background.backgroundType === "blur" ? {
                    cameraStream: t.cameraStream,
                    cameraVideoTrack: t.cameraVideoTrack,
                    enabled: t.enabled,
                    oldVideoTrack: t.videoTrack,
                    sender: t.sender,
                    state: "background-blur-initializing"
                  } : {
                    backgroundImage: e.background.backgroundImage,
                    cameraStream: t.cameraStream,
                    cameraVideoTrack: t.cameraVideoTrack,
                    enabled: t.enabled,
                    oldVideoTrack: t.videoTrack,
                    sender: t.sender,
                    state: "background-image-initializing"
                  };
              case "rtc-peer-connection-recreated": {
                const v = e.newRTCPeerConnection.addTrack(t.videoTrack, t.cameraStream);
                return {
                  ...t,
                  sender: v
                };
              }
              default:
                throw new X(e);
            }
          case "video-track-ended":
            switch (e.type) {
              case "screen-sharing-icon-pressed":
                const {
                  stream: c,
                  videoTrack: u
                } = await Vn();
                t.videoTrack.stop();
                let v = t.sender;
                return v == null ? v = n.addTrack(u, c) : await v.replaceTrack(u), {
                  cameraPreviouslyEnabled: !1,
                  enabled: !0,
                  sender: v,
                  state: "screen-sharing",
                  stream: c,
                  videoTrack: u
                };
              case "video-track-ended":
                On(t, e);
              case "virtual-background-created":
                return t;
              case "selected-camera-changed": {
                const {
                  stream: m,
                  videoTrack: p
                } = await qe(e.selectedCamera);
                return t.videoTrack.stop(), {
                  cameraStream: m,
                  cameraVideoTrack: p,
                  enabled: !0,
                  oldVideoTrack: void 0,
                  sender: t.sender,
                  state: "background-blur-initializing"
                };
              }
              case "camera-icon-pressed": {
                const {
                  stream: m,
                  videoTrack: p
                } = await qe(a);
                if (i.backgroundType === "none") {
                  let k = t.sender;
                  return k == null ? k = n.addTrack(p, m) : await k.replaceTrack(p), {
                    enabled: !0,
                    sender: k,
                    state: "plain-camera",
                    stream: m,
                    videoTrack: p
                  };
                } else
                  return i.backgroundType === "blur" ? {
                    cameraStream: m,
                    cameraVideoTrack: p,
                    enabled: !0,
                    oldVideoTrack: void 0,
                    sender: void 0,
                    state: "background-blur-initializing"
                  } : {
                    backgroundImage: i.backgroundImage,
                    cameraStream: m,
                    cameraVideoTrack: p,
                    enabled: !0,
                    oldVideoTrack: void 0,
                    sender: void 0,
                    state: "background-image-initializing"
                  };
              }
              case "selected-virtual-background-changed":
                return t;
              case "rtc-peer-connection-recreated":
                return {
                  ...t,
                  sender: void 0
                };
              default:
                throw new X(e);
            }
          default:
            throw new X(t);
        }
      } catch (c) {
        throw Ma(c, {
          extra: {
            silent: !0
          }
        }), c;
      }
    }, {
      loc: {
        file: ee,
        line: 202,
        column: 10
      },
      name: "effect",
      sid: "rkqs62:/src/features/meet-webrtc/model/rtc/video.ts:effect"
    }),
    mapParams: (e, n) => ({
      ...n,
      event: e
    }),
    source: {
      rtcPeerConnection: K,
      selectedBackgroundType: pi,
      selectedCamera: La,
      video: ce
    }
  },
  or: {
    loc: {
      file: ee,
      line: 201,
      column: 35
    },
    name: "videoStateReducerFx",
    sid: "ymsr7l:/src/features/meet-webrtc/model/rtc/video.ts:videoStateReducerFx"
  }
});
_({
  and: [{
    clock: Xd,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e) => ({
      canvasRef: e,
      type: "virtual-background-created"
    }),
    target: We
  }],
  or: {
    loc: {
      file: ee,
      line: 852,
      column: 0
    },
    sid: "xcpjlt:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
_({
  and: [{
    clock: pi,
    source: {
      rtcPeerConnection: K
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      rtcPeerConnection: e
    }) => e != null,
    fn: (e, n) => ({
      background: n,
      type: "selected-virtual-background-changed"
    }),
    target: We
  }],
  or: {
    loc: {
      file: ee,
      line: 862,
      column: 0
    },
    sid: "xtr600:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
const Zd = We.pending, Kr = we({
  and: [{
    video: ce,
    videoPending: Zd
  }],
  or: {
    loc: {
      file: ee,
      line: 877,
      column: 26
    },
    name: "$videoIsOn",
    sid: "p9imv6:/src/features/meet-webrtc/model/rtc/video.ts:$videoIsOn"
  }
}).map(({
  video: e,
  videoPending: n
}) => n || e.state === "plain-camera" && e.enabled || e.state === "background-blur-initializing" && e.enabled || e.state === "background-blur-ready" && e.enabled || e.state === "background-image-initializing" && e.enabled || e.state === "background-image-ready" && e.enabled);
_({
  and: [{
    clock: Yd,
    source: {
      rtcPeerConnection: K
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      rtcPeerConnection: e
    }) => e != null,
    fn: () => ({
      type: "camera-icon-pressed"
    }),
    target: We
  }],
  or: {
    loc: {
      file: ee,
      line: 890,
      column: 0
    },
    sid: "z7sfzv:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
_({
  and: [{
    clock: Kd,
    source: {
      rtcPeerConnection: K
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      rtcPeerConnection: e
    }) => e != null,
    fn: () => ({
      type: "screen-sharing-icon-pressed"
    }),
    target: We
  }],
  or: {
    loc: {
      file: ee,
      line: 901,
      column: 0
    },
    sid: "-pds4pm:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
ce.on(We.doneData, (e, n) => n);
_({
  and: [{
    clock: Jd,
    source: {
      rtcPeerConnection: K,
      selectedCamera: La,
      video: ce
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      rtcPeerConnection: e
    }) => e != null,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: ({
      rtcPeerConnection: e,
      selectedCamera: n,
      video: i
    }) => ({
      rtcPeerConnection: e,
      selectedCamera: n ?? Pa,
      video: i
    }),
    target: Qd
  }],
  or: {
    loc: {
      file: ee,
      line: 913,
      column: 0
    },
    sid: "-ovmx4p:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
_({
  and: [{
    clock: La,
    source: {
      rtcPeerConnection: K
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      rtcPeerConnection: e
    }, n) => e != null && n != null,
    fn: (e, n) => ({
      selectedCamera: n,
      type: "selected-camera-changed"
    }),
    target: We
  }],
  or: {
    loc: {
      file: ee,
      line: 930,
      column: 0
    },
    sid: "-nz724e:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
_({
  and: [{
    // clock: $remoteSettings.map(({ backgroundImage, backgroundType }) => ({
    //   backgroundImage,
    //   backgroundType,
    // })),
    clock: Wo({
      clock: pi,
      until: Ii
    }),
    source: {
      rtcPeerConnection: K,
      selectedBackgroundType: pi,
      video: ce
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      rtcPeerConnection: e
    }) => e != null,
    target: Yr
  }],
  or: {
    loc: {
      file: ee,
      line: 944,
      column: 0
    },
    sid: "-nfy9cr:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
Qd.use(async ({
  selectedCamera: e
}) => {
  if (e == null)
    return Pa;
  if (e.type === "simple")
    return {
      facing: e.facing === "environment" ? "user" : "environment",
      type: "simple"
    };
  const n = (await Ie.enumerateDevices()).filter((t) => t.kind === "videoinput"), i = n.findIndex((t) => t.deviceId === e.id);
  return {
    id: n[(i + 1) % n.length].deviceId,
    type: "by_id"
  };
});
_({
  and: [{
    clock: Ua,
    fn: () => ({
      type: "video-track-ended"
    }),
    target: We
  }],
  or: {
    loc: {
      file: ee,
      line: 995,
      column: 0
    },
    sid: "-l26csf:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
async function qe(e) {
  const n = e ?? Pa, i = {
    audio: !1,
    video: n.type === "simple" ? {
      facingMode: n
    } : {
      deviceId: {
        exact: n.id
      }
    }
  };
  let a;
  try {
    a = await Ie.getUserMedia(i);
  } catch (l) {
    if (!(typeof l == "object" && (l == null ? void 0 : l.name) === "OverconstrainedError" && l.constraint === "deviceId"))
      throw Ma(l, {
        extra: {
          silent: !0
        }
      }), _a({
        message: `Ошибка камеры: ${l instanceof Error ? l.message : String(l)}`,
        status: "warning"
      }), l;
    a = await Ie.getUserMedia({
      audio: !1,
      video: {
        facingMode: "user"
      }
    });
  }
  const t = a.getVideoTracks()[0];
  return t.addEventListener("ended", () => {
    Ua();
  }), {
    stream: a,
    videoTrack: t
  };
}
async function Vn() {
  const e = await Ie.getDisplayMedia({
    audio: !1,
    video: {
      // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
      // @ts-ignore
      cursor: "always"
    }
  }), n = e.getVideoTracks()[0];
  return n.addEventListener("ended", () => {
    Ua();
  }), {
    stream: e,
    videoTrack: n
  };
}
function On(e, n) {
  throw new Error(`unreachable state: ${JSON.stringify(e)} event: ${JSON.stringify(n)}`);
}
const Jr = (e, n) => {
  const i = navigator.webkitGetUserMedia !== void 0, a = {
    autoGainControl: !1,
    echoCancellation: n !== V.MediaAudioModePlay,
    googEchoCancellation: n !== V.MediaAudioModePlay,
    googExperimentalEchoCancellation: n !== V.MediaAudioModePlay,
    googExperimentalNoiseSuppression: n !== V.MediaAudioModePlay,
    googHighpassFilter: n !== V.MediaAudioModePlay,
    googNoiseSuppression: n !== V.MediaAudioModePlay,
    googTypingNoiseDetection: n !== V.MediaAudioModePlay,
    noiseSuppression: n !== V.MediaAudioModePlay
  };
  return i ? {
    audio: {
      ...a,
      echoCancellationType: n !== V.MediaAudioModePlay ? "aec3" : void 0,
      ...e != null ? {
        deviceId: {
          exact: e
        }
      } : {}
    },
    video: !1
  } : {
    audio: {
      ...e ? {
        deviceId: {
          exact: e
        }
      } : {},
      ...a
    },
    video: !1
  };
};
var le = "/src/features/meet-webrtc/model/rtc/audio.ts";
const el = h({
  loc: {
    file: le,
    line: 110,
    column: 42
  },
  name: "eventMicrophoneIconPressed",
  sid: "-a4cvf4:/src/features/meet-webrtc/model/rtc/audio.ts:eventMicrophoneIconPressed"
}), $a = h({
  loc: {
    file: le,
    line: 111,
    column: 37
  },
  name: "eventAudioModeChanged",
  sid: "nyp2rd:/src/features/meet-webrtc/model/rtc/audio.ts:eventAudioModeChanged"
}), Ba = h({
  loc: {
    file: le,
    line: 112,
    column: 36
  },
  name: "eventAudioTrackEnded",
  sid: "y1x1b7:/src/features/meet-webrtc/model/rtc/audio.ts:eventAudioTrackEnded"
}), za = h({
  loc: {
    file: le,
    line: 114,
    column: 43
  },
  name: "eventNewAudioDeviceSelected",
  sid: "k7mhrj:/src/features/meet-webrtc/model/rtc/audio.ts:eventNewAudioDeviceSelected"
}), nl = h({
  loc: {
    file: le,
    line: 117,
    column: 53
  },
  name: "eventNewAudioDeviceSelectionCancelled",
  sid: "-fd6x4t:/src/features/meet-webrtc/model/rtc/audio.ts:eventNewAudioDeviceSelectionCancelled"
}), Pe = D({
  enabled: !1,
  state: "initial"
}, {
  loc: {
    file: le,
    line: 119,
    column: 22
  },
  name: "$audio",
  sid: "8lchw8:/src/features/meet-webrtc/model/rtc/audio.ts:$audio"
}), Qr = D(null, {
  loc: {
    file: le,
    line: 124,
    column: 35
  },
  name: "$selectedMicrophone",
  sid: "-evlt7f:/src/features/meet-webrtc/model/rtc/audio.ts:$selectedMicrophone"
}), Zr = D(null, {
  loc: {
    file: le,
    line: 125,
    column: 33
  },
  name: "$selectedSpeakers",
  sid: "cqf6e4:/src/features/meet-webrtc/model/rtc/audio.ts:$selectedSpeakers"
}), un = yn({
  and: {
    effect: $(async ({
      audio: e,
      currentMeetSpaceMyPermissions: n,
      event: i,
      rtcPeerConnection: a,
      selectedMicrophone: t
    }) => {
      var l, o;
      try {
        if (a == null)
          throw new Error("$rtcPeerConnection is null");
        switch (e.state) {
          case "initial":
            switch (i.type) {
              case "rtc-peer-connection-recreated":
                return e;
              case "microphone-icon-pressed": {
                if (!n.has(Y.PermissionChannelMediaAudioSend))
                  return e;
                const {
                  audioTrack: s,
                  sender: r,
                  stream: c
                } = await Ee(void 0, a, t, V.MediaAudioModeTalk);
                return {
                  audioTrack: s,
                  enabled: !0,
                  sender: r,
                  state: "mode-talk-mic-on",
                  stream: c
                };
              }
              case "audio-track-ended":
              case "selected-microphone-changed":
                return e;
              case "audio-mode-changed": {
                const s = i.newMode;
                if (s === V.MediaAudioModeListen)
                  return {
                    audioTrack: void 0,
                    enabled: !1,
                    sender: void 0,
                    state: "mode-listen",
                    stream: void 0
                  };
                if (!n.has(Y.PermissionChannelMediaAudioSend))
                  return e;
                const {
                  audioTrack: r,
                  sender: c,
                  stream: u
                } = await Ee(void 0, a, t, s);
                switch (s) {
                  case V.MediaAudioModePlay:
                    return {
                      audioTrack: r,
                      enabled: !0,
                      sender: c,
                      state: "mode-play",
                      stream: u
                    };
                  case V.MediaAudioModeTalk:
                    return {
                      audioTrack: r,
                      enabled: !0,
                      sender: c,
                      state: "mode-talk-mic-on",
                      stream: u
                    };
                  default:
                    throw new X(s);
                }
              }
              case "audio-send-permission-revoked":
                return e;
              case "audio-track-updated":
                return e;
              default:
                throw new X(i);
            }
          case "mode-listen":
            switch (i.type) {
              case "rtc-peer-connection-recreated":
                if (e.audioTrack != null && e.stream != null) {
                  const s = i.newRTCPeerConnection.addTrack(e.audioTrack, e.stream);
                  return {
                    ...e,
                    sender: s
                  };
                } else
                  return {
                    ...e,
                    sender: void 0
                  };
              case "microphone-icon-pressed":
                return e;
              case "selected-microphone-changed":
                return e.audioTrack != null && e.stream != null ? (e.audioTrack.stop(), {
                  ...e,
                  audioTrack: void 0
                }) : {
                  ...e
                };
              case "audio-track-ended":
                return {
                  ...e,
                  audioTrack: void 0
                };
              case "audio-mode-changed":
                switch (i.newMode) {
                  case V.MediaAudioModeListen:
                    return e;
                  case V.MediaAudioModePlay:
                    if (!n.has(Y.PermissionChannelMediaAudioSend))
                      return e;
                    if (e.audioTrack != null && e.stream != null) {
                      e.audioTrack.enabled = !0;
                      let s = e.sender;
                      return s == null && (s = a.addTrack(e.audioTrack, e.stream)), {
                        audioTrack: e.audioTrack,
                        enabled: !0,
                        sender: s,
                        state: "mode-play",
                        stream: e.stream
                      };
                    } else {
                      const {
                        audioTrack: s,
                        sender: r,
                        stream: c
                      } = await Ee(e.sender, a, t, V.MediaAudioModePlay);
                      return {
                        audioTrack: s,
                        enabled: !0,
                        sender: r,
                        state: "mode-play",
                        stream: c
                      };
                    }
                  case V.MediaAudioModeTalk: {
                    if (!n.has(Y.PermissionChannelMediaAudioSend))
                      return e;
                    e.audioTrack != null && e.stream != null && e.audioTrack.stop();
                    const {
                      audioTrack: s,
                      sender: r,
                      stream: c
                    } = await Ee(e.sender, a, t, V.MediaAudioModeTalk);
                    return {
                      audioTrack: s,
                      enabled: !0,
                      sender: r,
                      state: "mode-talk-mic-on",
                      stream: c
                    };
                  }
                  default:
                    throw new X(i.newMode);
                }
              case "audio-send-permission-revoked":
                return e.audioTrack != null && (e.audioTrack.enabled = !1), {
                  ...e,
                  enabled: !1,
                  state: "mode-talk-mic-off"
                };
              case "audio-track-updated": {
                (l = e.audioTrack) == null || l.stop();
                let s = e.sender;
                return s != null ? await s.replaceTrack(i.newAudioTrack) : s = a.addTrack(i.newAudioTrack, i.newStream), {
                  audioTrack: i.newAudioTrack,
                  enabled: !1,
                  sender: s,
                  state: "mode-listen",
                  stream: i.newStream
                };
              }
              default:
                throw new X(i);
            }
          case "mode-play":
            switch (i.type) {
              case "microphone-icon-pressed":
                return e;
              case "rtc-peer-connection-recreated": {
                const s = i.newRTCPeerConnection.addTrack(e.audioTrack, e.stream);
                return {
                  ...e,
                  sender: s
                };
              }
              case "audio-track-ended":
                return $a(V.MediaAudioModeTalk), {
                  audioTrack: void 0,
                  enabled: !1,
                  sender: e.sender,
                  state: "mode-talk-mic-off",
                  stream: e.stream
                };
              case "selected-microphone-changed": {
                if (!n.has(Y.PermissionChannelMediaAudioSend))
                  return e.audioTrack.enabled = !1, {
                    ...e,
                    enabled: !1,
                    state: "mode-talk-mic-off"
                  };
                const {
                  audioTrack: s,
                  sender: r,
                  stream: c
                } = await Ee(e.sender, a, i.selectedMicrophoneId, V.MediaAudioModePlay);
                return {
                  audioTrack: s,
                  enabled: !0,
                  sender: r,
                  state: "mode-play",
                  stream: c
                };
              }
              case "audio-mode-changed":
                switch (i.newMode) {
                  case V.MediaAudioModeListen:
                    return e.audioTrack.enabled = !1, {
                      audioTrack: e.audioTrack,
                      enabled: !1,
                      sender: e.sender,
                      state: "mode-listen",
                      stream: e.stream
                    };
                  case V.MediaAudioModePlay:
                    return e;
                  case V.MediaAudioModeTalk:
                    return e.audioTrack.enabled = !0, {
                      audioTrack: e.audioTrack,
                      enabled: !0,
                      sender: e.sender,
                      state: "mode-talk-mic-on",
                      stream: e.stream
                    };
                  default:
                    throw new X(i.newMode);
                }
              case "audio-send-permission-revoked":
                return e.audioTrack.enabled = !1, {
                  ...e,
                  enabled: !1,
                  state: "mode-talk-mic-off"
                };
              case "audio-track-updated": {
                e.audioTrack.stop();
                let s = e.sender;
                return s != null ? await s.replaceTrack(i.newAudioTrack) : s = a.addTrack(i.newAudioTrack, i.newStream), {
                  audioTrack: i.newAudioTrack,
                  enabled: !0,
                  sender: s,
                  state: "mode-play",
                  stream: i.newStream
                };
              }
              default:
                throw new X(i);
            }
          case "mode-talk-mic-off":
            switch (i.type) {
              case "rtc-peer-connection-recreated":
                if (e.audioTrack != null && e.stream != null) {
                  const s = i.newRTCPeerConnection.addTrack(e.audioTrack, e.stream);
                  return {
                    audioTrack: e.audioTrack,
                    enabled: !1,
                    sender: s,
                    state: "mode-talk-mic-off",
                    stream: e.stream
                  };
                } else
                  return {
                    ...e,
                    sender: void 0,
                    state: "mode-talk-mic-off"
                  };
              case "audio-mode-changed":
                switch (i.newMode) {
                  case V.MediaAudioModeListen:
                    return e.audioTrack != null && (e.audioTrack.enabled = !1), {
                      audioTrack: e.audioTrack,
                      enabled: !1,
                      sender: e.sender,
                      state: "mode-listen",
                      stream: e.stream
                    };
                  case V.MediaAudioModePlay: {
                    if (!n.has(Y.PermissionChannelMediaAudioSend))
                      return e;
                    const {
                      audioTrack: s,
                      sender: r,
                      stream: c
                    } = await Ee(e.sender, a, t, V.MediaAudioModePlay);
                    return {
                      audioTrack: s,
                      enabled: !0,
                      sender: r,
                      state: "mode-play",
                      stream: c
                    };
                  }
                  case V.MediaAudioModeTalk:
                    if (e.audioTrack != null && e.stream != null)
                      return e.audioTrack.enabled = !0, {
                        audioTrack: e.audioTrack,
                        enabled: !0,
                        sender: e.sender,
                        state: "mode-talk-mic-on",
                        stream: e.stream
                      };
                    {
                      const {
                        audioTrack: s,
                        sender: r,
                        stream: c
                      } = await Ee(e.sender, a, t, V.MediaAudioModeTalk);
                      return {
                        audioTrack: s,
                        enabled: !0,
                        sender: r,
                        state: "mode-talk-mic-on",
                        stream: c
                      };
                    }
                  default:
                    throw new X(i.newMode);
                }
              case "audio-track-ended":
                return {
                  ...e,
                  audioTrack: void 0
                };
              case "selected-microphone-changed":
                return e;
              case "microphone-icon-pressed": {
                if (e.audioTrack != null && e.stream != null)
                  return e.audioTrack.enabled = !0, {
                    audioTrack: e.audioTrack,
                    enabled: !0,
                    sender: e.sender,
                    state: "mode-talk-mic-on",
                    stream: e.stream
                  };
                {
                  const {
                    audioTrack: s,
                    sender: r,
                    stream: c
                  } = await Ee(e.sender, a, t, V.MediaAudioModeTalk);
                  return {
                    audioTrack: s,
                    enabled: !0,
                    sender: r,
                    state: "mode-talk-mic-on",
                    stream: c
                  };
                }
              }
              case "audio-send-permission-revoked":
                return e;
              case "audio-track-updated": {
                (o = e.audioTrack) == null || o.stop();
                let s = e.sender;
                return s != null ? await s.replaceTrack(i.newAudioTrack) : s = a.addTrack(i.newAudioTrack, i.newStream), {
                  audioTrack: i.newAudioTrack,
                  enabled: !1,
                  sender: s,
                  state: "mode-talk-mic-off",
                  stream: i.newStream
                };
              }
              default:
                throw new X(i);
            }
          case "mode-talk-mic-on":
            switch (i.type) {
              case "audio-track-ended":
                return {
                  audioTrack: void 0,
                  enabled: !1,
                  sender: e.sender,
                  state: "mode-talk-mic-off",
                  stream: e.stream
                };
              case "rtc-peer-connection-recreated": {
                const s = i.newRTCPeerConnection.addTrack(e.audioTrack, e.stream);
                return {
                  ...e,
                  sender: s
                };
              }
              case "microphone-icon-pressed":
                return e.audioTrack.enabled = !1, {
                  ...e,
                  enabled: !1,
                  state: "mode-talk-mic-off"
                };
              case "selected-microphone-changed": {
                const {
                  audioTrack: s,
                  sender: r,
                  stream: c
                } = await Ee(e.sender, a, i.selectedMicrophoneId, V.MediaAudioModeTalk);
                return {
                  audioTrack: s,
                  enabled: !0,
                  sender: r,
                  state: "mode-talk-mic-on",
                  stream: c
                };
              }
              case "audio-mode-changed":
                switch (i.newMode) {
                  case V.MediaAudioModeListen:
                    return e.audioTrack.enabled = !1, {
                      audioTrack: e.audioTrack,
                      enabled: !1,
                      sender: e.sender,
                      state: "mode-listen",
                      stream: e.stream
                    };
                  case V.MediaAudioModePlay:
                    const {
                      audioTrack: s,
                      sender: r,
                      stream: c
                    } = await Ee(e.sender, a, t, V.MediaAudioModePlay);
                    return {
                      audioTrack: s,
                      enabled: !0,
                      sender: r,
                      state: "mode-play",
                      stream: c
                    };
                  case V.MediaAudioModeTalk:
                    return e.audioTrack.enabled = !0, {
                      audioTrack: e.audioTrack,
                      enabled: !0,
                      sender: e.sender,
                      state: "mode-talk-mic-on",
                      stream: e.stream
                    };
                  default:
                    throw new X(i.newMode);
                }
              case "audio-send-permission-revoked":
                return e.audioTrack.enabled = !1, {
                  ...e,
                  enabled: !1,
                  state: "mode-talk-mic-off"
                };
              case "audio-track-updated": {
                e.audioTrack.stop();
                let s = e.sender;
                return s != null ? await s.replaceTrack(i.newAudioTrack) : s = a.addTrack(i.newAudioTrack, i.newStream), {
                  audioTrack: i.newAudioTrack,
                  enabled: !0,
                  sender: s,
                  state: "mode-talk-mic-on",
                  stream: i.newStream
                };
              }
              default:
                throw new X(i);
            }
          default:
            throw new X(e);
        }
      } catch (s) {
        throw Ma(s, {
          extra: {
            silent: !0
          }
        }), s;
      }
    }, {
      loc: {
        file: le,
        line: 128,
        column: 10
      },
      name: "effect",
      sid: "z88y64:/src/features/meet-webrtc/model/rtc/audio.ts:effect"
    }),
    mapParams(e, n) {
      return {
        ...n,
        event: e
      };
    },
    source: {
      audio: Pe,
      currentMeetSpaceMyPermissions: tn,
      rtcPeerConnection: K,
      selectedMicrophone: Qr,
      selectedSpeakers: Zr
    }
  },
  or: {
    loc: {
      file: le,
      line: 127,
      column: 35
    },
    name: "audioStateReducerFx",
    sid: "lqvep4:/src/features/meet-webrtc/model/rtc/audio.ts:audioStateReducerFx"
  }
});
Pe.on(un.doneData, (e, n) => n);
const em = un.pending, nm = Pe.map((e) => {
  switch (e.state) {
    case "initial":
    case "mode-talk-mic-on":
    case "mode-talk-mic-off":
      return V.MediaAudioModeTalk;
    case "mode-listen":
      return V.MediaAudioModeListen;
    case "mode-play":
      return V.MediaAudioModePlay;
    default:
      throw new X(e);
  }
}), im = $((e) => Z().mutate({
  mutation: qd,
  variables: {
    audio_mode: e
  }
}).then((n) => {
  var i;
  return (i = n.data) == null ? void 0 : i.meet.rtc.media;
}), {
  loc: {
    file: le,
    line: 803,
    column: 26
  },
  name: "audioModeChangeFx",
  sid: "-w9d9uf:/src/features/meet-webrtc/model/rtc/audio.ts:audioModeChangeFx"
});
_({
  and: [{
    clock: $a,
    target: im
  }],
  or: {
    loc: {
      file: le,
      line: 813,
      column: 0
    },
    sid: "-51r1cv:/src/features/meet-webrtc/model/rtc/audio.ts:"
  }
});
const am = _({
  and: [{
    clock: Ei.map((e) => e.add ?? e.change),
    source: {
      currentMeetSpaceMe: an
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: ({
      currentMeetSpaceMe: e
    }, n) => {
      var a, t;
      const i = (a = e == null ? void 0 : e.member) == null ? void 0 : a.id;
      return ((t = n == null ? void 0 : n.find((l) => l.id === i)) == null ? void 0 : t.media.audio_mode) ?? null;
    }
  }],
  or: {
    loc: {
      file: le,
      line: 815,
      column: 33
    },
    name: "eventMyAudioModeReceived",
    sid: "-js4t3h:/src/features/meet-webrtc/model/rtc/audio.ts:eventMyAudioModeReceived"
  }
});
_({
  and: [{
    clock: am.filter({
      fn: (e) => e != null
    }),
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e) => ({
      newMode: e,
      type: "audio-mode-changed"
    }),
    target: un
  }],
  or: {
    loc: {
      file: le,
      line: 825,
      column: 0
    },
    sid: "-4jltry:/src/features/meet-webrtc/model/rtc/audio.ts:"
  }
});
_({
  and: [{
    clock: el,
    fn: () => ({
      type: "microphone-icon-pressed"
    }),
    target: un
  }],
  or: {
    loc: {
      file: le,
      line: 836,
      column: 0
    },
    sid: "-420ese:/src/features/meet-webrtc/model/rtc/audio.ts:"
  }
});
_({
  and: [{
    clock: za,
    fn: (e) => ({
      selectedMicrophoneId: e.deviceId,
      type: "selected-microphone-changed"
    }),
    target: un
  }],
  or: {
    loc: {
      file: le,
      line: 842,
      column: 0
    },
    sid: "-3n5yrn:/src/features/meet-webrtc/model/rtc/audio.ts:"
  }
});
_({
  and: [{
    clock: Ba,
    fn: () => ({
      type: "audio-track-ended"
    }),
    target: un
  }],
  or: {
    loc: {
      file: le,
      line: 852,
      column: 0
    },
    sid: "-364cdg:/src/features/meet-webrtc/model/rtc/audio.ts:"
  }
});
_({
  and: [{
    clock: tn,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => !e.has(Y.PermissionChannelMediaAudioSend),
    fn: () => ({
      type: "audio-send-permission-revoked"
    }),
    target: un
  }],
  or: {
    loc: {
      file: le,
      line: 858,
      column: 0
    },
    sid: "-32tkta:/src/features/meet-webrtc/model/rtc/audio.ts:"
  }
});
const il = D(null, {
  loc: {
    file: le,
    line: 871,
    column: 2
  },
  name: "$showNewAudioDeviceSelect",
  sid: "-lstfuo:/src/features/meet-webrtc/model/rtc/audio.ts:$showNewAudioDeviceSelect"
});
il.reset(Ne, ue, za, nl, _i).on(Ba, () => ({
  type: "disconnected"
}));
async function Ee(e, n, i, a) {
  try {
    const t = await Ie.getUserMedia(Jr(i, a)), l = t.getAudioTracks()[0];
    l.addEventListener("ended", () => {
      Ba();
    });
    let o = e;
    return o == null ? o = n.addTrack(l, t) : await o.replaceTrack(l), {
      audioTrack: l,
      sender: o,
      stream: t
    };
  } catch (t) {
    throw _a({
      message: `Ошибка аудио: ${t instanceof Error ? t.message : String(t)}`,
      status: "warning"
    }), t;
  }
}
var ii = {};
ii.byteLength = lm;
ii.toByteArray = sm;
ii.fromByteArray = cm;
var xe = [], Fe = [], tm = typeof Uint8Array < "u" ? Uint8Array : Array, Yi = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
for (var fn = 0, dm = Yi.length; fn < dm; ++fn)
  xe[fn] = Yi[fn], Fe[Yi.charCodeAt(fn)] = fn;
Fe[45] = 62;
Fe[95] = 63;
function al(e) {
  var n = e.length;
  if (n % 4 > 0)
    throw new Error("Invalid string. Length must be a multiple of 4");
  var i = e.indexOf("=");
  i === -1 && (i = n);
  var a = i === n ? 0 : 4 - i % 4;
  return [i, a];
}
function lm(e) {
  var n = al(e), i = n[0], a = n[1];
  return (i + a) * 3 / 4 - a;
}
function om(e, n, i) {
  return (n + i) * 3 / 4 - i;
}
function sm(e) {
  var n, i = al(e), a = i[0], t = i[1], l = new tm(om(e, a, t)), o = 0, s = t > 0 ? a - 4 : a, r;
  for (r = 0; r < s; r += 4)
    n = Fe[e.charCodeAt(r)] << 18 | Fe[e.charCodeAt(r + 1)] << 12 | Fe[e.charCodeAt(r + 2)] << 6 | Fe[e.charCodeAt(r + 3)], l[o++] = n >> 16 & 255, l[o++] = n >> 8 & 255, l[o++] = n & 255;
  return t === 2 && (n = Fe[e.charCodeAt(r)] << 2 | Fe[e.charCodeAt(r + 1)] >> 4, l[o++] = n & 255), t === 1 && (n = Fe[e.charCodeAt(r)] << 10 | Fe[e.charCodeAt(r + 1)] << 4 | Fe[e.charCodeAt(r + 2)] >> 2, l[o++] = n >> 8 & 255, l[o++] = n & 255), l;
}
function rm(e) {
  return xe[e >> 18 & 63] + xe[e >> 12 & 63] + xe[e >> 6 & 63] + xe[e & 63];
}
function mm(e, n, i) {
  for (var a, t = [], l = n; l < i; l += 3)
    a = (e[l] << 16 & 16711680) + (e[l + 1] << 8 & 65280) + (e[l + 2] & 255), t.push(rm(a));
  return t.join("");
}
function cm(e) {
  for (var n, i = e.length, a = i % 3, t = [], l = 16383, o = 0, s = i - a; o < s; o += l)
    t.push(mm(e, o, o + l > s ? s : o + l));
  return a === 1 ? (n = e[i - 1], t.push(
    xe[n >> 2] + xe[n << 4 & 63] + "=="
  )) : a === 2 && (n = (e[i - 2] << 8) + e[i - 1], t.push(
    xe[n >> 10] + xe[n >> 4 & 63] + xe[n << 2 & 63] + "="
  )), t.join("");
}
const Ki = Os.QuickBase64;
Ki && typeof Ki.install == "function" && Ki.install();
function um(e) {
  let n = "", i = 0, a = e.length;
  for (; i < a; ) {
    const t = e[i++];
    n += String.fromCharCode(t);
  }
  return n;
}
function km(e) {
  const n = new ArrayBuffer(e.length), i = new Uint8Array(n);
  for (let a = 0, t = e.length; a < t; a++)
    i[a] = e.charCodeAt(a);
  return n;
}
function vm(e) {
  return typeof base64ToArrayBuffer < "u" ? new Uint8Array(base64ToArrayBuffer(e)) : ii.toByteArray(e);
}
function ja(e) {
  const n = km(e);
  return typeof base64FromArrayBuffer < "u" ? base64FromArrayBuffer(n) : ii.fromByteArray(new Uint8Array(n));
}
function Zi(e) {
  const n = vm(e);
  return um(n);
}
var tl = "/src/features/meet-webrtc/model/rtc/events.ts";
const dl = h("eventRTCTrack", {
  loc: {
    file: tl,
    line: 4,
    column: 29
  },
  name: "eventRTCTrack",
  sid: "-pxbhif:/src/features/meet-webrtc/model/rtc/events.ts:eventRTCTrack"
}), ll = h("eventRTCIceCandidate", {
  loc: {
    file: tl,
    line: 9,
    column: 36
  },
  name: "eventRTCIceCandidate",
  sid: "-5yfjt9:/src/features/meet-webrtc/model/rtc/events.ts:eventRTCIceCandidate"
});
var O = "/src/features/meet-webrtc/model/rtc/common.ts";
const ol = h({
  loc: {
    file: O,
    line: 53,
    column: 45
  },
  name: "eventRTCSignalingStateUpdated",
  sid: "xqgquw:/src/features/meet-webrtc/model/rtc/common.ts:eventRTCSignalingStateUpdated"
}), Ha = h({
  loc: {
    file: O,
    line: 55,
    column: 2
  },
  name: "eventRTCConnectionStateUpdated",
  sid: "-pl99j1:/src/features/meet-webrtc/model/rtc/common.ts:eventRTCConnectionStateUpdated"
}), Wa = h({
  loc: {
    file: O,
    line: 57,
    column: 2
  },
  name: "eventRTCIceConnectionStateUpdated",
  sid: "-l5eawu:/src/features/meet-webrtc/model/rtc/common.ts:eventRTCIceConnectionStateUpdated"
}), sl = h({
  loc: {
    file: O,
    line: 59,
    column: 2
  },
  name: "eventRTCIceGatheringStateUpdated",
  sid: "-2ilfx7:/src/features/meet-webrtc/model/rtc/common.ts:eventRTCIceGatheringStateUpdated"
}), Ga = h({
  loc: {
    file: O,
    line: 60,
    column: 38
  },
  name: "eventNegotiationNeeded",
  sid: "vhl96h:/src/features/meet-webrtc/model/rtc/common.ts:eventNegotiationNeeded"
}), Dn = D(null, {
  loc: {
    file: O,
    line: 62,
    column: 31
  },
  name: "$signalingState",
  sid: "-up41k1:/src/features/meet-webrtc/model/rtc/common.ts:$signalingState"
}).on(ol, (e, n) => n), kn = D(null, {
  loc: {
    file: O,
    line: 66,
    column: 32
  },
  name: "$connectionState",
  sid: "nldqg6:/src/features/meet-webrtc/model/rtc/common.ts:$connectionState"
}).on(Ha, (e, n) => n), In = D(null, {
  loc: {
    file: O,
    line: 69,
    column: 35
  },
  name: "$iceConnectionState",
  sid: "-nmfgn1:/src/features/meet-webrtc/model/rtc/common.ts:$iceConnectionState"
}).on(Wa, (e, n) => n), qa = D(null, {
  loc: {
    file: O,
    line: 72,
    column: 34
  },
  name: "$iceGatheringState",
  sid: "-1e8kxl:/src/features/meet-webrtc/model/rtc/common.ts:$iceGatheringState"
}).on(sl, (e, n) => n);
let $e = /* @__PURE__ */ function(e) {
  return e[e.Good = 0] = "Good", e[e.Loading = 1] = "Loading", e[e.BadConnection = 2] = "BadConnection", e;
}({});
const lS = we({
  and: [{
    connectionState: kn,
    iceConnectionState: In,
    iceGatheringState: qa,
    signalingState: Dn
  }, ({
    connectionState: e,
    iceConnectionState: n,
    iceGatheringState: i,
    signalingState: a
  }) => e === "failed" ? $e.BadConnection : e === "closed" || e === "disconnected" || e === "connecting" || e === "new" ? $e.Loading : n === "disconnected" || n === "failed" || n === "closed" ? $e.BadConnection : n === "new" || n === "checking" || i === "gathering" ? $e.Loading : a === "closed" ? $e.BadConnection : a === "have-local-offer" || a === "have-local-pranswer" || a === "have-remote-offer" || a === "have-remote-pranswer" ? $e.Loading : $e.Good],
  or: {
    loc: {
      file: O,
      line: 81,
      column: 51
    },
    name: "$webrtcUserFriendlyConnectionStatus",
    sid: "-9rbwm0:/src/features/meet-webrtc/model/rtc/common.ts:$webrtcUserFriendlyConnectionStatus"
  }
}), Xa = D([], {
  loc: {
    file: O,
    line: 135,
    column: 27
  },
  name: "$iceServers",
  sid: "-ymwy7l:/src/features/meet-webrtc/model/rtc/common.ts:$iceServers"
});
Xa.on(xa, (e, n) => Si(e, n.ice_servers) ? e : n.ice_servers);
function Sm(e) {
  const n = new Us({
    iceServers: e,
    iceTransportPolicy: "relay"
  });
  return n.addEventListener("connectionstatechange", () => {
    Ha(n.connectionState);
  }), n.addEventListener("iceconnectionstatechange", () => {
    Wa(n.iceConnectionState);
  }), n.addEventListener("icegatheringstatechange", () => {
    sl(n.iceGatheringState);
  }), n.addEventListener("signalingstatechange", () => {
    ol(n.signalingState);
  }), n.addEventListener("negotiationneeded", () => {
    Ga();
  }), n.addEventListener(
    "track",
    // @ts-expect-error wrong type
    (i) => {
      dl(i);
    }
  ), n.addEventListener(
    "icecandidate",
    // @ts-expect-error wrong type
    (i) => {
      ll(i);
    }
  ), n.addTransceiver("video", void 0), n.addTransceiver("audio", void 0), typeof window < "u" && (window.rtcPeerConnection = n), n;
}
const Ai = $({
  loc: {
    file: O,
    line: 193,
    column: 41
  },
  name: "closeRTCPeertConnectionFx",
  sid: "-cw1zrl:/src/features/meet-webrtc/model/rtc/common.ts:closeRTCPeertConnectionFx"
});
Ai.use(({
  rtcPeerConnection: e
}) => {
  e == null || e.close();
});
_({
  and: [{
    clock: wa,
    source: {
      rtcPeerConnection: K
    },
    target: Ai
  }],
  or: {
    loc: {
      file: O,
      line: 202,
      column: 0
    },
    sid: "ljxko0:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
K.reset(Ai.done);
const Vi = yn({
  and: {
    effect: $(({
      iceServers: e,
      rtcPeerConnection: n
    }) => {
      try {
        n == null || n.close();
      } catch {
      }
      return {
        newRTCPeerConnection: Sm(e)
      };
    }, {
      loc: {
        file: O,
        line: 212,
        column: 10
      },
      name: "effect",
      sid: "-6imz4z:/src/features/meet-webrtc/model/rtc/common.ts:effect"
    }),
    source: {
      iceServers: Xa,
      rtcPeerConnection: K
    }
  },
  or: {
    loc: {
      file: O,
      line: 211,
      column: 57
    },
    name: "recreateRTCPeerConnectionAfterReconnectFx",
    sid: "yhfhex:/src/features/meet-webrtc/model/rtc/common.ts:recreateRTCPeerConnectionAfterReconnectFx"
  }
}), pm = _({
  and: [{
    clock: Xa,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => e.length > 0
  }],
  or: {
    loc: {
      file: O,
      line: 233,
      column: 35
    },
    name: "eventIceServersListUpdated",
    sid: "-h5i6yg:/src/features/meet-webrtc/model/rtc/common.ts:eventIceServersListUpdated"
  }
});
_({
  and: [{
    clock: xa,
    source: K,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    // filter: (rtcPeerConnection, init) =>
    //   init.codecs.length > 0 && rtcPeerConnection != null,
    target: Vi
  }],
  or: {
    loc: {
      file: O,
      line: 243,
      column: 0
    },
    sid: "ngnuu5:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
_({
  and: [{
    clock: pm,
    target: Vi
  }],
  or: {
    loc: {
      file: O,
      line: 252,
      column: 0
    },
    sid: "nx5omz:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
K.on(Vi.doneData, (e, n) => n.newRTCPeerConnection);
const rl = $(() => {
  Bo();
}, {
  loc: {
    file: O,
    line: 263,
    column: 42
  },
  name: "restartGraphQLConnectionFx",
  sid: "6h69iz:/src/features/meet-webrtc/model/rtc/common.ts:restartGraphQLConnectionFx"
}), Nm = _({
  and: [{
    clock: [Wa, Ha],
    filter: (e) => e === "failed"
  }],
  or: {
    loc: {
      file: O,
      line: 273,
      column: 33
    },
    name: "eventRTCConnectionFailed",
    sid: "5j768i:/src/features/meet-webrtc/model/rtc/common.ts:eventRTCConnectionFailed"
  }
});
_({
  and: [{
    clock: [Go({
      leading: !0,
      source: Nm,
      timeout: 1e4,
      trailing: !0
    }), xd],
    source: {
      currentSpaceId: Qn,
      rtcPeerConnection: K
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => e.currentSpaceId != null,
    target: rl
  }],
  or: {
    loc: {
      file: O,
      line: 285,
      column: 0
    },
    sid: "pdxxln:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
_({
  and: [{
    clock: Vi.doneData,
    fn: (e) => ({
      newRTCPeerConnection: e.newRTCPeerConnection,
      type: "rtc-peer-connection-recreated"
    }),
    target: We
  }],
  or: {
    loc: {
      file: O,
      line: 327,
      column: 0
    },
    sid: "-xrxzbw:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
const Le = $({
  loc: {
    file: O,
    line: 337,
    column: 24
  },
  name: "hangupFx",
  sid: "1khq10:/src/features/meet-webrtc/model/rtc/common.ts:hangupFx"
});
Le.use(({
  audio: e,
  peers: n,
  rtcPeerConnection: i,
  video: a
}) => {
  var t, l;
  if (i != null) {
    switch (e.state !== "initial" && ((t = e.audioTrack) == null || t.stop()), a.state) {
      case "background-blur-ready":
      case "background-image-ready":
        a.virtualBackgroundStream.getTracks().forEach((o) => {
          o.stop();
        });
      case "background-blur-initializing":
      case "background-image-initializing":
        a.cameraStream.getTracks().forEach((o) => {
          o.stop();
        });
        break;
      case "plain-camera":
      case "screen-sharing":
        a.stream.getTracks().forEach((o) => {
          o.stop();
        });
        break;
      case "initial":
      case "video-track-ended":
        break;
      default:
        throw new X(a);
    }
    for (const o of n)
      for (const s of ((l = o.stream) == null ? void 0 : l.getTracks()) ?? [])
        s.stop();
  }
});
vr.on(Le.done, (e, n) => n.params.exitReason);
Pe.reset(Le.done);
ce.reset(Le.done);
Ci.reset(Le.done);
sr.on(Le, () => !0);
_({
  and: [{
    clock: Le.done,
    target: hr
  }],
  or: {
    loc: {
      file: O,
      line: 396,
      column: 0
    },
    sid: "-uh6f5w:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
_({
  and: [{
    clock: Le.done,
    source: {
      rtcPeerConnection: K
    },
    target: Ai
  }],
  or: {
    loc: {
      file: O,
      line: 400,
      column: 0
    },
    sid: "-k5hi1s:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
const fm = h({
  loc: {
    file: O,
    line: 406,
    column: 41
  },
  name: "eventOfferingStateUpdated",
  sid: "9r9wz5:/src/features/meet-webrtc/model/rtc/common.ts:eventOfferingStateUpdated"
}), oS = D(!1, {
  loc: {
    file: O,
    line: 407,
    column: 27
  },
  name: "$isOffering",
  sid: "-ymhgem:/src/features/meet-webrtc/model/rtc/common.ts:$isOffering"
}).on(fm, (e, n) => n), Fm = $((e) => {
  e.candidate != null && e.candidate.length > 0 && Z().mutate({
    mutation: Hr,
    variables: {
      candidate: ja(JSON.stringify(e.candidate))
    }
  });
}, {
  loc: {
    file: O,
    line: 412,
    column: 34
  },
  name: "sendIceCandidateFx",
  sid: "ihae8q:/src/features/meet-webrtc/model/rtc/common.ts:sendIceCandidateFx"
}), ea = yn({
  and: {
    effect: $(async ({
      rtcPeerConnection: e
    }) => {
      if (e == null)
        throw new Error("$rtcPeerConnection is null");
      e.signalingState !== "have-local-offer" && await e.setLocalDescription(), await Z().mutate({
        mutation: Wr,
        variables: {
          offer: ja(JSON.stringify(e.localDescription))
        }
      });
    }, {
      loc: {
        file: O,
        line: 425,
        column: 10
      },
      name: "effect",
      sid: "-3syxl3:/src/features/meet-webrtc/model/rtc/common.ts:effect"
    }),
    source: {
      rtcPeerConnection: K
    }
  },
  or: {
    loc: {
      file: O,
      line: 424,
      column: 27
    },
    name: "sendOfferFx",
    sid: "-yid6d3:/src/features/meet-webrtc/model/rtc/common.ts:sendOfferFx"
  }
});
_({
  and: [{
    clock: Ga,
    source: {
      isOffering: ea.pending
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    // filter: ({ isOffering }) => isOffering === false,
    target: ea
  }],
  or: {
    loc: {
      file: O,
      line: 449,
      column: 0
    },
    sid: "-i4cv4r:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
qo({
  effect: ea,
  maxAttempts: 5,
  target: Ga,
  timeout: 1e4
});
const gm = _({
  and: [{
    clock: [kn, In, Dn],
    filter: (e) => !["closed", "failed"].includes(e ?? "")
  }],
  or: {
    loc: {
      file: O,
      line: 466,
      column: 30
    },
    name: "eventStopReconnecting",
    sid: "14fg9g:/src/features/meet-webrtc/model/rtc/common.ts:eventStopReconnecting"
  }
}), hm = Xt({
  filter: (e) => ["closed", "failed"].includes(e.$connectionState ?? "") || ["closed", "failed"].includes(e.$iceConnectionState ?? "") || e.$signalingState === "have-local-offer",
  source: we({
    and: [{
      $connectionState: kn,
      $iceConnectionState: In,
      $signalingState: Dn
    }],
    or: {
      loc: {
        file: O,
        line: 477,
        column: 10
      },
      name: "source",
      sid: "-ojq9a2:/src/features/meet-webrtc/model/rtc/common.ts:source"
    }
  }),
  timeout: 1e4
}), bm = Xt({
  filter: (e) => ["closed", "failed"].includes(e.$connectionState ?? "") || ["closed", "failed"].includes(e.$iceConnectionState ?? "") || e.$signalingState === "have-local-offer",
  source: we({
    and: [{
      $connectionState: kn,
      $iceConnectionState: In,
      $signalingState: Dn
    }],
    or: {
      loc: {
        file: O,
        line: 491,
        column: 10
      },
      name: "source",
      sid: "1ysksu:/src/features/meet-webrtc/model/rtc/common.ts:source"
    }
  }),
  timeout: 3e4
});
bm.watch(() => {
  $s("WebRTC connection stalled for over 30 seconds.");
});
const ym = Po({
  sid: "-gjg3h2:/src/features/meet-webrtc/model/rtc/common.ts:eventReconnectInterval",
  fn: () => Bs({
    leading: !0,
    start: hm,
    stop: gm,
    timeout: 15e3,
    trailing: !1
  }),
  name: "eventReconnectInterval",
  method: "interval",
  loc: {
    file: O,
    line: 503,
    column: 31
  }
});
_({
  and: [{
    clock: ym.tick,
    source: {
      rtcPeerConnection: K
    },
    target: rl
  }],
  or: {
    loc: {
      file: O,
      line: 510,
      column: 0
    },
    sid: "-501lfk:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
const ml = $({
  name: "addIceCandidateFx"
}, {
  loc: {
    file: O,
    line: 529,
    column: 33
  },
  name: "addIceCandidateFx",
  sid: "-b8svcj:/src/features/meet-webrtc/model/rtc/common.ts:addIceCandidateFx"
});
ml.use(async ({
  candidate: e,
  rtcPeerConnection: n
}) => {
  if (n == null)
    throw new Error("$rtcPeerConnection is null");
  await Promise.all(e.candidates.map((i) => {
    const a = JSON.parse(Zi(i));
    return n.addIceCandidate(a);
  }));
});
_({
  and: [{
    clock: Cd,
    source: K,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => ({
      rtcPeerConnection: e,
      candidate: n
    }),
    target: ml
  }],
  or: {
    loc: {
      file: O,
      line: 551,
      column: 0
    },
    sid: "-33bb9f:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
const cl = $({
  loc: {
    file: O,
    line: 560,
    column: 28
  },
  name: "processSDPFx",
  sid: "-wacjf9:/src/features/meet-webrtc/model/rtc/common.ts:processSDPFx"
});
_({
  and: [{
    clock: wd,
    source: K,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => ({
      rtcPeerConnection: e,
      sdp: n
    }),
    target: cl
  }],
  or: {
    loc: {
      file: O,
      line: 567,
      column: 0
    },
    sid: "-2iyxb2:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
cl.use(async ({
  rtcPeerConnection: e,
  sdp: n
}) => {
  if (e == null)
    throw new Error("$rtcPeerConnection is null");
  if (n.answer != null && n.answer !== "") {
    const a = Zi(n.answer);
    await e.setRemoteDescription(new RTCSessionDescription(JSON.parse(a)));
  }
  if (!(n.offer != null && n.offer !== ""))
    return;
  const i = Zi(n.offer);
  await e.setRemoteDescription(new RTCSessionDescription(JSON.parse(i))), await e.setLocalDescription(), await Z().mutate({
    mutation: Gr,
    variables: {
      answer: ja(JSON.stringify(e.localDescription))
    }
  });
});
_({
  and: [{
    clock: ll,
    target: Fm
  }],
  or: {
    loc: {
      file: O,
      line: 606,
      column: 0
    },
    sid: "9altyg:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
var Tm = "/src/features/meet-webrtc/model/rtc/link-quality.ts";
let pe = /* @__PURE__ */ function(e) {
  return e[e.GREEN = 0] = "GREEN", e[e.YELLOW = 1] = "YELLOW", e[e.RED = 2] = "RED", e;
}({});
const Mm = we({
  and: [{
    connectionState: kn,
    iceConnectionState: In,
    iceGatheringState: qa,
    signalingState: Dn
  }],
  or: {
    loc: {
      file: Tm,
      line: 16,
      column: 28
    },
    name: "$linkQuality",
    sid: "iwv0ll:/src/features/meet-webrtc/model/rtc/link-quality.ts:$linkQuality"
  }
}).map(({
  connectionState: e,
  iceConnectionState: n,
  iceGatheringState: i,
  signalingState: a
}) => {
  const t = e === "connected" || e == null ? pe.GREEN : e === "connecting" ? pe.YELLOW : pe.RED, l = n === "connected" || n === "completed" ? pe.GREEN : n === "new" || n === "checking" ? pe.YELLOW : pe.RED, o = i === "complete" ? pe.GREEN : pe.YELLOW, s = a === "stable" ? pe.GREEN : a === "closed" ? pe.RED : pe.YELLOW;
  return Math.max(t, l, o, s);
});
fe.on(dl, (e, {
  streams: n
}) => e.map((i) => i.id === n[0].id ? {
  ...i,
  stream: n[0]
} : i));
fe.on(Ei, (e, n) => {
  if (n.add != null)
    return e.concat(n.add);
  if (n.remove != null)
    return e.filter((i) => {
      var a;
      return !((a = n.remove) != null && a.includes(i.id));
    });
  if (n.data != null)
    return n.data;
  if (n.change != null) {
    const i = n.change;
    return e.map((a) => {
      const t = i.find((l) => l.id === a.id);
      return t == null ? a : Td((l, o) => o ?? l, a, t);
    });
  }
  return e;
});
var Ya = "/src/features/meet-webrtc/model/update-av-status.ts";
const Ka = $({
  loc: {
    file: Ya,
    line: 17,
    column: 31
  },
  name: "updateAVStateFx",
  sid: "edigt4:/src/features/meet-webrtc/model/update-av-status.ts:updateAVStateFx"
});
Ka.use(async (e) => {
  await Z().mutate({
    mutation: qd,
    variables: {
      audio: e.audio,
      video: e.video
    }
  });
});
_({
  and: [{
    clock: Pe,
    source: {
      meetSubscriptionStatus: Dd,
      webrtcInitialized: Ii
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      meetSubscriptionStatus: e,
      webrtcInitialized: n
    }) => e.status === ka.Success && n,
    fn: (e, n) => ({
      audio: n.enabled ? ki.MediaAudioMicrophone : ki.MediaAudioOff
    }),
    target: Ka
  }],
  or: {
    loc: {
      file: Ya,
      line: 33,
      column: 0
    },
    sid: "-1ykblj:/src/features/meet-webrtc/model/update-av-status.ts:"
  }
});
_({
  and: [{
    clock: ce,
    source: {
      meetSubscriptionStatus: Dd,
      webrtcInitialized: Ii
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      meetSubscriptionStatus: e,
      webrtcInitialized: n
    }, i) => i.state !== "initial" && e.status === ka.Success && n,
    fn(e, n) {
      return n.state === "initial" || n.state === "video-track-ended" ? {
        video: Re.MediaVideoOff
      } : {
        video: n.enabled ? n.state === "screen-sharing" ? Re.MediaVideoScreen : n.state === "plain-camera" || n.state === "background-blur-ready" || n.state === "background-image-ready" ? Re.MediaVideoCamera : Re.MediaVideoOff : Re.MediaVideoOff
      };
    },
    target: Ka
  }],
  or: {
    loc: {
      file: Ya,
      line: 55,
      column: 0
    },
    sid: "-zdhmf:/src/features/meet-webrtc/model/update-av-status.ts:"
  }
});
function sS() {
  return I(kn) === "connected";
}
const rS = /* @__PURE__ */ Qt.memo(() => {
  const {
    t: e
  } = ie(), n = me(), {
    audio: i,
    audioPending: a
  } = I({
    audio: Pe,
    audioPending: em
  }), t = I(tn), l = E(() => {
    el();
  }, []), o = i.enabled ? "green.50" : "rgba(255, 255, 255, 0.12)", s = i.enabled ? "green.50" : "rgba(255, 255, 255, 0.08)", r = t.has(Y.PermissionChannelMediaAudioSend) ? i.enabled ? i.audioTrack.label : "" : e("ROOM.CONTROLS.MIC.NO_PERMISSION_TOOLTIP");
  return /* @__PURE__ */ d(Na, {
    closeDelay: 3e3,
    label: r,
    children: /* @__PURE__ */ d(Yn, {
      _focus: {
        bg: o
      },
      _hover: {
        bg: o
      },
      _pressed: {
        bg: o
      },
      bg: s,
      disabled: a || !t.has(Y.PermissionChannelMediaAudioSend),
      h: {
        base: "48px",
        lg: "48px",
        md: "36px"
      },
      onPress: l,
      w: {
        base: "48px",
        lg: "48px",
        md: "36px"
      },
      children: /* @__PURE__ */ d(ya, {
        fill: i.enabled ? n.colors.white : n.colors.gray[50],
        height: "24",
        width: "24"
      })
    })
  });
}), mS = /* @__PURE__ */ Qt.memo(() => {
  const e = me(), {
    video: n,
    videoIsOn: i,
    videoPending: a
  } = I({
    video: ce,
    videoIsOn: Kr,
    videoPending: Zd
  }), t = I(tn), l = E(() => {
    Yd();
  }, []), o = L(() => {
    var r;
    return i ? ((r = Wd(n)) == null ? void 0 : r.getVideoTracks()[0].label) ?? "" : "";
  }, [n, i]), s = i ? "green.50" : "rgba(255, 255, 255, 0.12)";
  return /* @__PURE__ */ d(Na, {
    closeDelay: 3e3,
    label: o,
    children: /* @__PURE__ */ d(Yn, {
      _focus: {
        bg: s
      },
      _hover: {
        bg: s
      },
      _pressed: {
        bg: s
      },
      bg: i ? "green.50" : "rgba(255, 255, 255, 0.08)",
      disabled: !t.has(Y.PermissionChannelMediaVideoSend),
      h: {
        base: "48px",
        lg: "48px",
        md: "36px"
      },
      onPress: l,
      opacity: !t.has(Y.PermissionChannelMediaVideoSend) || a ? 0.4 : 1,
      w: "48px",
      children: /* @__PURE__ */ d(id, {
        fill: i ? e.colors.white : e.colors.gray[50],
        height: "24",
        width: "24"
      })
    })
  });
}), Cm = /* @__PURE__ */ g(() => {
  const [e, n] = H(!1), [i, a] = H(!1);
  Q(() => {
    (async () => {
      const r = await Ie.enumerateDevices();
      a(r.filter((c) => c.kind === "videoinput").length >= 2);
    })();
  }, []);
  const t = mn(), l = me(), o = E(() => {
    Jd();
  }, []);
  return i || t === "base" ? /* @__PURE__ */ d(He, {
    _focus: {
      bg: "rgba(255, 255, 255, 0.12)"
    },
    _hover: {
      bg: "rgba(255, 255, 255, 0.12)"
    },
    _pressed: {
      bg: "rgba(255, 255, 255, 0.12)"
    },
    bg: "rgba(255, 255, 255, 0.08)",
    borderRadius: "32",
    h: {
      base: "48px",
      lg: "48px",
      md: "36px"
    },
    onHoverIn: () => n(!0),
    onHoverOut: () => n(!1),
    onPress: o,
    shadow: "1",
    w: {
      base: "48px",
      lg: "48px",
      md: "36px"
    },
    children: /* @__PURE__ */ d(hs, {
      fill: e ? "white" : l.colors.gray[400]
    })
  }) : null;
});
Cm.displayName = "ControlChangeCamera";
const wm = /* @__PURE__ */ g(() => {
  const e = I(nm);
  return /* @__PURE__ */ f(w, {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    position: "relative",
    children: [/* @__PURE__ */ d(ci, {
      activeMode: e,
      mode: V.MediaAudioModeTalk
    }), /* @__PURE__ */ d(ci, {
      activeMode: e,
      mode: V.MediaAudioModeListen
    }), /* @__PURE__ */ d(ci, {
      activeMode: e,
      mode: V.MediaAudioModePlay
    })]
  });
});
wm.displayName = "ControlMode";
const ci = /* @__PURE__ */ g(({
  activeMode: e,
  mode: n
}) => {
  const [i, a] = H(!1), t = me(), l = E(() => {
    $a(n);
  }, [n]), o = e === n;
  return /* @__PURE__ */ d(He, {
    _focus: {
      bg: o ? t.colors.green[600] : "rgba(255, 255, 255, 0.12)"
    },
    _hover: {
      bg: o ? t.colors.green[600] : "rgba(255, 255, 255, 0.12)"
    },
    _pressed: {
      bg: o ? t.colors.green[600] : "rgba(255, 255, 255, 0.12)"
    },
    bg: o ? t.colors.green[700] : "rgba(255, 255, 255, 0.08)",
    borderRadius: "32",
    h: {
      base: "48px",
      lg: "48px",
      md: "36px"
    },
    onHoverIn: () => a(!0),
    onHoverOut: () => a(!1),
    onPress: l,
    px: "5",
    shadow: "1",
    children: /* @__PURE__ */ d(w, {
      alignItems: "center",
      flexDirection: "row",
      children: /* @__PURE__ */ d(b, {
        color: i ? t.colors.white : o ? t.colors.text[300] : t.colors.text[100],
        fontSize: "14",
        ml: "3",
        children: _m(n)
      })
    })
  });
});
ci.displayName = "ControlModeButton";
function _m(e) {
  switch (e) {
    case V.MediaAudioModeListen:
      return x("ROOM.MUSIC_MODE.LISTEN");
    case V.MediaAudioModeTalk:
      return x("ROOM.MUSIC_MODE.TALK");
    case V.MediaAudioModePlay:
      return x("ROOM.MUSIC_MODE.PLAY");
    default:
      throw new X(e);
  }
}
const Em = /* @__PURE__ */ g(({
  iconOnly: e
}) => {
  const [n, i] = H(!1), a = me(), {
    t
  } = ie(), l = I(ce), o = E(() => {
    Kd();
  }, []), s = l.state === "screen-sharing" && l.enabled;
  return /* @__PURE__ */ d(He, {
    _focus: {
      bg: "rgba(255, 255, 255, 0.12)"
    },
    _hover: {
      bg: "rgba(255, 255, 255, 0.12)"
    },
    _pressed: {
      bg: "rgba(255, 255, 255, 0.12)"
    },
    bg: "rgba(255, 255, 255, 0.08)",
    borderRadius: "32",
    h: {
      base: "48px",
      lg: "48px",
      md: "36px"
    },
    onHoverIn: () => i(!0),
    onHoverOut: () => i(!1),
    onPress: o,
    px: "5",
    shadow: "1",
    children: /* @__PURE__ */ f(w, {
      alignItems: "center",
      flexDirection: "row",
      children: [/* @__PURE__ */ d(bs, {
        fill: s ? a.colors.green[50] : n ? a.colors.white : a.colors.gray[400],
        height: "24",
        width: "24"
      }), e ? null : /* @__PURE__ */ f(w, {
        position: "relative",
        children: [/* @__PURE__ */ d(b, {
          color: n ? a.colors.white : a.colors.text[100],
          fontSize: "14",
          ml: "3",
          opacity: s && n ? 0 : 1,
          children: s ? t("ROOM.CONTROLS.SCREEN_SHARING_START", {
            defaultValue: "Идет демонстрация"
          }) : t("ROOM.CONTROLS.SCREEN_SHARING", {
            defaultValue: "Демонстрация"
          })
        }), /* @__PURE__ */ d(b, {
          bottom: "0",
          color: "white",
          display: s && n ? "block" : "none",
          fontSize: "14",
          left: "0",
          position: "absolute",
          right: "0",
          textAlign: "center",
          top: "0",
          children: t("ROOM.CONTROLS.SCREEN_SHARING_STOP", {
            defaultValue: "Остановить"
          })
        })]
      })]
    })
  });
});
Em.displayName = "ControlScreenShare";
const Dt = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "ChatSendMessage"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "text"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "embeds"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "EmbedInput"
            }
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "send"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "text"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "text"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "embeds"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "embeds"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Rm = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "ChatEditMessage"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "embeds"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "EmbedInput"
            }
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "text"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "edit"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "embeds"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "embeds"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "text"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "text"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, xm = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "ChatDeleteMessage"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "remove"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Dm = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "ChatReactToMessage"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "emoji"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "messageId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "react"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "reaction"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "emoji"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "messageId"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Im = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "ChatUnreactToMessage"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "emoji"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "messageId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "unreact"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "reaction"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "emoji"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "messageId"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
};
function ul(e) {
  const n = e.trim();
  return n.length === 0 ? "" : zs(n).map(oe);
}
function oe(e) {
  if (Array.isArray(e))
    return e.map(oe);
  switch (e.type) {
    case "BIG_EMOJI":
      return /* @__PURE__ */ d(b, {
        display: "inline-flex",
        fontSize: "48px",
        lineHeight: "48px",
        children: e.value.map((n) => oe(n))
      });
    case "BOLD":
      return /* @__PURE__ */ d(b, {
        bold: !0,
        children: e.value.map(oe)
      });
    case "CODE":
      return /* @__PURE__ */ d(b, {
        fontFamily: "monospace",
        children: e.value.map(oe)
      });
    case "CODE_LINE":
      return /* @__PURE__ */ d(b, {
        children: oe(e.value)
      });
    case "COLOR":
      return null;
    case "PLAIN_TEXT":
      return e.value;
    case "EMOJI":
      return /* @__PURE__ */ d(b, {
        display: "inline-flex",
        children: e.unicode != null ? e.unicode : null
      });
    case "HEADING":
      return /* @__PURE__ */ d(hn, {
        size: Am(e.level),
        children: e.value.map(oe)
      });
    case "INLINE_CODE":
      return /* @__PURE__ */ d(b, {
        fontFamily: "monospace",
        children: oe(e.value)
      });
    case "ITALIC":
      return /* @__PURE__ */ d(b, {
        italic: !0,
        children: e.value.map(oe)
      });
    case "STRIKE":
      return /* @__PURE__ */ d(b, {
        strikeThrough: !0,
        children: e.value.map(oe)
      });
    case "LINK":
      return /* @__PURE__ */ d(ld, {
        onPress: () => od.openURL(e.value.src.value),
        children: /* @__PURE__ */ d(b, {
          underline: !0,
          color: "blue.400",
          children: oe(e.value.label)
        })
      });
    case "PARAGRAPH":
      return [...e.value.map((n) => oe(n)), /* @__PURE__ */ d(b, {
        children: `
`
      }, "z")];
    case "KATEX":
      return e.value;
    case "INLINE_KATEX":
      return e.value;
    case "QUOTE":
      return /* @__PURE__ */ d(b, {
        bgColor: "gray.400",
        children: e.value.map(oe)
      });
    case "LIST_ITEM":
      return /* @__PURE__ */ f(b, {
        children: [e.number, " ", e.value.map(oe)]
      });
    case "MENTION_CHANNEL":
      return oe(e.value);
    case "MENTION_USER":
      return oe(e.value);
    case "TASKS":
    case "UNORDERED_LIST":
    case "ORDERED_LIST":
      return e.value.flatMap((n) => n.value.map(oe));
    case "LINE_BREAK":
      return /* @__PURE__ */ d(b, {
        children: `
`
      });
    case "IMAGE":
      return /* @__PURE__ */ d(gi, {
        source: {
          uri: e.value.src.value
        }
      });
    default:
      throw new X(e);
  }
}
function Am(e) {
  switch (e) {
    case 1:
      return "xl";
    case 2:
      return "lg";
    case 3:
      return "md";
    case 4:
      return "sm";
    default:
      throw new X(e);
  }
}
var vn = "/src/features/meet/chat/model/file-attachments/index.ts";
const Oi = h({
  loc: {
    file: vn,
    line: 30,
    column: 41
  },
  name: "eventFileAttachmentsAdded",
  sid: "-zgcvdc:/src/features/meet/chat/model/file-attachments/index.ts:eventFileAttachmentsAdded"
}), kl = h({
  loc: {
    file: vn,
    line: 31,
    column: 43
  },
  name: "eventFileAttachmentsRemoved",
  sid: "jwovfx:/src/features/meet/chat/model/file-attachments/index.ts:eventFileAttachmentsRemoved"
}), vl = h({
  loc: {
    file: vn,
    line: 32,
    column: 41
  },
  name: "eventFileAttachmentsReset",
  sid: "2kkb27:/src/features/meet/chat/model/file-attachments/index.ts:eventFileAttachmentsReset"
}), Sl = h({
  loc: {
    file: vn,
    line: 34,
    column: 48
  },
  name: "eventFileAttachmentsUploadFailed",
  sid: "-1fuia5:/src/features/meet/chat/model/file-attachments/index.ts:eventFileAttachmentsUploadFailed"
}), pl = h({
  loc: {
    file: vn,
    line: 38,
    column: 50
  },
  name: "eventFileAttachmentsUploadFinished",
  sid: "t7djkz:/src/features/meet/chat/model/file-attachments/index.ts:eventFileAttachmentsUploadFinished"
}), Nl = h({
  loc: {
    file: vn,
    line: 42,
    column: 57
  },
  name: "eventFileAttachmentsUploadProgressUpdated",
  sid: "-kyu6rn:/src/features/meet/chat/model/file-attachments/index.ts:eventFileAttachmentsUploadProgressUpdated"
}), Ja = D({
  ref: /* @__PURE__ */ new Map()
}, {
  loc: {
    file: vn,
    line: 47,
    column: 40
  },
  name: "$fileAttachmentsInternal",
  sid: "-6ebvek:/src/features/meet/chat/model/file-attachments/index.ts:$fileAttachmentsInternal"
}), fl = Ja.map((e) => [...e.ref.values()]);
Ja.on(vl, () => ({
  ref: /* @__PURE__ */ new Map()
})).on(Oi, (e, n) => {
  for (const i of n)
    e.ref.set(i.name, {
      file: i,
      progress: 0,
      state: "new"
    });
  return {
    ref: e.ref
  };
}).on(kl, (e, n) => (e.ref.delete(n), {
  ref: e.ref
})).on(pl, (e, n) => {
  const i = e.ref.get(n.fileAttachment.file.name);
  if (i != null) {
    const a = {
      file: i.file,
      response: n.response,
      state: "uploaded"
    };
    e.ref.set(i.file.name, a);
  }
  return {
    ref: e.ref
  };
}).on(Sl, (e, n) => {
  const i = e.ref.get(n.fileAttachment.file.name);
  if (i != null) {
    const a = {
      error: n.error,
      file: i.file,
      state: "error"
    };
    e.ref.set(i.file.name, a);
  }
  return {
    ref: e.ref
  };
}).on(Nl, (e, n) => {
  const i = e.ref.get(n.fileAttachment.file.name);
  if (i != null && i.state === "new") {
    const a = {
      ...i,
      progress: n.progress
    };
    e.ref.set(i.file.name, a);
  }
  return {
    ref: e.ref
  };
});
var Qa = "/src/features/meet/chat/model/message-edit.ts";
const Fl = h({
  loc: {
    file: Qa,
    line: 3,
    column: 40
  },
  name: "eventMessageEditSelected",
  sid: "fn4ufr:/src/features/meet/chat/model/message-edit.ts:eventMessageEditSelected"
}), gl = h({
  loc: {
    file: Qa,
    line: 5,
    column: 41
  },
  name: "eventMessageReplySelected",
  sid: "gwuwii:/src/features/meet/chat/model/message-edit.ts:eventMessageReplySelected"
}), hl = h({
  loc: {
    file: Qa,
    line: 7,
    column: 37
  },
  name: "eventMessageEditReset",
  sid: "eu2jkh:/src/features/meet/chat/model/message-edit.ts:eventMessageEditReset"
});
var z = "/src/features/meet/chat/model/common.ts";
const Za = h({
  loc: {
    file: z,
    line: 92,
    column: 39
  },
  name: "eventChatMessageReacted",
  sid: "bmcwk1:/src/features/meet/chat/model/common.ts:eventChatMessageReacted"
}), bl = $({
  loc: {
    file: z,
    line: 97,
    column: 37
  },
  name: "sendMessageReactionFx",
  sid: "-via7k3:/src/features/meet/chat/model/common.ts:sendMessageReactionFx"
}), yl = h({
  loc: {
    file: z,
    line: 102,
    column: 41
  },
  name: "eventChatMessageUnreacted",
  sid: "-n73qsn:/src/features/meet/chat/model/common.ts:eventChatMessageUnreacted"
}), Tl = $({
  loc: {
    file: z,
    line: 107,
    column: 39
  },
  name: "sendMessageUnreactionFx",
  sid: "1jvgzy:/src/features/meet/chat/model/common.ts:sendMessageUnreactionFx"
}), Ml = h("eventSendMessage", {
  loc: {
    file: z,
    line: 112,
    column: 32
  },
  name: "eventSendMessage",
  sid: "o3dia5:/src/features/meet/chat/model/common.ts:eventSendMessage"
}), Cl = D([], {
  loc: {
    file: z,
    line: 114,
    column: 36
  },
  name: "$unconfirmedMessages",
  sid: "bc9ms0:/src/features/meet/chat/model/common.ts:$unconfirmedMessages"
}), Pi = D(en(), {
  loc: {
    file: z,
    line: 118,
    column: 33
  },
  name: "$receivedMessages",
  sid: "c1bueq:/src/features/meet/chat/model/common.ts:$receivedMessages"
}), wl = _({
  and: [{
    source: {
      $receivedMessages: Pi,
      $unconfirmedMessages: Cl
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn(e) {
      return Xe((n) => [...e.$unconfirmedMessages, ...n], e.$receivedMessages);
    }
  }],
  or: {
    loc: {
      file: z,
      line: 122,
      column: 29
    },
    name: "$chatMessages",
    sid: "2nns2x:/src/features/meet/chat/model/common.ts:$chatMessages"
  }
}), Sn = D({
  cursorPosition: 0,
  localId: Wn(),
  text: " ",
  type: "new"
}, {
  loc: {
    file: z,
    line: 171,
    column: 31
  },
  name: "$currentMessage",
  sid: "-mz8gim:/src/features/meet/chat/model/common.ts:$currentMessage"
});
Zo({
  deserialize: (e) => {
    try {
      const n = JSON.parse(e);
      return n.type != null ? n : {
        curPosition: 0,
        text: " ",
        type: "new"
      };
    } catch {
      return {
        curPosition: 0,
        text: " ",
        type: "new"
      };
    }
  },
  store: Sn
});
const {
  __: Vm,
  eventSubscriptionChatEditMessagesReceived_: Om,
  eventSubscriptionChatReactionCountReceived_: Pm,
  eventSubscriptionChatReactionSelfReceived_: Lm,
  eventSubscriptionChatRemoveMessagesReceived_: Um,
  eventSubscriptionChatSentMessagesReceived_: $m
} = Lo({
  and: [Ed, {
    eventSubscriptionChatEditMessagesReceived_: (e) => e.edit != null,
    eventSubscriptionChatReactionCountReceived_: (e) => {
      var n;
      return ((n = e.reaction) == null ? void 0 : n.count) != null;
    },
    eventSubscriptionChatReactionSelfReceived_: (e) => {
      var n;
      return ((n = e.reaction) == null ? void 0 : n.self) != null;
    },
    eventSubscriptionChatRemoveMessagesReceived_: (e) => e.remove != null,
    eventSubscriptionChatSentMessagesReceived_: (e) => e.sent != null
  }],
  or: {
    loc: {
      file: z,
      line: 203,
      column: 4
    },
    sid: "7ri8ci:/src/features/meet/chat/model/common.ts:"
  }
});
Vm.watch((e) => (
  // eslint-disable-next-line no-console
  console.warn("Unknown chat event received", e)
));
const _l = Um.map((e) => e.remove), El = $m.map((e) => e.sent), Rl = Om.map((e) => e.edit), Bm = Pm.map((e) => e.reaction.count), zm = Lm.map((e) => e.reaction.self), xl = h({
  loc: {
    file: z,
    line: 244,
    column: 41
  },
  name: "eventCurrentMesageChanged",
  sid: "8lu8aj:/src/features/meet/chat/model/common.ts:eventCurrentMesageChanged"
}), na = h({
  loc: {
    file: z,
    line: 248,
    column: 55
  },
  name: "eventCurrentMesageCursorPositionChanged",
  sid: "-9qv0q3:/src/features/meet/chat/model/common.ts:eventCurrentMesageCursorPositionChanged"
}), Dl = h({
  loc: {
    file: z,
    line: 264,
    column: 47
  },
  name: "eventCurrentMesageEmojiInserted",
  sid: "d37329:/src/features/meet/chat/model/common.ts:eventCurrentMesageEmojiInserted"
}), An = $({
  loc: {
    file: z,
    line: 266,
    column: 33
  },
  name: "sendChatMessageFx",
  sid: "-yr2lkw:/src/features/meet/chat/model/common.ts:sendChatMessageFx"
}), et = $({
  loc: {
    file: z,
    line: 273,
    column: 40
  },
  name: "sendMessageUploadFilesFx",
  sid: "cbh0e0:/src/features/meet/chat/model/common.ts:sendMessageUploadFilesFx"
}), nt = h({
  loc: {
    file: z,
    line: 283,
    column: 41
  },
  name: "eventChatScrollEndReached",
  sid: "rq11wl:/src/features/meet/chat/model/common.ts:eventChatScrollEndReached"
}), Hn = $(({
  cursor: e,
  limit: n
}) => Z().query({
  fetchPolicy: "network-only",
  query: nr,
  variables: {
    cursor: e,
    limit: Math.max(n ?? 0, 5)
  }
}).then((i) => i.data.meet.chat.history), {
  loc: {
    file: z,
    line: 287,
    column: 42
  },
  name: "fetchMessagesFromHistoryFx",
  sid: "jracg:/src/features/meet/chat/model/common.ts:fetchMessagesFromHistoryFx"
}), Il = D("", {
  loc: {
    file: z,
    line: 303,
    column: 30
  },
  name: "$messageHistoryCursor",
  sid: "mgvqr1:/src/features/meet/chat/model/common.ts:$messageHistoryCursor"
});
Il.on(Hn.done, (e, n) => n.result.cursor ?? null).reset(ue);
_({
  and: [{
    clock: Ca,
    fn: () => ({
      cursor: ""
    }),
    target: Hn
  }],
  or: {
    loc: {
      file: z,
      line: 311,
      column: 0
    },
    sid: "mvujok:/src/features/meet/chat/model/common.ts:"
  }
});
_({
  and: [{
    clock: nt,
    source: {
      messageHistoryCursor: Il,
      isPending: Hn.pending
    },
    filter: (e) => e.messageHistoryCursor != null && !e.isPending,
    fn: (e, n) => ({
      cursor: e.messageHistoryCursor,
      limit: n.distanceFromEnd
    }),
    target: Hn
  }],
  or: {
    loc: {
      file: z,
      line: 317,
      column: 0
    },
    sid: "mz5b8q:/src/features/meet/chat/model/common.ts:"
  }
});
const cS = An.pending, it = $({
  loc: {
    file: z,
    line: 334,
    column: 31
  },
  name: "deleteMessageFx",
  sid: "-86jp9n:/src/features/meet/chat/model/common.ts:deleteMessageFx"
}), Al = _({
  and: [{
    clock: Ml,
    source: {
      accessToken: es,
      currentMessage: Sn,
      fileAttachments: fl
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      currentMessage: e,
      fileAttachments: n
    }) => e.text.trim().length > 0 || n.length > 0
  }],
  or: {
    loc: {
      file: z,
      line: 336,
      column: 33
    },
    name: "eventActuallySendMessage",
    sid: "-rpkzzn:/src/features/meet/chat/model/common.ts:eventActuallySendMessage"
  }
});
_({
  and: [{
    clock: Al,
    target: et
  }],
  or: {
    loc: {
      file: z,
      line: 348,
      column: 0
    },
    sid: "oetz0o:/src/features/meet/chat/model/common.ts:"
  }
});
et.use(async ({
  accessToken: e,
  fileAttachments: n
}) => n.length > 0 ? Promise.all(n.map(async (i) => {
  try {
    const a = new FormData();
    a.append("file", i.file);
    const l = (await js.request({
      data: a,
      headers: {
        Authorization: e
      },
      method: "POST",
      onUploadProgress(o) {
        Nl({
          fileAttachment: i,
          progress: Math.round(100 * o.loaded / (o.total ?? 1))
        });
      },
      url: "/media"
    })).data;
    return pl({
      fileAttachment: i,
      response: l
    }), {
      file: i.file,
      response: l,
      state: "uploaded"
    };
  } catch (a) {
    return a instanceof Hs && Sl({
      error: a,
      fileAttachment: i
    }), {
      error: a,
      file: i.file,
      state: "error"
    };
  }
})) : []);
An.use(async ({
  currentMessage: e,
  fileAttachments: n
}) => {
  if (e.text === "" && !n.every((t) => t.state === "uploaded"))
    throw new Error("currentMessage is null and no attachments");
  if (n.some((t) => t.state !== "uploaded"))
    throw new Error("Cannot upload failed or non-uploaded files");
  const a = n.map((t) => ({
    type: bt.EmbedTypeFile,
    value: t.response.id
  }));
  switch (e.type) {
    case "new": {
      const t = await Z().mutate({
        mutation: Dt,
        variables: {
          embeds: a,
          text: e.text
        }
      });
      if (t.data == null)
        throw new Error("data is null");
      return {
        messageId: t.data.meet.chat.send
      };
    }
    case "editing":
      return await Z().mutate({
        mutation: Rm,
        variables: {
          embeds: e.oldMessage.embeds.map((t) => ({
            type: t.type,
            value: t.value
          })).concat(a),
          id: e.oldMessage.id,
          text: e.text
        }
      }), {
        messageId: null
      };
    case "reply": {
      const t = await Z().mutate({
        mutation: Dt,
        variables: {
          embeds: a.concat([{
            type: bt.EmbedTypeMessage,
            value: e.repliedMessage.id
          }]),
          text: e.text
        }
      });
      if (t.data == null)
        throw new Error("data is null");
      return {
        messageId: t.data.meet.chat.send
      };
    }
    default:
      throw new X(e);
  }
});
_({
  and: [{
    clock: et.done,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e) => ({
      ...e.params,
      fileAttachments: e.result
    }),
    target: An
  }],
  or: {
    loc: {
      file: z,
      line: 469,
      column: 0
    },
    sid: "-uz8rco:/src/features/meet/chat/model/common.ts:"
  }
});
Ja.on(An.done, () => ({
  ref: /* @__PURE__ */ new Map()
}));
Sn.on(na, (e, n) => ({
  ...e,
  cursorPosition: n
})).on(xl, (e, n) => ({
  ...e,
  cursorPosition: n.cursorPosition,
  text: n.text
}));
Sn.on(Dl, (e, n) => {
  const i = e.text, a = i.slice(0, e.cursorPosition), t = i.slice(e.cursorPosition), l = `${a}${n}${t}`;
  return {
    ...e,
    cursorPosition: e.cursorPosition + n.length,
    text: l
  };
});
Sn.on(_({
  and: [{
    clock: Fl,
    source: Pi,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => Se((i) => i.find((a) => a.type === "RECEIVED" && a.message.id === n), void 0, e)
  }],
  or: {
    loc: {
      file: z,
      line: 505,
      column: 2
    },
    sid: "-j7bdtj:/src/features/meet/chat/model/common.ts:"
  }
}), (e, n) => n == null ? {
  cursorPosition: 0,
  // embeds: [],
  localId: Wn(),
  text: " ",
  type: "new"
} : {
  cursorPosition: n.message.text.length - 1,
  // embeds: payload.message.embeds.map(({ type, value }) => ({
  //   type,
  //   value,
  // })),
  localId: Wn(),
  oldMessage: n.message,
  text: n.message.text,
  type: "editing"
});
Sn.on(_({
  and: [{
    clock: gl,
    source: Pi,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => Se((i) => i.find((a) => a.type === "RECEIVED" && a.message.id === n), void 0, e)
  }],
  or: {
    loc: {
      file: z,
      line: 543,
      column: 4
    },
    sid: "-hc8hdr:/src/features/meet/chat/model/common.ts:"
  }
}), (e, n) => n == null ? {
  cursorPosition: 0,
  // embeds: [],
  localId: Wn(),
  text: " ",
  type: "new"
} : {
  cursorPosition: 0,
  // embeds: payload.message.embeds.map(({ type, value }) => ({
  //   type,
  //   value,
  // })),
  localId: Wn(),
  repliedMessage: n.message,
  text: " ",
  type: "reply"
}).reset(hl, An.done);
const Vl = D(0, {
  loc: {
    file: z,
    line: 579,
    column: 41
  },
  name: "$receivedMessagesEditedAt",
  sid: "iudvzm:/src/features/meet/chat/model/common.ts:$receivedMessagesEditedAt"
}).on(_l, () => Date.now()).on(Rl, () => Date.now());
Pi.on(Hn.doneData, (e, n) => he([...Se((i) => i, [], e), ...n.data.map((i) => ({
  message: i,
  type: "RECEIVED"
})).reverse()])).on(_({
  and: [{
    clock: El,
    source: an,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn(e, n) {
      var i;
      return {
        messages: n,
        myPeerId: (i = e == null ? void 0 : e.member) == null ? void 0 : i.id
      };
    }
  }],
  or: {
    loc: {
      file: z,
      line: 593,
      column: 4
    },
    sid: "-ez0des:/src/features/meet/chat/model/common.ts:"
  }
}), (e, n) => he([...n.messages.messages.map((i) => ({
  message: i,
  type: "RECEIVED"
})).reverse(), ...Se((i) => i, [], e)])).on(_l, (e, n) => Xe((i) => i.filter((a) => a.type !== "RECEIVED" || !n.messages.includes(a.message.id)), e)).on(Rl, (e, n) => Xe((i) => i.map((a) => {
  const t = n.messages.find((l) => a.type === "RECEIVED" && l.id === a.message.id);
  return t == null || a.type !== "RECEIVED" ? a : {
    ...a,
    message: {
      ...a.message,
      embeds: t.embeds,
      text: t.text,
      updated: t.updated
    }
  };
}), e)).on(Bm, (e, n) => Xe((i) => i.map((a) => {
  if (a.type !== "RECEIVED")
    return a;
  const t = n.messages.find((l) => l.id === a.message.id);
  return t == null ? a : {
    ...a,
    message: {
      ...a.message,
      reactions: t.reactions.map((l) => {
        var o;
        return {
          ...l,
          me: ((o = a.message.reactions.find((s) => s.name === l.name)) == null ? void 0 : o.me) ?? !1
        };
      })
    }
  };
}), e)).on(zm, (e, n) => Xe((i) => i.map((a) => {
  if (a.type !== "RECEIVED")
    return a;
  const t = n.messages.find((l) => l.id === a.message.id);
  return t == null ? a : {
    ...a,
    message: {
      ...a.message,
      reactions: a.message.reactions.map((l) => ({
        ...l,
        me: t.reactions.includes(l.name)
      }))
    }
  };
}), e)).reset(ue, Ne);
Cl.on(Al, (e, n) => (
  /* FIXME переделать на sample? */
  n.currentMessage.type === "new" ? [{
    localId: n.currentMessage.localId,
    messageText: n.currentMessage.text,
    sent: (/* @__PURE__ */ new Date()).toISOString(),
    type: "UNCONFIRMED"
  }, ...e] : void 0
)).on(An.done, (e, n) => e.filter((i) => i.localId !== n.params.currentMessage.localId)).reset(ue, Ne);
it.use((e) => Z().mutate({
  mutation: xm,
  variables: {
    id: e
  }
}).then(() => {
}));
_({
  and: [{
    clock: Za,
    target: bl
  }],
  or: {
    loc: {
      file: z,
      line: 738,
      column: 0
    },
    sid: "bm9fjh:/src/features/meet/chat/model/common.ts:"
  }
});
bl.use(({
  messageId: e,
  reaction: n
}) => Z().mutate({
  mutation: Dm,
  variables: {
    emoji: n,
    messageId: e
  }
}).then(() => {
}));
_({
  and: [{
    clock: yl,
    target: Tl
  }],
  or: {
    loc: {
      file: z,
      line: 748,
      column: 0
    },
    sid: "c3b1xo:/src/features/meet/chat/model/common.ts:"
  }
});
Tl.use(({
  messageId: e,
  reaction: n
}) => Z().mutate({
  mutation: Im,
  variables: {
    emoji: n,
    messageId: e
  }
}).then(() => {
}));
function Wn() {
  return ui("unsent");
}
var ai = "/src/features/meet/chat/model/has-new-messages.ts";
const Ol = h({
  loc: {
    file: ai,
    line: 7,
    column: 45
  },
  name: "eventToggleChatHasNewMessages",
  sid: "-6v8mqw:/src/features/meet/chat/model/has-new-messages.ts:eventToggleChatHasNewMessages"
}), Pl = h({
  loc: {
    file: ai,
    line: 8,
    column: 44
  },
  name: "eventResetChatHasNewMessages",
  sid: "-dikvdt:/src/features/meet/chat/model/has-new-messages.ts:eventResetChatHasNewMessages"
}), jm = D(!1, {
  loc: {
    file: ai,
    line: 10,
    column: 35
  },
  name: "$chatHasNewMessages",
  sid: "-c23oj9:/src/features/meet/chat/model/has-new-messages.ts:$chatHasNewMessages"
}).on(Ol, (e, n) => n).reset(Pl);
_({
  and: [{
    clock: Zn,
    source: xn,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => e === re.Chat,
    target: Pl
  }],
  or: {
    loc: {
      file: ai,
      line: 15,
      column: 0
    },
    sid: "2hcwqc:/src/features/meet/chat/model/has-new-messages.ts:"
  }
});
_({
  and: [{
    clock: El,
    source: xn,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e) => e !== re.Chat,
    target: Ol
  }],
  or: {
    loc: {
      file: ai,
      line: 25,
      column: 0
    },
    sid: "2yej4j:/src/features/meet/chat/model/has-new-messages.ts:"
  }
});
var Li = "/src/features/meet/chat/model/message-context-menu.ts";
const ia = h({
  loc: {
    file: Li,
    line: 8,
    column: 36
  },
  name: "eventOpenContextMenu",
  sid: "-abihg0:/src/features/meet/chat/model/message-context-menu.ts:eventOpenContextMenu"
}), Ll = h({
  loc: {
    file: Li,
    line: 9,
    column: 37
  },
  name: "eventCloseContextMenu",
  sid: "3e18dw:/src/features/meet/chat/model/message-context-menu.ts:eventCloseContextMenu"
}), Hm = h({
  loc: {
    file: Li,
    line: 10,
    column: 37
  },
  name: "eventResetContextMenu",
  sid: "193cet:/src/features/meet/chat/model/message-context-menu.ts:eventResetContextMenu"
}), Ul = D({
  messageId: "",
  opened: !1
}, {
  and: {
    name: "$chatContextMenuStore"
  },
  loc: {
    file: Li,
    line: 12,
    column: 38
  },
  name: "$chatContextMenuOpened",
  sid: "85cfcg:/src/features/meet/chat/model/message-context-menu.ts:$chatContextMenuOpened"
}).on(ia, (e, n) => n).on(Ll, () => ({
  messageId: "",
  opened: !1
})).reset(Hm);
var at = "/src/features/meet/chat/model/message-delete.ts";
const $l = h({
  loc: {
    file: at,
    line: 5,
    column: 42
  },
  name: "eventMessageDeleteSelected",
  sid: "ywqjn7:/src/features/meet/chat/model/message-delete.ts:eventMessageDeleteSelected"
}), Bl = h({
  loc: {
    file: at,
    line: 7,
    column: 39
  },
  name: "eventMessageDeleteReset",
  sid: "-xbb983:/src/features/meet/chat/model/message-delete.ts:eventMessageDeleteReset"
}), Wm = D(null, {
  loc: {
    file: at,
    line: 8,
    column: 32
  },
  name: "$messageDeleteId",
  sid: "s90ulh:/src/features/meet/chat/model/message-delete.ts:$messageDeleteId"
}).on($l, (e, n) => n).reset(Bl, it.done), Ni = /* @__PURE__ */ g(({
  file: e,
  fileName: n,
  fileSize: i,
  mime: a,
  onDelete: t,
  progress: l,
  url: o
}) => {
  const s = me(), r = E(() => {
    t == null || t();
  }, [t]), c = L(() => {
    var u;
    return a != null && a.includes("image/") ? o != null ? (u = va(o, 48)) == null ? void 0 : u.uri : e != null ? URL.createObjectURL(e) : null : null;
  }, [e, a, o]);
  return /* @__PURE__ */ f(te, {
    alignItems: "center",
    flexDirection: "row",
    onPress: () => {
      o != null && od.openURL(o);
    },
    py: "1",
    children: [/* @__PURE__ */ d(w, {
      alignItems: "center",
      bg: "rgba(255, 255, 255, 0.08)",
      borderRadius: "5",
      h: "48px",
      justifyContent: "center",
      mr: "3",
      w: "48px",
      children: c != null ? /* @__PURE__ */ d(gi, {
        borderRadius: "5",
        h: "48px",
        resizeMode: "cover",
        source: {
          uri: c
        },
        w: "48px"
      }) : /* @__PURE__ */ d(ys, {
        fill: s.colors.blue[50]
      })
    }), /* @__PURE__ */ f(w, {
      flex: "1",
      children: [/* @__PURE__ */ d(b, {
        color: "gray.400",
        ellipsizeMode: "middle",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "20",
        mb: "1",
        numberOfLines: 1,
        children: n
      }), /* @__PURE__ */ d(b, {
        color: "text.200",
        ellipsizeMode: "tail",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "20",
        mb: "1",
        numberOfLines: 1,
        children: i
      }), l ? /* @__PURE__ */ d(as, {
        size: "xs",
        value: l,
        w: "100%"
      }) : null]
    }), /* @__PURE__ */ d(w, {
      children: t != null ? /* @__PURE__ */ d(bi, {
        onPress: r,
        children: /* @__PURE__ */ d(Ti, {
          fill: s.colors.gray[400],
          height: "32",
          width: "32"
        })
      }) : null
    })]
  });
});
Ni.displayName = "AttachedFile";
const Gm = [
  "B",
  "kB",
  "MB",
  "GB",
  "TB",
  "PB",
  "EB",
  "ZB",
  "YB"
], qm = [
  "B",
  "kiB",
  "MiB",
  "GiB",
  "TiB",
  "PiB",
  "EiB",
  "ZiB",
  "YiB"
], Xm = [
  "b",
  "kbit",
  "Mbit",
  "Gbit",
  "Tbit",
  "Pbit",
  "Ebit",
  "Zbit",
  "Ybit"
], Ym = [
  "b",
  "kibit",
  "Mibit",
  "Gibit",
  "Tibit",
  "Pibit",
  "Eibit",
  "Zibit",
  "Yibit"
], It = (e, n, i) => {
  let a = e;
  return typeof n == "string" || Array.isArray(n) ? a = e.toLocaleString(n, i) : (n === !0 || i !== void 0) && (a = e.toLocaleString(void 0, i)), a;
};
var Km = (e, n) => {
  if (!Number.isFinite(e))
    throw new TypeError(`Expected a finite number, got ${typeof e}: ${e}`);
  n = Object.assign({ bits: !1, binary: !1 }, n);
  const i = n.bits ? n.binary ? Ym : Xm : n.binary ? qm : Gm;
  if (n.signed && e === 0)
    return ` 0 ${i[0]}`;
  const a = e < 0, t = a ? "-" : n.signed ? "+" : "";
  a && (e = -e);
  let l;
  if (n.minimumFractionDigits !== void 0 && (l = { minimumFractionDigits: n.minimumFractionDigits }), n.maximumFractionDigits !== void 0 && (l = Object.assign({ maximumFractionDigits: n.maximumFractionDigits }, l)), e < 1) {
    const c = It(e, n.locale, l);
    return t + c + " " + i[0];
  }
  const o = Math.min(Math.floor(n.binary ? Math.log(e) / Math.log(1024) : Math.log10(e) / 3), i.length - 1);
  e /= Math.pow(n.binary ? 1024 : 1e3, o), l || (e = e.toPrecision(3));
  const s = It(Number(e), n.locale, l), r = i[o];
  return t + s + " " + r;
};
const aa = /* @__PURE__ */ yd(Km), zl = /* @__PURE__ */ g(({
  message: e
}) => {
  const n = me(), {
    t: i
  } = ie(), a = E(() => {
    hl();
  }, []);
  return /* @__PURE__ */ f(w, {
    alignItems: "center",
    flexDirection: "row",
    mb: "2",
    children: [e.type === "editing" ? /* @__PURE__ */ d(Ts, {
      fill: n.colors.blue[100],
      height: "24",
      width: "24"
    }) : /* @__PURE__ */ d(Ms, {
      fill: n.colors.blue[100],
      height: "24",
      width: "24"
    }), /* @__PURE__ */ f(w, {
      borderLeftColor: "blue.100",
      borderLeftStyle: "solid",
      borderLeftWidth: "2",
      flex: "1",
      mx: "2",
      px: "10px",
      children: [/* @__PURE__ */ d(b, {
        color: "blue.100",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "18",
        children: e.type === "editing" ? i("CHAT.TITLE.EDIT", {
          defaultValue: "Редактирование"
        }) : i("CHAT.TITLE.REPLY", {
          defaultValue: "Ответ"
        })
      }), /* @__PURE__ */ d(b, {
        color: "gray.400",
        ellipsizeMode: "tail",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "18",
        numberOfLines: 1,
        children: e.type === "editing" ? e.oldMessage.text : e.repliedMessage.text
      })]
    }), /* @__PURE__ */ d(te, {
      onPress: a,
      w: "6",
      children: /* @__PURE__ */ d(Ti, {
        height: "24",
        width: "24"
      })
    })]
  });
});
zl.displayName = "ChatMessageEditPreview";
const Jm = {
  backgroundColor: "rgba(0, 0, 0, 0)",
  bottom: -12,
  left: -12,
  position: "absolute",
  right: -12,
  top: -12,
  zIndex: 1
}, tt = /* @__PURE__ */ g(() => I(Ul).opened ? /* @__PURE__ */ d("div", {
  "data-testid": "ContextMenuOverlay",
  style: Jm
}) : null);
tt.displayName = "ContextMenuOverlay";
var Wt;
const dt = typeof window < "u" && !!((Wt = window.document) != null && Wt.createElement), Qm = dt && !!(window.addEventListener || window.attachEvent), Zm = dt && !!window.screen, ec = !1, nc = {
  /**
   * Denotes the currently running platform.
   * Can be one of ios, android, web.
   */
  OS: Qe.OS,
  /**
   * Returns the value with the matching platform.
   * Object keys can be any of ios, android, native, web, default.
   *
   * @ios ios, native, default
   * @android android, native, default
   * @web web, default
   */
  select: Qe.select,
  /**
   * Denotes if the DOM API is available in the current environment.
   * The DOM is not available in native React runtimes and Node.js.
   */
  isDOMAvailable: dt,
  /**
   * Denotes if the current environment can attach event listeners
   * to the window. This will return false in native React
   * runtimes and Node.js.
   */
  canUseEventListeners: Qm,
  /**
   * Denotes if the current environment can inspect properties of the
   * screen on which the current window is being rendered. This will
   * return false in native React runtimes and Node.js.
   */
  canUseViewport: Zm,
  /**
   * If the JavaScript is being executed in a remote JavaScript environment.
   * When `true`, synchronous native invocations cannot be executed.
   */
  isAsyncDebugging: ec
}, ic = nc;
class ac extends Error {
  constructor(i, a) {
    super(a);
    qi(this, "code");
    qi(this, "info");
    this.code = i;
  }
}
globalThis.ExpoModulesCore_CodedError = ac;
var tc = { exports: {} };
(function(e, n) {
  (function(i, a) {
    e.exports = a();
  })(bd, function() {
    var i = /^v?(?:\d+)(\.(?:[x*]|\d+)(\.(?:[x*]|\d+)(\.(?:[x*]|\d+))?(?:-[\da-z\-]+(?:\.[\da-z\-]+)*)?(?:\+[\da-z\-]+(?:\.[\da-z\-]+)*)?)?)?$/i;
    function a(v, m) {
      return v.indexOf(m) === -1 ? v.length : v.indexOf(m);
    }
    function t(v) {
      var m = v.replace(/^v/, "").replace(/\+.*$/, ""), p = a(m, "-"), k = m.substring(0, p).split(".");
      return k.push(m.substring(p + 1)), k;
    }
    function l(v) {
      return isNaN(Number(v)) ? v : Number(v);
    }
    function o(v) {
      if (typeof v != "string")
        throw new TypeError("Invalid argument expected string");
      if (!i.test(v))
        throw new Error("Invalid argument not valid semver ('" + v + "' received)");
    }
    function s(v, m) {
      [v, m].forEach(o);
      for (var p = t(v), k = t(m), F = 0; F < Math.max(p.length - 1, k.length - 1); F++) {
        var S = parseInt(p[F] || 0, 10), N = parseInt(k[F] || 0, 10);
        if (S > N)
          return 1;
        if (N > S)
          return -1;
      }
      var y = p[p.length - 1], T = k[k.length - 1];
      if (y && T) {
        var M = y.split(".").map(l), C = T.split(".").map(l);
        for (F = 0; F < Math.max(M.length, C.length); F++) {
          if (M[F] === void 0 || typeof C[F] == "string" && typeof M[F] == "number")
            return -1;
          if (C[F] === void 0 || typeof M[F] == "string" && typeof C[F] == "number" || M[F] > C[F])
            return 1;
          if (C[F] > M[F])
            return -1;
        }
      } else if (y || T)
        return y ? -1 : 1;
      return 0;
    }
    var r = [
      ">",
      ">=",
      "=",
      "<",
      "<="
    ], c = {
      ">": [1],
      ">=": [0, 1],
      "=": [0],
      "<=": [-1, 0],
      "<": [-1]
    };
    function u(v) {
      if (typeof v != "string")
        throw new TypeError("Invalid operator type, expected string but got " + typeof v);
      if (r.indexOf(v) === -1)
        throw new TypeError("Invalid operator, expected one of " + r.join("|"));
    }
    return s.validate = function(v) {
      return typeof v == "string" && i.test(v);
    }, s.compare = function(v, m, p) {
      u(p);
      var k = s(v, m);
      return c[p].indexOf(k) > -1;
    }, s;
  });
})(tc);
var At;
(function(e) {
  e.GRANTED = "granted", e.UNDETERMINED = "undetermined", e.DENIED = "denied";
})(At || (At = {}));
const dc = {
  get name() {
    return "ExpoDocumentPicker";
  },
  async getDocumentAsync({ type: e = "*/*", multiple: n = !1 }) {
    if (!ic.isDOMAvailable)
      return { canceled: !0, assets: null };
    const i = document.createElement("input");
    return i.style.display = "none", i.setAttribute("type", "file"), i.setAttribute("accept", Array.isArray(e) ? e.join(",") : e), i.setAttribute("id", String(Math.random())), n && i.setAttribute("multiple", "multiple"), document.body.appendChild(i), new Promise((a, t) => {
      i.addEventListener("change", async () => {
        if (i.files) {
          const o = [];
          for (let s = 0; s < i.files.length; s++)
            o.push(lc(i.files[s]));
          try {
            const s = await Promise.all(o);
            a({ canceled: !1, assets: s, output: i.files });
          } catch (s) {
            t(s);
          }
        } else
          a({ canceled: !0, assets: null });
        document.body.removeChild(i);
      });
      const l = new MouseEvent("click");
      i.dispatchEvent(l);
    });
  }
};
function lc(e) {
  return new Promise((n, i) => {
    const a = e.type, t = new FileReader();
    t.onerror = () => {
      i(new Error("Failed to read the selected media because the operation failed."));
    }, t.onload = ({ target: l }) => {
      const o = l.result;
      n({
        uri: o,
        mimeType: a,
        name: e.name,
        lastModified: e.lastModified,
        size: e.size,
        file: e
      });
    }, t.readAsDataURL(e);
  });
}
async function oc({ type: e = "*/*", copyToCacheDirectory: n = !0, multiple: i = !1 } = {}) {
  return typeof e == "string" && (e = [e]), await dc.getDocumentAsync({
    type: e,
    copyToCacheDirectory: n,
    multiple: i
  });
}
const sc = async () => {
  const e = await oc();
  !e.canceled && e.output && Oi(e.assets.map((n) => n.file).filter(Mn));
}, jl = /* @__PURE__ */ g(() => /* @__PURE__ */ d(te, {
  color: "gray.400",
  onPress: sc,
  children: /* @__PURE__ */ d(Ws, {})
}));
jl.displayName = "FilePicker";
const Hl = "textarea-enter-message", rc = "_container_1v965_1", mc = "_row_1v965_5", cc = "_rowInside_1v965_10", uc = "_button_1v965_20", kc = "_containerShort_1v965_24", vc = "_gap_1v965_48", Sc = "_inputContainer_1v965_53", pc = "_input_1v965_53", Nc = "_buttonSend_1v965_88", fc = "_buttonDisabled_1v965_92", Te = {
  container: rc,
  row: mc,
  rowInside: cc,
  button: uc,
  containerShort: kc,
  gap: vc,
  inputContainer: Sc,
  input: pc,
  buttonSend: Nc,
  buttonDisabled: fc
}, lt = /* @__PURE__ */ g(({
  placeholder: e,
  shortView: n
}) => {
  const {
    t: i
  } = ie(), a = I(Sn), [t, l] = H(!1), o = je(null), s = je(null), r = E(() => {
    o.current != null && (s.current != null && (clearTimeout(s.current), s.current = null), o.current.classList.add("focused"));
  }, [s]), c = E(() => {
    s.current != null && (clearTimeout(s.current), s.current = null), s.current = setTimeout(() => {
      o.current != null && o.current.classList.remove("focused");
    }, 500);
  }, []), u = E(() => {
    o.current != null && o.current.focus();
  }, []), v = E(() => {
    var C;
    na(((C = o.current) == null ? void 0 : C.selectionStart) ?? 0);
  }, []), m = E(() => {
    o.current != null && (o.current.style.height = "24px", o.current.style.height = `${o.current.scrollHeight}px`);
  }, []), p = E(() => {
    if (o.current == null)
      return;
    const C = o.current.value, P = o.current.selectionStart;
    xl({
      cursorPosition: P,
      text: C
    }), l(!1), m();
  }, [m]), k = E(() => {
    o.current != null && (l(!1), Ml(), m(), u());
  }, [u, m]), F = E((C) => {
    C.preventDefault(), k();
  }, [k]), S = E(() => {
    l((C) => !C), u();
  }, [u]), N = E((C) => {
    C != null && C.code === "Enter" && !C.getModifierState("Shift") && (C.preventDefault(), C.stopPropagation(), k());
  }, [k]), y = E(() => {
    o.current != null && na(o.current.selectionStart);
  }, []), T = E((C) => {
    o.current != null && Dl(C);
  }, []), M = E((C) => {
    if (C == null)
      return;
    const P = C.clipboardData.items;
    for (let G = 0; G < P.length; G++)
      if (P[G].type.includes("image")) {
        const se = P[G].getAsFile();
        if (se == null)
          return;
        Oi([se]);
      }
  }, []);
  return Q(() => {
    o.current != null && (o.current.value = a.text, o.current.setSelectionRange(a.cursorPosition, a.cursorPosition), o.current.focus(), m());
  }, [a.cursorPosition, a.text, m]), /* @__PURE__ */ f(be, {
    children: [/* @__PURE__ */ d(tt, {}), /* @__PURE__ */ f("form", {
      className: De(Te.container, {
        [Te.containerShort]: n
      }),
      onSubmit: F,
      children: [a.type === "editing" || a.type === "reply" ? /* @__PURE__ */ d(zl, {
        message: a
      }) : null, /* @__PURE__ */ f("div", {
        className: Te.row,
        children: [n ? null : /* @__PURE__ */ f(be, {
          children: [/* @__PURE__ */ d(jl, {}), /* @__PURE__ */ d("div", {
            className: Te.gap
          })]
        }), /* @__PURE__ */ f("div", {
          className: Te.rowInside,
          children: [/* @__PURE__ */ d("div", {
            className: Te.inputContainer,
            children: /* @__PURE__ */ d("textarea", {
              ref: o,
              className: De(Te.input),
              id: Hl,
              onBlur: c,
              onChange: p,
              onClick: v,
              onFocus: r,
              onKeyDown: N,
              onKeyUp: y,
              onPaste: M,
              placeholder: e ?? i("CHAT.MESSAGE", {
                defaultValue: "Сообщение..."
              })
            })
          }), /* @__PURE__ */ d("div", {
            className: Te.gap
          }), /* @__PURE__ */ d("button", {
            className: Te.button,
            onClick: S,
            type: "button",
            children: /* @__PURE__ */ d(Gs, {})
          })]
        }), /* @__PURE__ */ d("button", {
          className: De(Te.button, Te.buttonSend, {
            // [css.buttonDisabled]: !Boolean(message.current),
            // [css.buttonSend]: Boolean(message.current),
          }),
          title: "Отправить сообщение",
          type: "submit",
          children: /* @__PURE__ */ d(qs, {})
        }), /* @__PURE__ */ d(ks, {
          onEmojiSelect: T,
          visible: t
        })]
      })]
    })]
  });
});
lt.displayName = "MessageInputPanel";
const Fc = /* @__PURE__ */ g(() => {
  const {
    t: e
  } = ie(), n = me(), i = I(fl), a = E(() => {
    vl();
  }, []);
  return i.length === 0 ? null : /* @__PURE__ */ d(w, {
    bg: "rgba(0, 0, 0, .7)",
    bottom: "0",
    justifyContent: "center",
    left: "0",
    p: "2",
    position: "absolute",
    right: "0",
    top: "0",
    zIndex: "9",
    children: /* @__PURE__ */ f(w, {
      bg: "#141414",
      borderRadius: "12",
      maxHeight: "100%",
      p: "6",
      shadow: "1",
      children: [/* @__PURE__ */ f(w, {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        mb: "6",
        children: [/* @__PURE__ */ d(b, {
          color: "white",
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "20px",
          children: e("CHAT.TITLE.ATTACH_FILE", {
            defaultValue: "Отправить файл"
          })
        }), /* @__PURE__ */ d(bi, {
          onPress: a,
          children: /* @__PURE__ */ d(Ti, {
            fill: n.colors.gray[400],
            height: "32",
            width: "32"
          })
        })]
      }), i.map((t) => /* @__PURE__ */ d(Ni, {
        file: t.file,
        fileName: t.file.name,
        fileSize: aa(t.file.size),
        mime: t.file.type,
        onDelete: () => {
          kl(t.file.name);
        },
        progress: t.state === "new" ? t.progress : void 0
      }, t.file.name + t.file.size)), /* @__PURE__ */ d(w, {
        mb: "8",
        mt: "6",
        children: /* @__PURE__ */ d(lt, {
          shortView: !0,
          placeholder: e("CHAT.SIGN", {
            defaultValue: "Подпись..."
          })
        })
      })]
    })
  });
});
Fc.displayName = "ChatAddAttachments";
const ta = "chatBodyPanel", gc = "_dropzoneContainer_m10z4_1", hc = "_dropzoneOverlay_m10z4_9", Vt = {
  dropzoneContainer: gc,
  dropzoneOverlay: hc
}, ot = /* @__PURE__ */ g(({
  children: e
}) => {
  const {
    getInputProps: n,
    getRootProps: i,
    isDragActive: a
  } = Ks({
    noClick: !0,
    // accept: {
    //   "image/*": [".png", ".gif", ".jpeg", ".jpg"],
    // },
    onDrop: Oi
  });
  return /* @__PURE__ */ f("div", {
    className: Vt.dropzoneContainer,
    ...i(),
    "data-testid": "ChatBodyPanel",
    id: ta,
    children: [/* @__PURE__ */ d("input", {
      ...n()
    }), a ? /* @__PURE__ */ d("div", {
      className: Vt.dropzoneOverlay
    }) : null, e]
  });
});
ot.displayName = "ChatBodyPanel";
const da = /* @__PURE__ */ g(({
  children: e,
  distance: n
}) => /* @__PURE__ */ f(b, {
  color: "white",
  fontSize: "14",
  lineHeight: "18",
  maxWidth: "100%",
  children: [e, /* @__PURE__ */ d(b, {
    display: "inline-flex",
    minWidth: "40px",
    opacity: "0",
    pr: "7",
    children: n
  })]
}));
da.displayName = "ChatItemText";
const la = /* @__PURE__ */ g(/* @__PURE__ */ ms(({
  children: e
}, n) => /* @__PURE__ */ d(b, {
  ref: n,
  bottom: "6px",
  color: "text.100",
  fontSize: "12",
  fontWeight: "500",
  lineHeight: "20px",
  position: "absolute",
  right: "2",
  children: e
})));
la.displayName = "ChatItemTime";
const st = /* @__PURE__ */ g(({
  isOwner: e,
  message: n
}) => {
  if (n.type !== "RECEIVED")
    return null;
  const i = n.message.reactions;
  return /* @__PURE__ */ d(w, {
    flexDirection: e ? "row-reverse" : "row",
    flexWrap: "wrap",
    ml: e ? "-1" : "0",
    mr: e ? "-1" : "0",
    my: "1",
    children: i.map((a) => /* @__PURE__ */ d(Wl, {
      count: a.count,
      hasMyReaction: a.me,
      isOwner: e ?? !1,
      messageId: n.message.id,
      reaction: a.name
    }, a.name))
  });
});
st.displayName = "ChatItemReaction";
const Wl = /* @__PURE__ */ g(({
  count: e,
  hasMyReaction: n,
  isOwner: i,
  messageId: a,
  reaction: t
}) => {
  const [l, o] = H(!1);
  return /* @__PURE__ */ f(te, {
    alignItems: "center",
    bg: "rgba(255, 255, 255, .04)",
    borderRadius: "12",
    flexDirection: "row",
    h: "24px",
    ml: i ? "0" : "1",
    mr: i ? "1" : "0",
    mt: "1",
    onHoverIn: () => o(!0),
    onHoverOut: () => o(!1),
    onPress: () => {
      n ? yl({
        messageId: a,
        reaction: t
      }) : Za({
        messageId: a,
        reaction: t
      });
    },
    px: "2",
    children: [/* @__PURE__ */ d(b, {
      fontSize: "12",
      lineHeight: "20",
      children: t
    }), /* @__PURE__ */ d(b, {
      color: l ? "white" : n ? "blue.100" : "text.100",
      fontSize: "12",
      fontWeight: "500",
      lineHeight: "20",
      ml: "1",
      position: "relative",
      top: "1px",
      children: e
    })]
  });
});
Wl.displayName = "Reaction";
const Gl = /* @__PURE__ */ g(({
  children: e,
  message: n
}) => /* @__PURE__ */ f(w, {
  alignItems: "flex-start",
  flex: "1",
  flexDirection: "column",
  mr: "36px",
  mt: "1",
  children: [/* @__PURE__ */ d(w, {
    bg: "#232324",
    borderBottomLeftRadius: "2px",
    borderBottomRightRadius: "12px",
    borderTopRadius: "10px",
    maxWidth: "100%",
    minWidth: 90,
    position: "relative",
    px: "2",
    py: "10px",
    children: e
  }), /* @__PURE__ */ d(st, {
    message: n
  })]
}));
Gl.displayName = "Message";
const bc = "_emojiesInner_16r1q_1", yc = "_emojies_16r1q_1", Ot = {
  emojiesInner: bc,
  emojies: yc
}, ql = /* @__PURE__ */ g(({
  onReactionSelect: e
}) => {
  const n = md.emojis, i = je(null);
  return Q(() => {
    const a = i.current;
    if (a != null) {
      const t = (l) => {
        a.scrollLeft += l.deltaY, l.preventDefault(), l.stopPropagation();
      };
      return a.addEventListener("wheel", t), () => {
        a.removeEventListener("wheel", t);
      };
    }
  }, [i]), Q(() => {
    const a = i.current;
    if (a != null) {
      const t = (l) => {
        l.code === "ArrowLeft" ? a.scrollLeft -= 10 : l.code === "ArrowRight" && (a.scrollLeft += 10);
      };
      return document.body.addEventListener("keydown", t, !1), () => {
        document.body.removeEventListener("keydown", t);
      };
    }
  }, []), /* @__PURE__ */ d("div", {
    ref: i,
    className: Ot.emojiesInner,
    children: /* @__PURE__ */ d("div", {
      className: Ot.emojies,
      children: Zt.emojis.map((a) => {
        const t = n[a];
        return t == null || t.version !== 1 ? null : /* @__PURE__ */ d(vs, {
          emoji: t.skins[0].native,
          onEmojiSelect: e
        }, t.id);
      })
    })
  });
});
ql.displayName = "MessageReactionSelect";
const Tc = "_menuContainer_utwk9_1", Mc = "_showMenu_utwk9_1", Cc = "_menu_utwk9_1", wc = "_menuItem_utwk9_20", _c = "_menuItemDelete_utwk9_33", Ec = "_container_utwk9_41", Rc = "_containerOwner_utwk9_47", xc = "_containerSelected_utwk9_51", Dc = "_containerHidden_utwk9_55", Ic = "_emojies_utwk9_71", Ac = "_bg_utwk9_96", Me = {
  menuContainer: Tc,
  showMenu: Mc,
  menu: Cc,
  menuItem: wc,
  menuItemDelete: _c,
  container: Ec,
  containerOwner: Rc,
  containerSelected: xc,
  containerHidden: Dc,
  emojies: Ic,
  bg: Ac
}, li = 208, Vc = 36, Pn = 10, Oc = 20, oa = /* @__PURE__ */ g(({
  children: e,
  isMe: n,
  messageId: i
}) => {
  const a = I(Ul), {
    height: t,
    width: l
  } = wn(), [o, s] = H({
    panelRect: void 0,
    rect: null,
    x: 0,
    y: 0
  }), r = je(null), c = je(!1), [u, v] = H({}), m = mn(), p = E((T) => {
    if (r.current == null)
      return;
    T.preventDefault(), (!a.opened || a.messageId !== i) && ia({
      messageId: i,
      opened: !0
    });
    const M = r.current.getBoundingClientRect(), C = document.querySelector(`#${ta}`);
    s({
      panelRect: C == null ? void 0 : C.getBoundingClientRect(),
      rect: M,
      x: T.clientX,
      y: T.clientY
    });
  }, [i, a.messageId, a.opened]), k = E(() => {
    a.opened && a.messageId === i && (Ll(), c.current = !1);
  }, [i, a.messageId, a.opened]), F = E(() => {
    c.current = !0;
  }, []), S = E((T) => {
    if (r.current == null)
      return;
    const M = r.current.getBoundingClientRect(), C = document.querySelector(`#${ta}`);
    s({
      panelRect: C == null ? void 0 : C.getBoundingClientRect(),
      rect: M,
      x: T.clientX,
      y: T.clientY
    }), c.current ? a.opened ? k() : ia({
      messageId: i,
      opened: !0
    }) : a.opened && k();
  }, [k, i, a.opened]), N = E((T) => {
    Za({
      messageId: i,
      reaction: T
    }), k();
  }, [k, i]);
  Q(() => {
    const T = (M) => {
      var C;
      (C = r.current) != null && C.contains(M.target) || k();
    };
    return document.addEventListener("click", T, !1), document.addEventListener("contextmenu", T, !1), () => {
      document.removeEventListener("click", T), document.removeEventListener("contextmenu", T);
    };
  }, [k]), Q(() => {
    const T = (M) => {
      M.code === "Escape" && k();
    };
    return document.body.addEventListener("keydown", T, !1), () => {
      document.body.removeEventListener("keydown", T);
    };
  }, [k]);
  const y = L(() => {
    const T = [{
      className: Me.menuItem,
      id: "reply",
      onClick: (M) => {
        M.stopPropagation(), gl(i), k();
      },
      title: "Ответить"
    }];
    return n && T.unshift({
      className: De(Me.menuItem, Me.menuItemDelete),
      id: "delete",
      onClick: (M) => {
        M.stopPropagation(), $l(i), k();
      },
      title: "Удалить"
    }, {
      className: Me.menuItem,
      id: "edit",
      onClick: (M) => {
        M.stopPropagation(), Fl(i), k();
      },
      title: "Редактировать"
    }), T;
  }, [k, n, i]);
  return Q(() => {
    if (!(r.current == null || o.rect == null || o.panelRect == null) && a.opened && a.messageId === i) {
      const {
        panelRect: T,
        rect: M,
        x: C,
        y: P
      } = o, G = Math.abs(C - M.x), se = Math.abs(P - M.y), J = y.length, A = Oc + J * Vc + 40, R = {
        left: C + li < l - Pn ? C - M.x : M.width - li,
        top: Number(T.bottom) > P + A + Pn ? se + Pn : M.height - A
      };
      m === "base" && (R.left = C - li > Pn ? G - li - Pn : 0), v(R);
    }
  }, [y.length, t, l, n, m, o, a.opened, a.messageId, i]), Q(() => {
    if (r.current == null)
      return;
    const T = r.current.parentNode;
    if (T == null || !(T instanceof HTMLDivElement))
      return;
    a.opened && a.messageId === i ? T.style.zIndex = "3" : T.style.zIndex = "auto";
    const M = document.querySelector(`#${Hl}`);
    M != null && M.classList.contains("focused") && M.focus();
  }, [i, a.messageId, a.opened]), /* @__PURE__ */ f("div", {
    ref: r,
    className: De(Me.container, {
      [Me.containerOwner]: n,
      [Me.containerSelected]: a.opened && a.messageId === i,
      [Me.containerHidden]: a.opened && a.messageId !== i
    }),
    onClick: S,
    onContextMenu: p,
    onTouchEnd: F,
    children: [e, a.opened && a.messageId === i && /* @__PURE__ */ f("div", {
      className: Me.menuContainer,
      style: u,
      children: [/* @__PURE__ */ d("div", {
        className: Me.emojies,
        children: /* @__PURE__ */ d(ql, {
          onReactionSelect: N
        })
      }), /* @__PURE__ */ d("div", {
        className: Me.menu,
        children: y.map(({
          className: T,
          id: M,
          onClick: C,
          title: P
        }) => /* @__PURE__ */ d(Xl, {
          className: T,
          onClick: C,
          children: P
        }, M))
      })]
    }), a.opened && a.messageId !== i ? /* @__PURE__ */ d(tt, {}) : null]
  });
});
oa.displayName = "MessageContainer";
const Xl = /* @__PURE__ */ g(({
  children: e,
  className: n,
  onClick: i
}) => /* @__PURE__ */ d("div", {
  className: n,
  onClick: i,
  children: e
}));
Xl.displayName = "MenuItem";
const Yl = /* @__PURE__ */ g(({
  children: e,
  message: n
}) => /* @__PURE__ */ f(w, {
  alignItems: "flex-end",
  flex: "1",
  flexDirection: "column",
  ml: "36px",
  children: [/* @__PURE__ */ d(w, {
    bg: "#232324",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "2px",
    borderTopRadius: "10px",
    maxWidth: "100%",
    position: "relative",
    px: "2",
    py: "10px",
    children: e
  }), /* @__PURE__ */ d(st, {
    isOwner: !0,
    message: n
  })]
}));
Yl.displayName = "MyMessage";
const sa = /* @__PURE__ */ g(({
  message: e
}) => {
  const n = I(ei), i = L(() => e.text !== "" ? ul(e.text) : e.text, [e.text]);
  return /* @__PURE__ */ f(w, {
    borderLeftColor: "blue.50",
    borderLeftStyle: "solid",
    borderLeftWidth: "2",
    mb: "3",
    pl: "10px",
    children: [/* @__PURE__ */ d(b, {
      color: "blue.50",
      ellipsizeMode: "tail",
      fontSize: "14",
      fontWeight: "500",
      lineHeight: "18",
      numberOfLines: 1,
      children: e.nickname ?? nn(n.ref.get(e.member_id))
    }), /* @__PURE__ */ d(b, {
      color: "gray.400",
      ellipsizeMode: "tail",
      fontSize: "14",
      fontWeight: "400",
      lineHeight: "18",
      numberOfLines: 1,
      children: i
    })]
  });
});
sa.displayName = "RespondingMessage";
const rt = /* @__PURE__ */ g(({
  message: e,
  myUserId: n
}) => {
  var v, m, p;
  const i = me(), a = I(ei), {
    data: t
  } = qn(Mi, {
    skip: e.type !== "RECEIVED",
    variables: {
      id: e.type === "RECEIVED" ? e.message.member_id : ""
    }
  }), [l, o] = e.type === "RECEIVED" ? [Yt(e.message.created), e.message.text] : [e.sent, e.messageText], s = L(() => {
    const k = new Date(l), F = Js(new Date(l)), S = Qs(k), N = Et(new Date(l), "HH:mm");
    return F ? `Сегодня, в ${N}` : S ? `Вчера, в ${N}` : `${Et(new Date(l), "dd.MM.yyyy")} в ${N}`;
  }, [l]), r = L(() => o !== "" ? ul(o) : o, [o]), c = L(() => {
    var k;
    if (e.type === "RECEIVED")
      return (k = e.message.embeds.find((F) => F.message != null)) == null ? void 0 : k.message;
  }, [e]), u = L(() => e.type === "RECEIVED" ? e.message.embeds.filter((k) => k.file != null).map((k) => k.file) : void 0, [e]);
  return e.type !== "RECEIVED" || n === e.message.user_id ? /* @__PURE__ */ d(oa, {
    isMe: !0,
    messageId: e.type === "RECEIVED" ? e.message.id : "",
    children: /* @__PURE__ */ f(Yl, {
      message: e,
      children: [c != null ? /* @__PURE__ */ d(sa, {
        message: c
      }) : null, u == null ? void 0 : u.map((k) => /* @__PURE__ */ d(Ni, {
        fileName: (k == null ? void 0 : k.filename) ?? "",
        fileSize: aa((k == null ? void 0 : k.size) ?? 0),
        mime: k == null ? void 0 : k.mime,
        onDelete: () => {
        },
        url: k == null ? void 0 : k.uri
      }, k == null ? void 0 : k.id)), /* @__PURE__ */ f(la, {
        children: [s, " ", /* @__PURE__ */ d(Kl, {
          fill: e.type === "RECEIVED" ? i.colors.blue[50] : i.colors.gray[500],
          style: Pc.mark
        })]
      }), /* @__PURE__ */ d(da, {
        distance: s,
        children: r
      })]
    })
  }) : /* @__PURE__ */ f(oa, {
    messageId: e.message.id,
    children: [c != null ? /* @__PURE__ */ d(sa, {
      message: c
    }) : null, u == null ? void 0 : u.map((k) => /* @__PURE__ */ d(Ni, {
      fileName: (k == null ? void 0 : k.filename) ?? "",
      fileSize: aa((k == null ? void 0 : k.size) ?? 0),
      mime: k == null ? void 0 : k.mime,
      url: k == null ? void 0 : k.uri
      // onDelete={() => {
      //   eventFileAttachmentsRemoved(item.file.fil);
      // }}
    }, k == null ? void 0 : k.id)), /* @__PURE__ */ f(w, {
      alignItems: "center",
      flexDirection: "row",
      mb: "6px",
      mr: "32px",
      children: [/* @__PURE__ */ d(fa, {
        bg: "#262626",
        h: "32px",
        source: va((m = (v = a.ref.get(e.message.member_id)) == null ? void 0 : v.user) == null ? void 0 : m.avatar.uri, 32),
        w: "32px",
        children: /* @__PURE__ */ d(b, {
          color: "text.100",
          fontSize: 16,
          fontWeight: 400,
          children: Sa(t == null ? void 0 : t.meet.member.get)
        })
      }), /* @__PURE__ */ d(b, {
        color: "text.100",
        ellipsizeMode: "tail",
        flex: "1",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "24",
        ml: "2",
        numberOfLines: 1,
        children: e.message.nickname ?? nn((p = a.ref.get(e.message.member_id)) == null ? void 0 : p.user)
      })]
    }), /* @__PURE__ */ f(Gl, {
      message: e,
      children: [/* @__PURE__ */ d(Na, {
        label: s,
        children: /* @__PURE__ */ d(la, {
          children: s
        })
      }), /* @__PURE__ */ d(da, {
        distance: s,
        children: r
      })]
    })]
  });
});
rt.displayName = "ChatMessageItem";
const Kl = /* @__PURE__ */ g((e) => /* @__PURE__ */ d(Zs, {
  fill: "none",
  height: 11,
  width: 16,
  ...e,
  children: /* @__PURE__ */ d(er, {
    d: "M11.071.653a.457.457 0 0 0-.304-.102.493.493 0 0 0-.381.178l-6.19 7.636-2.405-2.272a.463.463 0 0 0-.336-.146.47.47 0 0 0-.343.146l-.311.31a.445.445 0 0 0-.14.337c0 .136.047.25.14.343l2.996 2.996a.724.724 0 0 0 .501.203.697.697 0 0 0 .546-.266l6.646-8.417a.497.497 0 0 0 .108-.299.441.441 0 0 0-.19-.374L11.07.653Zm-2.45 7.674a15.31 15.31 0 0 1-.546-.355.43.43 0 0 0-.317-.12.46.46 0 0 0-.362.158l-.292.33a.482.482 0 0 0 .013.666l1.079 1.073c.135.135.3.203.495.203a.699.699 0 0 0 .552-.267l6.62-8.391a.446.446 0 0 0 .109-.298.487.487 0 0 0-.178-.375l-.355-.273a.487.487 0 0 0-.673.076L8.62 8.327Z"
  })
}));
Kl.displayName = "Mark";
const Pc = _n.create({
  mark: {
    marginLeft: 2
  }
}), Lc = /* @__PURE__ */ g(() => {
  var s;
  const e = (s = Fi()) == null ? void 0 : s.id, [n, i] = I([wl, Vl]), a = je(null);
  Q(() => {
    a.current != null && a.current.scrollToEnd({
      animated: !0
    });
  }, []);
  const t = E((r) => /* @__PURE__ */ d(rt, {
    message: r.item,
    myUserId: e
  }, r.item.type === "RECEIVED" ? r.item.message.id : r.item.messageText), [e]), l = L(() => Se((r) => r, [], n), [n]), o = L(() => [e, i], [e, i]);
  return /* @__PURE__ */ d(ot, {
    children: Xn(n) ? /* @__PURE__ */ d(Fa, {
      ref: a,
      inverted: !0,
      data: l,
      extraData: o,
      keyExtractor: Uc,
      mb: "2",
      onEndReached: nt,
      px: "4",
      renderItem: t
    }) : /* @__PURE__ */ d(hi, {
      flex: 1
    })
  });
});
Lc.displayName = "ChatBody";
const Uc = (e) => e.type === "RECEIVED" ? e.message.id + e.message.updated + e.message.created : e.messageText, $c = /* @__PURE__ */ g(() => {
  const e = I(Wm), {
    t: n
  } = ie(), i = E(() => {
    e != null && it(e);
  }, [e]), a = E(() => {
    Bl();
  }, []);
  return e == null ? null : /* @__PURE__ */ d(w, {
    bg: "rgba(0, 0, 0, .7)",
    bottom: "0",
    justifyContent: "center",
    left: "0",
    p: "2",
    position: "absolute",
    right: "0",
    top: "0",
    zIndex: "9",
    children: /* @__PURE__ */ f(w, {
      bg: "#141414",
      borderRadius: "12",
      maxHeight: "100%",
      p: "6",
      shadow: "1",
      children: [/* @__PURE__ */ d(b, {
        color: "white",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "20px",
        mb: "8",
        children: n("CHAT.TITLE.REMOVE_MESSAGE", {
          defaultValue: "Хотите удалить это сообщение?"
        })
      }), /* @__PURE__ */ f(Je, {
        justifyContent: "flex-end",
        space: "2",
        children: [/* @__PURE__ */ d(te, {
          alignItems: "center",
          bg: "rgba(61, 61, 77, 0.25)",
          borderRadius: "22",
          h: "44",
          justifyContent: "center",
          onPress: a,
          px: "6",
          children: /* @__PURE__ */ d(b, {
            color: "white",
            fontSize: "14",
            fontWeight: "500",
            lineHeight: "24",
            children: n("BUTTON.CANCEL")
          })
        }), /* @__PURE__ */ d(te, {
          alignItems: "center",
          bg: "rgba(61, 61, 77, 0.25)",
          borderRadius: "22",
          h: "44",
          justifyContent: "center",
          onPress: i,
          px: "6",
          children: /* @__PURE__ */ d(b, {
            color: "red.50",
            fontSize: "14",
            fontWeight: "500",
            lineHeight: "24",
            children: n("BUTTON.DELETE", {
              defaultValue: "Удалить"
            })
          })
        })]
      })]
    })
  });
});
$c.displayName = "ChatMessageDelete";
const Bc = /* @__PURE__ */ g(() => {
  const e = E(() => {
    Zn();
  }, []);
  return /* @__PURE__ */ d(w, {
    alignItems: "flex-end",
    display: {
      base: "flex",
      lg: "none"
    },
    pb: "3",
    pt: "5",
    px: "4",
    children: /* @__PURE__ */ d(te, {
      h: "32px",
      onPress: e,
      w: "32px",
      children: /* @__PURE__ */ d(ad, {})
    })
  });
});
Bc.displayName = "ChatMobileHead";
const Jl = /* @__PURE__ */ g(() => {
  var s;
  const e = (s = Fi()) == null ? void 0 : s.id, {
    chatMessagesRemoteData: n,
    receivedMessagesEditedAt: i
  } = I({
    chatMessagesRemoteData: wl,
    receivedMessagesEditedAt: Vl
  }), a = je(null);
  Q(() => {
    a.current != null && a.current.scrollToEnd({
      animated: !0
    });
  }, []);
  const t = E((r) => /* @__PURE__ */ d(rt, {
    message: r.item,
    myUserId: e
  }, r.item.type === "RECEIVED" ? r.item.message.id : r.item.messageText), [e]), l = L(() => Se((r) => r, [], n), [n]), o = L(() => [e, i], [e, i]);
  return /* @__PURE__ */ d(ot, {
    children: Xn(n) ? /* @__PURE__ */ d(Fa, {
      ref: a,
      inverted: !0,
      data: l,
      extraData: o,
      keyExtractor: zc,
      mb: "2",
      onEndReached: nt,
      px: "4",
      renderItem: t
    }) : /* @__PURE__ */ d(hi, {
      flex: 1
    })
  });
});
Jl.displayName = "MessagesList";
const zc = (e) => e.type === "RECEIVED" ? e.message.id + e.message.updated + e.message.created : e.messageText, jc = /* @__PURE__ */ g(() => /* @__PURE__ */ f(be, {
  children: [/* @__PURE__ */ d(w, {
    bg: "#141414",
    borderBottomRadius: "12",
    flex: "1",
    children: /* @__PURE__ */ d(Jl, {})
  }), /* @__PURE__ */ d(w, {
    bg: "#141414",
    borderRadius: "12",
    mt: "2",
    children: /* @__PURE__ */ d(lt, {})
  })]
}));
jc.displayName = "ChatPanel";
const Hc = /* @__PURE__ */ g(() => {
  const e = me(), n = E(() => {
    Zn();
  }, []);
  return /* @__PURE__ */ d(w, {
    flexDirection: "row",
    h: "48px",
    justifyContent: "flex-end",
    p: "2",
    children: /* @__PURE__ */ d(bi, {
      onPress: n,
      children: /* @__PURE__ */ d(Ti, {
        fill: e.colors.gray[400],
        height: "32",
        width: "32"
      })
    })
  });
});
Hc.displayName = "ChatPanelHead";
const Pt = j.object({
  conditions: j.object({
    join: j.object({
      // emails: z.boolean().optional().nullable(),
      link: j.boolean().optional().nullable(),
      // password: z.boolean().optional().nullable(),
      password: j.string().min(2).max(30).optional().nullable().transform((e) => e === "" ? void 0 : e)
    }),
    start: j.object({
      always: j.boolean().optional().nullable(),
      time: j.any().optional().nullable()
    }).optional().nullable(),
    terminate: j.object({
      empty: j.boolean().optional().nullable(),
      force: j.boolean().optional().nullable(),
      time: j.any().optional().nullable()
    }).optional().nullable()
  }),
  description: j.string().min(1, "должно содержать минимум один символ").optional().or(j.literal("")).transform((e) => e === "" ? void 0 : e),
  joinContactsEmails: j.array(j.object({
    value: j.string()
  })).optional().nullable(),
  pincode: j.string().optional().nullable().transform((e) => e === "" ? void 0 : e),
  recording: j.boolean().optional().nullable(),
  title: j.string({
    required_error: x("VALIDATION.ERRORS.REQUIRED")
  }).min(1, {
    message: x("VALIDATION.ERRORS.AT_LEAST_ONE", {
      defaultValue: "Название должно содержать минимум один символ"
    })
  }),
  vanity_name: j.string().min(4, "минимум 4 символа").regex(/^[a-z\d-.]+$/, 'содержит невалидные символы, разрешены цифры, английские буквы в нижнем регистре и тире "-"').optional().or(j.literal("")).transform((e) => e === "" ? void 0 : e)
}), Ql = /* @__PURE__ */ g(({
  meetSpace: e,
  onSuccess: n,
  type: i,
  variant: a
}) => {
  var G, se, J;
  const [t, l] = H(!1), [o, s] = H(!1), r = ns(), c = (r == null ? void 0 : r.type) === "authenticated" && r.user.premium, [u, {
    loading: v
  }] = Tn(kd, {
    refetchQueries: [cd, dr, xk]
  }), {
    control: m,
    formState: {
      errors: p,
      isValid: k
    },
    handleSubmit: F,
    setError: S
  } = En({
    defaultValues: {
      /* @ts-expect-error TODO null vs undefined */
      title: "",
      ...e,
      ...e == null ? {
        conditions: {
          join: {
            link: !0
          }
        }
      } : void 0,
      joinContactsEmails: (J = (se = (G = e == null ? void 0 : e.conditions) == null ? void 0 : G.join.contacts) == null ? void 0 : se.email) == null ? void 0 : J.map((A) => ({
        value: A
      }))
    },
    mode: "all",
    resolver: Jn(Pt)
  }), N = E(async (A) => {
    const {
      joinContactsEmails: R,
      ...q
    } = Pt.parse(A);
    try {
      await u({
        variables: {
          id: e == null ? void 0 : e.id,
          ...q,
          conditions: {
            ...q.conditions,
            join: {
              ...q.conditions.join,
              contacts: {
                email: R == null ? void 0 : R.map((U) => U.value)
              }
            }
            // ...(recording ? { terminate: { empty: true } } : {}),
          }
          // recording,
        }
      }), n();
    } catch (U) {
      U instanceof ca && S("root", {
        message: U.message === "rpc error: code = Unknown desc = permission denied" ? "Доступ запрещен" : U.message,
        type: "serverError"
      });
    }
  }, [u, e == null ? void 0 : e.id, n, S]), {
    append: y,
    fields: T,
    remove: M
  } = rd({
    control: m,
    name: "joinContactsEmails"
  }), C = E(() => {
    T.length === 0 && y({
      value: ""
    }), s((A) => !A);
  }, [y, T.length]), P = i === "create" ? a === "room" ? x("ROOM.EDIT_ROOM.CREATE_ROOM") : x("ROOM.EDIT_ROOM.CREATE_EVENT") : a === "room" ? x("ROOM.EDIT_ROOM.EDIT_ROOM") : x("ROOM.EDIT_ROOM.EDIT_EVENT");
  return /* @__PURE__ */ d(w, {
    alignItems: "stretch",
    flex: {
      lg: "1"
    },
    justifyContent: "space-between",
    children: /* @__PURE__ */ f(Zl, {
      children: [/* @__PURE__ */ f(w, {
        width: "100%",
        children: [/* @__PURE__ */ f(Ce, {
          isRequired: !0,
          mb: {
            base: 6,
            lg: 10
          },
          children: [/* @__PURE__ */ d(ln, {
            mb: "2",
            children: P
          }), /* @__PURE__ */ d(ae, {
            control: m,
            name: "title",
            render: ({
              field: A,
              fieldState: {
                error: R
              }
            }) => /* @__PURE__ */ d(on, {
              errorMessage: R == null ? void 0 : R.message,
              isInvalid: !!R,
              mb: 0,
              onBlur: A.onBlur,
              onChangeText: A.onChange,
              placeholder: x("ROOM.EDIT.TITLE_PLACEHOLDER"),
              value: A.value,
              withAnimation: !1
            })
          })]
        }), /* @__PURE__ */ f(Ce, {
          mb: {
            base: 6,
            lg: 10
          },
          children: [/* @__PURE__ */ d(ln, {
            mb: "2",
            children: x("ROOM.EDIT.VANITY_NAME")
          }), /* @__PURE__ */ d(ae, {
            control: m,
            name: "vanity_name",
            render: ({
              field: A,
              fieldState: {
                error: R
              }
            }) => /* @__PURE__ */ d(on, {
              errorMessage: R == null ? void 0 : R.message,
              isInvalid: !!R,
              mb: 0,
              onBlur: A.onBlur,
              onChangeText: A.onChange,
              placeholder: x("ROOM.EDIT.VANITY_NAME_PLACEHOLDER"),
              value: A.value,
              withAnimation: !1
            })
          })]
        }), /* @__PURE__ */ f(Ce, {
          mb: {
            base: 6,
            lg: 10
          },
          children: [/* @__PURE__ */ d(ln, {
            mb: "2",
            children: x("ROOM.EDIT.DESCRIPTION")
          }), /* @__PURE__ */ d(ae, {
            control: m,
            name: "description",
            render: ({
              field: A,
              fieldState: {
                error: R
              }
            }) => /* @__PURE__ */ d(on, {
              errorMessage: R == null ? void 0 : R.message,
              isInvalid: !!R,
              mb: 0,
              onBlur: A.onBlur,
              onChangeText: A.onChange,
              placeholder: x("ROOM.EDIT.DESCRIPTION_PLACEHOLDER"),
              value: A.value,
              withAnimation: !1
            })
          })]
        }), a === "event" ? /* @__PURE__ */ f(be, {
          children: [/* @__PURE__ */ d(hn, {
            color: "black",
            fontSize: "16",
            fontWeight: "600",
            lineHeight: "24",
            mb: {
              base: 4,
              lg: 6
            },
            mt: "0",
            children: x("ROOM.CREATE.DATE_AND_TIME_ACTIVITY", {
              defaultValue: "Время активности встречи"
            })
          }), /* @__PURE__ */ f(w, {
            mb: "3",
            children: [/* @__PURE__ */ d(ln, {
              display: {
                base: "none",
                lg: "flex"
              },
              mb: "2",
              children: x("ROOM.CREATE.DATE_AND_TIME", {
                defaultValue: "Дата и время"
              })
            }), /* @__PURE__ */ d(ln, {
              display: {
                base: "flex",
                lg: "none"
              },
              mb: "2",
              children: x("ROOM.CREATE.DATE_AND_TIME_START", {
                defaultValue: "Дата и время начала встречи"
              })
            }), /* @__PURE__ */ f(w, {
              alignItems: "flex-start",
              flex: "1",
              flexDirection: {
                base: "column",
                lg: "row"
              },
              children: [/* @__PURE__ */ d(ae, {
                control: m,
                name: "conditions.start.time",
                render: ({
                  field: {
                    onBlur: A,
                    onChange: R,
                    value: q
                  }
                }) => /* @__PURE__ */ d(Ct, {
                  mode: "datetime",
                  onBlur: A,
                  onChange: R,
                  value: q
                })
              }), /* @__PURE__ */ d(b, {
                color: "black",
                display: {
                  base: "none",
                  lg: "flex"
                },
                fontSize: "16",
                fontWeight: "500",
                h: "12",
                justifyContent: "center",
                lineHeight: "48",
                textAlign: "center",
                w: "10",
                children: "–"
              }), /* @__PURE__ */ d(ln, {
                display: {
                  base: "flex",
                  lg: "none"
                },
                mb: "2",
                mt: "2",
                children: x("ROOM.CREATE.DATE_AND_TIME_STOP", {
                  defaultValue: "Дата и время окончания встречи"
                })
              }), /* @__PURE__ */ d(ae, {
                control: m,
                name: "conditions.terminate.time",
                render: ({
                  field: {
                    onBlur: A,
                    onChange: R,
                    value: q
                  }
                }) => /* @__PURE__ */ d(Ct, {
                  mode: "datetime",
                  onBlur: A,
                  onChange: R,
                  value: q
                })
              })]
            })]
          }), /* @__PURE__ */ d(Ce, {
            children: /* @__PURE__ */ d(ae, {
              control: m,
              name: "conditions.start.always",
              render: ({
                field: {
                  onChange: A,
                  value: R
                }
              }) => /* @__PURE__ */ f(te, {
                flexDir: "row",
                onPress: () => A(!R),
                children: [/* @__PURE__ */ d(Ge, {
                  checked: !!R,
                  view: {
                    mr: 2
                  }
                }), /* @__PURE__ */ d(b, {
                  children: x("ROOM.CREATE.AUTOSTART", {
                    defaultValue: "Стартует автоматически"
                  })
                })]
              })
            })
          }), /* @__PURE__ */ d(Ce, {
            children: /* @__PURE__ */ d(ae, {
              control: m,
              name: "conditions.terminate.empty",
              render: ({
                field: {
                  onChange: A,
                  value: R
                }
              }) => /* @__PURE__ */ f(te, {
                flexDir: "row",
                onPress: () => A(!R),
                children: [/* @__PURE__ */ d(Ge, {
                  checked: !!R,
                  view: {
                    mr: 2
                  }
                }), /* @__PURE__ */ d(b, {
                  children: x("ROOM.CREATE.AUTOCLOSE", {
                    defaultValue: "Завершается при выходе последнего участника"
                  })
                })]
              })
            })
          }), /* @__PURE__ */ d(Ce, {
            children: /* @__PURE__ */ d(ae, {
              control: m,
              name: "conditions.terminate.force",
              render: ({
                field: {
                  onChange: A,
                  value: R
                }
              }) => /* @__PURE__ */ f(te, {
                flexDir: "row",
                onPress: () => A(!R),
                children: [/* @__PURE__ */ d(Ge, {
                  checked: !!R,
                  view: {
                    mr: 2
                  }
                }), /* @__PURE__ */ d(b, {
                  children: x("ROOM.CREATE.CLOSE_BY_HAND", {
                    defaultValue: "Можно завершить вручную"
                  })
                })]
              })
            })
          })]
        }) : null, c ? /* @__PURE__ */ d(Ce, {
          children: /* @__PURE__ */ d(ae, {
            control: m,
            name: "recording",
            render: ({
              field: {
                onChange: A,
                value: R
              }
            }) => /* @__PURE__ */ f(w, {
              children: [/* @__PURE__ */ f(te, {
                flexDir: "row",
                onPress: () => A(!R),
                children: [/* @__PURE__ */ d(Ge, {
                  checked: !!R,
                  view: {
                    mr: 2
                  }
                }), /* @__PURE__ */ d(b, {
                  children: x("ROOM.CREATE.ENABLE_RECORDING", {
                    defaultValue: "Разрешена запись аудио"
                  })
                })]
              }), R ? /* @__PURE__ */ f(w, {
                flexDirection: "row",
                mt: 1,
                children: [/* @__PURE__ */ d(Ge, {
                  checked: !0,
                  disabled: !0,
                  view: {
                    mr: 2
                  }
                }), /* @__PURE__ */ d(b, {
                  children: x("ROOM.CREATE.AUTOCLOSE", {
                    defaultValue: "Завершается при выходе последнего участника"
                  })
                })]
              }) : null]
            })
          })
        }) : null, /* @__PURE__ */ d(hn, {
          color: "black",
          fontSize: "16",
          fontWeight: "600",
          lineHeight: "24",
          mb: {
            base: 4,
            lg: 6
          },
          mt: 4,
          children: x("ROOM.CREATE.ACCESS", {
            defaultValue: "Доступ"
          })
        }), /* @__PURE__ */ d(Ce, {
          children: /* @__PURE__ */ d(ae, {
            control: m,
            name: "conditions.join.link",
            render: ({
              field: {
                onChange: A,
                value: R
              }
            }) => /* @__PURE__ */ f(te, {
              flexDir: "row",
              onPress: () => A(!R),
              children: [/* @__PURE__ */ d(Ge, {
                checked: !!R,
                view: {
                  mr: 2
                }
              }), /* @__PURE__ */ d(b, {
                children: x("ROOM.CREATE.BY_LINK", {
                  defaultValue: "По ссылке"
                })
              })]
            })
          })
        }), /* @__PURE__ */ f(Ce, {
          flexDir: "column",
          isInvalid: !!p.title,
          children: [/* @__PURE__ */ f(te, {
            flexDir: "row",
            onPress: () => l((A) => !A),
            children: [/* @__PURE__ */ d(Ge, {
              checked: t,
              view: {
                mr: 2
              }
            }), /* @__PURE__ */ d(b, {
              children: x("ROOM.CREATE.BY_PASSWORD", {
                defaultValue: "По паролю"
              })
            })]
          }), t ? /* @__PURE__ */ d(ae, {
            control: m,
            name: "conditions.join.password",
            render: ({
              field: {
                onBlur: A,
                onChange: R,
                value: q
              },
              fieldState: {
                error: U,
                isDirty: ke
              }
            }) => /* @__PURE__ */ d(on, {
              errorMessage: U == null ? void 0 : U.message,
              isInvalid: !!U && ke,
              mb: "4",
              mt: "3",
              onBlur: A,
              onChangeText: R,
              type: "password",
              value: q ?? void 0,
              withAnimation: !1
            })
          }) : null]
        }), /* @__PURE__ */ f(Ce, {
          flexDirection: "column",
          children: [/* @__PURE__ */ f(te, {
            flexDir: "row",
            onPress: () => C(),
            children: [/* @__PURE__ */ d(Ge, {
              checked: o,
              view: {
                mr: 2
              }
            }), /* @__PURE__ */ d(b, {
              children: x("ROOM.CREATE.BY_EMAILS", {
                defaultValue: "Список по e-mail"
              })
            })]
          }), o ? /* @__PURE__ */ f(be, {
            children: [/* @__PURE__ */ d(Bn, {
              mb: "4",
              mt: "3",
              space: 3,
              children: T.map((A, R) => /* @__PURE__ */ f(Je, {
                alignItems: "center",
                space: 1,
                children: [/* @__PURE__ */ d(w, {
                  flex: "1",
                  children: /* @__PURE__ */ d(ae, {
                    control: m,
                    name: `joinContactsEmails[${R}].value`,
                    render: ({
                      field: {
                        name: q,
                        onBlur: U,
                        onChange: ke,
                        value: dn
                      }
                    }) => /* @__PURE__ */ d(on, {
                      mb: "0",
                      nativeID: q,
                      onBlur: U,
                      onChange: ke,
                      value: dn,
                      w: "100%"
                    })
                  })
                }), /* @__PURE__ */ d(te, {
                  alignItems: "center",
                  h: "36px",
                  justifyContent: "center",
                  onPress: () => M(R),
                  w: "36px",
                  children: /* @__PURE__ */ d(Jt, {})
                })]
              }, A.id))
            }), /* @__PURE__ */ d(w, {
              flexDir: "row-reverse",
              pr: "10",
              children: /* @__PURE__ */ d(He, {
                h: "36",
                onPress: () => y({
                  value: ""
                }),
                pl: "2",
                pr: "4",
                variant: "primary",
                children: /* @__PURE__ */ f(w, {
                  alignItems: "center",
                  flexDirection: "row",
                  children: [/* @__PURE__ */ d(Cs, {
                    fill: ze.colors.white,
                    height: "24",
                    width: "24"
                  }), /* @__PURE__ */ d(b, {
                    color: "white",
                    fontSize: "14",
                    fontWeight: "500",
                    ml: "2",
                    children: x("BUTTON.ADD_EMAIL", {
                      defaultValue: "Добавить email"
                    })
                  })]
                })
              })
            })]
          }) : null]
        })]
      }), p.root != null ? /* @__PURE__ */ d(Ss, {
        message: p.root.message
      }) : null, /* @__PURE__ */ d(w, {
        alignSelf: "flex-start",
        mt: 6,
        w: {
          base: "100%",
          lg: "auto"
        },
        children: /* @__PURE__ */ d(He, {
          color: "white",
          disabled: !k,
          isLoadingText: "Сохранение",
          loading: v,
          onPress: F(N),
          variant: "primary",
          w: {
            base: "100%"
          },
          children: /* @__PURE__ */ d(b, {
            color: "white",
            fontSize: "14",
            fontWeight: "500",
            children: x(i === "create" ? "BUTTON.CREATE" : "BUTTON.SAVE", {
              defaultValue: i === "create" ? "Создать" : "Сохранить"
            })
          })
        })
      })]
    })
  });
});
Ql.displayName = "EditMeetSpaceForm";
const Ce = /* @__PURE__ */ g((e) => /* @__PURE__ */ d(Be, {
  mb: "3",
  ...e
}));
Ce.displayName = "MyFormControl";
const ln = /* @__PURE__ */ g(({
  isSwitch: e,
  ...n
}) => /* @__PURE__ */ d(Be.Label, {
  alignItems: "center",
  flexDirection: e ? {
    lg: "row-reverse"
  } : void 0,
  mr: {
    lg: 2
  },
  ...n
}));
ln.displayName = "MyFormControlLabel";
const Zl = /* @__PURE__ */ g((e) => {
  const {
    height: n
  } = wn();
  return /* @__PURE__ */ d(ga, {
    maxHeight: {
      base: n - 94,
      lg: Math.min(n - 100, 716)
    },
    mb: "4",
    mr: {
      base: -2,
      lg: 0
    },
    pr: {
      base: 2,
      lg: 0
    },
    py: {
      base: 2,
      lg: 5
    },
    children: e.children
  });
});
Zl.displayName = "FormContent";
const eo = /* @__PURE__ */ g(({
  children: e,
  onClose: n,
  title: i
}) => /* @__PURE__ */ d(sd, {
  transparent: !0,
  visible: !0,
  onRequestClose: n,
  children: /* @__PURE__ */ f(w, {
    bg: "white",
    borderBottomLeftRadius: {
      base: 0,
      lg: 24
    },
    borderTopLeftRadius: {
      base: 0,
      lg: 24
    },
    flex: 1,
    marginLeft: {
      base: 0,
      lg: "auto"
    },
    p: 2,
    width: {
      base: "100%",
      lg: "50%"
    },
    children: [/* @__PURE__ */ f(Je, {
      alignItems: "center",
      justifyContent: "space-between",
      children: [/* @__PURE__ */ d(b, {
        color: "black",
        fontSize: "24",
        fontWeight: "500",
        lineHeight: "24",
        children: i
      }), /* @__PURE__ */ d(ha, {
        _focus: {
          bg: "transparent"
        },
        _hover: {
          bg: "transparent"
        },
        _pressed: {
          bg: "transparent"
        },
        bg: "transparent",
        height: "6",
        mr: 3,
        onPress: n,
        variant: "unstyled",
        width: "6",
        children: /* @__PURE__ */ d(ws, {})
      })]
    }), e]
  })
}));
eo.displayName = "LayoutModal";
const Wc = /* @__PURE__ */ g(({
  onClose: e,
  params: n
}) => {
  const {
    t: i
  } = ie(), {
    data: a,
    error: t,
    loading: l
  } = qn(ud, {
    skip: n.type === "create",
    variables: {
      id: n.type === "edit" ? n.roomId : (
        /* impossible case — will be skipped */
        ""
      )
    }
  }), o = a == null ? void 0 : a.user.meet.get.space, s = n.type === "create" ? n.variant : fr(o), r = n.type === "create" ? i(s === "room" ? "ROOM.EDIT_ROOM.CREATE_ROOM" : "ROOM.EDIT_ROOM.CREATE_EVENT") : i(s === "room" ? "ROOM.EDIT_ROOM.EDIT_ROOM" : "ROOM.EDIT_ROOM.EDIT_EVENT");
  return /* @__PURE__ */ f(eo, {
    onClose: e,
    title: r,
    children: [t != null ? /* @__PURE__ */ d(Kn, {
      error: t
    }) : null, l ? /* @__PURE__ */ d(zn, {
      size: "lg"
    }) : /* @__PURE__ */ d(Ql, {
      meetSpace: o,
      onSuccess: e,
      type: n.type,
      variant: s
    })]
  });
});
Wc.displayName = "EditMeetSpace";
const uS = (e) => e != null && e.createEvent ? {
  roomId: void 0,
  type: "create",
  variant: "event"
} : e != null && e.createRoom ? {
  roomId: void 0,
  type: "create",
  variant: "room"
} : e != null && e.edit ? {
  roomId: e.edit,
  type: "edit"
} : null;
var Ui = "/src/features/meet/focused-peer/model/index.ts";
const $i = h({
  loc: {
    file: Ui,
    line: 12,
    column: 34
  },
  name: "eventMemberFocused",
  sid: "j8uxwn:/src/features/meet/focused-peer/model/index.ts:eventMemberFocused"
}), no = D({
  disallowAtomaticChange: !1,
  memberId: null
}, {
  loc: {
    file: Ui,
    line: 14,
    column: 25
  },
  name: "$focusedMemberId",
  sid: "-6q48vs:/src/features/meet/focused-peer/model/index.ts:$focusedMemberId"
});
no.on($i, (e, n) => n == null ? {
  disallowAtomaticChange: !0,
  memberId: null
} : e.memberId === n ? {
  disallowAtomaticChange: !0,
  memberId: null
} : {
  disallowAtomaticChange: !0,
  memberId: n
}).on(_({
  and: [{
    clock: fe,
    source: {
      currentMeetSpaceMemberMe: an
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => ({
      ...e,
      peers: n
    })
  }],
  or: {
    loc: {
      file: Ui,
      line: 32,
      column: 4
    },
    sid: "hpgo9r:/src/features/meet/focused-peer/model/index.ts:"
  }
}), (e, n) => {
  var a, t;
  const i = n.peers.find((l) => l.id === e.memberId);
  if (e.disallowAtomaticChange || e.memberId != null && i == null)
    return e;
  if (e.memberId == null) {
    const l = n.peers.filter((o) => o.media.video === Re.MediaVideoScreen);
    if (l.length === 1 && ((t = (a = n.currentMeetSpaceMemberMe) == null ? void 0 : a.peer) == null ? void 0 : t.id) !== l[0].id)
      return {
        disallowAtomaticChange: !0,
        memberId: l[0].id
      };
  }
  return e;
});
const kS = we({
  and: [{
    focusedMemberId: no,
    members: ei,
    peers: fe
  }, ({
    focusedMemberId: e,
    members: n,
    peers: i
  }) => {
    const a = i.find((t) => t.id === e.memberId);
    return e.memberId != null && a != null ? {
      member: n.ref.get(e.memberId),
      peer: a
    } : null;
  }],
  or: {
    loc: {
      file: Ui,
      line: 67,
      column: 28
    },
    name: "$focusedPeer",
    sid: "okq1as:/src/features/meet/focused-peer/model/index.ts:$focusedPeer"
  }
}), mt = /* @__PURE__ */ g(({
  meetSpace: e
}) => /* @__PURE__ */ d(ed, {
  mobileView: "full",
  textForCopy: qt(e),
  view: {
    bg: "blue.50",
    color: "white"
  },
  children: /* @__PURE__ */ d(b, {
    color: "white",
    fontSize: "14",
    fontWeight: "500",
    lineHeight: "24",
    children: Xo(e)
  })
}));
mt.displayName = "InviteToCallButton";
const Gc = /* @__PURE__ */ g(({
  height: e,
  meetSpace: n,
  width: i
}) => {
  var t;
  const {
    t: a
  } = ie();
  return /* @__PURE__ */ d(w, {
    display: {
      base: "none",
      lg: "flex"
    },
    h: e,
    p: {
      base: 1,
      lg: 2
    },
    w: i,
    children: /* @__PURE__ */ d(hi, {
      bg: "#141414",
      borderRadius: "12",
      flex: "1",
      children: (t = n.conditions) != null && t.join.link ? /* @__PURE__ */ f(be, {
        children: [/* @__PURE__ */ d(b, {
          color: "white",
          fontSize: "24",
          fontWeight: "500",
          lineHeight: "24",
          mb: "5",
          textAlign: "center",
          children: a("CALL.INVITE_MESSAGE", {
            defaultValue: "Пригласите других участников"
          })
        }), /* @__PURE__ */ d(mt, {
          meetSpace: n
        })]
      }) : /* @__PURE__ */ d(b, {
        color: "gray.500",
        fontSize: "24",
        fontWeight: "500",
        lineHeight: "24",
        mb: "5",
        textAlign: "center",
        children: a("CALL.INVITE_MESSAGE_WAITING")
      })
    })
  });
});
Gc.displayName = "InviteToCallCard";
const Bi = /* @__PURE__ */ g(({
  mobile: e
}) => /* @__PURE__ */ d(w, {
  left: 1,
  position: "absolute",
  top: 1,
  zIndex: 1,
  children: /* @__PURE__ */ d(Ta, {
    fill: ze.colors.yellow[500],
    height: "24",
    width: "24"
  })
}));
Bi.displayName = "RaiseHandPopup";
const qc = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetUpdateReaction"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "emotion"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "gesture"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "reaction"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "emotion"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "emotion"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "gesture"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "gesture"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Xc = "_emojiContainer_jg6pi_1", Yc = {
  emojiContainer: Xc
}, Kc = /* @__PURE__ */ g(() => {
  const [e] = Tn(qc), n = md.emojis, i = E((a) => {
    e({
      variables: {
        emotion: a
      }
    });
  }, [e]);
  return /* @__PURE__ */ d(ga, {
    horizontal: !0,
    maxWidth: "900",
    w: "90%",
    children: /* @__PURE__ */ d("div", {
      className: Yc.emojiContainer,
      children: /* @__PURE__ */ d(Je, {
        space: "2",
        children: Zt.emojis.map((a) => {
          const t = n[a];
          return t == null || t.version !== 1 ? null : /* @__PURE__ */ d(io, {
            emoji: t.skins[0].native,
            onPress: () => i(t.skins[0].native)
          }, t.id);
        })
      })
    })
  });
});
Kc.displayName = "MeetSpaceReaction";
const io = /* @__PURE__ */ g(({
  emoji: e,
  ...n
}) => {
  const [i, a] = H(!1), t = L(() => i ? "rgba(255, 255, 255, .12)" : "rgba(255, 255, 255, 0.08)", [i]);
  return /* @__PURE__ */ d(te, {
    _focus: {
      bg: t
    },
    _hover: {
      bg: t
    },
    _pressed: {
      bg: t
    },
    alignItems: "center",
    bg: t,
    borderRadius: "32",
    h: "48px",
    justifyContent: "center",
    onHoverIn: () => a(!0),
    onHoverOut: () => a(!1),
    shadow: "1",
    w: "48px",
    ...n,
    children: /* @__PURE__ */ d(b, {
      fontSize: "xl",
      selectable: !1,
      children: e
    })
  });
});
io.displayName = "Emojya";
const Jc = "_container_8i69q_1", Qc = "_fly_8i69q_1", Zc = "_reaction_8i69q_12", eu = "_author_8i69q_18", Ji = {
  container: Jc,
  fly: Qc,
  reaction: Zc,
  author: eu
}, nu = /* @__PURE__ */ g(() => {
  const e = I(Cr), n = L(() => e.filter((i) => Date.now() - i.created.getTime() < Pd), [e]);
  return /* @__PURE__ */ d(Xs, {
    children: n.map((i) => /* @__PURE__ */ d(ao, {
      authorId: i.author,
      left: i.left,
      reaction: i.reaction
    }, i.id.toString()))
  });
});
nu.displayName = "FlyingReactions";
const ao = /* @__PURE__ */ g(({
  authorId: e,
  left: n,
  reaction: i
}) => {
  const {
    data: a,
    loading: t
  } = qn(Mi, {
    variables: {
      id: e
    }
  });
  return /* @__PURE__ */ f("div", {
    className: Ji.container,
    style: {
      left: n
    },
    children: [/* @__PURE__ */ d("div", {
      className: Ji.reaction,
      children: i
    }), e && !t ? /* @__PURE__ */ d("div", {
      className: Ji.author,
      children: nn(a == null ? void 0 : a.meet.member.get)
    }) : null]
  });
});
ao.displayName = "R";
const iu = /* @__PURE__ */ g(() => {
  var o;
  const e = I(an), [n, {
    error: i,
    loading: a
  }] = Tn(tr), t = E(() => n({
    variables: {
      gesture: bn
    }
  }), [n]), l = E(() => n({
    variables: {
      gesture: ""
    }
  }), [n]);
  return ((o = e == null ? void 0 : e.peer) == null ? void 0 : o.reaction.gesture) === bn ? /* @__PURE__ */ d(rn, {
    onPress: l,
    children: x("ROOM.GESTURES.LOWER_HAND", {
      defaultValue: "Опустить руку"
    })
  }) : /* @__PURE__ */ d(rn, {
    onPress: t,
    children: x("ROOM.GESTURES.RAISE_HAND", {
      defaultValue: "Поднять руку"
    })
  });
});
iu.displayName = "MenuItemRaiseHand";
const vS = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "RecordingTranscript"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RecordingTranscriptType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "collected"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transcript"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "duration"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "member_id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "text"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "time"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "nickname"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "user"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "id"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "nickname"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "end"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "exported"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recognized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "start"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "submitted"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "summarized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "summary"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "text"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "orders"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "orders"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transcribed"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "debug"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "summary_request"
            }
          }]
        }
      }]
    }
  }]
}, SS = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "Recording"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RecordingType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "collected"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "end"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "exported"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recognized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "start"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "submitted"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "summarized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transcribed"
        }
      }]
    }
  }]
}, pS = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetRecordingsList"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "recording"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "list"
                    },
                    arguments: [{
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "id"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "id"
                        }
                      }
                    }],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "Recording"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "Recording"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RecordingType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "collected"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "end"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "exported"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recognized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "start"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "submitted"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "summarized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transcribed"
        }
      }]
    }
  }]
}, NS = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetRecording"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "recordingId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "recording"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "get"
                    },
                    arguments: [{
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "id"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "id"
                        }
                      }
                    }, {
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "recording_id"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "recordingId"
                        }
                      }
                    }],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "RecordingTranscript"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "RecordingTranscript"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RecordingTranscriptType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "collected"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transcript"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "duration"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "member_id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "text"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "time"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "nickname"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "user"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "id"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "nickname"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "end"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "exported"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recognized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "start"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "submitted"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "summarized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "summary"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "text"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "orders"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "orders"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transcribed"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "debug"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "summary_request"
            }
          }]
        }
      }]
    }
  }]
}, au = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetRecordingStart"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "recording"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "start"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, tu = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetRecordingStop"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "recording"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "stop"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
var ti = "/src/features/meet/recording/model/index.ts";
const ct = h({
  loc: {
    file: ti,
    line: 16,
    column: 36
  },
  name: "eventToggleRecording",
  sid: "-mwbf1o:/src/features/meet/recording/model/index.ts:eventToggleRecording"
}), ut = Ri.map((e) => (e == null ? void 0 : e.recording_session_video) != null || (e == null ? void 0 : e.recording_session_audio) != null), to = $(() => Z().mutate({
  mutation: au
}), {
  loc: {
    file: ti,
    line: 23,
    column: 25
  },
  name: "startRecordingFx",
  sid: "1oheuy:/src/features/meet/recording/model/index.ts:startRecordingFx"
}), lo = $(() => Z().mutate({
  mutation: tu
}), {
  loc: {
    file: ti,
    line: 26,
    column: 24
  },
  name: "stopRecordingFx",
  sid: "-8mh06g:/src/features/meet/recording/model/index.ts:stopRecordingFx"
});
Ea.on([to.failData, lo.failData], (e, n) => n instanceof ca ? [...e, ...n.graphQLErrors.map((i) => i.extensions.code === "not_authorized" && i.extensions.details === "permission denied" ? {
  id: ui("alert"),
  message: "Нет прав для включения записи",
  status: "error"
} : void 0).filter(Mn)] : [...e, {
  id: ui("alert"),
  message: String(n),
  status: "error"
}]);
_({
  and: [{
    clock: ct,
    source: ut,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => e,
    target: lo
  }],
  or: {
    loc: {
      file: ti,
      line: 61,
      column: 0
    },
    sid: "-btv8vu:/src/features/meet/recording/model/index.ts:"
  }
});
_({
  and: [{
    clock: ct,
    source: ut,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => !e,
    target: to
  }],
  or: {
    loc: {
      file: ti,
      line: 68,
      column: 0
    },
    sid: "-bq0oqb:/src/features/meet/recording/model/index.ts:"
  }
});
const Lt = B.object({
  description: B.string().nullable().optional(),
  recordByDefault: B.boolean().nullish().optional(),
  title: B.string({
    required_error: x("VALIDATION.ERRORS.REQUIRED")
  }).min(1, {
    message: x("VALIDATION.ERRORS.AT_LEAST_ONE", {
      defaultValue: "Название должно содержать минимум один символ"
    })
  })
}).strict(), oo = /* @__PURE__ */ g(({
  meetSpace: e
}) => {
  var r, c;
  const {
    control: n,
    formState: {
      isDirty: i
    },
    handleSubmit: a
  } = En({
    defaultValues: {
      description: e.description,
      recordByDefault: ((c = (r = e.conditions) == null ? void 0 : r.recording.enabled) == null ? void 0 : c.default) ?? !1,
      title: e.title ?? ""
    },
    mode: "all",
    resolver: Jn(Lt)
  }), [t, {
    error: l,
    loading: o
  }] = Tn(kd, {
    refetchQueries: [cd]
  }), s = E(async (u) => {
    const {
      description: v,
      recordByDefault: m,
      title: p
    } = Lt.parse(u);
    try {
      await t({
        variables: {
          conditions: {
            recording: {
              enabled: {
                default: m ?? !1
              }
            }
          },
          description: v,
          id: e.id,
          title: p
        }
      });
    } catch (k) {
      console.error(k);
    }
  }, [e.id, t]);
  return /* @__PURE__ */ f(be, {
    children: [l ? /* @__PURE__ */ d(Kn, {
      error: l
    }) : null, /* @__PURE__ */ d(b, {
      color: "white",
      children: x("ROOM.EDIT.SHORT_ANNOTATION")
    }), /* @__PURE__ */ f(Be, {
      isRequired: !0,
      mb: {
        base: 3,
        lg: 5
      },
      children: [/* @__PURE__ */ d(Be.Label, {
        mb: "2",
        mt: 5,
        children: x("ROOM.EDIT.TITLE")
      }), /* @__PURE__ */ d(ae, {
        control: n,
        name: "title",
        render: ({
          field: u,
          fieldState: {
            error: v
          }
        }) => /* @__PURE__ */ d(on, {
          errorMessage: v == null ? void 0 : v.message,
          isInvalid: !!v,
          mb: 0,
          onBlur: u.onBlur,
          onChangeText: u.onChange,
          placeholder: x("ROOM.EDIT.TITLE_PLACEHOLDER"),
          value: u.value,
          withAnimation: !1
        })
      })]
    }), /* @__PURE__ */ f(Be, {
      mb: {
        base: 3,
        lg: 5
      },
      children: [/* @__PURE__ */ d(Be.Label, {
        mb: "2",
        children: x("ROOM.EDIT.DESCRIPTION")
      }), /* @__PURE__ */ d(ae, {
        control: n,
        name: "description",
        render: ({
          field: u,
          fieldState: {
            error: v
          }
        }) => /* @__PURE__ */ d(on, {
          errorMessage: v == null ? void 0 : v.message,
          isInvalid: !!v,
          mb: 0,
          onBlur: u.onBlur,
          onChangeText: u.onChange,
          placeholder: x("ROOM.EDIT.DESCRIPTION_PLACEHOLDER"),
          value: u.value ?? "",
          withAnimation: !1
        })
      })]
    }), /* @__PURE__ */ d(Be, {
      mb: {
        base: 3,
        lg: 5
      },
      children: /* @__PURE__ */ f(Be.Label, {
        justifyContent: "space-between",
        mb: "2",
        children: [x("ROOM.EDIT.RECORD_BY_DEFAULT"), /* @__PURE__ */ d(ae, {
          control: n,
          name: "recordByDefault",
          render: ({
            field: u
          }) => /* @__PURE__ */ d(ts, {
            isChecked: u.value ?? !1,
            onValueChange: u.onChange
          })
        })]
      })
    }), /* @__PURE__ */ d(Je, {
      children: /* @__PURE__ */ d(He, {
        disabled: !i,
        height: 7,
        loading: o,
        onPress: a(s),
        variant: "primary",
        children: x("ROOM.EDIT.BUTTON_APPLY")
      })
    })]
  });
});
oo.displayName = "EditShortMeetSpaceSettings";
const du = /* @__PURE__ */ g(({
  images: e,
  isOwner: n,
  meetSpace: i,
  onChange: a,
  value: t
}) => {
  const {
    t: l
  } = ie();
  return /* @__PURE__ */ f(Ps, {
    style: Ut.scroll,
    children: [n ? /* @__PURE__ */ d(oo, {
      meetSpace: i
    }) : null, /* @__PURE__ */ d(hn, {
      borderTopColor: "white",
      borderTopWidth: 1,
      mt: 5,
      pt: 5,
      size: "md",
      children: l("ROOM.EDIT.VIDEO_SETTINGS_TITLE")
    }), /* @__PURE__ */ d(To, {
      small: !0,
      images: e,
      onChange: a,
      value: t
    }), /* @__PURE__ */ d(dd, {
      style: Ut.inviteToCallButton,
      children: /* @__PURE__ */ d(mt, {
        meetSpace: i
      })
    })]
  });
});
du.displayName = "MeetSpaceRuntimeSettings";
const Ut = _n.create({
  inviteToCallButton: {
    marginTop: "auto"
  },
  scroll: {
    flex: 1,
    padding: ze.sizes[3]
  }
}), lu = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetFetchMemberList"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "limit"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "Int"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "cursor"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "member"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "list"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "cursor"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "cursor"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "limit"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "limit"
                    }
                  }
                }],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cursor"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "data"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetMember"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }]
};
var ou = "/src/features/meet/user-list/model/index.ts";
const {
  $cursor: fS,
  $data: so,
  eventLoadMore: $t
} = Yo(lu, (e) => e.data.meet.member.list);
so.reset(ue, Ne);
const su = fe.map((e) => new Set(e.map((n) => n.id))), ru = we({
  and: [{
    $data: so,
    $peerIds: su
  }],
  or: {
    loc: {
      file: ou,
      line: 27,
      column: 39
    },
    name: "$meetSpaceUsersListData",
    sid: "6s6adq:/src/features/meet/user-list/model/index.ts:$meetSpaceUsersListData"
  }
}).map(({
  $data: e,
  $peerIds: n
}) => e.filter((i) => !n.has(i.id))), mu = B.object({
  hadIssues: B.boolean(),
  issueDescription: B.string().nullable()
}).strict(), cu = /* @__PURE__ */ g(({
  onClose: e
}) => {
  const n = Fi(), i = I(pa), {
    control: a,
    handleSubmit: t
  } = En({
    defaultValues: {
      hadIssues: !1,
      issueDescription: ""
    }
  });
  return /* @__PURE__ */ d(sd, {
    onRequestClose: e,
    children: /* @__PURE__ */ f(ba, {
      hasCancel: !0,
      okButtonText: "ОК",
      onClose: e,
      onOk: t((o) => {
        const s = mu.parse(o), r = wt.captureMessage("User Feedback");
        wt.captureUserFeedback({
          comments: s.issueDescription ?? "",
          email: n == null ? void 0 : n.email,
          event_id: r,
          name: `userId:${n == null ? void 0 : n.id}, username:${n == null ? void 0 : n.username}, nickname:${((n == null ? void 0 : n.nickname) ?? "") !== "" ? n == null ? void 0 : n.nickname : i}`
        }), pd(), e();
      }),
      title: "Оцените качество звонка",
      children: [/* @__PURE__ */ d(ae, {
        control: a,
        name: "hadIssues",
        render: ({
          field: o
        }) => /* @__PURE__ */ f(w, {
          alignItems: "center",
          flexDirection: "row",
          my: 5,
          children: [/* @__PURE__ */ d(Ls, {
            onValueChange: o.onChange,
            value: o.value
          }), /* @__PURE__ */ d(b, {
            ml: 4,
            children: " Во-время звонка возникли проблемы"
          })]
        })
      }), /* @__PURE__ */ d(ae, {
        control: a,
        name: "issueDescription",
        render: ({
          field: o,
          fieldState: {
            error: s
          }
        }) => /* @__PURE__ */ d(ds, {
          autoCompleteType: "off",
          isInvalid: !!s,
          onBlur: o.onBlur,
          onChangeText: o.onChange,
          placeholder: "Опишите проблему",
          value: o.value ?? "",
          w: "100%"
        })
      }), /* @__PURE__ */ d(ha, {
        _text: {
          color: "black"
        },
        backgroundColor: "green.600",
        borderRadius: 500,
        mt: 4,
        onPress: () => Sd(),
        variant: "subtle",
        children: "Вернуться к звонку"
      })]
    })
  });
});
cu.displayName = "RateACallModal";
const uu = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "subscription",
    name: {
      kind: "Name",
      value: "User"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "authentication"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anonymous"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "expiration"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "session_id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "user"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "User"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "contact"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "add"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "User"
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "remove"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "init"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "peer_id"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "meet"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "invite"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "add"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "invited"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "space"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "FragmentSpread",
                            name: {
                              kind: "Name",
                              value: "MeetSpace"
                            }
                          }]
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "user"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "FragmentSpread",
                            name: {
                              kind: "Name",
                              value: "User"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "remove"
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "space"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "add"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpace"
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "remove"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, ku = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "UserMeetSpaces"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "meet"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "list"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "invites"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "invited"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "space"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "FragmentSpread",
                            name: {
                              kind: "Name",
                              value: "MeetSpace"
                            }
                          }]
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "user"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "FragmentSpread",
                            name: {
                              kind: "Name",
                              value: "User"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "spaces"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "joined"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "space"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "FragmentSpread",
                            name: {
                              kind: "Name",
                              value: "MeetSpace"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_audio"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_video"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "recording"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "enabled"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "default"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "manual"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
var vu = "/src/features/user/model/index.ts";
const Su = D([], {
  loc: {
    file: vu,
    line: 6,
    column: 27
  },
  name: "$myContacts",
  sid: "z2ixy1:/src/features/user/model/index.ts:$myContacts"
}), Bt = j.object({
  deviceId: j.string()
}), pu = /* @__PURE__ */ g(() => {
  const {
    microphoneDevices: e,
    showNewAudioDeviceSelect: n
  } = I({
    microphoneDevices: $r,
    showNewAudioDeviceSelect: il
  }), i = E((r) => {
    const c = Bt.parse(r);
    za(c);
  }, []), a = E(() => {
    nl();
  }, []), t = Se((r) => r.length > 0 ? r[0].deviceId : void 0, void 0, e), {
    control: l,
    handleSubmit: o
  } = En({
    defaultValues: {
      deviceId: t
    },
    mode: "all",
    resolver: Jn(Bt)
  }), s = t != null;
  return n ? /* @__PURE__ */ d(ba, {
    hasCancel: s,
    okButtonText: "Продолжить",
    onClose: a,
    onOk: s ? o(i) : a,
    title: n.type === "new-device-connected" ? "Обнаружено новое аудиоустройство" : "Аудиоустройство отключено, выберите другое",
    children: Gt((r) => r.length > 0 ? /* @__PURE__ */ d(ae, {
      control: l,
      name: "deviceId",
      render: ({
        field: c,
        fieldState: {
          error: u
        }
      }) => /* @__PURE__ */ d(Be, {
        isRequired: !0,
        isInvalid: u != null,
        mb: "3",
        children: /* @__PURE__ */ d(Un, {
          color: "white",
          onPointerLeave: c.onBlur,
          onValueChange: c.onChange,
          placeholder: "Выберите устройство",
          selectedValue: c.value,
          children: r.map((v) => /* @__PURE__ */ d(Un.Item, {
            color: "white",
            label: v.label,
            value: v.deviceId
          }, v.deviceId))
        })
      })
    }) : /* @__PURE__ */ d(b, {
      children: "Не обнаружено доступных микрофонов"
    }), () => /* @__PURE__ */ d(zn, {
      size: "lg"
    }), () => /* @__PURE__ */ d(zn, {
      size: "lg"
    }), (r) => /* @__PURE__ */ d(b, {
      children: String(r)
    }))(e)
  }, String(s)) : null;
});
pu.displayName = "AudioDeviceChangedModal";
var zi = "/src/features/video-call/speaking-analyzer/model/index.ts";
const kt = h("eventSpeakingUpdate", {
  loc: {
    file: zi,
    line: 7,
    column: 35
  },
  name: "eventSpeakingUpdate",
  sid: "-3d0v2m:/src/features/video-call/speaking-analyzer/model/index.ts:eventSpeakingUpdate"
}), vt = h({
  loc: {
    file: zi,
    line: 11,
    column: 37
  },
  name: "eventMeSpeakingUpdate",
  sid: "fz3bcb:/src/features/video-call/speaking-analyzer/model/index.ts:eventMeSpeakingUpdate"
}), Nu = fe.map((e) => e.filter((n) => {
  var i, a;
  return (((i = n.stream) == null ? void 0 : i.getAudioTracks().length) ?? 0) > 0 && ((a = n.stream) == null ? void 0 : a.getAudioTracks().some((t) => t.enabled));
})), ro = D(!1, {
  loc: {
    file: zi,
    line: 21,
    column: 27
  },
  name: "$meSpeaking",
  sid: "-6ln4ae:/src/features/video-call/speaking-analyzer/model/index.ts:$meSpeaking"
}).on(vt, (e, n) => n).reset(ue), mo = D({
  ref: /* @__PURE__ */ new Map()
}, {
  loc: {
    file: zi,
    line: 25,
    column: 30
  },
  name: "$peersSpeaking",
  sid: "sybh3x:/src/features/video-call/speaking-analyzer/model/index.ts:$peersSpeaking"
}).on(kt, (e, n) => {
  if (e.ref.size !== n.length)
    return {
      ref: new Map(n.map((a) => [a.peerId, a.speaking]))
    };
  let i;
  for (const a of n)
    e.ref.get(a.peerId) !== a.speaking && (e.ref.set(a.peerId, a.speaking), i = !0);
  return i ? {
    ref: e.ref
  } : void 0;
});
Cn([kt, vt, ro, mo], {
  log: "disabled"
});
const co = -75, uo = 120, ko = 500;
function FS() {
  const e = I(Nu);
  Q(() => {
    if (process.env.VITE_NO_STATS !== "true") {
      if (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        window.AudioContext ?? window.webkitAudioContext
      )
        return () => {
        };
      const n = new AudioContext();
      let i = null;
      const a = e.map((t) => {
        const l = n.createAnalyser();
        l.minDecibels = co;
        const o = n.createMediaStreamSource(t.stream);
        return o.connect(l), {
          analyzer: l,
          peerId: t.id,
          streamNode: o
        };
      });
      if (a.length > 0) {
        const t = new Uint8Array(a[0].analyzer.frequencyBinCount);
        i = setInterval(() => {
          const o = a.map(({
            analyzer: s,
            peerId: r
          }) => {
            s.getByteFrequencyData(t);
            let c = !1;
            for (let u = 0; u < Math.min(50, t.length); u++)
              t[u] > uo && (c = !0);
            return {
              peerId: r,
              speaking: c
            };
          });
          kt(o);
        }, ko);
      }
      return () => {
        for (const {
          analyzer: t,
          streamNode: l
        } of a)
          t.disconnect(), l.disconnect();
        n.close(), i && clearInterval(i);
      };
    } else
      return () => {
      };
  }, [e]);
}
function gS() {
  const e = I(Pe);
  Q(() => {
    if (process.env.VITE_NO_STATS !== "true") {
      let n, i, a;
      if (e.state !== "initial" && e.stream != null) {
        a = new AudioContext(), i = a.createAnalyser(), i.minDecibels = co, a.createMediaStreamSource(e.stream).connect(i);
        const l = new Uint8Array(i.frequencyBinCount);
        n = setInterval(() => {
          if (i == null)
            return;
          i.getByteFrequencyData(l);
          let s = !1;
          for (let r = 0; r < Math.min(50, l.length); r++)
            l[r] > uo && (s = !0);
          vt(s);
        }, ko);
      }
      return () => {
        n && clearInterval(n), i == null || i.disconnect(), a == null || a.close();
      };
    } else
      return () => {
      };
  }, [e]);
}
var vo = {}, St = {};
Object.defineProperty(St, "__esModule", { value: !0 });
var fu = { radix: 1e3, unit: ["b", "kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"] }, Fu = { radix: 1024, unit: ["b", "Kib", "Mib", "Gib", "Tib", "Pib", "Eib", "Zib", "Yib"] }, gu = { radix: 1024, unit: ["b", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"] };
St.SPECS = {
  si: fu,
  iec: Fu,
  jedec: gu
};
Object.defineProperty(vo, "__esModule", { value: !0 });
var zt = St;
function hu(e, n, i) {
  n === void 0 && (n = 1), e = Math.abs(e);
  for (var a = zt.SPECS[i] || zt.SPECS.jedec, t = a.radix, l = a.unit, o = 0; e >= t; )
    e /= t, ++o;
  return e.toFixed(n) + " " + l[o];
}
var Ke = vo.default = hu, pt = "/src/features/video-call/stats/model/stats.ts";
const Nt = h({
  loc: {
    file: pt,
    line: 58,
    column: 33
  },
  name: "eventStatsUpdated",
  sid: "-lads7q:/src/features/video-call/stats/model/stats.ts:eventStatsUpdated"
}), ji = D({
  ref: {
    myStats: {
      audio: void 0,
      video: void 0
    },
    peers: /* @__PURE__ */ new Map()
  }
}, {
  loc: {
    file: pt,
    line: 60,
    column: 26
  },
  name: "$peerStats",
  sid: "-5y3ur8:/src/features/video-call/stats/model/stats.ts:$peerStats"
});
Cn([ji, Nt], {
  log: "disabled"
});
ji.on(_({
  and: [{
    clock: Nt,
    source: {
      $peersByTrackId: Ad
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => ({
      peers: e,
      stats: n
    })
  }],
  or: {
    loc: {
      file: pt,
      line: 66,
      column: 2
    },
    sid: "-nriy1n:/src/features/video-call/stats/model/stats.ts:"
  }
}), (e, n) => {
  var m, p, k, F;
  const i = /* @__PURE__ */ new Map(), a = /* @__PURE__ */ new Map(), t = /* @__PURE__ */ new Map(), l = /* @__PURE__ */ new Map(), o = /* @__PURE__ */ new Map(), s = /* @__PURE__ */ new Map();
  let r, c;
  n.stats.forEach((S) => {
    switch (S.type) {
      case "inbound-rtp": {
        S.kind === "video" ? i.set(S.id, S) : a.set(S.id, S);
        break;
      }
      case "remote-inbound-rtp": {
        S.kind === "video" ? t.set(S.localId, S) : l.set(S.localId, S);
        break;
      }
      case "outbound-rtp": {
        S.kind === "video" ? r = S : c = S;
        break;
      }
      case "remote-outbound-rtp": {
        S.kind === "video" ? o.set(S.id, S) : s.set(S.id, S);
        break;
      }
    }
  });
  for (const S of i.values()) {
    const N = n.peers.$peersByTrackId.ref.get(S.trackIdentifier), y = o.get(S.remoteId);
    if (N != null && y != null) {
      const T = e.ref.peers.get(N), M = (m = T == null ? void 0 : T.video) == null ? void 0 : m.inbound, C = oi(S.bytesReceived, S.timestamp, M == null ? void 0 : M.bytesReceived, M == null ? void 0 : M.timestamp), P = {
        computed: {
          lastPacketReceivedDelay: S.timestamp - (S.lastPacketReceivedTimestamp ?? 0),
          speed: C,
          speedKib: Ke(C)
        },
        inbound: S,
        remoteOutbound: y
      };
      T == null ? e.ref.peers.set(N, {
        audio: void 0,
        video: P
      }) : e.ref.peers.set(N, {
        ...T,
        video: P
      });
    }
  }
  for (const S of a.values()) {
    const N = n.peers.$peersByTrackId.ref.get(S.trackIdentifier), y = s.get(S.remoteId);
    if (N != null && y != null) {
      const T = e.ref.peers.get(N), M = (p = T == null ? void 0 : T.audio) == null ? void 0 : p.inbound, C = oi(S.bytesReceived, S.timestamp, M == null ? void 0 : M.bytesReceived, M == null ? void 0 : M.timestamp), P = {
        computed: {
          lastPacketReceivedDelay: S.timestamp - (S.lastPacketReceivedTimestamp ?? 0),
          speed: C,
          speedKib: Ke(C)
        },
        inbound: S,
        remoteOutbound: y
      };
      T == null ? e.ref.peers.set(N, {
        audio: P,
        video: void 0
      }) : e.ref.peers.set(N, {
        ...T,
        audio: P
      });
    }
  }
  let u, v;
  if (r != null) {
    const S = t.get(r.id), N = (k = e.ref.myStats.video) == null ? void 0 : k.outbound, y = oi(r.bytesSent, r.timestamp, N == null ? void 0 : N.bytesSent, N == null ? void 0 : N.timestamp);
    u = {
      computed: {
        speed: y,
        speedKib: Ke(y)
      },
      outbound: r,
      remoteInbound: S
    };
  }
  if (c != null) {
    const S = l.get(c.id), N = (F = e.ref.myStats.audio) == null ? void 0 : F.outbound, y = oi(c.bytesSent, c.timestamp, N == null ? void 0 : N.bytesSent, N == null ? void 0 : N.timestamp);
    v = {
      computed: {
        speed: y,
        speedKib: Ke(y)
      },
      outbound: c,
      remoteInbound: S
    };
  }
  return {
    ref: {
      myStats: {
        audio: v,
        video: u
      },
      peers: e.ref.peers
    }
  };
});
function oi(e, n, i, a) {
  return ((e ?? 0) - (i ?? 0)) / ((n - (a ?? 0)) / 1e3);
}
function hS() {
  const e = I(K);
  Q(() => {
    let n = null;
    return process.env.VITE_NO_STATS !== "true" && (n = setInterval(() => {
      e == null || e.getStats().then((a) => {
        Nt(a);
      });
    }, 1e3)), () => {
      n != null && clearInterval(n);
    };
  }, [e]);
}
const So = /* @__PURE__ */ g(({
  peerId: e
}) => {
  const n = I(ji).ref, i = I({
    connectionState: kn,
    iceConnectionState: In,
    iceGatheringState: qa,
    signalingState: Dn
  }), a = e != null ? n.peers.get(e) : n.myStats, t = JSON.stringify(i, null, 2);
  return /* @__PURE__ */ f(Bn, {
    children: [/* @__PURE__ */ d(b, {
      children: t
    }), /* @__PURE__ */ d(b, {
      children: JSON.stringify({
        peerId: e,
        ...a
      }, null, 2)
    })]
  });
});
So.displayName = "StatsPopoverBody";
const ft = /* @__PURE__ */ g(({
  peerId: e,
  showDetails: n,
  small: i
}) => {
  var c, u, v, m, p, k;
  const {
    t: a
  } = ie(), t = I(Mm), l = t === pe.GREEN ? ze.colors.green[400] : t === pe.YELLOW ? ze.colors.yellow[400] : ze.colors.red[400], o = I(ji).ref, s = e != null ? o.peers.get(e) : null, r = e == null ? o.myStats : null;
  return /* @__PURE__ */ f(sn, {
    backgroundColor: "rgba(0,0,0,0.64)",
    borderRadius: "50%",
    bottom: i ? void 0 : 0,
    left: 0,
    position: "absolute",
    top: i ? 0 : void 0,
    children: [/* @__PURE__ */ d(ge, {
      trigger: (F) => /* @__PURE__ */ d(Yn, {
        ...F,
        _focus: {
          bg: "rgba(255, 255, 255, 0.12)"
        },
        _hover: {
          bg: "rgba(255, 255, 255, 0.12)"
        },
        bg: "rgba(255, 255, 255, 0.08)",
        h: i ? "16px" : "36px",
        w: i ? "16px" : "36px",
        children: /* @__PURE__ */ d(_s, {
          color: l,
          fill: l
        })
      }),
      children: /* @__PURE__ */ f(ge.Content, {
        children: [/* @__PURE__ */ d(ge.Arrow, {}), /* @__PURE__ */ d(ge.CloseButton, {}), /* @__PURE__ */ d(ge.Header, {
          children: a("ROOM.CONNECTION_STATS.LABEL")
        }), /* @__PURE__ */ d(ge.Body, {
          height: "2xl",
          overflow: "scroll",
          w: "100%",
          children: /* @__PURE__ */ d(So, {
            peerId: e
          })
        })]
      })
    }), n ? s != null ? /* @__PURE__ */ f(sn, {
      flexWrap: "nowrap",
      w: "max-content",
      children: [/* @__PURE__ */ f(b, {
        color: "white",
        children: ["a: ", Ke(((c = s.audio) == null ? void 0 : c.computed.speed) ?? 0), ",", " ", jt((u = s.audio) == null ? void 0 : u.computed.lastPacketReceivedDelay)]
      }), /* @__PURE__ */ f(b, {
        color: "white",
        children: ["v: ", Ke(((v = s.video) == null ? void 0 : v.computed.speed) ?? 0), ",", " ", jt((m = s.video) == null ? void 0 : m.computed.lastPacketReceivedDelay)]
      })]
    }) : r != null ? /* @__PURE__ */ f(sn, {
      flexWrap: "nowrap",
      w: "max-content",
      children: [/* @__PURE__ */ f(b, {
        color: "white",
        children: ["a: ", Ke(((p = r.audio) == null ? void 0 : p.computed.speed) ?? 0)]
      }), /* @__PURE__ */ f(b, {
        color: "white",
        children: ["v: ", Ke(((k = r.video) == null ? void 0 : k.computed.speed) ?? 0)]
      })]
    }) : null : null]
  });
});
ft.displayName = "StatsPopover";
const jt = (e) => e == null ? "" : e < 1e3 ? `${Math.round(e)} мс` : `${Math.round(e / 1e3)} сек`, bu = B.object({
  backgroundImage: B.union([B.string(), B.undefined()]),
  backgroundType: B.union([B.literal("blur"), B.literal("image"), B.literal("none")])
}), di = {
  "160x96": [160, 96],
  "256x144": [256, 144],
  "256x256": [256, 256],
  "640x360": [640, 360]
};
function yu(e, n) {
  switch (e) {
    case "meet":
      return n === "256x144" ? "segm_full_v679" : "segm_lite_v681";
    case "mlkit":
      return "selfiesegmentation_mlkit-256x256-2021_01_19-v1215.f16";
    default:
      throw new Error(`No TFLite file for this segmentation model: ${e}`);
  }
}
const Ae = String.raw;
function pn(e, n, i, a, t) {
  const l = Tu(e, n, i), o = e.getAttribLocation(l, "a_position");
  e.enableVertexAttribArray(o), e.bindBuffer(e.ARRAY_BUFFER, a), e.vertexAttribPointer(o, 2, e.FLOAT, !1, 0, 0);
  const s = e.getAttribLocation(l, "a_texCoord");
  return e.enableVertexAttribArray(s), e.bindBuffer(e.ARRAY_BUFFER, t), e.vertexAttribPointer(s, 2, e.FLOAT, !1, 0, 0), l;
}
function Tu(e, n, i) {
  const a = e.createProgram();
  if (e.attachShader(a, n), e.attachShader(a, i), e.linkProgram(a), !e.getProgramParameter(a, e.LINK_STATUS))
    throw new Error(`Could not link WebGL program: ${e.getProgramInfoLog(a)}`);
  return a;
}
function Ve(e, n, i) {
  const a = e.createShader(n);
  if (e.shaderSource(a, i), e.compileShader(a), !e.getShaderParameter(a, e.COMPILE_STATUS))
    throw new Error(`Could not compile shader: ${e.getShaderInfoLog(a)}`);
  return a;
}
function Ze(e, n, i, a, t = e.NEAREST, l = e.NEAREST) {
  const o = e.createTexture();
  return e.bindTexture(e.TEXTURE_2D, o), e.texParameteri(e.TEXTURE_2D, e.TEXTURE_WRAP_S, e.CLAMP_TO_EDGE), e.texParameteri(e.TEXTURE_2D, e.TEXTURE_WRAP_T, e.CLAMP_TO_EDGE), e.texParameteri(e.TEXTURE_2D, e.TEXTURE_MIN_FILTER, t), e.texParameteri(e.TEXTURE_2D, e.TEXTURE_MAG_FILTER, l), e.texStorage2D(e.TEXTURE_2D, 1, n, i, a), o;
}
async function Mu(e, n, i, a, t, l, o, s) {
  const r = e.createBuffer();
  return e.bindBuffer(e.PIXEL_PACK_BUFFER, r), e.bufferData(e.PIXEL_PACK_BUFFER, s.byteLength, e.STREAM_READ), e.readPixels(n, i, a, t, l, o, 0), e.bindBuffer(e.PIXEL_PACK_BUFFER, null), await Cu(e, e.PIXEL_PACK_BUFFER, r, 0, s), e.deleteBuffer(r), s;
}
async function Cu(e, n, i, a, t, l, o) {
  const s = e.fenceSync(e.SYNC_GPU_COMMANDS_COMPLETE, 0);
  e.flush();
  const r = await wu(e, s);
  e.deleteSync(s), r !== e.WAIT_FAILED && (e.bindBuffer(n, i), e.getBufferSubData(n, a, t, l, o), e.bindBuffer(n, null));
}
function wu(e, n) {
  return new Promise((i) => {
    function a() {
      const t = e.clientWaitSync(n, 0, 0);
      if (t === e.WAIT_FAILED) {
        i(t);
        return;
      }
      if (t === e.TIMEOUT_EXPIRED) {
        requestAnimationFrame(a);
        return;
      }
      i(t);
    }
    requestAnimationFrame(a);
  });
}
function _u(e, n, i, a, t, l) {
  const o = Eu(e, n, i, a, t, l), s = Ru(e, i, a, l);
  function r() {
    o.render(), s.render();
  }
  function c(v) {
    s.updateCoverage(v);
  }
  function u() {
    s.cleanUp(), o.cleanUp();
  }
  return {
    cleanUp: u,
    render: r,
    updateCoverage: c
  };
}
function Eu(e, n, i, a, t, l) {
  const o = Ae`#version 300 es

    precision highp float;

    uniform sampler2D u_inputFrame;
    uniform sampler2D u_personMask;
    uniform vec2 u_texelSize;

    in vec2 v_texCoord;

    out vec4 outColor;

    const float offset[5] = float[](0.0, 1.0, 2.0, 3.0, 4.0);
    const float weight[5] = float[](0.2270270270, 0.1945945946, 0.1216216216,
      0.0540540541, 0.0162162162);

    void main() {
      vec4 centerColor = texture(u_inputFrame, v_texCoord);
      float personMask = texture(u_personMask, v_texCoord).a;

      vec4 frameColor = centerColor * weight[0] * (1.0 - personMask);

      for (int i = 1; i < 5; i++) {
        vec2 offset = vec2(offset[i]) * u_texelSize;

        vec2 texCoord = v_texCoord + offset;
        frameColor += texture(u_inputFrame, texCoord) * weight[i] *
          (1.0 - texture(u_personMask, texCoord).a);

        texCoord = v_texCoord - offset;
        frameColor += texture(u_inputFrame, texCoord) * weight[i] *
          (1.0 - texture(u_personMask, texCoord).a);
      }
      outColor = vec4(frameColor.rgb + (1.0 - frameColor.a) * centerColor.rgb, 1.0);
    }
  `, s = 0.2, r = l.width * s, c = l.height * s, u = 1 / r, v = 1 / c, m = Ve(e, e.FRAGMENT_SHADER, o), p = pn(e, n, m, i, a), k = e.getUniformLocation(p, "u_inputFrame"), F = e.getUniformLocation(p, "u_personMask"), S = e.getUniformLocation(p, "u_texelSize"), N = Ze(e, e.RGBA8, r, c, e.NEAREST, e.LINEAR), y = Ze(e, e.RGBA8, r, c, e.NEAREST, e.LINEAR), T = e.createFramebuffer();
  e.bindFramebuffer(e.FRAMEBUFFER, T), e.framebufferTexture2D(e.FRAMEBUFFER, e.COLOR_ATTACHMENT0, e.TEXTURE_2D, N, 0);
  const M = e.createFramebuffer();
  e.bindFramebuffer(e.FRAMEBUFFER, M), e.framebufferTexture2D(e.FRAMEBUFFER, e.COLOR_ATTACHMENT0, e.TEXTURE_2D, y, 0), e.useProgram(p), e.uniform1i(F, 1);
  function C() {
    e.viewport(0, 0, r, c), e.useProgram(p), e.uniform1i(k, 0), e.activeTexture(e.TEXTURE1), e.bindTexture(e.TEXTURE_2D, t);
    for (let G = 0; G < 3; G++)
      e.uniform2f(S, 0, v), e.bindFramebuffer(e.FRAMEBUFFER, T), e.drawArrays(e.TRIANGLE_STRIP, 0, 4), e.activeTexture(e.TEXTURE2), e.bindTexture(e.TEXTURE_2D, N), e.uniform1i(k, 2), e.uniform2f(S, u, 0), e.bindFramebuffer(e.FRAMEBUFFER, M), e.drawArrays(e.TRIANGLE_STRIP, 0, 4), e.bindTexture(e.TEXTURE_2D, y);
  }
  function P() {
    e.deleteFramebuffer(M), e.deleteFramebuffer(T), e.deleteTexture(y), e.deleteTexture(N), e.deleteProgram(p), e.deleteShader(m);
  }
  return {
    cleanUp: P,
    render: C
  };
}
function Ru(e, n, i, a) {
  const t = Ae`#version 300 es

    in vec2 a_position;
    in vec2 a_texCoord;

    out vec2 v_texCoord;

    void main() {
      // Flipping Y is required when rendering to canvas
      gl_Position = vec4(a_position * vec2(1.0, -1.0), 0.0, 1.0);
      v_texCoord = a_texCoord;
    }
  `, l = Ae`#version 300 es

    precision highp float;

    uniform sampler2D u_inputFrame;
    uniform sampler2D u_personMask;
    uniform sampler2D u_blurredInputFrame;
    uniform vec2 u_coverage;

    in vec2 v_texCoord;

    out vec4 outColor;

    void main() {
      vec3 color = texture(u_inputFrame, v_texCoord).rgb;
      vec3 blurredColor = texture(u_blurredInputFrame, v_texCoord).rgb;
      float personMask = texture(u_personMask, v_texCoord).a;
      personMask = smoothstep(u_coverage.x, u_coverage.y, personMask);
      outColor = vec4(mix(blurredColor, color, personMask), 1.0);
    }
  `, {
    height: o,
    width: s
  } = a, r = Ve(e, e.VERTEX_SHADER, t), c = Ve(e, e.FRAGMENT_SHADER, l), u = pn(e, r, c, n, i), v = e.getUniformLocation(u, "u_inputFrame"), m = e.getUniformLocation(u, "u_personMask"), p = e.getUniformLocation(u, "u_blurredInputFrame"), k = e.getUniformLocation(u, "u_coverage");
  e.useProgram(u), e.uniform1i(v, 0), e.uniform1i(m, 1), e.uniform1i(p, 2), e.uniform2f(k, 0, 1);
  function F() {
    e.viewport(0, 0, s, o), e.useProgram(u), e.bindFramebuffer(e.FRAMEBUFFER, null), e.drawArrays(e.TRIANGLE_STRIP, 0, 4);
  }
  function S(y) {
    e.useProgram(u), e.uniform2f(k, y[0], y[1]);
  }
  function N() {
    e.deleteProgram(u), e.deleteShader(c), e.deleteShader(r);
  }
  return {
    cleanUp: N,
    render: F,
    updateCoverage: S
  };
}
function xu(e, n, i, a, t, l) {
  const o = Ae`#version 300 es

    uniform vec2 u_backgroundScale;
    uniform vec2 u_backgroundOffset;

    in vec2 a_position;
    in vec2 a_texCoord;

    out vec2 v_texCoord;
    out vec2 v_backgroundCoord;

    void main() {
      // Flipping Y is required when rendering to canvas
      gl_Position = vec4(a_position * vec2(1.0, -1.0), 0.0, 1.0);
      v_texCoord = a_texCoord;
      v_backgroundCoord = a_texCoord * u_backgroundScale + u_backgroundOffset;
    }
  `, s = Ae`#version 300 es

    precision highp float;

    uniform sampler2D u_inputFrame;
    uniform sampler2D u_personMask;
    uniform sampler2D u_background;
    uniform vec2 u_coverage;
    uniform float u_lightWrapping;
    uniform float u_blendMode;

    in vec2 v_texCoord;
    in vec2 v_backgroundCoord;

    out vec4 outColor;

    vec3 screen(vec3 a, vec3 b) {
      return 1.0 - (1.0 - a) * (1.0 - b);
    }

    vec3 linearDodge(vec3 a, vec3 b) {
      return a + b;
    }

    void main() {
      vec3 frameColor = texture(u_inputFrame, v_texCoord).rgb;
      vec3 backgroundColor = texture(u_background, v_backgroundCoord).rgb;
      float personMask = texture(u_personMask, v_texCoord).a;
      float lightWrapMask = 1.0 - max(0.0, personMask - u_coverage.y) / (1.0 - u_coverage.y);
      vec3 lightWrap = u_lightWrapping * lightWrapMask * backgroundColor;
      frameColor = u_blendMode * linearDodge(frameColor, lightWrap) +
        (1.0 - u_blendMode) * screen(frameColor, lightWrap);
      personMask = smoothstep(u_coverage.x, u_coverage.y, personMask);
      outColor = vec4(frameColor * personMask + backgroundColor * (1.0 - personMask), 1.0);
    }
  `, {
    height: r,
    width: c
  } = l, u = c / r, v = Ve(e, e.VERTEX_SHADER, o), m = Ve(e, e.FRAGMENT_SHADER, s), p = pn(e, v, m, n, i), k = e.getUniformLocation(p, "u_backgroundScale"), F = e.getUniformLocation(p, "u_backgroundOffset"), S = e.getUniformLocation(p, "u_inputFrame"), N = e.getUniformLocation(p, "u_personMask"), y = e.getUniformLocation(p, "u_background"), T = e.getUniformLocation(p, "u_coverage"), M = e.getUniformLocation(p, "u_lightWrapping"), C = e.getUniformLocation(p, "u_blendMode");
  e.useProgram(p), e.uniform2f(k, 1, 1), e.uniform2f(F, 0, 0), e.uniform1i(S, 0), e.uniform1i(N, 1), e.uniform2f(T, 0, 1), e.uniform1f(M, 0), e.uniform1f(C, 0);
  let P = null;
  t != null && t.complete ? se(t) : t && t.addEventListener("load", () => {
    se(t);
  });
  function G() {
    e.viewport(0, 0, c, r), e.useProgram(p), e.activeTexture(e.TEXTURE1), e.bindTexture(e.TEXTURE_2D, a), P !== null && (e.activeTexture(e.TEXTURE2), e.bindTexture(e.TEXTURE_2D, P), e.uniform1i(y, 2)), e.bindFramebuffer(e.FRAMEBUFFER, null), e.drawArrays(e.TRIANGLE_STRIP, 0, 4);
  }
  function se(U) {
    P = Ze(e, e.RGBA8, U.naturalWidth, U.naturalHeight, e.LINEAR, e.LINEAR), e.texSubImage2D(e.TEXTURE_2D, 0, 0, 0, U.naturalWidth, U.naturalHeight, e.RGBA, e.UNSIGNED_BYTE, U);
    let ke = 0, dn = 0, Nn = U.naturalWidth, Ue = U.naturalHeight;
    Nn / Ue < u ? (Ue = Nn / u, dn = (U.naturalHeight - Ue) / 2) : (Nn = Ue * u, ke = (U.naturalWidth - Nn) / 2);
    const Wi = Nn / U.naturalWidth, Gi = Ue / U.naturalHeight;
    ke /= U.naturalWidth, dn /= U.naturalHeight, e.uniform2f(k, Wi, Gi), e.uniform2f(F, ke, dn);
  }
  function J(U) {
    e.useProgram(p), e.uniform2f(T, U[0], U[1]);
  }
  function A(U) {
    e.useProgram(p), e.uniform1f(M, U);
  }
  function R(U) {
    e.useProgram(p), e.uniform1f(C, U === "screen" ? 0 : 1);
  }
  function q() {
    e.deleteTexture(P), e.deleteProgram(p), e.deleteShader(m), e.deleteShader(v);
  }
  return {
    cleanUp: q,
    render: G,
    updateBlendMode: R,
    updateCoverage: J,
    updateLightWrapping: A
  };
}
function Du(e, n, i, a, t, l, o, s) {
  const r = Ae`#version 300 es

    precision highp float;

    uniform sampler2D u_inputFrame;
    uniform sampler2D u_segmentationMask;
    uniform vec2 u_texelSize;
    uniform float u_step;
    uniform float u_radius;
    uniform float u_offset;
    uniform float u_sigmaTexel;
    uniform float u_sigmaColor;

    in vec2 v_texCoord;

    out vec4 outColor;

    float gaussian(float x, float sigma) {
      float coeff = -0.5 / (sigma * sigma * 4.0 + 1.0e-6);
      return exp((x * x) * coeff);
    }

    void main() {
      vec2 centerCoord = v_texCoord;
      vec3 centerColor = texture(u_inputFrame, centerCoord).rgb;
      float newVal = 0.0;

      float spaceWeight = 0.0;
      float colorWeight = 0.0;
      float totalWeight = 0.0;

      // Subsample kernel space.
      for (float i = -u_radius + u_offset; i <= u_radius; i += u_step) {
        for (float j = -u_radius + u_offset; j <= u_radius; j += u_step) {
          vec2 shift = vec2(j, i) * u_texelSize;
          vec2 coord = vec2(centerCoord + shift);
          vec3 frameColor = texture(u_inputFrame, coord).rgb;
          float outVal = texture(u_segmentationMask, coord).a;

          spaceWeight = gaussian(distance(centerCoord, coord), u_sigmaTexel);
          colorWeight = gaussian(distance(centerColor, frameColor), u_sigmaColor);
          totalWeight += spaceWeight * colorWeight;

          newVal += spaceWeight * colorWeight * outVal;
        }
      }
      newVal /= totalWeight;

      outColor = vec4(vec3(0.0), newVal);
    }
  `, [c, u] = di[l.inputResolution], {
    height: v,
    width: m
  } = s, p = 1 / m, k = 1 / v, F = Ve(e, e.FRAGMENT_SHADER, r), S = pn(e, n, F, i, a), N = e.getUniformLocation(S, "u_inputFrame"), y = e.getUniformLocation(S, "u_segmentationMask"), T = e.getUniformLocation(S, "u_texelSize"), M = e.getUniformLocation(S, "u_step"), C = e.getUniformLocation(S, "u_radius"), P = e.getUniformLocation(S, "u_offset"), G = e.getUniformLocation(S, "u_sigmaTexel"), se = e.getUniformLocation(S, "u_sigmaColor"), J = e.createFramebuffer();
  e.bindFramebuffer(e.FRAMEBUFFER, J), e.framebufferTexture2D(e.FRAMEBUFFER, e.COLOR_ATTACHMENT0, e.TEXTURE_2D, o, 0), e.useProgram(S), e.uniform1i(N, 0), e.uniform1i(y, 1), e.uniform2f(T, p, k), R(0), q(0);
  function A() {
    e.viewport(0, 0, m, v), e.useProgram(S), e.activeTexture(e.TEXTURE1), e.bindTexture(e.TEXTURE_2D, t), e.bindFramebuffer(e.FRAMEBUFFER, J), e.drawArrays(e.TRIANGLE_STRIP, 0, 4);
  }
  function R(ke) {
    ke *= Math.max(m / c, v / u);
    const dn = 0.66, Ue = Math.max(1, Math.sqrt(ke) * dn), ht = ke, Wi = Ue > 1 ? Ue * 0.5 : 0, Gi = Math.max(p, k) * ke;
    e.useProgram(S), e.uniform1f(M, Ue), e.uniform1f(C, ht), e.uniform1f(P, Wi), e.uniform1f(G, Gi);
  }
  function q(ke) {
    e.useProgram(S), e.uniform1f(se, ke);
  }
  function U() {
    e.deleteFramebuffer(J), e.deleteProgram(S), e.deleteShader(F);
  }
  return {
    cleanUp: U,
    render: A,
    updateSigmaColor: q,
    updateSigmaSpace: R
  };
}
function Iu(e, n, i, a, t, l, o) {
  const s = Ae`#version 300 es

    precision highp float;

    uniform sampler2D u_inputSegmentation;

    in vec2 v_texCoord;

    out vec4 outColor;

    void main() {
      float segmentation = texture(u_inputSegmentation, v_texCoord).r;
      outColor = vec4(vec3(0.0), segmentation);
    }
  `, r = l._getOutputMemoryOffset() / 4, [c, u] = di[t.inputResolution], v = Ve(e, e.FRAGMENT_SHADER, s), m = pn(e, n, v, i, a), p = e.getUniformLocation(m, "u_inputSegmentation"), k = Ze(e, e.R32F, c, u), F = e.createFramebuffer();
  e.bindFramebuffer(e.FRAMEBUFFER, F), e.framebufferTexture2D(e.FRAMEBUFFER, e.COLOR_ATTACHMENT0, e.TEXTURE_2D, o, 0), e.useProgram(m), e.uniform1i(p, 1);
  function S() {
    e.viewport(0, 0, c, u), e.useProgram(m), e.activeTexture(e.TEXTURE1), e.bindTexture(e.TEXTURE_2D, k), e.texSubImage2D(e.TEXTURE_2D, 0, 0, 0, c, u, e.RED, e.FLOAT, l.HEAPF32, r), e.bindFramebuffer(e.FRAMEBUFFER, F), e.drawArrays(e.TRIANGLE_STRIP, 0, 4);
  }
  function N() {
    e.deleteFramebuffer(F), e.deleteTexture(k), e.deleteProgram(m), e.deleteShader(v);
  }
  return {
    cleanUp: N,
    render: S
  };
}
function Au(e, n, i, a, t, l) {
  const o = Ae`#version 300 es

    precision highp float;

    uniform sampler2D u_inputFrame;

    in vec2 v_texCoord;

    out vec4 outColor;

    void main() {
      outColor = texture(u_inputFrame, v_texCoord);
    }
  `, s = l._getInputMemoryOffset() / 4, [r, c] = di[t.inputResolution], u = r * c, v = Ve(e, e.FRAGMENT_SHADER, o), m = pn(e, n, v, i, a), p = e.getUniformLocation(m, "u_inputFrame"), k = Ze(e, e.RGBA8, r, c), F = e.createFramebuffer();
  e.bindFramebuffer(e.FRAMEBUFFER, F), e.framebufferTexture2D(e.FRAMEBUFFER, e.COLOR_ATTACHMENT0, e.TEXTURE_2D, k, 0);
  const S = new Uint8Array(u * 4);
  e.useProgram(m), e.uniform1i(p, 0);
  async function N() {
    e.viewport(0, 0, r, c), e.useProgram(m), e.bindFramebuffer(e.FRAMEBUFFER, F), e.drawArrays(e.TRIANGLE_STRIP, 0, 4);
    const T = Mu(e, 0, 0, r, c, e.RGBA, e.UNSIGNED_BYTE, S);
    t.deferInputResizing || await T;
    for (let M = 0; M < u; M++) {
      const C = s + M * 3, P = M * 4;
      l.HEAPF32[C] = S[P] / 255, l.HEAPF32[C + 1] = S[P + 1] / 255, l.HEAPF32[C + 2] = S[P + 2] / 255;
    }
  }
  function y() {
    e.deleteFramebuffer(F), e.deleteTexture(k), e.deleteProgram(m), e.deleteShader(v);
  }
  return {
    cleanUp: y,
    render: N
  };
}
function Vu(e, n, i, a, t, l, o) {
  const s = Ae`#version 300 es

    precision highp float;

    uniform sampler2D u_inputSegmentation;

    in vec2 v_texCoord;

    out vec4 outColor;

    void main() {
      vec2 segmentation = texture(u_inputSegmentation, v_texCoord).rg;
      float shift = max(segmentation.r, segmentation.g);
      float backgroundExp = exp(segmentation.r - shift);
      float personExp = exp(segmentation.g - shift);
      outColor = vec4(vec3(0.0), personExp / (backgroundExp + personExp));
    }
  `, r = l._getOutputMemoryOffset() / 4, [c, u] = di[t.inputResolution], v = Ve(e, e.FRAGMENT_SHADER, s), m = pn(e, n, v, i, a), p = e.getUniformLocation(m, "u_inputSegmentation"), k = Ze(e, e.RG32F, c, u), F = e.createFramebuffer();
  e.bindFramebuffer(e.FRAMEBUFFER, F), e.framebufferTexture2D(e.FRAMEBUFFER, e.COLOR_ATTACHMENT0, e.TEXTURE_2D, o, 0), e.useProgram(m), e.uniform1i(p, 1);
  function S() {
    e.viewport(0, 0, c, u), e.useProgram(m), e.activeTexture(e.TEXTURE1), e.bindTexture(e.TEXTURE_2D, k), e.texSubImage2D(e.TEXTURE_2D, 0, 0, 0, c, u, e.RG, e.FLOAT, l.HEAPF32, r), e.bindFramebuffer(e.FRAMEBUFFER, F), e.drawArrays(e.TRIANGLE_STRIP, 0, 4);
  }
  function N() {
    e.deleteFramebuffer(F), e.deleteTexture(k), e.deleteProgram(m), e.deleteShader(v);
  }
  return {
    cleanUp: N,
    render: S
  };
}
function Ou(e, n, i, a, t, l, o) {
  const s = Ae`#version 300 es

    in vec2 a_position;
    in vec2 a_texCoord;

    out vec2 v_texCoord;

    void main() {
      gl_Position = vec4(a_position, 0.0, 1.0);
      v_texCoord = a_texCoord;
    }
  `, {
    height: r,
    width: c
  } = e, [u, v] = di[a.inputResolution], m = t.getContext("webgl2"), p = Ve(m, m.VERTEX_SHADER, s), k = m.createVertexArray();
  m.bindVertexArray(k);
  const F = m.createBuffer();
  m.bindBuffer(m.ARRAY_BUFFER, F), m.bufferData(m.ARRAY_BUFFER, new Float32Array([-1, -1, 1, -1, -1, 1, 1, 1]), m.STATIC_DRAW);
  const S = m.createBuffer();
  m.bindBuffer(m.ARRAY_BUFFER, S), m.bufferData(m.ARRAY_BUFFER, new Float32Array([0, 0, 1, 0, 0, 1, 1, 1]), m.STATIC_DRAW);
  const N = m.createTexture();
  m.bindTexture(m.TEXTURE_2D, N), m.texParameteri(m.TEXTURE_2D, m.TEXTURE_WRAP_S, m.CLAMP_TO_EDGE), m.texParameteri(m.TEXTURE_2D, m.TEXTURE_WRAP_T, m.CLAMP_TO_EDGE), m.texParameteri(m.TEXTURE_2D, m.TEXTURE_MIN_FILTER, m.NEAREST), m.texParameteri(m.TEXTURE_2D, m.TEXTURE_MAG_FILTER, m.NEAREST);
  const y = Ze(m, m.RGBA8, u, v), T = Ze(m, m.RGBA8, c, r), M = Au(m, p, F, S, a, l), C = a.model === "meet" ? Vu(m, p, F, S, a, l, y) : Iu(m, p, F, S, a, l, y), P = Du(m, p, F, S, y, a, T, t), G = i.type === "blur" ? _u(m, p, F, S, T, t) : xu(m, F, S, T, n, t);
  async function se() {
    m.activeTexture(m.TEXTURE0), m.bindTexture(m.TEXTURE_2D, N), m.texImage2D(m.TEXTURE_2D, 0, m.RGBA, m.RGBA, m.UNSIGNED_BYTE, e.htmlElement), m.bindVertexArray(k), await M.render(), o(), l._runInference(), o(), C.render(), P.render(), G.render();
  }
  function J(R) {
    if (P.updateSigmaSpace(R.jointBilateralFilter.sigmaSpace), P.updateSigmaColor(R.jointBilateralFilter.sigmaColor), i.type === "image") {
      const q = G;
      q.updateCoverage(R.coverage), q.updateLightWrapping(R.lightWrapping), q.updateBlendMode(R.blendMode);
    } else if (i.type === "blur")
      G.updateCoverage(R.coverage);
    else {
      const q = G;
      q.updateCoverage([0, 0.9999]), q.updateLightWrapping(0);
    }
  }
  function A() {
    G.cleanUp(), P.cleanUp(), C.cleanUp(), M.cleanUp(), m.deleteTexture(T), m.deleteTexture(y), m.deleteTexture(N), m.deleteBuffer(S), m.deleteBuffer(F), m.deleteVertexArray(k), m.deleteShader(p);
  }
  return {
    cleanUp: A,
    render: se,
    updatePostProcessingConfig: J
  };
}
function Pu(e) {
  return new Worker(
    "/assets/timerWorker--7dR5ATP.js",
    {
      name: e == null ? void 0 : e.name
    }
  );
}
function Lu() {
  const e = /* @__PURE__ */ new Map(), n = new Pu();
  n.addEventListener("message", (o) => {
    const s = e.get(o.data.callbackId);
    s && (e.delete(o.data.callbackId), s());
  });
  let i = 1;
  function a(o, s) {
    const r = i++;
    return e.set(r, o), n.postMessage({
      callbackId: r,
      timeoutMs: s
    }), r;
  }
  function t(o) {
    e.has(o) && (n.postMessage({
      callbackId: o
    }), e.delete(o));
  }
  function l() {
    e.clear(), n.terminate();
  }
  return {
    clearTimeout: t,
    setTimeout: a,
    terminate: l
  };
}
function Uu(e, n, i, a) {
  const [t, l] = H(null), o = je(null), s = je(null), [r, c] = H(0), [u, v] = H([]);
  return Q(() => {
    if (a == null || e == null)
      return () => {
      };
    const m = 1e3 / i.targetFps;
    let p = 0, k = 0, F = 0, S = 0;
    const N = [];
    let y;
    const T = Lu();
    if (i.pipeline !== "webgl2")
      throw new Error('segmentationConfig.pipeline !== "webgl2"');
    const M = Ou(e, o.current, n, i, s.current, a, G);
    async function C() {
      const J = performance.now();
      P(), await M.render(), se(), y = T.setTimeout(C, Math.max(0, m - (performance.now() - J)));
    }
    function P() {
      k = Date.now();
    }
    function G() {
      const J = Date.now();
      N[F] = J - k, k = J, F++;
    }
    function se() {
      const J = Date.now();
      N[F] = J - k, S++, J >= p + 1e3 && (c(S * 1e3 / (J - p)), v(N), p = J, S = 0), F = 0;
    }
    return C(), console.info("Animation started:", e, n, i), l(M), () => {
      T.clearTimeout(y), T.terminate(), M.cleanUp(), console.info("Animation stopped:", e, n, i), l(null);
    };
  }, [e, n, i, a]), {
    backgroundImageRef: o,
    canvasRef: s,
    durations: u,
    fps: r,
    pipeline: t
  };
}
function $u(e) {
  const [n, i] = H(), [a, t] = H(), [l, o] = H(), [s, r] = H(!1);
  return Q(() => {
    async function c() {
      createTFLiteModule().then(i);
      try {
        const u = await createTFLiteSIMDModule();
        t(u), r(!0);
      } catch (u) {
        console.warn("Failed to create TFLite SIMD WebAssembly module.", u);
      }
    }
    c();
  }, []), Q(() => {
    async function c() {
      if (!n || s && !a || !s && e.backend === "wasmSimd" || e.model !== "meet" && e.model !== "mlkit")
        return;
      o(void 0);
      const u = e.backend === "wasmSimd" ? a : n;
      if (!u)
        throw new Error(`TFLite backend unavailable: ${e.backend}`);
      const v = yu(e.model, e.inputResolution);
      console.info("Loading tflite model:", v);
      const m = `/models/${v}.tflite`, p = await (await fetch(m)).arrayBuffer();
      console.info("Model buffer size:", p.byteLength);
      const k = u._getModelBufferMemoryOffset();
      console.info("Model buffer memory offset:", k), console.info("Loading model buffer..."), u.HEAPU8.set(new Uint8Array(p), k), console.info("_loadModel result:", u._loadModel(p.byteLength)), console.info("Input memory offset:", u._getInputMemoryOffset()), console.info("Input height:", u._getInputHeight()), console.info("Input width:", u._getInputWidth()), console.info("Input channels:", u._getInputChannelCount()), console.info("Output memory offset:", u._getOutputMemoryOffset()), console.info("Output height:", u._getOutputHeight()), console.info("Output width:", u._getOutputWidth()), console.info("Output channels:", u._getOutputChannelCount()), o(u);
    }
    c();
  }, [n, a, s, e.model, e.backend, e.inputResolution]), {
    isSIMDSupported: s,
    tflite: l
  };
}
const po = /* @__PURE__ */ g(() => {
  const e = I(ce), [n, i] = H(), [a, t] = H({
    backend: "wasm",
    deferInputResizing: !0,
    inputResolution: "160x96",
    model: "meet",
    pipeline: "webgl2",
    // 60 introduces fps drop and unstable fps on Chrome
    // targetFps: 65,
    targetFps: 30
  }), [
    l
    /* setPostProcessingConfig */
  ] = H({
    blendMode: "screen",
    coverage: [0.5, 0.75],
    jointBilateralFilter: {
      sigmaColor: 0.1,
      sigmaSpace: 1
    },
    lightWrapping: 0.3,
    smoothSegmentationMask: !0
  }), {
    isSIMDSupported: o,
    tflite: s
  } = $u(a);
  Q(() => {
    t((S) => S.backend === "wasm" && o ? {
      ...S,
      backend: "wasmSimd"
    } : S);
  }, [o]);
  const r = L(() => e.state === "background-blur-initializing" || e.state === "background-blur-ready" ? {
    type: "blur"
  } : e.state === "background-image-initializing" || e.state === "background-image-ready" ? {
    type: "image",
    url: e.backgroundImage
  } : {
    type: "none",
    url: void 0
  }, [e]), {
    backgroundImageRef: c,
    canvasRef: u,
    // durations: [resizingDuration, inferenceDuration, postProcessingDuration],
    // fps,
    pipeline: v
  } = Uu(
    n,
    r,
    a,
    // props.bodyPix,
    s
  );
  Q(() => {
    i(void 0);
  }, []), Q(() => {
    v && v.updatePostProcessingConfig(l);
  }, [v, l]);
  const m = E((S) => {
    const N = S.target;
    i({
      height: N.videoHeight,
      htmlElement: N,
      width: N.videoWidth
    }), Xd(u);
  }, [u]), p = L(() => ({
    // border: "1px solid red",
    position: "absolute",
    // zIndex: 99,
    visibility: "hidden",
    zIndex: -1
  }), []), k = L(() => Wd(e), [e]), F = e.state === "background-image-initializing" || e.state === "background-image-ready" ? e.backgroundImage : void 0;
  return /* @__PURE__ */ f("div", {
    style: p,
    children: [/* @__PURE__ */ d("img", {
      ref: c,
      height: n == null ? void 0 : n.height,
      width: n == null ? void 0 : n.width,
      alt: "",
      src: F
    }, e.state === "background-image-initializing" || e.state === "background-image-ready" ? e.backgroundImage : ""), /* @__PURE__ */ d(yi, {
      muted: !0,
      onLoadedData: m,
      stream: k
    }), /* @__PURE__ */ d("canvas", {
      ref: u,
      height: n == null ? void 0 : n.height,
      width: n == null ? void 0 : n.width
    }, a.pipeline)]
  });
});
po.displayName = "VirtualBackgroundViewerInner";
const Bu = /* @__PURE__ */ g(() => {
  const e = I(ce), n = e.state === "initial" || e.state === "video-track-ended" ? null : e.state === "background-blur-initializing" || e.state === "background-blur-ready" ? e.enabled ? "blur" : null : (e.state === "background-image-initializing" || e.state === "background-image-ready") && e.enabled ? `image-${e.backgroundImage}` : null;
  return n != null ? /* @__PURE__ */ d(po, {}, n) : null;
});
Bu.displayName = "VirtualBackgroundViewer";
const zu = /* @__PURE__ */ g(() => {
  const [e, n] = H(!1), i = me(), a = E(() => {
    gd();
  }, []), t = I(xn), l = L(() => e || t === re.Votes ? "white" : i.colors.gray[400], [e, t, i.colors.gray]), o = L(() => e || t === re.Votes ? "rgba(255, 255, 255, .12)" : "transparent", [t, e]), s = I(Er);
  return /* @__PURE__ */ f(He, {
    _focus: {
      bg: o
    },
    _hover: {
      bg: o
    },
    _pressed: {
      bg: o
    },
    bg: o,
    borderRadius: "32",
    h: "48px",
    onHoverIn: () => n(!0),
    onHoverOut: () => n(!1),
    onPress: a,
    shadow: "1",
    w: "48px",
    children: [/* @__PURE__ */ d(Ta, {
      fill: l
    }), s > 0 ? /* @__PURE__ */ d(w, {
      alignItems: "center",
      backgroundColor: "red.100",
      borderRadius: "50%",
      color: "white",
      justifyContent: "center",
      position: "absolute",
      right: -5,
      top: -5,
      w: "2ch",
      children: /* @__PURE__ */ d(b, {
        fontSize: 10,
        children: s
      })
    }) : null]
  });
});
zu.displayName = "ControlActiveVotesToggle";
const ju = "_container_1tydh_1", Hu = "_panel_1tydh_11", Wu = "_openPanel_1tydh_1", Gu = "_header_1tydh_40", qu = "_footer_1tydh_47", Xu = "_body_1tydh_51", Yu = "_title_1tydh_58", Ku = "_buttonClose_1tydh_65", Ju = "_label_1tydh_87", Qu = "_group_1tydh_95", Zu = "_input_1tydh_99", ek = "_inputFocused_1tydh_112", nk = "_inputWrap_1tydh_117", ik = "_inputEmpty_1tydh_128", ak = "_inputNotEmpty_1tydh_136", tk = "_inputAdd_1tydh_140", ve = {
  container: ju,
  panel: Hu,
  openPanel: Wu,
  header: Gu,
  footer: qu,
  body: Xu,
  title: Yu,
  buttonClose: Ku,
  label: Ju,
  group: Qu,
  input: Zu,
  inputFocused: ek,
  inputWrap: nk,
  inputEmpty: ik,
  inputNotEmpty: ak,
  inputAdd: tk
}, Ht = B.object({
  answers: B.array(B.object({
    value: B.string()
  })).min(2),
  // conditions: z
  //   .object({
  //     terminate: z
  //       .object({
  //         completion: z.boolean(),
  //         majority: z.boolean(),
  //         time: z.date(),
  //       })
  //       .strict(),
  //   })
  //   .strict(),
  title: B.string().min(1)
}).strict(), dk = /* @__PURE__ */ g(() => I(Rr) ? /* @__PURE__ */ d(No, {}) : null);
dk.displayName = "CreateVote";
const No = /* @__PURE__ */ g(() => {
  const {
    t: e
  } = ie(), n = E(() => {
    jn(!1);
  }, []), {
    control: i,
    formState: {
      errors: a,
      isSubmitting: t,
      isValid: l
    },
    handleSubmit: o,
    register: s,
    setValue: r
  } = En({
    defaultValues: {
      answers: [{
        value: ""
      }],
      /* conditions: { terminate: {} }, */
      title: ""
    },
    mode: "all",
    resolver: Jn(Ht)
  }), {
    append: c,
    fields: u,
    remove: v
  } = rd({
    control: i,
    name: "answers"
  }), [m, {
    error: p,
    loading: k
  }] = Tn(vd), F = !l || t, S = E(async (N) => {
    const y = Ht.parse(N);
    await m({
      variables: {
        cases: y.answers.filter((T) => T.value.trim() !== "").map((T) => T.value),
        conditions: {
          terminate: {
            completion: !0,
            majority: !0
          }
        },
        multiple: !1,
        title: y.title
      }
    }), jn(!1);
  }, [m]);
  return /* @__PURE__ */ f("div", {
    className: ve.container,
    children: [/* @__PURE__ */ f("div", {
      className: ve.panel,
      children: [/* @__PURE__ */ f("div", {
        className: ve.header,
        children: [/* @__PURE__ */ d("div", {
          className: ve.title,
          children: e("ROOM.NEW_VOTING", {
            defaultValue: "Новое голосование"
          })
        }), /* @__PURE__ */ d("button", {
          className: ve.buttonClose,
          onClick: n,
          type: "button",
          children: /* @__PURE__ */ d(_t, {
            height: "10",
            width: "10"
          })
        })]
      }), /* @__PURE__ */ f("div", {
        className: ve.body,
        children: [/* @__PURE__ */ f("div", {
          className: ve.group,
          children: [/* @__PURE__ */ d("div", {
            className: ve.label,
            children: e("LABEL.QUESTION", {
              defaultValue: "Вопрос"
            })
          }), /* @__PURE__ */ d("div", {
            ...s("title"),
            contentEditable: !0,
            className: De(ve.input),
            onInput: (N) => {
              r("title", N.currentTarget.textContent ?? "", {
                shouldDirty: !0,
                shouldTouch: !0,
                shouldValidate: !0
              });
            },
            tabIndex: -1
          })]
        }), /* @__PURE__ */ d("div", {
          className: ve.label,
          children: e("LABEL.ANSWERS_VARIANTS", {
            defaultValue: "Варианты ответа"
          })
        }), u.map((N, y) => /* @__PURE__ */ f("div", {
          className: ve.inputWrap,
          children: [/* @__PURE__ */ d("div", {
            ...s(`answers.${y}.value`),
            contentEditable: !0,
            className: De(ve.input),
            onFocus: (T) => {
              y === u.length - 1 && c({
                value: ""
              }, {
                shouldFocus: !1
              });
            },
            onInput: (T) => {
              r(`answers.${y}.value`, T.currentTarget.textContent ?? "", {
                shouldDirty: !0,
                shouldTouch: !0,
                shouldValidate: !0
              });
            },
            tabIndex: 0
          }), /* @__PURE__ */ d("button", {
            className: ve.buttonClose,
            onClick: () => v(y),
            type: "button",
            children: /* @__PURE__ */ d(_t, {
              height: "10",
              width: "10"
            })
          })]
        }, N.id))]
      }), /* @__PURE__ */ d("div", {
        className: ve.footer,
        children: /* @__PURE__ */ d(He, {
          bg: F ? "#19328F" : "blue.50",
          disabled: F,
          onPress: o(S),
          variant: "primary",
          children: /* @__PURE__ */ d(b, {
            color: "white",
            fontSize: "14",
            fontWeight: "500",
            lineHeight: "24",
            opacity: F ? 0.3 : 1,
            children: e("BUTTON.CREATE", {
              defaultValue: "Создать"
            })
          })
        })
      })]
    }), p ? /* @__PURE__ */ d(Kn, {
      error: p,
      onOk: n
    }) : null]
  });
});
No.displayName = "CreateVotingContainer";
const fo = /* @__PURE__ */ g(({
  vote: e
}) => {
  const {
    currentMeetSpaceMyPermissions: n,
    pending: i
  } = I({
    currentMeetSpaceMyPermissions: tn,
    pending: Rt.pending
  }), a = !n.has(Y.PermissionChannelVoteControl), t = E(() => {
    Rt(e);
  }, [e]);
  return /* @__PURE__ */ d(ps, {
    size: "xs",
    children: /* @__PURE__ */ d(rn, {
      disabledText: i ? "загружается" : e.terminated != null ? "Голосование уже закончено" : a ? "Нет прав" : void 0,
      onPress: t,
      children: "Завершить голосование"
    })
  });
});
fo.displayName = "VoteListItemMenu";
const lk = "_container_b0y1t_1", ok = "_author_b0y1t_8", sk = "_authorAvatar_b0y1t_14", rk = "_content_b0y1t_20", mk = "_control_b0y1t_26", ck = "_label_b0y1t_32", uk = "_controlRadio_b0y1t_44", kk = "_controlAside_b0y1t_57", vk = "_checkbox_b0y1t_60", Sk = "_controlResult_b0y1t_64", pk = "_controlResultLine_b0y1t_74", Nk = "_iconCheck_b0y1t_81", fk = "_controlContent_b0y1t_93", Fk = "_footer_b0y1t_98", gk = "_menu_b0y1t_105", de = {
  container: lk,
  author: ok,
  authorAvatar: sk,
  content: rk,
  control: mk,
  label: ck,
  controlRadio: uk,
  controlAside: kk,
  checkbox: vk,
  controlResult: Sk,
  controlResultLine: pk,
  iconCheck: Nk,
  controlContent: fk,
  footer: Fk,
  menu: gk
}, Fo = /* @__PURE__ */ g(({
  vote: e
}) => {
  var s;
  const n = e.cases.reduce((r, c) => r + c.count, 0), i = !e.terminated && e.cases.every((r) => !r.me), {
    data: a,
    error: t,
    loading: l
  } = qn(Mi, {
    variables: {
      id: e.member
    }
  }), o = a == null ? void 0 : a.meet.member.get;
  return /* @__PURE__ */ f("div", {
    className: de.container,
    children: [/* @__PURE__ */ d("div", {
      className: de.menu,
      children: /* @__PURE__ */ d(fo, {
        vote: e
      })
    }), o != null ? /* @__PURE__ */ f("div", {
      className: de.author,
      children: [/* @__PURE__ */ d("div", {
        className: de.authorAvatar,
        children: /* @__PURE__ */ d(fa, {
          h: "32px",
          source: {
            uri: ((s = o.user) == null ? void 0 : s.avatar.uri) ?? fi(o.id)
          },
          w: "32px"
        })
      }), /* @__PURE__ */ d(b, {
        color: "text.100",
        fontSize: "14",
        fontWeight: "500",
        lineBreakMode: "tail",
        lineHeight: "24",
        numberOfLines: 1,
        children: nn(o)
      })]
    }) : null, /* @__PURE__ */ f("div", {
      className: de.content,
      children: [/* @__PURE__ */ d(b, {
        color: "white",
        display: "flex",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "18",
        mb: "3",
        children: e.title
      }), i ? /* @__PURE__ */ d(go, {
        vote: e
      }) : /* @__PURE__ */ d(bo, {
        totalVotes: n,
        vote: e
      }), /* @__PURE__ */ f("div", {
        className: de.footer,
        children: [n === 0 ? /* @__PURE__ */ d(b, {
          color: "text.100",
          fontSize: "12",
          fontWeight: "500",
          lineHeight: "20",
          children: "нет голосов"
        }) : null, /* @__PURE__ */ d(b, {
          color: "text.100",
          fontSize: "12",
          fontWeight: "500",
          lineHeight: "20",
          ml: "auto",
          children: Ko(Yt(e.created))
        })]
      })]
    }), /* @__PURE__ */ d(Kn, {
      error: t
    })]
  });
});
Fo.displayName = "VoteListItem";
const go = /* @__PURE__ */ g(({
  vote: e
}) => /* @__PURE__ */ d(be, {
  children: e.cases.map((n, i) => /* @__PURE__ */ d(ho, {
    index: i,
    multiple: e.multiple,
    text: n.name,
    voteId: e.id
  }, `${e.id}_${n.name}`))
}));
go.displayName = "Inputs";
const ho = /* @__PURE__ */ g(({
  index: e,
  multiple: n,
  text: i,
  voteId: a
}) => {
  const t = E(() => Ud({
    cases: [e],
    voteId: a
  }), [e, a]);
  return /* @__PURE__ */ f("label", {
    className: De(de.control, de.label),
    children: [/* @__PURE__ */ d("input", {
      name: "name1",
      onChange: t,
      onSelect: t,
      type: "radio"
    }), /* @__PURE__ */ d("div", {
      className: de.controlAside,
      children: /* @__PURE__ */ d("div", {
        className: De(de.controlRadio, n && de.checkbox)
      })
    }), /* @__PURE__ */ d("div", {
      className: de.controlContent,
      children: /* @__PURE__ */ d(b, {
        color: "gray.400",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "20",
        children: i
      })
    })]
  });
});
ho.displayName = "AnswerInput";
const bo = /* @__PURE__ */ g(({
  totalVotes: e,
  vote: n
}) => /* @__PURE__ */ d(be, {
  children: n.cases.map((i) => {
    const a = i.count, t = e > 0 ? Math.floor(a / e * 100) : 0;
    return /* @__PURE__ */ f("div", {
      className: De(de.control, de.result),
      children: [/* @__PURE__ */ d("div", {
        className: de.controlAside,
        children: /* @__PURE__ */ d(b, {
          color: "text.100",
          fontSize: "12",
          fontWeight: "500",
          lineHeight: "20",
          children: `${t}%`
        })
      }), /* @__PURE__ */ f("div", {
        className: de.controlContent,
        children: [/* @__PURE__ */ d(b, {
          color: "gray.400",
          fontSize: "14",
          fontWeight: "500",
          lineHeight: "20",
          children: i.name
        }), /* @__PURE__ */ f("div", {
          className: de.controlResult,
          children: [i.me ? /* @__PURE__ */ d(Ys, {
            className: de.iconCheck,
            height: "12",
            width: "12"
          }) : null, /* @__PURE__ */ d("div", {
            className: de.controlResultLine,
            style: {
              width: `${t}%`
            }
          })]
        })]
      })]
    }, i.name);
  })
}));
bo.displayName = "Results";
const hk = /* @__PURE__ */ g(() => {
  const {
    t: e
  } = ie(), n = I(Bd), i = E(({
    item: a
  }) => /* @__PURE__ */ d(Fo, {
    vote: a
  }), []);
  return /* @__PURE__ */ f(sn, {
    flex: 1,
    children: [/* @__PURE__ */ d(ha, {
      bg: "gray.200",
      mb: 3,
      onPress: () => {
        jn(!0);
      },
      children: e("ROOM.CREATE_VOTING", {
        defaultValue: "Создать голосование"
      })
    }), /* @__PURE__ */ d(Fa, {
      ItemSeparatorComponent: yo,
      data: n,
      flex: 1,
      keyExtractor: bk,
      onEndReached: Ld,
      renderItem: i,
      updateCellsBatchingPeriod: 1
    })]
  });
});
hk.displayName = "VotesPanel";
const bk = (e) => e.id, yo = /* @__PURE__ */ g(() => /* @__PURE__ */ d(w, {
  style: yk.separator
}));
yo.displayName = "FlatListItemSeparator";
const yk = _n.create({
  separator: {
    backgroundColor: ze.colors.black,
    height: 2,
    width: "100%"
  }
}), Tk = B.object({
  defaultCamera: Xr
}).merge(bu), Mk = Tk.partial(), Ck = Gt((e) => e.map((n) => /* @__PURE__ */ d(Fn, {
  value: n.deviceId,
  children: n.label
}, n.deviceId)), () => /* @__PURE__ */ d(zn, {
  size: "sm"
}), () => /* @__PURE__ */ d(zn, {
  size: "sm"
}), (e) => /* @__PURE__ */ d(b, {
  color: "red.100",
  children: String(e)
})), To = /* @__PURE__ */ g(({
  images: e,
  onChange: n,
  small: i,
  value: a
}) => {
  var r, c, u;
  const [t, l] = H(((r = a.defaultCamera) == null ? void 0 : r.type) ?? "simple");
  Q(() => {
    jd();
  }, []);
  const o = I(Ur), s = E((v) => {
    const m = Mk.parse(v);
    n({
      ...a,
      ...m
    });
  }, [n, a]);
  return /* @__PURE__ */ f(w, {
    marginY: 3,
    children: [/* @__PURE__ */ f(w, {
      children: [/* @__PURE__ */ d(b, {
        fontSize: 16,
        mb: 2,
        children: x("SETTINGS.CAMERA.TYPE_SELECTION_LABEL")
      }), /* @__PURE__ */ d(Fn.Group, {
        name: "camera_type",
        onChange: (v) => {
          l(v);
        },
        size: 1,
        value: t,
        children: /* @__PURE__ */ f(Tt, {
          alignItems: {
            base: "flex-start",
            md: "center"
          },
          direction: {
            base: "column",
            md: "row"
          },
          mb: 4,
          space: 4,
          w: "100%",
          children: [/* @__PURE__ */ d(Fn, {
            value: "simple",
            children: x("SETTINGS.CAMERA.SELECTION_TYPE_SIMPLE")
          }), /* @__PURE__ */ d(Fn, {
            value: "by_id",
            children: x("SETTINGS.CAMERA.SELECTION_TYPE_DETAILED")
          })]
        })
      })]
    }), t === "simple" ? /* @__PURE__ */ f(Un, {
      onValueChange: (v) => s({
        defaultCamera: {
          facing: v,
          type: "simple"
        }
      }),
      placeholder: "Выберите камеру",
      selectedValue: ((c = a.defaultCamera) == null ? void 0 : c.type) === "simple" ? a.defaultCamera.facing : void 0,
      children: [/* @__PURE__ */ d(Un.Item, {
        label: x("SETTINGS.CAMERA.FACING_USER"),
        value: "user"
      }), /* @__PURE__ */ d(Un.Item, {
        label: x("SETTINGS.CAMERA.FACING_ENVIRONMENT"),
        value: "environment"
      })]
    }) : /* @__PURE__ */ d(w, {
      borderBottomColor: "gray.500",
      borderBottomStyle: "solid",
      borderBottomWidth: "1px",
      pb: 2,
      children: /* @__PURE__ */ d(Fn.Group, {
        name: "camera_id",
        onChange: (v) => {
          s({
            defaultCamera: {
              id: v,
              type: "by_id"
            }
          });
        },
        size: 1,
        value: ((u = a.defaultCamera) == null ? void 0 : u.type) === "by_id" ? a.defaultCamera.id : void 0,
        children: /* @__PURE__ */ d(Tt, {
          children: Ck(o)
        })
      })
    }), /* @__PURE__ */ d(Gd, {
      images: e,
      onChange: s,
      small: i,
      value: a
    })]
  });
});
To.displayName = "SettingsCameraForm";
const wk = /* @__PURE__ */ g(() => {
  const e = {}, [n, i] = H(en());
  Q(() => {
    async function t() {
      try {
        const l = await Ie.enumerateDevices();
        i(he(l.filter((o) => o.kind === "audioinput")));
      } catch {
        i(ua("Нет доступа к устройствам"));
      }
    }
    t();
  }, []);
  const a = Se((t) => t, [], n);
  return /* @__PURE__ */ f(Ns, {
    children: [/* @__PURE__ */ d(fs, {
      children: "Микрофон"
    }), /* @__PURE__ */ d(w, {
      children: /* @__PURE__ */ d(Fn.Group, {
        accessibilityLabel: "default microphone",
        name: "myRadioGroup",
        onChange: (t) => {
        },
        size: "0.5",
        value: e.defaultCamera,
        children: a.map((t, l) => /* @__PURE__ */ d(Fs, {
          label: t.label === "" ? `Микрофон ${l + 1}` : t.label,
          noBorder: a.length - 1 === l,
          selected: e.defaultMic === t.deviceId,
          value: t.deviceId
        }, t.deviceId))
      })
    })]
  });
});
wk.displayName = "SettingsMicrophone";
const _k = /* @__PURE__ */ g((e) => /* @__PURE__ */ d(w, {
  bg: "#141414",
  borderRadius: "12",
  bottom: "100%",
  display: {
    base: "none",
    lg: "flex"
  },
  left: "0",
  mb: "1",
  position: "absolute",
  shadow: "1",
  w: "300px",
  zIndex: 1e3,
  ...e
}));
_k.displayName = "UsersListDesktop";
const Ek = /* @__PURE__ */ g(({
  linkForCopy: e,
  textForCopy: n
}) => {
  const {
    width: i
  } = wn(), a = L(() => ({
    bg: "blue.50",
    borderRadius: {
      base: 0,
      lg: "24"
    },
    color: "white",
    justifyContent: {
      base: "center",
      lg: "flex-start"
    },
    width: {
      base: i,
      lg: "auto"
    }
  }), [i]);
  return /* @__PURE__ */ d(w, {
    flexDirection: "row",
    justifyContent: "flex-start",
    p: {
      lg: 5
    },
    children: /* @__PURE__ */ d(ed, {
      mobileView: "full",
      textForCopy: e,
      view: a,
      children: /* @__PURE__ */ d(b, {
        color: "white",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "24",
        children: n
      })
    })
  });
});
Ek.displayName = "UsersListFooter";
const Gn = /* @__PURE__ */ g(({
  audioDisabled: e,
  avatarUri: n,
  hideControls: i,
  isOrganizer: a,
  nickname: t,
  onAudioClick: l,
  onVideoClick: o,
  permissions: s = /* @__PURE__ */ new Set(),
  videoDisabled: r
}) => {
  const c = Sa({
    nickname: t
  }), u = L(() => va(n, 48), [n]);
  return /* @__PURE__ */ d(ge, {
    trigger: (v) => /* @__PURE__ */ f(te, {
      ...v,
      alignItems: "center",
      flexDirection: "row",
      h: "60px",
      px: {
        base: 2,
        lg: 4
      },
      py: "6px",
      children: [/* @__PURE__ */ d(fa, {
        bg: "rgba(255, 255, 255, 0.08)",
        h: "48px",
        source: u,
        w: "48px",
        children: /* @__PURE__ */ d(b, {
          color: "text.100",
          fontSize: 16,
          fontWeight: 400,
          children: c.toLocaleUpperCase()
        })
      }), /* @__PURE__ */ f(w, {
        flex: "1",
        pl: "3",
        children: [/* @__PURE__ */ d(b, {
          isTruncated: !0,
          color: "gray.400",
          fontSize: "14",
          fontWeight: "500",
          lineHeight: "20px",
          noOfLines: 1,
          children: t
        }), a && /* @__PURE__ */ d(b, {
          isTruncated: !0,
          color: "text.100",
          fontSize: "14",
          fontWeight: "500",
          lineHeight: "20px",
          noOfLines: 1,
          children: "Организатор"
        })]
      }), !i && /* @__PURE__ */ f(be, {
        children: [/* @__PURE__ */ d(ra, {
          Icon: ya,
          IconDisabled: td,
          disabled: e ?? !1,
          hasPermission: s.has(Y.PermissionChannelMediaAudioSend),
          onClick: l
        }), /* @__PURE__ */ d(ra, {
          Icon: Es,
          IconDisabled: Rs,
          disabled: r ?? !1,
          hasPermission: s.has(Y.PermissionChannelMediaVideoSend),
          onClick: o
        })]
      })]
    }),
    children: /* @__PURE__ */ f(ge.Content, {
      children: [/* @__PURE__ */ d(ge.Arrow, {}), /* @__PURE__ */ d(ge.CloseButton, {}), /* @__PURE__ */ d(ge.Header, {
        children: "Информация"
      }), /* @__PURE__ */ f(ge.Body, {
        children: [/* @__PURE__ */ d(hn, {
          size: "sm",
          children: "Доступы"
        }), /* @__PURE__ */ d(Mo, {
          permissions: s
        })]
      })]
    })
  });
});
Gn.displayName = "UsersListItemView";
const Mo = /* @__PURE__ */ g(({
  permissions: e
}) => {
  const {
    t: n
  } = ie(), i = Jo(Y);
  return /* @__PURE__ */ d(Bn, {
    children: i.map((a) => /* @__PURE__ */ d(w, {
      children: /* @__PURE__ */ d(b, {
        children: `${e.has(a) ? "✅" : "❌"} ${n(`BACKEND.PERMISSIONS.${a}`)}`
      })
    }, a))
  });
});
Mo.displayName = "PermissionsList";
const ra = /* @__PURE__ */ g(({
  disabled: e,
  hasPermission: n,
  Icon: i,
  IconDisabled: a,
  onClick: t
}) => {
  const l = me();
  return /* @__PURE__ */ d(w, {
    w: "92px",
    children: /* @__PURE__ */ d(bi, {
      onPress: t,
      children: e || !n ? /* @__PURE__ */ d(a, {
        fill: n ? l.colors.gray[400] : l.colors.red[400],
        height: "16",
        opacity: n ? 1 : 0.4,
        width: "16"
      }) : /* @__PURE__ */ d(i, {
        fill: l.colors.gray[400],
        height: "16",
        width: "16"
      })
    })
  });
});
ra.displayName = "DeviceControl";
const Co = /* @__PURE__ */ g(({
  canControlUser: e,
  isOrganizer: n,
  member: i,
  peer: a
}) => {
  var m;
  const t = I(Ri), [l, {
    error: o,
    loading: s
  }] = Tn(ar), r = o, c = L(() => Md(t == null ? void 0 : t.permissions, i), [i, t == null ? void 0 : t.permissions]), u = E(async () => {
    e && !s && (c.has(Y.PermissionChannelMediaAudioSend) ? await l({
      variables: {
        id: a.id,
        permissions_deny: [Y.PermissionChannelMediaAudioSend]
      }
    }) : await l({
      variables: {
        id: a.id,
        permissions_grant: [Y.PermissionChannelMediaAudioSend]
      }
    }));
  }, [e, s, a.id, c, l]), v = E(async () => {
    e && !s && (c.has(Y.PermissionChannelMediaVideoSend) ? await l({
      variables: {
        id: a.id,
        permissions_deny: [Y.PermissionChannelMediaVideoSend]
      }
    }) : await l({
      variables: {
        id: a.id,
        permissions_grant: [Y.PermissionChannelMediaVideoSend]
      }
    }));
  }, [e, s, a.id, c, l]);
  return /* @__PURE__ */ f(be, {
    children: [/* @__PURE__ */ d(Gn, {
      audioDisabled: (a.media.audio ?? ki.MediaAudioOff) === ki.MediaAudioOff,
      avatarUri: ((m = i.user) == null ? void 0 : m.avatar.uri) ?? fi(a.id),
      isOrganizer: n,
      nickname: nn(i),
      onAudioClick: u,
      onVideoClick: v,
      permissions: c,
      videoDisabled: (a.media.video ?? Re.MediaVideoOff) === Re.MediaVideoOff
    }), r != null ? /* @__PURE__ */ d(Kn, {
      error: r
    }) : null]
  });
});
Co.displayName = "MeetMemberActivePeer";
const Rk = /* @__PURE__ */ g(() => {
  const {
    width: e
  } = wn(), {
    currentMeetSpaceMembersExceptMe: n,
    video: i
  } = I({
    currentMeetSpaceMembersExceptMe: Va,
    video: ce
  }), a = I(an), {
    meSpeaking: t,
    peersSpeaking: l
  } = I({
    meSpeaking: ro,
    peersSpeaking: mo
  });
  return /* @__PURE__ */ d(ga, {
    horizontal: !0,
    height: {
      base: 96,
      lg: 112
    },
    children: /* @__PURE__ */ f(Je, {
      alignItems: "center",
      justifyContent: "center",
      minWidth: e,
      px: {
        base: "4px",
        lg: "8px"
      },
      space: {
        base: "4px",
        lg: "8px"
      },
      children: [(a == null ? void 0 : a.peer) != null ? /* @__PURE__ */ d(ma, {
        member: a.member,
        myStream: Br(i),
        peer: a.peer,
        speaking: t
      }) : null, n.slice(1).filter((o) => o.peer != null).map((o) => /* @__PURE__ */ d(ma, {
        member: o.member,
        peer: o.peer,
        speaking: l.ref.get(o.peer.id) ?? !1
      }, o.member.id))]
    })
  });
});
Rk.displayName = "UsersListMobileWithPreviews";
const ma = /* @__PURE__ */ g(({
  member: e,
  myStream: n,
  peer: i,
  speaking: a
}) => {
  var v;
  const t = mn(), l = (i.media.video ?? Re.MediaVideoOff) !== Re.MediaVideoOff, o = L(() => ({
    style: {
      ...si.rtcView,
      ...t === "base" ? si.rtcViewBase : (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        t === "lg" ? si.rtcViewLG : {}
      ),
      ...l ? {} : si.hidden
    }
  }), [t, l]), s = n ?? i.stream, r = E(() => {
    e != null && $i(e.id);
  }, [e]), c = ((v = e == null ? void 0 : e.user) == null ? void 0 : v.avatar.uri) ?? "", u = L(() => c !== "" ? c : fi((e == null ? void 0 : e.id) ?? i.id), [e == null ? void 0 : e.id, i.id, c]);
  return /* @__PURE__ */ f(wo, {
    children: [/* @__PURE__ */ d(w, {
      borderColor: a ? "blue.50" : "gray.800",
      borderRadius: "12",
      borderStyle: "solid",
      borderWidth: a ? "2" : "1",
      bottom: 0,
      left: 0,
      opacity: 1,
      position: "absolute",
      right: 0,
      top: 0,
      zIndex: 2
    }), /* @__PURE__ */ f(te, {
      flex: "1",
      onPress: r,
      testID: n != null ? "ULPItem-Pressable-me" : "ULPItem-Pressable",
      children: [i.reaction.gesture === bn ? /* @__PURE__ */ d(Bi, {}) : null, /* @__PURE__ */ d(yi, {
        muted: s == null,
        objectFit: "contain",
        stream: Qe.OS === "web" ? s : void 0,
        streamURL: Qe.OS !== "web" ? s == null ? void 0 : s.toURL() : void 0,
        videoProps: o
      }), l ? null : /* @__PURE__ */ d(_o, {
        alt: Sa(e),
        uri: u
      }), /* @__PURE__ */ d(Eo, {
        children: n != null ? Qo(e) : nn(e)
      })]
    })]
  });
});
ma.displayName = "ULPItem";
const wo = /* @__PURE__ */ g((e) => /* @__PURE__ */ d(w, {
  alignItems: "center",
  bg: "#141414",
  borderRadius: "6",
  height: "96px",
  justifyContent: "center",
  position: "relative",
  width: {
    base: "96px",
    lg: "172px"
  },
  ...e
}));
wo.displayName = "ULPContainer";
const _o = /* @__PURE__ */ g(({
  alt: e,
  uri: n
}) => /* @__PURE__ */ d(gi, {
  alt: e,
  bg: "#141414",
  borderRadius: {
    base: 12,
    lg: 6
  },
  h: {
    base: "96px",
    lg: "56px"
  },
  source: {
    uri: n
  },
  testID: "Image",
  w: {
    base: "96px",
    lg: "56px"
  }
}));
_o.displayName = "ULPImage";
const Eo = /* @__PURE__ */ g((e) => /* @__PURE__ */ d(b, {
  bg: "rgba(0, 0, 0, 0.64)",
  borderRadius: "16",
  bottom: {
    base: 1,
    lg: 2
  },
  color: "gray.400",
  ellipsizeMode: "tail",
  h: {
    base: "20px",
    lg: "32px"
  },
  left: {
    base: 1,
    lg: 2
  },
  lineHeight: {
    base: "20px",
    lg: "24px"
  },
  numberOfLines: 1,
  position: "absolute",
  px: {
    base: "6px",
    lg: "12px"
  },
  py: {
    base: 0,
    lg: 1
  },
  testID: "nickname",
  textAlign: "center",
  w: {
    base: "88px",
    // base: "64px",
    lg: "156px"
    // lg: "120px",
  },
  ...e
}));
Eo.displayName = "ULPNickname";
const si = _n.create({
  hidden: {
    display: "none"
  },
  rtcView: {
    flex: 1,
    minHeight: 0
  },
  rtcViewBase: {
    height: 96,
    width: 96
  },
  rtcViewLG: {
    height: 56,
    width: 56
  }
}), xk = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetSpaceVanityNames"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "vanity_name"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "list"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "VanityName"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "VanityName"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "VanityNameType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space_id"
        }
      }]
    }
  }]
}, bS = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetSpaceVanityNameRemove"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "name"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "vanity_name"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "remove"
                    },
                    arguments: [{
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "name"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "name"
                        }
                      }
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, Dk = /* @__PURE__ */ g(() => {
  const e = I(Ea);
  return e.length > 0 ? /* @__PURE__ */ d(Bn, {
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    children: e.map((n) => /* @__PURE__ */ d(Mt, {
      left: 0,
      position: "absolute",
      right: 0,
      status: n.status,
      top: 0,
      zIndex: 1,
      children: /* @__PURE__ */ d(Bn, {
        flexShrink: 1,
        space: 2,
        w: "100%",
        children: /* @__PURE__ */ f(Je, {
          flexShrink: 1,
          justifyContent: "space-between",
          space: 2,
          children: [/* @__PURE__ */ f(Je, {
            flexShrink: 1,
            space: 2,
            children: [/* @__PURE__ */ d(Mt.Icon, {
              mt: "1"
            }), /* @__PURE__ */ d(b, {
              color: "gray.800",
              fontSize: "md",
              children: n.message
            })]
          }), /* @__PURE__ */ d(ls, {
            _focus: {
              borderWidth: 0
            },
            _icon: {
              color: "coolGray.600"
            },
            icon: /* @__PURE__ */ d(Jt, {
              size: "3"
            }),
            onPress: () => {
              fd(n.id);
            },
            variant: "unstyled",
            zIndex: 3
          })]
        })
      })
    }, String(n.message)))
  }) : null;
});
Dk.displayName = "Alerts";
const Hi = /* @__PURE__ */ g(({
  active: e,
  Icon: n,
  onPress: i
}) => {
  const [a, t] = H(!1), l = L(() => a || e ? "white" : ze.colors.gray[400], [e, a]), o = L(() => a || e ? "rgba(255, 255, 255, .12)" : "transparent", [e, a]);
  return /* @__PURE__ */ d(He, {
    _focus: {
      bg: o
    },
    _hover: {
      bg: o
    },
    _pressed: {
      bg: o
    },
    bg: o,
    borderRadius: "32",
    h: "48px",
    onHoverIn: () => t(!0),
    onHoverOut: () => t(!1),
    onPress: i,
    shadow: "1",
    w: "48px",
    children: /* @__PURE__ */ d(n, {
      fill: l,
      height: 24,
      width: 24
    })
  });
});
Hi.displayName = "CallRoomControlButton";
const Ik = /* @__PURE__ */ g(() => {
  const e = I(jm), n = E(() => {
    Zn();
  }, []), i = I(xn);
  return /* @__PURE__ */ d(Hi, {
    Icon: e ? xs : ad,
    active: i === re.Chat,
    onPress: n
  });
});
Ik.displayName = "ControlChatToggle";
const Ak = /* @__PURE__ */ g(() => {
  const e = E(() => {
    Oa();
  }, []), n = I(Vd);
  return /* @__PURE__ */ d(Hi, {
    Icon: Ds,
    active: n,
    onPress: e
  });
});
Ak.displayName = "ControlReaction";
const Vk = /* @__PURE__ */ g(() => {
  const e = E(() => {
    hd();
  }, []), n = I(xn);
  return /* @__PURE__ */ d(Hi, {
    Icon: Is,
    active: n === re.Chat,
    onPress: e
  });
});
Vk.displayName = "ControlSettingsToggle";
const Ok = /* @__PURE__ */ g(() => {
  const e = I(fe), [n, i] = H(!1), a = me(), t = I(xn), l = E(() => {
    Fd();
  }, []), o = L(() => n || t === re.UserList ? "white" : a.colors.gray[400], [t, n, a.colors.gray]), s = L(() => n || t === re.UserList ? "rgba(255, 255, 255, .12)" : "transparent", [t, n]);
  return /* @__PURE__ */ d(w, {
    position: "relative",
    children: /* @__PURE__ */ f(Yn, {
      alignItems: "center",
      bg: s,
      flexDirection: "row",
      height: "12",
      onHoverIn: () => i(!0),
      onHoverOut: () => i(!1),
      onPress: l,
      px: "4",
      width: "auto",
      children: [
        /* @__PURE__ */ d(As, {
          fill: o,
          height: "24",
          width: "24"
        }),
        e.length > 0 ? /* @__PURE__ */ d(b, {
          color: o,
          fontSize: "14",
          fontWeight: "500",
          ml: "2",
          children: e.length
        }) : /* @__PURE__ */ d(b, {})
        /* пустой элемент чтобы не вылетала typescript ошибка */
      ]
    })
  });
});
Ok.displayName = "ControlUsersListToggle";
const Pk = /* @__PURE__ */ g(({
  meetSpace: e
}) => {
  const {
    t: n
  } = ie(), {
    onCopy: i
  } = os(), a = ss(), t = E(() => (a.show({
    description: n("ROOM.ROOM_LINK_COPIED_TO_CLIPBOARD")
  }), i(qt(e))), [e, i, n, a]);
  return /* @__PURE__ */ d(rn, {
    onPress: t,
    children: n("ROOM.COPY_LINK")
  });
});
Pk.displayName = "MenuItemCopyRoomLink";
const Lk = /* @__PURE__ */ g(({}) => {
  const {
    t: e
  } = ie();
  return /* @__PURE__ */ d(rn, {
    onPress: Uk,
    children: e("ROOM.CREATE_VOTING", {
      defaultValue: "Создать голосование"
    })
  });
});
Lk.displayName = "MenuItemCreateVote";
const Uk = () => {
  jn(!0);
}, $k = /* @__PURE__ */ g(() => {
  const {
    t: e
  } = ie(), {
    currentMeetSpaceMyPermissions: n,
    isRecording: i
  } = I({
    currentMeetSpaceMyPermissions: tn,
    isRecording: ut
  }), a = E(() => ct(), []);
  return n.has(Y.PermissionChannelControl) ? /* @__PURE__ */ d(rn, {
    onPress: a,
    children: e(i ? "ROOM.RECORDING.MENU_ITEM_STOP" : "ROOM.RECORDING.MENU_ITEM_START")
  }) : null;
});
$k.displayName = "MenuItemMeetRecording";
const Bk = /* @__PURE__ */ g(({}) => {
  const {
    t: e
  } = ie();
  return /* @__PURE__ */ d(rn, {
    onPress: zk,
    children: e("ROOM.ADD_RESPONSE", {
      defaultValue: "Отправить отклик"
    })
  });
});
Bk.displayName = "MenuItemSendReaction";
const zk = () => {
  Oa();
}, Ft = /* @__PURE__ */ g(({
  hasAudioEnabled: e,
  hasVideoEnabled: n,
  small: i
}) => {
  const a = me();
  return /* @__PURE__ */ f(sn, {
    bottom: i ? void 0 : 2,
    position: "absolute",
    right: "2",
    top: i ? 2 : void 0,
    children: [/* @__PURE__ */ d(w, {
      alignItems: "center",
      bg: "rgba(0, 0, 0, 0.64)",
      borderRadius: "16",
      h: "32px",
      justifyContent: "center",
      w: "32px",
      children: e ? /* @__PURE__ */ d(ya, {
        fill: a.colors.gray[500],
        height: "16",
        width: "16"
      }) : /* @__PURE__ */ d(td, {
        fill: a.colors.gray[500],
        height: "16",
        width: "16"
      })
    }), /* @__PURE__ */ d(w, {
      alignItems: "center",
      bg: "rgba(0, 0, 0, 0.64)",
      borderRadius: "16",
      h: "32px",
      justifyContent: "center",
      w: "32px",
      children: n ? /* @__PURE__ */ d(id, {
        fill: a.colors.gray[500],
        height: "16",
        width: "16"
      }) : /* @__PURE__ */ d(Vs, {
        fill: a.colors.gray[500],
        height: "16",
        width: "16"
      })
    })]
  });
});
Ft.displayName = "PeerMediaIndicators";
const gt = /* @__PURE__ */ g(({
  children: e,
  fullscreen: n,
  height: i,
  onPress: a,
  speaking: t,
  width: l
}) => /* @__PURE__ */ d(te, {
  flex: n ? 1 : void 0,
  height: n ? "auto" : i,
  onPress: a,
  p: {
    base: 1,
    lg: 2
  },
  testID: "VideoContainer",
  width: n ? "auto" : l,
  children: /* @__PURE__ */ f(hi, {
    bg: "#141414",
    borderRadius: "12",
    flex: "1",
    position: "relative",
    zIndex: "3",
    children: [/* @__PURE__ */ d(w, {
      borderColor: t ? "blue.50" : "gray.800",
      borderRadius: "12",
      borderStyle: "solid",
      borderWidth: t ? "2" : "1",
      bottom: "0",
      left: "0",
      position: "absolute",
      right: "0",
      top: "0"
    }), e]
  })
}));
gt.displayName = "VideoContainer";
const jk = /* @__PURE__ */ g(({
  cardHeight: e,
  cardWidth: n,
  enableVideoRendering: i,
  fullscreen: a,
  hasAudioEnabled: t,
  hasVideoEnabled: l,
  hideConnectionDetails: o,
  isHidden: s,
  memberId: r,
  peerId: c,
  reaction: u,
  small: v = !1,
  speaking: m,
  stream: p
}) => {
  var T;
  const {
    data: k
  } = qn(Mi, {
    skip: r == null,
    variables: {
      id: r
    }
  }), F = k == null ? void 0 : k.meet.member.get, S = E(() => {
    r != null && $i(r);
  }, [r]), N = L(() => ({
    style: {
      ...ri.rtcView,
      ...l ? {} : ri.hidden
    }
  }), [l]), y = nn(F);
  return /* @__PURE__ */ f(gt, {
    fullscreen: a ?? !1,
    height: e,
    onPress: S,
    speaking: m,
    width: n,
    children: [(u == null ? void 0 : u.gesture) === bn ? /* @__PURE__ */ d(Bi, {}) : null, l ? null : /* @__PURE__ */ d(nd, {
      nickname: y,
      uri: (T = F == null ? void 0 : F.user) == null ? void 0 : T.avatar.uri
      /* ?? makeGravatarUri(peerId) */
      // showName={!isMe}
    }), i && /* @__PURE__ */ d(
      yi,
      {
        objectFit: "contain",
        stream: Qe.OS === "web" ? p : void 0,
        streamURL: Qe.OS !== "web" ? p.toURL() : void 0,
        style: [ri.rtcView, !l || s ? ri.hidden : void 0],
        videoProps: N
      }
    ), l && /* @__PURE__ */ d(w, {
      bottom: "2",
      flexDirection: "row",
      justifyContent: "center",
      left: v ? 0 : "48px",
      position: "absolute",
      right: v ? 0 : "48px",
      zIndex: "3",
      children: /* @__PURE__ */ d(b, {
        bg: "rgba(0, 0, 0, .64)",
        borderRadius: "32",
        color: "gray.400",
        ellipsizeMode: "tail",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "24",
        maxWidth: "100%",
        numberOfLines: 1,
        px: "4",
        py: "1",
        children: y
      })
    }), /* @__PURE__ */ d(ft, {
      peerId: c,
      showDetails: !o && (l || t),
      small: v
    }), /* @__PURE__ */ d(Ft, {
      hasAudioEnabled: t,
      hasVideoEnabled: l,
      small: v
    })]
  });
});
jk.displayName = "PeerCard";
const ri = _n.create({
  hidden: {
    display: "none"
  },
  rtcView: {
    height: "100%",
    width: "100%"
  }
}), Hk = /* @__PURE__ */ g(({
  avatarUri: e,
  cardHeight: n,
  cardWidth: i,
  enableVideoRendering: a,
  fullscreen: t,
  hasAudioEnabled: l,
  hasVideoEnabled: o,
  hideConnectionDetails: s,
  isHidden: r,
  myPeer: c,
  nickname: u,
  small: v = !1,
  speaking: m,
  stream: p
}) => {
  const {
    t: k
  } = ie(), F = me(), S = E(() => {
    (c == null ? void 0 : c.id) != null && $i(c.id);
  }, [c == null ? void 0 : c.id]), N = L(() => ({
    style: {
      ...Ln.rtcView,
      ...o ? {} : Ln.hidden
    }
  }), [o]), y = u !== "" ? u : k("ROOM.ANONYMOUS_USER"), T = L(() => [Ln.rtcView, !o || r ? Ln.hidden : void 0], [o, r]);
  return /* @__PURE__ */ f(gt, {
    fullscreen: t ?? !1,
    height: n,
    onPress: S,
    speaking: m,
    width: i,
    children: [(c == null ? void 0 : c.reaction.gesture) === bn ? /* @__PURE__ */ d(Bi, {}) : null, o ? null : /* @__PURE__ */ d(nd, {
      nickname: y,
      small: v,
      uri: e
      // showName={!isMe}
    }), a && /* @__PURE__ */ d(yi, {
      muted: !0,
      objectFit: "contain",
      stream: Qe.OS === "web" ? p : void 0,
      streamURL: Qe.OS !== "web" ? p.toURL() : void 0,
      style: T,
      videoProps: N
    }), o && // <Text
    //   color="white"
    //   fontSize={{
    //     base: 20,
    //     lg: 24,
    //   }}
    //   fontWeight={500}
    //   lineHeight={24}
    //   m={6}
    //   textAlign="center"
    // >
    //   {nickname1}
    // </Text>
    /* @__PURE__ */ f(w, {
      bottom: "2",
      flexDirection: "row",
      justifyContent: "center",
      left: "48px",
      position: "absolute",
      right: "48px",
      zIndex: "3",
      children: [(c == null ? void 0 : c.reaction.gesture) === bn ? /* @__PURE__ */ d(w, {
        mr: "2",
        children: /* @__PURE__ */ d(Ta, {
          fill: F.colors.gray[500],
          height: "16",
          style: Ln.iconRaisedHand,
          width: "16"
        })
      }) : null, /* @__PURE__ */ d(b, {
        bg: "rgba(0, 0, 0, .64)",
        borderRadius: "32",
        color: "gray.400",
        ellipsizeMode: "tail",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "24",
        maxWidth: "100%",
        numberOfLines: 1,
        px: "4",
        py: "1",
        children: y
      })]
    }), /* @__PURE__ */ d(ft, {
      showDetails: !s && (l || o),
      small: v
    }), /* @__PURE__ */ d(Ft, {
      hasAudioEnabled: l,
      hasVideoEnabled: o,
      small: v
    })]
  });
});
Hk.displayName = "SelfPeerCard";
const Ln = _n.create({
  hidden: {
    display: "none"
  },
  iconRaisedHand: {
    marginRight: 8
  },
  rtcView: {
    aspectRatio: 1,
    height: "100%",
    minHeight: 0,
    minWidth: 0,
    objectFit: "contain",
    transform: [{
      scaleX: -1
    }],
    width: "100%"
  }
}), Ro = /* @__PURE__ */ g(({
  isOrganizer: e,
  member: n
}) => {
  var i;
  return /* @__PURE__ */ d(Gn, {
    hideControls: !0,
    avatarUri: ((i = n.user) == null ? void 0 : i.avatar.uri) ?? fi(n.id),
    isOrganizer: e,
    nickname: nn(n)
  });
});
Ro.displayName = "MeetMemberInactive";
const Wk = /* @__PURE__ */ g(() => {
  var p;
  const {
    t: e
  } = ie(), n = Fi(), {
    anonymousNickname: i,
    audio: a,
    currentMeetSpace: t,
    currentMeetSpaceMembersExceptMe: l,
    currentMeetSpaceMyPermissions: o,
    meetSpaceUsersListData: s,
    video: r
  } = I({
    anonymousNickname: pa,
    audio: Pe,
    currentMeetSpace: Ri,
    currentMeetSpaceMembersExceptMe: Va,
    currentMeetSpaceMyPermissions: tn,
    meetSpaceUsersListData: ru,
    video: ce
  });
  Q(() => {
    $t({
      distanceFromEnd: 5
    });
  }, []);
  const c = L(() => [{
    data: [n != null ? {
      me: n,
      type: "me"
    } : {
      anonymousNickname: i,
      type: "me_anon"
    }, ...l.filter((k) => k.member.presence_state === zo.MemberPresenceStateOnline && k.peer != null).map((k) => ({
      member: k.member,
      peer: k.peer,
      type: "peer"
    }))],
    title: `${e("ROOM.USERSLIST.MEMBERS_COUNT", {
      defaultValue: "Участников"
    })} (${l.length + 1})`
  }, {
    data: s.map((k) => ({
      member: k,
      type: "not_on_call"
    })),
    title: "Оффлайн"
  }], [n, i, l, e, s]), u = o.has(Y.PermissionChannelMediaControl), v = E(({
    item: k
  }) => {
    var F, S, N, y;
    return k.type === "me" ? /* @__PURE__ */ d(Gn, {
      audioDisabled: !a.enabled,
      avatarUri: k.me.avatar.uri,
      isOrganizer: k.me.id === ((F = t == null ? void 0 : t.owner) == null ? void 0 : F.id),
      nickname: k.me.nickname,
      onAudioClick: () => {
      },
      permissions: o,
      videoDisabled: r.state === "initial" || r.state === "video-track-ended" || !r.enabled
    }, k.me.id) : k.type === "me_anon" ? /* @__PURE__ */ d(Gn, {
      audioDisabled: !a.enabled,
      avatarUri: "",
      isOrganizer: !1,
      nickname: k.anonymousNickname ?? "",
      onAudioClick: () => {
      },
      permissions: o,
      videoDisabled: r.state === "initial" || r.state === "video-track-ended" || !r.enabled
    }, "anon") : k.type === "peer" ? /* @__PURE__ */ d(Co, {
      canControlUser: u,
      isOrganizer: k.peer.id === ((S = t == null ? void 0 : t.owner) == null ? void 0 : S.id),
      member: k.member,
      peer: k.peer
    }, k.peer.id) : /* @__PURE__ */ d(Ro, {
      isOrganizer: ((N = t == null ? void 0 : t.owner) == null ? void 0 : N.id) === ((y = k.member.user) == null ? void 0 : y.id),
      member: k.member
    });
  }, [a, u, (p = t == null ? void 0 : t.owner) == null ? void 0 : p.id, o, r]), m = L(() => ({
    currentMeetSpaceMembershipInfo: t
  }), [t]);
  return /* @__PURE__ */ d(w, {
    flex: "1",
    flexDirection: "column",
    py: "5",
    children: /* @__PURE__ */ d(rs, {
      extraData: m,
      keyExtractor: Gk,
      onEndReached: (k) => {
        $t(k);
      },
      renderItem: v,
      renderSectionHeader: ({
        section: k
      }) => /* @__PURE__ */ d(b, {
        color: "text.100",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "24",
        my: "2",
        px: "4",
        children: k.title
      }),
      sections: c
    })
  });
});
Wk.displayName = "UsersList";
const Gk = (e, n) => ((e.type === "me" ? e.me.id : e.type === "me_anon" ? e.anonymousNickname : e.type === "not_on_call" ? e.member.id : e.peer.id) ?? "empty") + n;
var ye = "/src/processes/meet/index.ts";
const gn = h({
  loc: {
    file: ye,
    line: 44,
    column: 2
  },
  name: "eventUserSubscriptionAuthenticationChanged",
  sid: "-e8o6dl:/src/processes/meet/index.ts:eventUserSubscriptionAuthenticationChanged"
}), xo = h({
  loc: {
    file: ye,
    line: 46,
    column: 52
  },
  name: "eventUserSubscriptionMyPeerIdUpdated",
  sid: "b43djc:/src/processes/meet/index.ts:eventUserSubscriptionMyPeerIdUpdated"
});
yr.on(xo, (e, n) => he(n));
const Do = h({
  loc: {
    file: ye,
    line: 51,
    column: 49
  },
  name: "eventUserSubscriptionContactAdded",
  sid: "-98cxqg:/src/processes/meet/index.ts:eventUserSubscriptionContactAdded"
}), Io = h({
  loc: {
    file: ye,
    line: 52,
    column: 51
  },
  name: "eventUserSubscriptionContactRemoved",
  sid: "ji6wlc:/src/processes/meet/index.ts:eventUserSubscriptionContactRemoved"
});
_({
  and: [{
    clock: $n,
    filter: (e) => e.status === ka.Success,
    target: Ca
  }],
  or: {
    loc: {
      file: ye,
      line: 54,
      column: 0
    },
    sid: "-lmsyem:/src/processes/meet/index.ts:"
  }
});
Kt.on(gn, (e, n) => n);
Su.on(Do, (e, n) => [...e, n].sort((i, a) => i.nickname.localeCompare(a.nickname))).on(Io, (e, n) => e.filter((i) => i.id !== n));
const qk = $(() => Z().subscribe({
  query: uu
}).subscribe({
  error: (e) => {
    gn(ua(e));
  },
  next: (e) => {
    var n, i, a, t, l;
    e.data != null && (((n = e.data.user.authentication) == null ? void 0 : n.anonymous) != null && gn(he({
      type: "anonymous"
    })), ((i = e.data.user.authentication) == null ? void 0 : i.authenticated) != null && gn(he({
      type: "authenticated",
      user: e.data.user.authentication.authenticated.user
    })), e.data.user.init != null && xo(e.data.user.init.peer_id), ((a = e.data.user.contact) == null ? void 0 : a.add) != null && Do(e.data.user.contact.add), ((t = e.data.user.contact) == null ? void 0 : t.remove) != null && Io(e.data.user.contact.remove), ((l = e.data.user.meet) == null ? void 0 : l.space) != null);
  },
  start: () => {
    gn($o());
  }
}), {
  loc: {
    file: ye,
    line: 73,
    column: 39
  },
  name: "subscribeToUserEventsFx",
  sid: "-q802y8:/src/processes/meet/index.ts:subscribeToUserEventsFx"
}), yS = Uo(qk.doneData, null, {
  loc: {
    file: ye,
    line: 116,
    column: 33
  },
  name: "$userSubscription",
  sid: "gu2fbt:/src/processes/meet/index.ts:$userSubscription"
}), Ao = $(() => Z().query({
  fetchPolicy: "network-only",
  query: ku
}).then((e) => e.data.user.meet.list), {
  loc: {
    file: ye,
    line: 121,
    column: 37
  },
  name: "fetchUserMeetSpacesFx",
  sid: "-lsu3gk:/src/processes/meet/index.ts:fetchUserMeetSpacesFx"
});
_({
  and: [{
    clock: gn,
    filter: (e) => Se((n) => n.type === "authenticated", !1, e),
    target: Ao
  }],
  or: {
    loc: {
      file: ye,
      line: 126,
      column: 0
    },
    sid: "-jts7lo:/src/processes/meet/index.ts:"
  }
});
Aa.on(Ao.doneData, (e, n) => he({
  ref: Se((i) => (n.spaces.forEach((a) => {
    i.ref.set(a.space.id, a.space);
  }), i.ref), /* @__PURE__ */ new Map(), e)
}));
const Xk = _({
  and: [{
    clock: Ra,
    source: {
      currentMeetSpaceMemberMe: an
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => {
      var i;
      return ((i = n.remove) == null ? void 0 : i.members) != null ? n.remove.members.find((a) => {
        var t, l;
        return a.id === ((l = (t = e.currentMeetSpaceMemberMe) == null ? void 0 : t.member) == null ? void 0 : l.id);
      }) ?? null : null;
    }
  }],
  or: {
    loc: {
      file: ye,
      line: 147,
      column: 44
    },
    name: "eventSubscriptionDisconnectReceived",
    sid: "3ugf1v:/src/processes/meet/index.ts:eventSubscriptionDisconnectReceived"
  }
}).filter({
  fn: Mn
});
_({
  and: [{
    clock: Xk,
    source: {
      audio: Pe,
      peers: fe,
      rtcPeerConnection: K,
      video: ce
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn(e, n) {
      return {
        ...e,
        exitReason: Yk(n.source)
      };
    },
    target: Le
  }],
  or: {
    loc: {
      file: ye,
      line: 163,
      column: 0
    },
    sid: "-hz93sz:/src/processes/meet/index.ts:"
  }
});
_({
  and: [{
    clock: vi,
    source: {
      audio: Pe,
      peers: fe,
      rtcPeerConnection: K,
      video: ce
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn(e, n) {
      return {
        ...e,
        exitReason: n
      };
    },
    target: Le
  }],
  or: {
    loc: {
      file: ye,
      line: 181,
      column: 0
    },
    sid: "-h29g7b:/src/processes/meet/index.ts:"
  }
});
function Yk(e) {
  return e.ban != null ? {
    message: e.ban.reason,
    type: Ye.Ban
  } : e.kick != null ? {
    message: e.kick.reason,
    type: Ye.Kick
  } : e.conflict != null ? {
    message: e.conflict.device_id != null ? x("ROOM.EXIT_REASON.DEVICE_CONFLICT") : e.conflict.user_id != null ? x("ROOM.EXIT_REASON.USER_CONFLICT") : x("ROOM.EXIT_REASON.GENERIC_DISCONNECT"),
    type: Ye.DisconnectedFromBackend
  } : {
    message: x("ROOM.EXIT_REASON.GENERIC_DISCONNECT"),
    type: Ye.DisconnectedFromBackend
  };
}
export {
  Er as $activeVotesCount,
  Ea as $alerts,
  xn as $asidePanel,
  Pe as $audio,
  nm as $audioMode,
  em as $audioPending,
  Ul as $chatContextMenuOpened,
  jm as $chatHasNewMessages,
  wl as $chatMessages,
  kn as $connectionState,
  Qv as $controlsOpened,
  Ri as $currentMeetSpace,
  an as $currentMeetSpaceMe,
  ei as $currentMeetSpaceMembers,
  Va as $currentMeetSpaceMembersExceptMe,
  tn as $currentMeetSpaceMyPermissions,
  Sn as $currentMessage,
  Qn as $currentSpaceId,
  so as $data,
  Di as $devices,
  Ci as $fatalErrors,
  fl as $fileAttachments,
  Ja as $fileAttachmentsInternal,
  kS as $focusedPeer,
  In as $iceConnectionState,
  qa as $iceGatheringState,
  Xa as $iceServers,
  oS as $isOffering,
  ut as $isRecording,
  Rr as $isVoteCreateFormVisible,
  Mm as $linkQuality,
  eS as $loading,
  ro as $meSpeaking,
  fS as $meetSpaceUserListCursor,
  ru as $meetSpaceUsersListData,
  Aa as $meetSpacesBySpaceId,
  Ia as $meetSpacesMembershipBySpaceId,
  Dd as $meetSubscriptionStatus,
  Wm as $messageDeleteId,
  $r as $microphoneDevices,
  Su as $myContacts,
  yr as $myPeerId,
  Ad as $peerIdByTrackId,
  ji as $peerStats,
  fe as $peers,
  mo as $peersSpeaking,
  Nu as $peersWithActiveAudioTrack,
  Pi as $receivedMessages,
  Vl as $receivedMessagesEditedAt,
  vr as $roomExitReason,
  Vd as $roomReactionPanelOpened,
  Cr as $roomReactions,
  K as $rtcPeerConnection,
  pi as $selectedBackgroundType,
  La as $selectedCamera,
  Qr as $selectedMicrophone,
  Zr as $selectedSpeakers,
  cS as $sendingMessage,
  il as $showNewAudioDeviceSelect,
  sr as $showRateACallModal,
  dS as $showVirtualBackgroundCanvas,
  Dn as $signalingState,
  tS as $speakerDevices,
  Cl as $unconfirmedMessages,
  yS as $userSubscription,
  ce as $video,
  Ur as $videoDevices,
  Kr as $videoIsOn,
  Zd as $videoPending,
  Bd as $votes,
  Ii as $webrtcInitialized,
  lS as $webrtcUserFriendlyConnectionStatus,
  Dk as Alerts,
  re as AsidePanelItem,
  Ni as AttachedFile,
  pu as AudioDeviceChangedModal,
  xr as CallsView,
  Fc as ChatAddAttachments,
  Lc as ChatBody,
  ot as ChatBodyPanel,
  xm as ChatDeleteMessageDocument,
  Rm as ChatEditMessageDocument,
  da as ChatItemText,
  la as ChatItemTime,
  $c as ChatMessageDelete,
  zl as ChatMessageEditPreview,
  xv as ChatMessageEmbedFragmentDoc,
  Rv as ChatMessageEmbedPreviewFragmentDoc,
  Iv as ChatMessageFragmentDoc,
  rt as ChatMessageItem,
  Bc as ChatMobileHead,
  jc as ChatPanel,
  Hc as ChatPanelHead,
  Dm as ChatReactToMessageDocument,
  Dt as ChatSendMessageDocument,
  Im as ChatUnreactToMessageDocument,
  tt as ContextMenuOverlay,
  zu as ControlActiveVotesToggle,
  rS as ControlAudio,
  mS as ControlCamera,
  Cm as ControlChangeCamera,
  Ik as ControlChatToggle,
  Dr as ControlHangup,
  wm as ControlMode,
  Ak as ControlReaction,
  Em as ControlScreenShare,
  Vk as ControlSettingsToggle,
  Ok as ControlUsersListToggle,
  dk as CreateVote,
  Pa as DEFAULT_CAMERA,
  Pd as EMOJI_LIFETIME,
  Wc as EditMeetSpace,
  oo as EditShortMeetSpaceSettings,
  Ev as EmbeddedChatMessageFragmentDoc,
  Mi as FetchMemberByIdDocument,
  jl as FilePicker,
  nu as FlyingReactions,
  Lv as IceServersFragmentDoc,
  mt as InviteToCallButton,
  Gc as InviteToCallCard,
  pe as LinkQuality,
  Hv as MeeetEventSpaceFragmentDoc,
  ir as MeetDocument,
  Av as MeetEventChatFragmentDoc,
  Dv as MeetEventChatMessageEditFragmentDoc,
  Ov as MeetEventMemberFragmentDoc,
  Uv as MeetEventRtcFragmentDoc,
  Bv as MeetEventSelfFragmentDoc,
  Xv as MeetEventUserFragmentDoc,
  Kv as MeetEventVoteFragmentDoc,
  lr as MeetFetchVotesDocument,
  Co as MeetMemberActivePeer,
  Vv as MeetMemberFragmentDoc,
  $v as MeetMemberSpaceFragmentDoc,
  cd as MeetMySpacesListDocument,
  NS as MeetRecordingDocument,
  au as MeetRecordingStartDocument,
  tu as MeetRecordingStopDocument,
  pS as MeetRecordingsListDocument,
  ud as MeetSpaceByIdOrVanityNameDocument,
  zv as MeetSpaceConditionsContactsFragmentDoc,
  Ye as MeetSpaceExitReason,
  jv as MeetSpaceFragmentDoc,
  qv as MeetSpaceLeaveDocument,
  ar as MeetSpaceMemberControlDocument,
  Vr as MeetSpaceMinHeightLG,
  Kc as MeetSpaceReaction,
  Gv as MeetSpaceRemoveDocument,
  du as MeetSpaceRuntimeSettings,
  kd as MeetSpaceSaveDocument,
  bS as MeetSpaceVanityNameRemoveDocument,
  xk as MeetSpaceVanityNamesDocument,
  tr as MeetUpdateRtcReactionDocument,
  or as MeetVoteCastDocument,
  Yv as MeetVoteFragmentDoc,
  vd as MeetVoteStartDocument,
  Jv as MeetVoteTerminateDocument,
  Pk as MenuItemCopyRoomLink,
  Lk as MenuItemCreateVote,
  $k as MenuItemMeetRecording,
  iu as MenuItemRaiseHand,
  Bk as MenuItemSendReaction,
  lt as MessageInputPanel,
  Jl as MessagesList,
  dr as MyMeetCalendarDocument,
  Lr as PasswordModal,
  jk as PeerCard,
  Ft as PeerMediaIndicators,
  Bi as RaiseHandPopup,
  cu as RateACallModal,
  SS as RecordingFragmentDoc,
  vS as RecordingTranscriptFragmentDoc,
  nr as RequestChatHistoryDocument,
  Ar as RoomChatWidth,
  Ir as RoomControlsHeight,
  Xi as RoomReactionHeight,
  Pv as RtcMemberFragmentDoc,
  Hk as SelfPeerCard,
  Gr as SendAnswerDocument,
  Hr as SendIceCandidateDocument,
  Wr as SendOfferDocument,
  To as SettingsCameraForm,
  wk as SettingsMicrophone,
  ft as StatsPopover,
  qd as UpdateMyMediaStatusDocument,
  uu as UserDocument,
  ku as UserMeetSpacesDocument,
  Wk as UsersList,
  _k as UsersListDesktop,
  Ek as UsersListFooter,
  Gn as UsersListItemView,
  Rk as UsersListMobileWithPreviews,
  Wv as VanityNameFragmentDoc,
  gt as VideoContainer,
  Bu as VirtualBackgroundViewer,
  Fo as VoteListItem,
  hk as VotesPanel,
  $e as WebRTCConnectionStatus,
  ml as addIceCandidateFx,
  un as audioStateReducerFx,
  bu as backgroundSettingsSchema,
  Ud as castVoteFx,
  Ai as closeRTCPeertConnectionFx,
  hr as closeSubscriptionFx,
  Xr as defaultCameraSchema,
  it as deleteMessageFx,
  Rt as endVoteFx,
  Sr as eventAsidePanelClosed,
  $a as eventAudioModeChanged,
  Ba as eventAudioTrackEnded,
  pd as eventCallRatedSuccessfully,
  Zv as eventCallViewFullToggle,
  Za as eventChatMessageReacted,
  yl as eventChatMessageUnreacted,
  nt as eventChatScrollEndReached,
  Ll as eventCloseContextMenu,
  mr as eventControlsToggle,
  xl as eventCurrentMesageChanged,
  na as eventCurrentMesageCursorPositionChanged,
  Dl as eventCurrentMesageEmojiInserted,
  zd as eventDeviceListChanged,
  jd as eventDeviceListInitialized,
  cr as eventError,
  Oi as eventFileAttachmentsAdded,
  kl as eventFileAttachmentsRemoved,
  vl as eventFileAttachmentsReset,
  Sl as eventFileAttachmentsUploadFailed,
  pl as eventFileAttachmentsUploadFinished,
  Nl as eventFileAttachmentsUploadProgressUpdated,
  wa as eventGraphqlSubscriptionComplete,
  rr as eventGraphqlSubscriptionStarted,
  fd as eventHideAlert,
  $t as eventLoadMoreMeetUsers,
  Ld as eventLoadMoreVotes,
  vt as eventMeSpeakingUpdate,
  Ne as eventMeetSpaceCleanup,
  Nd as eventMeetSpaceExitFinished,
  ue as eventMeetSpaceJoinRequested,
  Ca as eventMeetSpaceJoinedSuccessfully,
  vi as eventMeetSpaceLeave,
  Sd as eventMeetSpaceRestoreCall,
  Ed as eventMeetSubscriptionChatReceived,
  Ra as eventMeetSubscriptionMemberReceived,
  Cd as eventMeetSubscriptionRtcIceCandidateReceived,
  xa as eventMeetSubscriptionRtcInitReceived,
  Ei as eventMeetSubscriptionRtcMemberReceived,
  gr as eventMeetSubscriptionRtcMidiEventsReceived,
  wd as eventMeetSubscriptionRtcSDPReceived,
  Fr as eventMeetSubscriptionSelfInitReceived,
  _i as eventMeetSubscriptionSelfReceived,
  Rd as eventMeetSubscriptionSpaceReceived,
  $n as eventMeetSubscriptionStatusUpdated,
  _d as eventMeetSubscriptionVoteReceived,
  $i as eventMemberFocused,
  Bl as eventMessageDeleteReset,
  $l as eventMessageDeleteSelected,
  hl as eventMessageEditReset,
  Fl as eventMessageEditSelected,
  gl as eventMessageReplySelected,
  el as eventMicrophoneIconPressed,
  Ga as eventNegotiationNeeded,
  _a as eventNewAlert,
  za as eventNewAudioDeviceSelected,
  nl as eventNewAudioDeviceSelectionCancelled,
  fm as eventOfferingStateUpdated,
  ia as eventOpenContextMenu,
  Ha as eventRTCConnectionStateUpdated,
  Wa as eventRTCIceConnectionStateUpdated,
  sl as eventRTCIceGatheringStateUpdated,
  ol as eventRTCSignalingStateUpdated,
  Pl as eventResetChatHasNewMessages,
  Hm as eventResetContextMenu,
  xd as eventRestartSubscription,
  Tr as eventRoomReactionPanelReset,
  Oa as eventRoomReactionPanelToggle,
  Mr as eventRoomReactionReset,
  Od as eventRoomReactionUpdate,
  Kd as eventScreenSharingIconPressed,
  Ml as eventSendMessage,
  kt as eventSpeakingUpdate,
  Nt as eventStatsUpdated,
  Rl as eventSubscriptionChatEditMessagesReceived,
  Bm as eventSubscriptionChatReactionCountReceived,
  zm as eventSubscriptionChatReactionSelfReceived,
  _l as eventSubscriptionChatRemoveMessagesReceived,
  El as eventSubscriptionChatSentMessagesReceived,
  Jd as eventSwitchToNextCamera,
  Yd as eventToggleCameraOnOff,
  Zn as eventToggleChat,
  Ol as eventToggleChatHasNewMessages,
  ct as eventToggleRecording,
  hd as eventToggleSettings,
  Fd as eventToggleUsersList,
  gd as eventToggleVotes,
  gn as eventUserSubscriptionAuthenticationChanged,
  Do as eventUserSubscriptionContactAdded,
  Io as eventUserSubscriptionContactRemoved,
  xo as eventUserSubscriptionMyPeerIdUpdated,
  Ua as eventVideoTrackEnded,
  Xd as eventVirtualBackgroundCreated,
  jn as eventVoteCreateFormVisible,
  wi as fetchMeetSpaceByIdOrVanityNameFx,
  Hn as fetchMessagesFromHistoryFx,
  Ao as fetchUserMeetSpacesFx,
  xi as fetchVotesFx,
  ul as formatMessage,
  Wd as getCameraStreamFromVideoState,
  fr as getRoomVariant,
  uS as getShowModalInitialState,
  Br as getVirtualStreamFromVideoState,
  Le as hangupFx,
  Md as mergePermissions,
  nS as postVoteFx,
  cl as processSDPFx,
  Vi as recreateRTCPeerConnectionAfterReconnectFx,
  rl as restartGraphQLConnectionFx,
  An as sendChatMessageFx,
  Fm as sendIceCandidateFx,
  bl as sendMessageReactionFx,
  Tl as sendMessageUnreactionFx,
  et as sendMessageUploadFilesFx,
  ea as sendOfferFx,
  ur as subscribeToFatalError,
  Id as subscribeToMeetFx,
  qk as subscribeToUserEventsFx,
  Qd as switchToNextCameraFx,
  Ka as updateAVStateFx,
  aS as useCardDimensions,
  hS as useConnectionStats,
  sS as useIsFullyConnected,
  gS as useMeSpeakingAnalyzer,
  iS as useMeetScreenDimensions,
  FS as useSpeakingAnalyzer,
  We as videoStateReducerFx
};
