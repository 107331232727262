import { $ as o } from "./apollo-1_umpas9.js";
import { b as x, m as T, i as h, k as D, j as v, c as F, a as L, F as S, L as M, R as N, S as R, U as b, h as E, f as j, d as A, e as O, l as q, s as w } from "./apollo-1_umpas9.js";
import { maybeData as n } from "@dmitry.olyenyov/remote-data";
import { useUnit as t } from "effector-react";
import { persist as B } from "effector-storage/local";
import { persist as G } from "effector-storage/session";
import "@apollo/client";
import "@apollo/client/dev";
import "@apollo/client/link/context";
import "@apollo/client/link/error";
import "@apollo/client/link/subscriptions";
import "effector";
import "i18next";
import "jwt-decode";
function k() {
  const s = t(o);
  return n((e) => e, null, s);
}
function I() {
  const s = t(o);
  return n((e) => e.type === "authenticated" ? e.user : null, null, s);
}
function $() {
  const s = t(o);
  return n((e) => e, null, s);
}
export {
  x as $accessToken,
  T as $accessTokenExpireTimestamp,
  h as $anonymousNickname,
  D as $isLoggedIn,
  v as $myNickname,
  o as $myUserInfo,
  F as $refreshToken,
  L as $traceId,
  S as FetchMyUserInfoDocument,
  M as LoginDocument,
  N as RefreshTokenDocument,
  R as SignUpDocument,
  b as UserFragmentDoc,
  E as eventAnonymousNicknameUpdated,
  j as eventLogout,
  A as eventTokenRefreshed,
  O as eventTraceIdReceived,
  q as loginFx,
  B as persistLS,
  G as persistSES,
  w as signupFx,
  k as useMyInfo,
  I as useMyInfoOld,
  $ as useMyNickname
};
