import { createEvent as w, createStore as u, createEffect as C, sample as a, is as v, withFactory as R } from "effector";
import { g as q } from "./apollo-1_umpas9.js";
import { delay as D, debounce as P } from "patronum";
import { ApolloError as I } from "@apollo/client";
import { format as Z } from "date-fns/format";
import { widen as G, create as M } from "ts-opaque";
import _ from "i18next";
import { StyleSheet as B } from "react-native";
import H from "react-native-media-query";
import "react";
import S from "zod";
import "@apollo/client/dev";
import "@apollo/client/link/context";
import "@apollo/client/link/error";
import "@apollo/client/link/subscriptions";
import "@dmitry.olyenyov/remote-data";
import "jwt-decode";
import "effector-react";
import "effector-storage/local";
import "effector-storage/session";
function J(e) {
  return e != null;
}
var y = "/src/lib/effector-factories/create-inifinite-loader.ts";
function ke(e, t) {
  const r = w({
    loc: {
      file: y,
      line: 18,
      column: 24
    },
    name: "eventLoadMore",
    sid: "-xyj26p:/src/lib/effector-factories/create-inifinite-loader.ts:eventLoadMore"
  }), o = u("", {
    loc: {
      file: y,
      line: 21,
      column: 18
    },
    name: "$cursor",
    sid: "-4fh6fd:/src/lib/effector-factories/create-inifinite-loader.ts:$cursor"
  }), n = C(({
    cursor: l,
    distanceFromEnd: c
  }) => q().query({
    query: e,
    variables: {
      cursor: l,
      limit: Math.max(c, 5)
    }
  }).then(t), {
    loc: {
      file: y,
      line: 23,
      column: 22
    },
    name: "fetchMoreFx",
    sid: "anm3xf:/src/lib/effector-factories/create-inifinite-loader.ts:fetchMoreFx"
  }), i = u([], {
    loc: {
      file: y,
      line: 41,
      column: 16
    },
    name: "$data",
    sid: "-2uu2i9:/src/lib/effector-factories/create-inifinite-loader.ts:$data"
  });
  return i.on(n.doneData, (l, c) => [...c.data, ...l]), o.on(n.doneData, (l, c) => c.cursor ?? null), a({
    and: [{
      clock: r,
      source: {
        $cursor: o,
        pending: n.pending
      },
      // eslint-disable-next-line sort-keys-fix/sort-keys-fix
      filter: (l) => J(l.$cursor) && !l.pending,
      // eslint-disable-next-line sort-keys-fix/sort-keys-fix
      fn: (l, c) => ({
        cursor: l.$cursor,
        distanceFromEnd: c.distanceFromEnd
      }),
      target: n
    }],
    or: {
      loc: {
        file: y,
        line: 49,
        column: 2
      },
      sid: "-r71psm:/src/lib/effector-factories/create-inifinite-loader.ts:"
    }
  }), {
    $cursor: o,
    $data: i,
    eventLoadMore: r
  };
}
var T = "/src/lib/utils/effector/postpone.ts";
const we = ({
  clock: e,
  reset: t,
  until: r
}) => {
  const o = u(null, {
    loc: {
      file: T,
      line: 21,
      column: 22
    },
    name: "$clockFired",
    sid: "-chpxf7:/src/lib/utils/effector/postpone.ts:$clockFired"
  });
  o.on(e, (i, l) => ({
    payload: l
  }));
  const n = a({
    and: [{
      clock: r,
      source: o,
      // eslint-disable-next-line sort-keys-fix/sort-keys-fix
      filter: (i, l) => i != null && l,
      fn: (i) => i.payload
    }],
    or: {
      loc: {
        file: T,
        line: 24,
        column: 25
      },
      name: "postponedEvent",
      sid: "-l3dp35:/src/lib/utils/effector/postpone.ts:postponedEvent"
    }
  });
  return o.reset(n), t != null && o.reset(t), n.map(
    (i) => i
    /* sample above insures that this event's payload is not null */
  );
};
var k = "/src/lib/utils/effector/retry-effect.ts";
const _e = ({
  effect: e,
  maxAttempts: t,
  timeout: r,
  target: o = e
}) => {
  const n = v.unit(t) ? t : u(t, {
    loc: {
      file: k,
      line: 25,
      column: 6
    },
    name: "$maxAttempts",
    sid: "odrpod:/src/lib/utils/effector/retry-effect.ts:$maxAttempts"
  });
  n.reset(e.done).on(e.fail, (i) => Math.max(0, i - 1)), a({
    and: [{
      clock: R({
        sid: "-9kgod9:/src/lib/utils/effector/retry-effect.ts:clock",
        fn: () => D({
          source: e.fail,
          timeout: r
        }),
        name: "clock",
        method: "delay",
        loc: {
          file: k,
          line: 32,
          column: 11
        }
      }),
      source: n,
      // eslint-disable-next-line sort-keys-fix/sort-keys-fix
      filter: (i) => i > 0,
      fn: (i, l) => l.params,
      target: o
    }],
    or: {
      loc: {
        file: k,
        line: 31,
        column: 2
      },
      sid: "u6rph2:/src/lib/utils/effector/retry-effect.ts:"
    }
  });
};
var $ = "/src/lib/utils/effector/to-store.ts";
function j(e) {
  if (v.store(e, {
    loc: {
      file: $,
      line: 7,
      column: 6
    },
    sid: "uknrle:/src/lib/utils/effector/to-store.ts:"
  }))
    return e;
  if (typeof e == "number") {
    if (e < 0 || !Number.isFinite(e))
      throw new Error(`timeout must be positive number or zero. Received: "${e}"`);
    return u(e, {
      and: {
        name: "$timeout"
      },
      loc: {
        file: $,
        line: 16,
        column: 11
      },
      sid: "xma4ro:/src/lib/utils/effector/to-store.ts:"
    });
  }
  throw new TypeError(`timeout parameter should be number or Store. "${typeof e}" was passed`);
}
function F(e, t) {
  return v.store(e, {
    loc: {
      file: $,
      line: 28,
      column: 6
    },
    sid: "m7knk1:/src/lib/utils/effector/to-store.ts:"
  }) ? e : typeof e == "boolean" ? u(e, {
    and: {
      name: t
    },
    loc: {
      file: $,
      line: 32,
      column: 11
    },
    sid: "-9y7uc6:/src/lib/utils/effector/to-store.ts:"
  }) : u(!1, {
    and: {
      name: t
    },
    loc: {
      file: $,
      line: 34,
      column: 11
    },
    sid: "-904ljs:/src/lib/utils/effector/to-store.ts:"
  });
}
var s = "/src/lib/utils/effector/throttle2.ts";
function xe({
  source: e,
  timeout: t,
  leading: r,
  trailing: o = !0,
  target: n = w({
    loc: {
      file: s,
      line: 44,
      column: 11
    },
    name: "target",
    sid: "-hrznrz:/src/lib/utils/effector/throttle2.ts:target"
  })
}) {
  if (!v.unit(e))
    throw new TypeError("source must be unit from effector");
  const i = j(t), l = C((m) => new Promise((f) => setTimeout(f, m)), {
    loc: {
      file: s,
      line: 58,
      column: 18
    },
    name: "timerFx",
    sid: "-jxvdsz:/src/lib/utils/effector/throttle2.ts:timerFx"
  }), c = u(null, {
    and: {
      serialize: "ignore"
    },
    loc: {
      file: s,
      line: 63,
      column: 19
    },
    name: "$payload",
    sid: "-qwcq7f:/src/lib/utils/effector/throttle2.ts:$payload"
  }).on(e, (m, f) => f), p = w({
    loc: {
      file: s,
      line: 67,
      column: 22
    },
    name: "triggerTick",
    sid: "-5hb7cs:/src/lib/utils/effector/throttle2.ts:triggerTick"
  }), E = F(r, "$leading"), d = F(o, "$trailing"), b = u(!0, {
    loc: {
      file: s,
      line: 72,
      column: 23
    },
    name: "$neverCalled",
    sid: "hrzd5l:/src/lib/utils/effector/throttle2.ts:$neverCalled"
  }).on(n, () => !1), h = u(0, {
    loc: {
      file: s,
      line: 73,
      column: 22
    },
    name: "$lastCalled",
    sid: "-hpjbyt:/src/lib/utils/effector/throttle2.ts:$lastCalled"
  }).on(n, () => Date.now()), z = u(!0, {
    and: {
      serialize: "ignore"
    },
    loc: {
      file: s,
      line: 75,
      column: 19
    },
    name: "$canTick",
    sid: "-f7cqr1:/src/lib/utils/effector/throttle2.ts:$canTick"
  }).on(p, () => !1).on(n, () => !0);
  return a({
    and: [{
      clock: e,
      filter: z,
      target: p
    }],
    or: {
      loc: {
        file: s,
        line: 79,
        column: 2
      },
      sid: "f2bkpm:/src/lib/utils/effector/throttle2.ts:"
    }
  }), a({
    and: [{
      source: i,
      clock: p,
      target: l
    }],
    or: {
      loc: {
        file: s,
        line: 85,
        column: 2
      },
      sid: "fh60qd:/src/lib/utils/effector/throttle2.ts:"
    }
  }), a({
    and: [{
      clock: c,
      source: [E, b],
      filter: ([m, f]) => m && f,
      target: n
    }],
    or: {
      loc: {
        file: s,
        line: 91,
        column: 2
      },
      sid: "fw0gr4:/src/lib/utils/effector/throttle2.ts:"
    }
  }), a({
    and: [{
      source: [d, c],
      clock: l.done,
      filter: ([m]) => m,
      fn: ([m, f]) => f,
      target: n
    }],
    or: {
      loc: {
        file: s,
        line: 98,
        column: 2
      },
      sid: "fzv0wn:/src/lib/utils/effector/throttle2.ts:"
    }
  }), a({
    and: [{
      clock: c,
      source: {
        trailing: d,
        lastCalled: h,
        timeout: i
      },
      filter: ({
        trailing: m,
        lastCalled: f,
        timeout: U
      }) => !m && f + U < Date.now(),
      fn: (m, f) => f,
      target: n
    }],
    or: {
      loc: {
        file: s,
        line: 105,
        column: 2
      },
      sid: "jnro64:/src/lib/utils/effector/throttle2.ts:"
    }
  }), n;
}
var g = "/src/lib/utils/effector/wait-for.ts";
function Se({
  filter: e,
  source: t,
  target: r,
  timeout: o
}) {
  const n = a({
    and: [{
      source: t,
      // eslint-disable-next-line sort-keys-fix/sort-keys-fix
      filter: e
    }],
    or: {
      loc: {
        file: g,
        line: 41,
        column: 24
      },
      name: "filteredEvent",
      sid: "pxyyg9:/src/lib/utils/effector/wait-for.ts:filteredEvent"
    }
  }), i = R({
    sid: "bt7w20:/src/lib/utils/effector/wait-for.ts:debouncedEvent",
    fn: () => P({
      source: n,
      timeout: j(o)
    }),
    name: "debouncedEvent",
    method: "debounce",
    loc: {
      file: g,
      line: 46,
      column: 35
    }
  });
  if (r == null)
    return a({
      and: [{
        clock: i,
        source: t,
        //  eslint-disable-next-line sort-keys-fix/sort-keys-fix
        filter: e,
        fn: () => {
        }
      }],
      or: {
        loc: {
          file: g,
          line: 51,
          column: 11
        },
        sid: "-9k49w1:/src/lib/utils/effector/wait-for.ts:"
      }
    });
  a({
    and: [{
      clock: i,
      source: t,
      //  eslint-disable-next-line sort-keys-fix/sort-keys-fix
      filter: e,
      fn: () => {
      },
      target: r
    }],
    or: {
      loc: {
        file: g,
        line: 59,
        column: 4
      },
      sid: "-yk0im9:/src/lib/utils/effector/wait-for.ts:"
    }
  });
}
const Q = (e, t) => Array.isArray(e) ? e.map((r) => Q(r, t)).join(",") : e instanceof I ? e.graphQLErrors.map((r) => {
  var o;
  return ((o = t == null ? void 0 : t[r.extensions.code]) == null ? void 0 : o[r.extensions.details]) ?? r.message;
}).join(",") : e instanceof Error ? e.message : String(e);
var x = function() {
  return x = Object.assign || function(t) {
    for (var r, o = 1, n = arguments.length; o < n; o++) {
      r = arguments[o];
      for (var i in r)
        Object.prototype.hasOwnProperty.call(r, i) && (t[i] = r[i]);
    }
    return t;
  }, x.apply(this, arguments);
};
function X(e) {
  return e.toLowerCase();
}
var Y = [/([a-z0-9])([A-Z])/g, /([A-Z])([A-Z][a-z])/g], K = /[^A-Z0-9]+/gi;
function V(e, t) {
  t === void 0 && (t = {});
  for (var r = t.splitRegexp, o = r === void 0 ? Y : r, n = t.stripRegexp, i = n === void 0 ? K : n, l = t.transform, c = l === void 0 ? X : l, p = t.delimiter, E = p === void 0 ? " " : p, d = N(N(e, o, "$1\0$2"), i, "\0"), b = 0, h = d.length; d.charAt(b) === "\0"; )
    b++;
  for (; d.charAt(h - 1) === "\0"; )
    h--;
  return d.slice(b, h).split("\0").map(c).join(E);
}
function N(e, t, r) {
  return t instanceof RegExp ? e.replace(t, r) : t.reduce(function(o, n) {
    return o.replace(n, r);
  }, e);
}
function W(e) {
  return e.charAt(0).toUpperCase() + e.substr(1);
}
function ee(e, t) {
  var r = e.toLowerCase();
  return t === 0 ? W(r) : r;
}
function te(e, t) {
  return t === void 0 && (t = {}), V(e, x({ delimiter: " ", transform: ee }, t));
}
const Te = (e) => te(e);
function Fe(e, t) {
  return Object.values(e).filter((r) => !r.toLowerCase().includes("unknown")).sort(t);
}
function Ne(e, t) {
  const r = Object.entries(e).find(([o, n]) => n === t);
  return r ? r[1] : void 0;
}
const Ae = (e) => {
  var t;
  return e.vanity_name != null && e.vanity_name.trim() !== "" ? e.vanity_name : ((t = e.id.match(/\d{1,3}/g)) == null ? void 0 : t.join("-")) ?? "-";
};
function A(e) {
  return e == null ? e : new Date(G(e));
}
function Oe(e) {
  return M(e.toISOString());
}
const O = (e) => e != null ? Z(e, "HH:mm") : "", Ce = (e, t) => {
  const r = O(A(e)), o = O(A(t));
  return `${r} — ${o}`;
}, L = (e) => e == null ? _.t("ROOM.ANONYMOUS_USER") : e.nickname != null && e.nickname !== "" ? e.nickname : e.username != null && e.username !== "" ? e.username : _.t("ROOM.ANONYMOUS_USER"), Re = (e) => `${L(e)} ${_.t("ROOM.NICKNAME_ME")}`, Me = (e) => {
  const r = L(e).split(" ");
  return r.length > 1 ? r[0].slice(0, 1) + r[1].slice(0, 1) : r[0].slice(0, 2);
};
function je(e, t) {
  if (e == null)
    return;
  const r = e.split("?"), o = e.includes("gravatar.com") && r.length > 1 ? [r[0], "?", `d=identicon&s=${t * 2}`] : e;
  return {
    uri: e.includes("/media/") ? `${e}?size=${1 << Math.ceil(Math.log2(t * 2))}` : Array.isArray(o) ? o.join("") : e
  };
}
function Le(e) {
  const {
    fullStyles: t,
    ids: r,
    styles: o
  } = H.create(e), n = B.create(o);
  return {
    fullStyles: t,
    ids: r,
    styles: n
  };
}
const ze = (e) => M(JSON.stringify(e)), Ue = (e, t) => S.preprocess((r) => e.safeParse(r).success ? r : t, S.custom((r) => !0));
export {
  ke as createNoParamsInfiniteLoaderModel,
  Oe as dateToOpaqueTime,
  Fe as enumToList,
  Me as formatAvatarShortName,
  Q as formatBackendError,
  Te as formatGenericEnumValue,
  O as formatMaybeEmptyTime,
  Ce as formatMaybeEmptyTimeRange,
  Re as formatMyUserName,
  Ae as formatRoomIdAsText,
  L as formatUserName,
  J as isNotNull,
  Le as makeStyleSheet,
  je as makeThumbnails,
  ze as objectToOpaqueJSON,
  A as opaqueTimeToDate,
  we as postpone,
  _e as retryEffect,
  Ne as stringToEnum,
  xe as throttle,
  F as toStoreBoolean,
  j as toStoreNumber,
  Se as waitFor,
  Ue as withFallback
};
